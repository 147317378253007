import { Button, Modal, Space, notification } from 'antd';
import React, { FC } from 'react';
import { ReactComponent as AdmitIcon } from 'assets/icons/admit.svg';
import './contacts-modal.scss';
import EiqPhone from '../../../../common/components/info-block/contacts/phone/phone-field';
import { IModal } from '../../../../common/models/model.interface';
import eiqSecureStore from '../../stores/eiq-secure.store';
import { getPhoneComparer } from 'features/eiq-secure/heplers/phone.helper';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { ownershipTypeStatusMap } from 'common/constans/common.constants';

const ContactsModal: FC<IModal<any, any>> = ({ isOpen, onClose, title }) => {
  const hideModal = (...rest: any) => {
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };

  const handleClickDial = () => {
    console.log('dial');
  };

  const getPhones = (): any[] => {
    const occupantsPhones = eiqSecureStore.selectedAddressOccupants
      ?.filter(
        (i) =>
          i.ownershipType !== ResidentTypesEnum.NonResident &&
          i.ownershipType !== ResidentTypesEnum.Manager &&
          i.ownershipType !== ResidentTypesEnum.Employee &&
          i.ownershipType !== ResidentTypesEnum.ClubMember,
      )
      .flatMap((o) => {
        const p = o.phones.slice().sort(getPhoneComparer());
        return p.map((i) => ({
          label: o.name,
          number: i.number,
          order: i.order,
          ownershipType: o.ownershipType,
          hostOwnershipType: o.host?.ownershipType,
        }));
      });

    let phones = eiqSecureStore.residentDetails?.phones ?? [];
    phones = phones.slice().sort(getPhoneComparer());

    const selectedAddressOwnership = eiqSecureStore.getSelectedResidentAddress()?.ownershipType;

    if (
      occupantsPhones &&
      selectedAddressOwnership !== ResidentTypesEnum.NonResident &&
      selectedAddressOwnership !== ResidentTypesEnum.Employee &&
      selectedAddressOwnership !== ResidentTypesEnum.ClubMember &&
      selectedAddressOwnership !== ResidentTypesEnum.Manager
    ) {
      const set = new Set();
      const result = [...phones, ...occupantsPhones].filter((obj) => !set.has(obj.number) && set.add(obj.number));
      return result;
    }

    return phones;
  };

  return (
    <Modal centered title={title} width={362} open={isOpen} wrapClassName="custom-modal custom-modal--so" closable={false}>
      <Space className="full-width mb-12 dial-phones" size={12} direction="vertical">
        {getPhones().map((phone: any, index: number) => {
          const labels = [{ value: phone.label, class: phone.label.toLocaleLowerCase() }];
          if (phone.ownershipType) {
            const className =
              phone.ownershipType === ResidentTypesEnum.Renter || phone.ownershipType === ResidentTypesEnum.Owner
                ? phone.ownershipType
                : phone.hostOwnershipType;
            labels.push({
              value: ownershipTypeStatusMap[phone.ownershipType as ResidentTypesEnum],
              class: className?.toLocaleLowerCase() ?? '',
            });
          }
          return (
            <a className="phone-container" href={`tel:${phone.number}`} key={`phone-${index}`}>
              <EiqPhone phone={phone.number} labels={labels} />
            </a>
          );
        })}
      </Space>
      <Space className="footer">
        <Button type="link" onClick={hideModal}>
          Cancel
        </Button>
        {/*<Button type="primary" className="primary-btn" icon={<AdmitIcon />} onClick={handleClickDial}>*/}
        {/*  Dial*/}
        {/*</Button>*/}
      </Space>
    </Modal>
  );
};

export default React.memo(ContactsModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
