import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: ReactNode;
  selector: string;
}

export const Portal: React.FC<PortalProps> = ({ children, selector }) => {
  const el = document.querySelector(selector);
  if (!el) throw new Error('Portal selector not found');

  return ReactDOM.createPortal(children, el);
};

export default Portal;
