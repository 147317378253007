import { ServerClient } from 'common/utils/http/http-clients';
import { IIncidentReportRequest, IIncidentReportRead } from './incident-report.types';
import { trackPromise } from 'react-promise-tracker';

class IncidentService {
  async getIncidents(pageLimit: any, filter: string | null | undefined, sort: string | null | undefined): Promise<IIncidentReportRead> {
    const res = await ServerClient.get<IIncidentReportRead>('/incidents', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  createIncident(request: IIncidentReportRequest): Promise<number> {
    return trackPromise(ServerClient.post<number>('/incidents', request).then((res) => res?.data));
  }

  getIncidentById(id: number): Promise<IIncidentReportRead> {
    return trackPromise(ServerClient.get<IIncidentReportRead>(`/incidents/${id}`).then((res) => res?.data));
  }

  updateIncident(id: number, request: IIncidentReportRequest): Promise<IIncidentReportRead> {
    return trackPromise(
      ServerClient.put(`/incidents/${id}`, request).then((res) => {
        return res?.data;
      }),
    );
  }

  deleteIncident(id: number): Promise<void> {
    return trackPromise(ServerClient.delete(`/incidents/${id}`));
  }
}

export const incidentReportService = new IncidentService();
export default incidentReportService;
