import useIncidentForm from 'common/hooks/use-incident-form/use-incident-form.hook';
import { forwardRef } from 'react';
import { IIncidentReportingPersonProps } from './incident-reporting-person.types';
import IncidentReportingPersonView from './incident-reporting-person-view/incident-reporting-person-view';
import IncidentReportingPersonEdit from './incident-reporting-person-edit/incident-reporting-person-edit';

export const IncidentReportingPerson = forwardRef(({ onSave, incident }: IIncidentReportingPersonProps, ref) => {
  const { mode, form, switchToEditMode, switchToViewMode, onSubmit } = useIncidentForm({ initialValues: incident, onSave, ref });

  if (mode === 'view') {
    return incident ? <IncidentReportingPersonView onEdit={switchToEditMode} incident={incident} /> : <></>;
  } else {
    return <IncidentReportingPersonEdit form={form} onSave={onSubmit} onCancel={switchToViewMode} incident={incident} />;
  }
});
