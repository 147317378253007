const phoneNumberLength = 10;

export const phoneValidationRule = (props: any) => ({
  validator: (_: any, value: string) => {
    const phone = value?.replace(/\D/g, '');

    if (phone && phone.length !== phoneNumberLength) {
      return Promise.reject('Enter a valid phone number!');
    }
    return Promise.resolve();
  },
});
