import { Checkbox, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTableContext } from 'common/components/table/table-context';
import { ICellRendererProps } from 'common/components/table/table.types';
import { occupantsColumns } from 'common/list-configs/occupants.config';
import { IAddressOccupant } from 'common/services/guest-service/guest.service.types';

const { name, ownershipType } = occupantsColumns();

export const residentDeleteModalOccupantsInitialColumns = [
  {
    key: 'selectNewOwner',
    width: 50,
    resizable: false,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IAddressOccupant>) => {
      const CustomComponent = () => {
        const tableContext = useTableContext();
        const selectedRow: IAddressOccupant = tableContext.getSelectedRow();

        return (
          <Checkbox
            checked={selectedRow?.id === cell.rowData?.id}
            onChange={(e: CheckboxChangeEvent) => {
              if (e.target.checked) {
                tableContext.setSelectedRow(cell.rowData);
              } else {
                tableContext.setSelectedRow(null);
              }
            }}
          />
        );
      };

      return <CustomComponent />;
    },
  },
  { ...name, width: 380 },
  { ...ownershipType, width: 380 },
];
