import Axios, { CustomParamsSerializer } from 'axios';
import loginService from 'features/login/service/login.service';
import qs from 'qs';
import { notification } from '../notification';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'common/stores/user-auth/user-auth.constants';
import { IServerError, IServerErrorMessage } from './http-client.types';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import _ from 'lodash';

const toLocalDate = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
};

const paramsSerializer: CustomParamsSerializer = (params, options) => {
  return qs.stringify(params, {
    serializeDate: toLocalDate,
  });
};

export class HttpClientBuilder {
  create(baseURL: string) {
    const client = Axios.create({
      baseURL: baseURL,
      paramsSerializer: paramsSerializer,
    });

    client.interceptors.request.use((config) => {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (accessToken && config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });

    client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const status = error.response ? error.response.status : null;
        const originalRequest = error.config;
        const errorMassage: IServerErrorMessage = error?.response?.data;

        if (errorMassage?.errorCode === 'InvalidRefreshToken') {
          notification.destroy();
          notification.error({ message: 'Your session has expired, please login' });
          return Promise.reject(error);
        }
        switch (status) {
          case 401:
            if (errorMassage.errorCode === 'InvalidVerificationCode') {
              notification.error({ message: 'Wrong verification code. Please try again or send it again' });
              return;
            }
            if (errorMassage.errorCode === '' || errorMassage.errorCode) {
              notification.error({ message: errorMassage.message });
            }
            if (!originalRequest._retry || !originalRequest._refreshRetry) {
              originalRequest._retry = true;
              const refreshToken: string | null = localStorage.getItem(REFRESH_TOKEN_KEY);

              if (!refreshToken || refreshToken === 'undefined') {
                return userAuthStore.logOut();
              }

              return loginService.refreshToken(refreshToken).then(
                (response) => {
                  userAuthStore.setAccessToken(response.refreshToken);
                  client.defaults.headers.common.Authorization = `Bearer ${response.refreshToken}`;

                  return client.request(originalRequest);
                },
                (error) => {
                  if (!originalRequest._refreshRetry) {
                    originalRequest._retry = false;
                    originalRequest._refreshRetry = true;
                    client.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`;

                    return client.request(originalRequest);
                  } else {
                    userAuthStore.logOut();
                    return Promise.reject(error);
                  }
                },
              );
            } else {
              userAuthStore.logOut();
            }
            break;
          case 500:
            const errorMessage = error.response.data.errorMessage || 'Some error occurred';
            notification.error({ message: errorMessage });
            return Promise.reject(error);
          case 422:
            if (errorMassage.errorCode === '' || errorMassage.errorCode) {
              notification.error({ message: errorMassage.message });
              return Promise.reject(error);
            }

            const serverError = error.response.data as IServerError;

            if (serverError?.details) {
              const fieldErrors = serverError.details.map((error) => {
                const pathArray = _.toPath(error.path);
                const correctedPath = pathArray.map((item: any) => (isNaN(Number(item)) ? item : Number(item)));
                return {
                  name: correctedPath,
                  errors: [error.summary],
                };
              });

              return Promise.reject({
                ...serverError,
                details: fieldErrors,
              });
            } else {
              console.log('Unresolved error', error);
              return Promise.reject(error?.response?.data);
            }

          case 400:
            if (errorMassage.errorCode === '' || errorMassage.errorCode) {
              notification.error({ message: errorMassage.message });
            } else {
              return Promise.reject(error);
            }

            break;

          case 409:
            if (errorMassage.message) {
              notification.error({ message: errorMassage.message });
              return Promise.reject(error);
            } else {
              return Promise.reject(error);
            }

          case 403:
            if (errorMassage.message) {
              notification.error({ message: errorMassage.message });
              return Promise.reject(error);
            } else {
              return Promise.reject(error);
            }

          case 404:
            return Promise.reject(error);

          default:
            return Promise.reject(error?.response?.data);
        }
      },
    );

    return client;
  }
}
