import { DeleteVehicleReasonTypesEnum } from 'common/enums/delete-vehicle-reason-types.enum';

export const mapDeleteVehicleReason = (type: DeleteVehicleReasonTypesEnum, isAdmin: boolean) => {
  const stolen = 'The vehicle was stolen';

  const deleteVehicleReasonTypeMap: Record<DeleteVehicleReasonTypesEnum, string> = {
    [DeleteVehicleReasonTypesEnum.Sold]: 'The vehicle has been sold',
    [DeleteVehicleReasonTypesEnum.IncorrectAddress]: 'The vehicle was assigned to the incorrect address',
    [DeleteVehicleReasonTypesEnum.Stolen]: isAdmin ? stolen : `${stolen} (Your transponder will be disabled and security will be notified)`,
    [DeleteVehicleReasonTypesEnum.Other]: 'Other',
  };

  return deleteVehicleReasonTypeMap[type];
};

export const getVehicleDeleteReasonsOptions = (isAdmin: boolean) => {
  return [
    { value: DeleteVehicleReasonTypesEnum.Sold, label: mapDeleteVehicleReason(DeleteVehicleReasonTypesEnum.Sold, isAdmin) },
    {
      value: DeleteVehicleReasonTypesEnum.IncorrectAddress,
      label: mapDeleteVehicleReason(DeleteVehicleReasonTypesEnum.IncorrectAddress, isAdmin),
    },
    { value: DeleteVehicleReasonTypesEnum.Stolen, label: mapDeleteVehicleReason(DeleteVehicleReasonTypesEnum.Stolen, isAdmin) },
    { value: DeleteVehicleReasonTypesEnum.Other, label: mapDeleteVehicleReason(DeleteVehicleReasonTypesEnum.Other, isAdmin) },
  ];
};
