import { makeAutoObservable } from 'mobx';
import { IGuestRead, IResidentDetails, IResidentRead } from '../../../common/services/residents-service/residents.service.types';
import { AdmissionType } from '../models/admit.type';
import { IAddress } from '../../../common/services/street-service/street.service.types';
import { IAddressOccupant } from 'common/services/guest-service/guest.service.types';

class EiqSecureStore {
  selectedResident: IResidentRead | null;
  selectedAddressId: number | null;
  selectedGuest: IGuestRead | null;
  residentDetails: IResidentDetails | null;
  admitFlow: AdmissionType;
  selectedVacantAddress: IAddress | null;
  isAdmitFlowProcessed: boolean;
  selectedAddressOccupants: IAddressOccupant[] | null;

  constructor() {
    makeAutoObservable(this);
    this.selectedResident = null;
    this.selectedAddressId = null;
    this.selectedGuest = null;
    this.residentDetails = null;
    this.admitFlow = null;
    this.selectedVacantAddress = null;
    this.isAdmitFlowProcessed = false;
    this.selectedAddressOccupants = null;
  }

  setSelectedResident(resident: IResidentRead | null) {
    this.selectedResident = resident;
  }

  setSelectedAddressId(selectedAddressId: number | null) {
    this.selectedAddressId = selectedAddressId;
  }

  setSelectedVacantAddress(selectedVacantAddress: IAddress) {
    this.selectedVacantAddress = selectedVacantAddress;
  }

  setResidentDetails(residentAddresses: IResidentDetails | null) {
    this.residentDetails = residentAddresses;
  }

  setAdmitFlow(admitType: AdmissionType) {
    this.admitFlow = admitType;
    this.isAdmitFlowProcessed = false;
  }

  setIsAdmitFlowProcessed() {
    this.isAdmitFlowProcessed = true;
  }
  setSelectedGuest(selectedGuest: IGuestRead | null) {
    this.selectedGuest = selectedGuest;
  }

  getSelectedAddress() {
    return eiqSecureStore.residentDetails?.addresses.find((address) => address?.address?.id === eiqSecureStore.selectedAddressId)?.address;
  }

  setSelectedAddressOccupants(occupants: IAddressOccupant[]) {
    this.selectedAddressOccupants = occupants;
  }

  getSelectedResidentAddress() {
    return eiqSecureStore.residentDetails?.addresses.find((address) => address?.address?.id === eiqSecureStore.selectedAddressId);
  }
}

const eiqSecureStore = new EiqSecureStore();
export default eiqSecureStore;
