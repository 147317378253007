import { ICellRendererProps } from 'common/components/table/table.types';
import { IAddressOccupant } from 'common/services/guest-service/guest.service.types';
import { Tag } from 'antd';
import residentStore from 'features/eiq-manage/stores/resident.store';

export const occupantsEIQManageColumns = () => ({
  isSharedGuestList: {
    key: 'sharedResidentAddressGuests',
    title: 'Guest list with occupants',
    dataKey: 'sharedResidentAddressData',
    width: 274,
    sortable: false,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IAddressOccupant>) => {
      const isShared = cell.rowData.sharedResidentAddressData?.some(
        (i) => i.sharedResidentAddressId === residentStore.selectedResidentAddress?.id && i.isGuestShared,
      );
      return (
        <Tag className="eiq-tag" color={guestListOccupantsMap[isShared + '']}>
          {isShared ? 'Sharing' : 'Not Sharing'}
        </Tag>
      );
    },
  },
  isSharedVehiclesList: {
    key: 'sharedResidentAddressVehicles',
    title: 'Vehicle list with occupants',
    dataKey: 'sharedResidentAddressData',
    width: 274,
    sortable: false,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IAddressOccupant>) => {
      const isShared = cell.rowData.sharedResidentAddressData?.some(
        (i) => i.sharedResidentAddressId === residentStore.selectedResidentAddress?.id && i.isVehicleShared && i.isVehicleShared,
      );
      return (
        <Tag className="eiq-tag" color={guestListOccupantsMap[isShared + '']}>
          {isShared ? 'Sharing' : 'Not Sharing'}
        </Tag>
      );
    },
  },
});

const guestListOccupantsMap: Record<string, string> = {
  true: '#EECAFF',
  false: '#D1E3FF',
};
