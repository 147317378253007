const setSearchFilter = (id: string, value: string) => {
  const element = document.getElementById(id);

  if (!element) {
    return;
  }

  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')!.set;
  nativeInputValueSetter!.call(element, value);

  const inputEvent = new Event('input', { bubbles: true });
  element.dispatchEvent(inputEvent);
};

export const setResidentListSearchFilter = (value: string) => {
  setSearchFilter(EIQ_SECURE_RESIDENT_LIST_SEARCH_FILTER_ID, value);
};

export const setVacantAddressSearchFilter = (value: string) => {
  setSearchFilter(EIQ_SECURE_VACANT_ADDRESS_SEARCH_FILTER_ID, value);
};

export const EIQ_SECURE_RESIDENT_LIST_SEARCH_FILTER_ID = 'eiq-secure-resident-list-search-filter-id';
export const EIQ_SECURE_VACANT_ADDRESS_SEARCH_FILTER_ID = 'eiq-secure-vacant-address-search-filter-id';
