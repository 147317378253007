import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { IPrivateRoute } from './private-route.type';
import { observer } from 'mobx-react-lite';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { notification } from 'common/utils/notification';

//const TOAST_ID = 'TOAST_USERS_FORBIDDEN';

export const PrivateRoute = observer(
  ({ userTypes, roles, permissions, element, children, isAvailable = true, navigateTo }: IPrivateRoute): any => {
    const location = useLocation();

    useEffect(() => {
      if (!hasRequiredRoles && !hasRequiredPermissions && !hasRequiredUserType) {
        notification.error({
          message: 'Access Denied',
        });
      }
    }, []);

    if (!isAvailable) {
      return null;
    }

    const hasRequiredUserType = userAuthStore.userType ? userTypes?.includes(userAuthStore.userType) : false;
    const hasRequiredRoles = false;
    const hasRequiredPermissions = userAuthStore.permissions
      ? permissions?.some((permission) => userAuthStore.permissions?.includes(permission))
      : false;

    if (!userAuthStore.isLogin) {
      // TODO extend depend on UserType
      //userAuthStore.setPrevPath(location.pathname);
      return <Navigate to={navigateTo ?? '/login'} />;
    }

    if (hasRequiredUserType || hasRequiredRoles || hasRequiredPermissions) {
      return element || children ? element || children : <Outlet />;
    } else {
      return null;
    }
  },
);
