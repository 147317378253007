import React, { FC, useEffect } from 'react';
import { Button, Form, Modal, Space, notification } from 'antd';
import { IModal } from 'common/models/model.interface';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import RadioGroup from 'common/components/form-elements/radio-group/radio-group';
import { mapDeleteVehicleReason, getVehicleDeleteReasonsOptions } from 'common/constans/vehicle-delete-reason.options.constants';
import styles from './delete-vehicle-modal.module.scss';
import { IConfirmDeleteVehicleProps } from './delete-vehicle-modal.types';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import { IDeleteVehicle } from 'common/services/vehicle-service/vehicle.service.types';
import { DeleteVehicleReasonTypesEnum } from 'common/enums/delete-vehicle-reason-types.enum';

const initValues = {
  reason: getVehicleDeleteReasonsOptions(true)[0].value,
};

const DeleteVehicleModal: FC<IModal<IConfirmDeleteVehicleProps, any>> = ({ isOpen, onClose, title, initData }) => {
  const [form] = Form.useForm();

  const hideModal = (isSuccess: boolean) => {
    notification.destroy();
    if (onClose) {
      onClose(isSuccess);
    }
  };

  const onFinish = async (values: any) => {
    if (initData) {
      const { residentId, addressId, vehicle } = initData;
      const data: IDeleteVehicle = {
        reason: mapDeleteVehicleReason(values.reason as DeleteVehicleReasonTypesEnum, true),
        reasonType: values.reason,
      };
      await vehicleService.deleteVehicle(residentId, addressId, vehicle.id, data);
      hideModal(true);
    }
  };

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initValues);
    }
  }, [isOpen, form, initData]);

  return (
    <Modal centered title={title} width={350} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} initialValues={initValues} layout="vertical">
        <Space className={styles.modalBody}>
          <span>
            <span>Are you sure you want to delete </span>
            <span className={styles.name}>{`${initData?.vehicle?.make} ${initData?.vehicle?.model}`}</span>
            <span> from a cars list? If so, please choose the reason:</span>
          </span>
        </Space>
        <Space>
          <Form.Item name="reason" label="Reason">
            <RadioGroup options={getVehicleDeleteReasonsOptions(true)} />
          </Form.Item>
        </Space>
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className={styles.button}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className={styles.button} icon={<DeleteIcon stroke="#F6F8FA" />}>
            Delete
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(DeleteVehicleModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
