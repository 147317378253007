import TableContextProvider, { useTableContext, useTableControls } from '../../../../../../../common/components/table/table-context';
import { Button, Col, Row, Space } from 'antd';
import { SearchFilter } from '../../../../../../../common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from '../../../../../../../common/enums/filter-types.enum';
import TableBase from '../../../../../../../common/components/table/table-base';
import { useEffect, useRef } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import residentStore from '../../../../../stores/resident.store';
import { occupantsInitialColumns } from './occupants.config';
import { observer } from 'mobx-react-lite';
import addressesService from '../../../../../../../common/services/addresses-service/addresses.service';
import { useReactToPrint } from 'react-to-print';
import EiqPrint from '../print/eiq-print';
import { occupantPrintColumns } from '../print/eiq-print.config';
import { useModal } from '../../../../../../../common/hooks/use-modal/use-modal.hook';
import EditOccupantModal from './edit-occupant-modal/edit-occupant-modal';
import { IAddressOccupant } from '../../../../../../../common/services/guest-service/guest.service.types';
import { IOccupantRead } from 'common/services/residents-service/residents.service.types';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { isOccupant, isHost } from 'common/helpers/resident-address.helper';
import { PlusOutlined } from '@ant-design/icons';
import ResidentAddEditModal from '../../resident-add-edit-modal/resident-add-edit-modal';

export const Occupants = observer(() => {
  const context = useTableContext();

  const getOccupants = () => {
    const addressId = residentStore.selectedResidentAddress?.address.id;
    if (addressId) {
      addressesService
        .getOccupantsByAddressId(addressId!)
        .then((res) => {
          residentStore.setOccupantsByAddress(res);
          const selectedRow: IAddressOccupant = tableControls.getSelectedRow();
          const occupant = res.find((i) => i.residentId === selectedRow?.residentId);
          if (occupant) {
            tableControls.updateSelectedRow({ ...selectedRow, ...occupant });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      residentStore.setOccupantsByAddress(null);
    }
  };

  useEffect(() => {
    getOccupants();
  }, [residentStore.selectedResidentAddress]);

  const tableControls = useTableControls({
    clientSide: true,
    data: residentStore.occupants,
    initialColumns: occupantsInitialColumns,
  });

  const addResidentModal = useModal({
    onBeforeShow: (setModalInitData) => {
      setModalInitData(residentStore.selectedResidentAddress);
    },
    onClose: (isSuccess?: boolean) => {
      if (isSuccess) {
        context.refetchData();
      }
    },
  });

  const editOccupantModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      if (!residentStore.selectedResidentAddress) {
        console.error(`Resident is not selected`);
        return;
      }

      const initData: IAddressOccupant = {
        ...selectedRow,
        host: { ...selectedRow.host },
      };
      setModalInitData(initData);
    },
    onClose: (isSuccessSaved) => {
      if (isSuccessSaved) {
        getOccupants();
      }
    },
  });

  const printRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const isEditDisabled = () => {
    const gestHolder: IOccupantRead = tableControls.getSelectedRow();
    const gestHost = residentStore.occupants?.find((i) => i.residentId === residentStore.residentId);

    if (!gestHolder || !gestHost) {
      return true;
    }
    if (gestHolder.residentId === gestHost.residentId) {
      return true;
    }
    if (
      (gestHost.ownershipType === ResidentTypesEnum.NonResident ||
        gestHost.ownershipType === ResidentTypesEnum.Manager ||
        gestHost.ownershipType === ResidentTypesEnum.Employee ||
        gestHost.ownershipType === ResidentTypesEnum.ClubMember) &&
      gestHolder.ownershipType === ResidentTypesEnum.Owner
    ) {
      return false;
    }
    if (
      gestHost.ownershipType === ResidentTypesEnum.Renter &&
      (gestHolder.host?.ownershipType === ResidentTypesEnum.Renter || gestHolder.ownershipType === ResidentTypesEnum.Renter)
    ) {
      return false;
    }
    if (gestHost.ownershipType === ResidentTypesEnum.Owner) {
      if (
        (gestHolder.ownershipType !== ResidentTypesEnum.Renter && gestHolder.host?.ownershipType === ResidentTypesEnum.Owner) ||
        gestHolder.ownershipType === ResidentTypesEnum.NonResident ||
        gestHolder.ownershipType === ResidentTypesEnum.Employee ||
        gestHolder.ownershipType === ResidentTypesEnum.ClubMember ||
        gestHolder.ownershipType === ResidentTypesEnum.Manager ||
        gestHolder.ownershipType === ResidentTypesEnum.Owner
      ) {
        return false;
      }
    }
    if (isOccupant(gestHost.ownershipType)) {
      if (isOccupant(gestHolder.ownershipType) && gestHost.host?.ownershipType === gestHolder.host?.ownershipType) {
        return false;
      }
      if (isHost(gestHolder.ownershipType) && gestHost.host?.ownershipType === gestHolder.ownershipType) {
        return false;
      }
    }

    return true;
  };

  //todo :: need to be refactored
  const getInitState = () => {
    if (isHost(residentStore.selectedResidentAddress?.ownershipType)) {
      return {
        address: { relatedResidentId: residentStore.selectedResidentAddress?.resident.id },
        phones: [
          {
            isNotificationEnabled: true,
            number: '',
            label: 'Primary',
            order: 0,
          },
        ],
      };
    }
  };

  return (
    <div>
      <EiqPrint
        firstName={residentStore.selectedResident?.firstName}
        lastName={residentStore.selectedResident?.lastName}
        address={residentStore.selectedResidentAddress?.address}
        data={residentStore.occupants}
        ref={printRef}
        columns={occupantPrintColumns}
      />
      <TableContextProvider controls={tableControls}>
        <div>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                adaptiveSearch={true}
                enableProgressiveSearch={true}
                placeholder="Search by name, host"
                rulesForColumn={{
                  name: FilterTypesEnum.Contains,
                  'occupant.host.name': FilterTypesEnum.Contains,
                  'occupant.host.ownershipType': FilterTypesEnum.Contains,
                }}
                rightSide={
                  <Space size={8}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      size="middle"
                      onClick={addResidentModal.showModal}
                      className="mobile-btn">
                      Add occupants
                    </Button>
                    <Button type="default" icon={<PrintIcon />} size={'middle'} onClick={handlePrint} className="mobile-btn">
                      Print
                    </Button>
                    <Button
                      icon={<EditIcon />}
                      size="middle"
                      disabled={isEditDisabled()}
                      onClick={editOccupantModal?.showModal}
                      className="mobile-btn">
                      Edit
                    </Button>
                  </Space>
                }
              />
            </Col>
          </Row>
          <TableBase selectFirstRowByDefault />
        </div>
      </TableContextProvider>
      <EditOccupantModal
        title="Edit"
        initData={editOccupantModal?.initData}
        isOpen={editOccupantModal?.isOpenModal}
        onClose={editOccupantModal?.hideModal}
        extraData={{ residentId: residentStore.residentId }}
      />
      {addResidentModal.isOpenModal && (
        <ResidentAddEditModal
          title="Add"
          isEdit={false}
          isOpen={addResidentModal.isOpenModal}
          onClose={addResidentModal.hideModal}
          initData={addResidentModal?.initData}
          initState={getInitState()}
        />
      )}
    </div>
  );
});
