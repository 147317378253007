import { ValidationMessages } from 'common/validation/validation-messages';
import { phoneValidationRule } from '../../../../../../common/validation/phone-number-rule';
import { RuleType } from 'rc-field-form/lib/interface';

export const addEditResidentValidation = {
  firstName: [{ required: true, message: 'First name is required!' }],
  lastName: [{ required: true, message: 'Last name is required!' }],
  type: [{ required: true, message: 'Type is required!' }],
  email: [{ message: ValidationMessages.Email.NotValidMessage, type: 'email' as RuleType }],
  pin: [{ required: true, message: 'Pin is required!' }],
  phone: [{ required: false, message: 'Phone number is required!' }, phoneValidationRule],
  rentStartDate: [{ required: true, message: 'Rent start date is required!' }],
  rentEndDate: [{ required: true, message: 'Rent end date is required!' }],
  relatedTo: [{ required: true, message: 'Parent resident is required!' }],
  vacationStartDate: [{ required: true, message: 'Vacation start date is required!' }],
  vacationEndDate: [{ required: true, message: 'Vacation end date is required!' }],
};
