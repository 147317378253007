import React, { FC, useState } from 'react';
import { Button, Form, Modal, Select, Space } from 'antd';
import { IModal } from 'common/models/model.interface';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { notification } from 'common/utils/notification';
import { observer } from 'mobx-react-lite';
import { ReactComponent as MoveOut } from 'assets/icons/move-white.svg';
import residentsService from 'common/services/residents-service/residents.service';
import { IResidentRead, IResidentResponse } from 'common/services/residents-service/residents.service.types';
import citationService from 'common/services/citation-service/citation.service';

const MoveCitationModal: FC<IModal<{ citationId: number }, any>> = observer(({ isOpen, onClose, title, initData }) => {
  const [form] = Form.useForm();
  const [residents, setResidents] = useState<IResidentRead[]>([]);

  const handleResidentSearch = (newValue?: string) => {
    let filter = newValue ? `firstName:*${newValue} OR lastName:*${newValue}` : null;
    residentsService.getResidents(null, filter, '+lastName').then((result: IResidentResponse) => {
      setResidents(result.items);
    });
  };

  const hideModal = (isSuccess: boolean) => {
    form.resetFields();
    notification.destroy();
    if (onClose) {
      onClose(isSuccess);
    }
  };

  const onFinish = async (values: any) => {
    if (!initData?.citationId) {
      console.error('Citation id is empty');
      return;
    }

    citationService.move(initData.citationId, { residentId: values.residentId }).then((result) => {
      hideModal(true);
    });
  };

  return (
    <Modal centered title={title} width={450} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} layout="vertical">
        <Space className="full-width">
          <Form.Item name="residentId" label="Resident">
            <Select
              placeholder="Select resident"
              defaultActiveFirstOption={false}
              showSearch
              allowClear
              onSearch={handleResidentSearch}
              filterOption={(inputValue, option) => option?.label?.toString().toLowerCase().includes(inputValue.toLowerCase()) ?? false}
              onDropdownVisibleChange={(isOpen) => isOpen && residents?.length === 0 && handleResidentSearch()}
              options={residents.map((i) => ({
                label: `${i.firstName} ${i.lastName}`,
                value: i.id,
              }))}
            />
          </Form.Item>
        </Space>
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<MoveOut />}>
            Move
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
});

export default React.memo(MoveCitationModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
