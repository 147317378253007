import { Button, Modal, Space, Input, Form } from 'antd';
import React, { FC, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/find.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/collect-key.svg';
import { IModal } from '../../../../common/models/model.interface';
import { FormWrapper } from '../../../../common/components/form-wrapper/form-wrapper';
import addressesKeyService from '../../../../common/services/addresses-key-service/addresses-key.service';
import { notification } from '../../../../common/utils/notification';
import { IKeyActivitiesRead } from '../../../../common/services/addresses-key-service/addresses-key.service.types';
import { EiqSecureKeyInfoRow } from '../eiq-secure-key-info-row/eiq-secure-key-info-row';
import KeyActionModal from '../key-action-modal/key-action-modal-modal';
import { useModal } from '../../../../common/hooks/use-modal/use-modal.hook';
import { formatCustomDateTime } from '../../../../common/helpers/date.helper';

const FindKeyModal: FC<IModal<any, any>> = ({ isOpen, onClose }) => {
  const [keyInfo, setKeyInfo] = useState<IKeyActivitiesRead | null>(null);
  const [form] = Form.useForm();

  const hideModal = (isSuccess: boolean) => {
    setKeyInfo(null);
    form.resetFields();
    if (!isSuccess) {
      notification.destroy();
    }
    if (onClose) {
      onClose();
    }
  };

  const onFinish = async ({ keyNumber }: { keyNumber: string }) => {
    const keyExistsAndOut = await addressesKeyService.getKeys({ offset: 0, limit: 1 }, `keyNumber:${keyNumber},status:out`, '');
    if (!keyExistsAndOut?.items?.length) {
      notification.error({ message: 'No results found. Please try again.' });
      return;
    }

    addressesKeyService.getKeysActivities({ offset: 0, limit: 1 }, `keyNumber:${keyNumber},status:2`, '-issueTimestamp').then((res) => {
      if (res.items.length > 0) {
        notification.destroy();
        setKeyInfo(res.items[0]);
      } else {
        notification.error({ message: 'No results found. Please try again.' });
        setKeyInfo(null);
      }
    });
  };

  const collectKeyModal = useModal({
    onBeforeShow: (setModalInitData) => {
      setModalInitData({ keyNumber: keyInfo?.keyNumber });
    },
    onClose: (isSuccessSaved) => {
      if (isSuccessSaved) {
        setTimeout(() => hideModal(true));
      }
    },
  });

  return (
    <Modal
      centered
      title={keyInfo ? `Key information #${keyInfo.keyNumber}` : 'Find the key'}
      width={362}
      open={isOpen}
      wrapClassName="custom-modal custom-modal--so"
      closable={false}>
      {!keyInfo ? (
        <FormWrapper form={form} onFinish={onFinish} layout="vertical">
          <Space className="full-width mb-12" size={8} direction="vertical">
            <Form.Item name="keyNumber" label="Find a Key by number" rules={[{ required: true }]}>
              <Input name="keyNumber" placeholder="Enter a key number" />
            </Form.Item>
          </Space>
          <Space className="footer">
            <Button className="eiq-button" type="link" onClick={() => hideModal(false)}>
              Cancel
            </Button>
            <Button type="primary" className="primary-btn eiq-button" icon={<SearchIcon />} onClick={() => form.submit()}>
              Find
            </Button>
          </Space>
        </FormWrapper>
      ) : (
        <div className="wg-content key-info-block">
          <EiqSecureKeyInfoRow title="Key number:" value={keyInfo.keyNumber} />
          <EiqSecureKeyInfoRow title="Key name:" value={keyInfo.keyName} />
          <EiqSecureKeyInfoRow title="Guest name:" value={keyInfo.guestName} />
          <EiqSecureKeyInfoRow title="Address:" value={keyInfo.address} />
          <EiqSecureKeyInfoRow title="Given by:" value={keyInfo.issueUserName} />
          <EiqSecureKeyInfoRow title="Gate In:" value={keyInfo.issueGate} />
          <EiqSecureKeyInfoRow title="Time in:" value={formatCustomDateTime(keyInfo?.issueTimestamp, true)} />
          <Space className="footer">
            <Button className="eiq-button" type="link" onClick={() => setKeyInfo(null)}>
              Cancel
            </Button>
            <Button type="primary" className="primary-btn eiq-button" icon={<KeyIcon />} onClick={collectKeyModal.showModal}>
              Collect key
            </Button>
          </Space>
        </div>
      )}
      <KeyActionModal
        title="Collect"
        initData={collectKeyModal.initData}
        isOpen={collectKeyModal?.isOpenModal}
        onClose={collectKeyModal?.hideModal}
      />
    </Modal>
  );
};

export default React.memo(FindKeyModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
