const currentYear = new Date().getFullYear() + 1;

const validateYear = (rule: any, value: any) => {
  if (!value) {
    return Promise.resolve();
  }
  if (!/^\d+$/.test(value)) {
    return Promise.reject('Vehicle’s production year is invalid');
  }
  if (value < 1900 || value > currentYear) {
    return Promise.reject(`Year must be between 1900 and ${currentYear}`);
  }
  return Promise.resolve();
};

export const addEditVehicleValidation = {
  owner: [{ required: true, message: 'Owner is required!' }],
  deviceId: [{ required: true, message: 'Device id is required!' }],
  deactivationReason: [{ required: true, message: 'Deactivation reason is required!' }],
  facilityCode: [{ required: true, message: 'Facility code is required!' }],
  make: [{ required: true, message: 'Make is required!' }],
  model: [{ required: true, message: 'Model is required!' }],
  licenseNumber: [{ required: true, message: 'License is required!' }],
  licenseState: [{ required: true, message: 'License state is required!' }],
  year: [{ required: true, message: 'Year is required!' }, { validator: validateYear }],
  color: [{ required: true, message: 'Color is required!' }],
  rentStartDate: [{ required: true, message: 'Start date is required!' }],
  rentEndDate: [
    { required: true, message: 'End date is required!' },
    ({ getFieldValue }: any) => ({
      validator: (_: any, value: string) => {
        const startDate = getFieldValue('rentStartDate');
        if (value && value < startDate) {
          return Promise.reject(new Error('End date must be greater than start date'));
        }
        return Promise.resolve();
      },
    }),
  ],
  addressId: [{ required: true, message: 'Address is required!' }],
  validTo: [
    ({ getFieldValue }: any) => ({
      validator: (_: any, value: string) => {
        const startDate = getFieldValue('validFrom');
        if (value && value < startDate) {
          return Promise.reject(new Error('End date must be greater than start date'));
        }
        return Promise.resolve();
      },
    }),
  ],
};
