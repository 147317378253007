import React from 'react';
import './vehicle-info-block.scss';
import dayjs from 'dayjs';
import { Tag } from 'antd';
import { vehicleStatusColorMap, vehicleStatusMap } from '../../constans/common.constants';
import { VehicleStatusEnum } from '../../enums/vehicle-status.enum';
import { IVehicleRead } from '../../services/residents-service/residents.service.types';

export const vehicleInfoBlock = (vehicle?: IVehicleRead) => {
  if (!vehicle) {
    return null;
  }

  let result = '';

  if (vehicle.year) {
    result += `${vehicle.year} `;
  }

  if (vehicle.make) {
    result += `${vehicle.make} `;
  }

  if (vehicle.model) {
    result += `${vehicle.model} - `;
  }

  result += `${vehicle.licenseNumber} (${vehicle.licenseState})`;

  return result?.length > 0 ? (
    <div className="vehicle-info-block">
      <span className="vehicle-info">{result}</span>
      <div className="vehicle-tags">
        {renderExpirationTag(vehicle)}
        {renderDeactivationTag(vehicle)}
        {vehicle?.status && (
          <Tag className="eiq-tag" color={vehicleStatusColorMap[vehicle?.status as VehicleStatusEnum]} style={{ color: '#292B2E' }}>
            {vehicleStatusMap[vehicle.status]}
          </Tag>
        )}
      </div>
    </div>
  ) : null;
};

const renderDeactivationTag = (vehicle?: IVehicleRead) => {
  if (!vehicle?.isDeactivated) {
    return null;
  }

  return <Tag className="eiq-tag deactivated">Deactivated</Tag>;
};

const renderExpirationTag = (vehicle?: IVehicleRead) => {
  if (!vehicle?.validTo) {
    return null;
  }
  const expirationDate = dayjs(vehicle.validTo, 'YYYY-MM-DD').startOf('day');
  const today = dayjs().startOf('day');
  const isExpired = expirationDate.isBefore(today);

  return isExpired && <Tag className="eiq-tag expired">Expired</Tag>;
};
