import { ServerClient } from 'common/utils/http/http-clients';
import { IAccessCardRead } from '../residents-service/residents.service.types';
import { ICreateUpdateAccessCard } from './access-card.service.types';

class AccessCardService {
  async getAll(pageLimit: any, filter: string | null | undefined, sort: string | null | undefined): Promise<IAccessCardRead> {
    const res = await ServerClient.get<IAccessCardRead>('access-cards', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async create(residentId: number, addressId: number, data: ICreateUpdateAccessCard): Promise<IAccessCardRead> {
    const res = await ServerClient.post<IAccessCardRead>(`/residents/${residentId}/addresses/${addressId}/access-cards`, data);
    return res?.data;
  }

  async update(residentId: number, addressId: number, accessCardId: number, data: ICreateUpdateAccessCard): Promise<IAccessCardRead> {
    const res = await ServerClient.patch<IAccessCardRead>(
      `/residents/${residentId}/addresses/${addressId}/access-cards/${accessCardId}`,
      data,
    );
    return res?.data;
  }

  async delete(residentId: number, addressId: number, accessCardId: number): Promise<any> {
    const res = await ServerClient.delete(`/residents/${residentId}/addresses/${addressId}/access-cards/${accessCardId}`);
    return res?.data;
  }
}

export const accessCardService = new AccessCardService();
export default accessCardService;
