import { Button, Modal, Radio, Space, notification } from 'antd';
import React, { FC, useState } from 'react';
import { ReactComponent as TurnbackIcon } from 'assets/icons/turnback.svg';
import { IModal } from '../../../../common/models/model.interface';
import { reasonsRadioOptions } from '../../constans/common.eiq-secure';

const TurnbackModal: FC<IModal<any, any>> = ({ isOpen, onClose, title }) => {
  const [turnbackReason, setTurnbackReason] = useState<string>('No answer');

  const hideModal = (...rest: any) => {
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };

  const handleClickTurnback = () => {
    hideModal(true, turnbackReason);
  };

  return (
    <Modal centered title={title} width={362} open={isOpen} wrapClassName="custom-modal custom-modal--so" closable={false}>
      <Space className="full-width mb-12" size={8} direction="vertical">
        <p className="sub-title">Reason</p>
        <Radio.Group
          options={reasonsRadioOptions}
          value={turnbackReason}
          className="vertical-radio-group"
          onChange={(e) => setTurnbackReason(e.target.value)}></Radio.Group>
      </Space>
      <Space className="footer">
        <Button className="eiq-button" type="link" onClick={() => hideModal(false)}>
          Cancel
        </Button>
        <Button className="important-btn eiq-button" icon={<TurnbackIcon />} onClick={handleClickTurnback}>
          Turnback
        </Button>
      </Space>
    </Modal>
  );
};

export default React.memo(TurnbackModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
