import BaseCellText from 'common/components/table/base-cell-text';
import { ICellRendererProps } from 'common/components/table/table.types';
import { IVehicleRead } from 'common/services/residents-service/residents.service.types';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import { DateToFormat } from 'common/helpers/date.helper';
import { VehicleStatusEnum } from 'common/enums/vehicle-status.enum';
import React from 'react';
import { Image, Tag } from 'antd';
import { ownershipTypeStatusMap, vehicleStatusColorMap, vehicleStatusMap } from 'common/constans/common.constants';
import { IVehicleAdminRead } from 'common/services/vehicle-service/vehicle.service.types';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';

const getRentingPeriod = (rowData: IVehicleRead) => {
  const isPeriod = rowData?.rentStartDate && rowData?.rentEndDate;

  return isPeriod
    ? `${DateToFormat(rowData?.rentStartDate, DATE_SHORT_MMM_DD_YYYY)} - ${DateToFormat(rowData?.rentEndDate, DATE_SHORT_MMM_DD_YYYY)}`
    : '';
};

const getValidPeriod = (rowData: IVehicleRead) => {
  const isPeriod = rowData?.validFrom && rowData?.validTo;

  return isPeriod
    ? `${DateToFormat(rowData.validFrom, DATE_SHORT_MMM_DD_YYYY)} - ${DateToFormat(rowData.validTo, DATE_SHORT_MMM_DD_YYYY)}`
    : '';
};

export const vehiclesColumns = () => ({
  photo: {
    key: 'photoUrl',
    title: 'Registration',
    dataKey: 'photoUrl',
    width: 110,
    fixed: 1,
    resizable: false,
    sortable: false,
    cellRenderer: (cell: any) => {
      return cell.cellData && <Image src={cell.cellData} width={24} height={24} preview={{ className: 'vehicle-image-preview' }} />;
    },
  },
  owner: {
    key: 'owner',
    title: 'Owner',
    dataKey: 'owner',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  ownershipType: {
    key: 'residentAddress_ownershipType',
    title: 'Owner Type',
    dataKey: 'residentAddress_ownershipType',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleAdminRead>) => {
      return ownershipTypeStatusMap[cell.rowData.ownershipType as ResidentTypesEnum];
    },
  },
  facilityCode: {
    key: 'facilityCode',
    title: 'Facility code',
    dataKey: 'facilityCode',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  deviceId: {
    key: 'deviceId',
    title: 'Device ID',
    dataKey: 'deviceId',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleRead>) => {
      return <BaseCellText>{cell?.rowData?.deviceId}</BaseCellText>;
    },
  },
  year: {
    key: 'year',
    title: 'Year',
    dataKey: 'year',
    width: 90,
    minWidth: 90,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<IVehicleRead>) => {
      return <BaseCellText>{cell?.rowData?.year}</BaseCellText>;
    },
    flexGrow: 1,
  },
  make: {
    key: 'make',
    title: 'Make',
    dataKey: 'make',
    width: 150,
    minWidth: 90,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  model: {
    key: 'model',
    title: 'Model',
    dataKey: 'model',
    width: 150,
    minWidth: 90,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  cardClass: {
    key: 'cardClass',
    title: 'Card Class',
    dataKey: 'cardClass',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleRead>) => {
      return (
        cell?.rowData?.cardClass && (
          <Tag className="eiq-tag" color="#C1FFEA" style={{ color: '#292B2E' }}>
            {cell?.rowData.cardClass}
          </Tag>
        )
      );
    },
  },
  licenseNumber: {
    key: 'licenseNumber',
    title: 'License',
    dataKey: 'licenseNumber',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  licenseState: {
    key: 'licenseState',
    title: 'License State',
    dataKey: 'licenseState',
    width: 150,
    minWidth: 100,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  color: {
    key: 'color',
    title: 'Color',
    dataKey: 'color',
    width: 150,
    minWidth: 90,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  rantingDates: {
    key: 'rantingDates',
    title: 'Renting dates',
    dataKey: 'rantingDates',
    width: 195,
    minWidth: 150,
    resizable: true,
    sortable: false,
    getValue: getRentingPeriod,
    cellRenderer: (cell: ICellRendererProps<IVehicleRead>) => {
      return <BaseCellText>{getRentingPeriod(cell?.rowData)}</BaseCellText>;
    },
    flexGrow: 1,
  },
  validityPeriod: {
    key: 'validityPeriod',
    title: 'Validity period',
    dataKey: 'validityPeriod',
    width: 195,
    minWidth: 150,
    resizable: true,
    sortable: false,
    getValue: getValidPeriod,
    cellRenderer: (cell: ICellRendererProps<IVehicleRead>) => {
      return <BaseCellText>{getValidPeriod(cell?.rowData)}</BaseCellText>;
    },
    flexGrow: 1,
  },
  status: {
    key: 'status',
    title: 'Status',
    dataKey: 'status',
    width: 150,
    minWidth: 90,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<IVehicleRead>) => {
      return (
        cell?.rowData?.status && (
          <Tag className="eiq-tag" color={vehicleStatusColorMap[cell?.cellData as VehicleStatusEnum]} style={{ color: '#292B2E' }}>
            {vehicleStatusMap[cell?.rowData?.status]}
          </Tag>
        )
      );
    },
    flexGrow: 1,
  },
  additionalInformation: {
    key: 'additionalInformation',
    title: 'Additional text information',
    dataKey: 'additionalInformation',
    width: 245,
    minWidth: 245,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    hidden: true,
  },
  parkSpaceNumber: {
    key: 'parkSpaceNumber',
    title: 'Park space number',
    dataKey: 'parkSpaceNumber',
    width: 195,
    minWidth: 195,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    hidden: true,
  },
  registrationAddress: {
    key: 'registrationAddress',
    title: 'Address of registration',
    dataKey: 'registrationAddress',
    width: 245,
    minWidth: 245,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    hidden: true,
  },
  isShared: {
    key: 'isShared',
    title: 'Is owner?',
    dataKey: 'isShared',
    width: 100,
    sortable: false,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleRead>) => {
      return (
        <Tag className="eiq-tag" color={cell.cellData ? '#FFB68C' : '#C1FFEA'} style={{ color: '#292B2E' }}>
          {cell.cellData ? 'No' : 'Yes'}
        </Tag>
      );
    },
  },
  transponder: {
    key: 'isNoTransponder',
    title: 'Transponder',
    dataKey: 'isNoTransponder',
    width: 140,
    minWidth: 140,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    hidden: false,
    cellRenderer: (cell: ICellRendererProps<IVehicleRead>) => {
      return cell.cellData ? 'Not available' : 'Available';
    },
  },
});
