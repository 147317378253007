export const loadZendeskWidget = (accountKey: string, onLoaded: () => void) => {
  if (document.getElementById('ze-snippet')) {
    onLoaded();
  }

  const zendeskScript = document.createElement('script');
  zendeskScript.id = 'ze-snippet';
  zendeskScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${accountKey}`;
  zendeskScript.async = true;
  document.body.appendChild(zendeskScript);

  zendeskScript.onload = () => {
    onLoaded();
  };
};
