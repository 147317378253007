import React, { FC, useEffect, useState } from 'react';
import { Button, DatePickerProps, Form, Modal, Select, Space } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { residentTypeOptions } from '../../../../../../constants/resident-type-options.constant';
import { notification } from 'common/utils/notification';
import { DatePicker } from 'antd/lib';
import residentsService from 'common/services/residents-service/residents.service';
import { ResidentTypesEnum } from '../../../../../../../../common/enums/resident-types.enum';
import residentStore from '../../../../../../stores/resident.store';
import { IResidentRead } from 'common/services/residents-service/residents.service.types';
import { IModal } from 'common/models/model.interface';
import { IAddAddressModalForm } from '../addresses.types';
import AddressSelect from '../../../address-select/address-select';
import { ResidentAlreadyAssignedToTheAddress } from 'common/constans/errorCodes.constants';
import { formatZoning } from 'common/helpers/address.helper';
import { IAddress } from 'common/services/street-service/street.service.types';
import { isOccupant } from 'common/helpers/resident-address.helper';

const AddAddressModal: FC<IModal<any, any>> = ({ title, isOpen, onClose }) => {
  const [form] = Form.useForm();
  const [ownerType, setOwnerType] = useState<string | null>();
  const [residents, setResidents] = useState<IResidentRead[]>([]);
  const isRenter = ownerType === ResidentTypesEnum.Renter;
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);
  const [selectedAddressName, setSelectedAddressName] = useState<string | null>(null);
  const [addressSelectResetTrigger, setAddressSelectResetTrigger] = useState<boolean>(false);

  const isOccupants = ownerType && isOccupant(ownerType as ResidentTypesEnum);
  const customFormat: DatePickerProps['format'] = (value) => `${value.format('MMMM D, YYYY')}`;

  const hideModal = (...rest: any) => {
    notification.destroy();
    form.resetFields();
    setOwnerType(null);
    setSelectedAddressId(null);
    if (onClose) {
      console.log('hideModal add-address', rest);
      onClose(...rest);
    }
  };

  const onFinish = async (values: IAddAddressModalForm) => {
    values['address'].addressId = selectedAddressId;
    values['address'].rentStartDate = values['address'].rentStartDate?.format('YYYY-MM-DD');
    values['address'].rentEndDate = values['address'].rentEndDate?.format('YYYY-MM-DD');
    try {
      const res = await residentsService.addResidentAddress(residentStore.residentId!, values['address']);
      hideModal(true, res);
    } catch (error: any) {
      if (error.response?.data?.errorCode == ResidentAlreadyAssignedToTheAddress) {
        notification.destroy();
        notification.error({ message: `Resident is assigned to ${selectedAddressName}` });
        return;
      }
      throw error;
    }
  };

  const handleOwnerType = (value: string) => {
    setOwnerType(value);
  };

  const handleResidentSearch = (newValue?: string) => {
    const filter = `addresses_ownershipType:Renter OR addresses_ownershipType:Owner,addresses_address_id:${selectedAddressId}`;

    residentsService
      .getResidents(null, newValue ? filter + `,name:*` + newValue : filter, '+lastName')
      .then((options) => {
        setResidents(options.items);
      })
      .catch((e) => {
        setResidents([]);
        notification.error({ message: 'Can not fetch street list' });
        console.log(e);
      });
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields([['address', 'relatedResidentId']]);
      setResidents([]);
    }
  }, [selectedAddressId, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setAddressSelectResetTrigger(!addressSelectResetTrigger);
    }
  }, [isOpen]);

  const handleAddressChanged = (value: IAddress | null) => {
    const addressId = value ? value.id : null;
    setSelectedAddressId(addressId);
    setSelectedAddressName(formatZoning(value));
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} layout="vertical">
        <>
          <Space className="full-width">
            <Form.Item
              name={['address', 'ownershipType']}
              className="eiq-select"
              label="Type"
              rules={[{ required: true, message: 'Ownership type is required!' }]}>
              <Select showSearch options={residentTypeOptions} onChange={handleOwnerType} />
            </Form.Item>
          </Space>

          {isRenter && (
            <Space className="full-width" size={13}>
              <Form.Item name={['address', 'rentStartDate']} label="From">
                <DatePicker format={customFormat} placeholder="Start date" />
              </Form.Item>
              <Form.Item name={['address', 'rentEndDate']} label="To">
                <DatePicker format={customFormat} placeholder="End date" />
              </Form.Item>
            </Space>
          )}

          <AddressSelect value={selectedAddressId} setAddress={handleAddressChanged} isOpen={addressSelectResetTrigger} form={form} />

          {isOccupants && (
            <Space className="full-width" size={13}>
              <Form.Item className="eiq-select" name={['address', 'relatedResidentId']} label="Related to">
                <Select
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  onSearch={handleResidentSearch}
                  onDropdownVisibleChange={(isOpen) => isOpen && residents?.length === 0 && handleResidentSearch()}
                  filterOption={(inputValue, option) => option?.label?.toString().toLowerCase().includes(inputValue.toLowerCase()) ?? false}
                  options={residents?.map((resid) => ({ value: resid.id, label: `${resid.firstName} ${resid.lastName}` }))}
                />
              </Form.Item>
            </Space>
          )}
        </>
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className="eiq-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};
export default React.memo(AddAddressModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
