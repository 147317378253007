import { forwardRef } from 'react';
import IncidentDetailsEdit from './incident-details-edit/incident-details-edit';
import IncidentDetailsView from './incident-details-view/incident-details-view';
import { IIncidentDetailsProps } from './incident-details.types';
import useIncidentForm from 'common/hooks/use-incident-form/use-incident-form.hook';

const IncidentDetails = forwardRef(({ onSave, incident }: IIncidentDetailsProps, ref) => {
  const { mode, form, switchToEditMode, switchToViewMode, onSubmit } = useIncidentForm({ initialValues: incident, onSave, ref });

  if (mode === 'view') {
    return incident ? <IncidentDetailsView onEdit={switchToEditMode} incident={incident} /> : <></>;
  } else {
    return <IncidentDetailsEdit form={form} onSave={onSubmit} onCancel={switchToViewMode} incident={incident} />;
  }
});

export default IncidentDetails;
