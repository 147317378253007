import { ServerClient } from 'common/utils/http/http-clients';
import {
  IApproveVehicle,
  ICreateVehicle,
  IDeleteVehicle,
  IListResponse,
  IRejectVehicle,
  IUpdateVehicle,
  IVehicleAdminRead,
} from './vehicle.service.types';
import { IImportResult } from 'common/models/import-result.interface';

class VehicleService {
  async createVehicle(residentId: number, addressId: number, data: ICreateVehicle): Promise<ICreateVehicle> {
    const res = await ServerClient.post<ICreateVehicle>(`/residents/${residentId}/addresses/${addressId}/vehicles`, data);
    return res?.data;
  }

  async updateVehicle(residentId: number, addressId: number, data: IUpdateVehicle): Promise<IUpdateVehicle> {
    const res = await ServerClient.put<IUpdateVehicle>(`/residents/${residentId}/addresses/${addressId}/vehicles/${data.id}`, data);
    return res?.data;
  }

  async deleteVehicle(residentId: number, addressId: number, vehicleId: number, reason: IDeleteVehicle): Promise<any> {
    const res = await ServerClient.delete(`/residents/${residentId}/addresses/${addressId}/vehicles/${vehicleId}`, {
      data: reason,
    });
    return res?.data;
  }

  async getVehicles(
    pageLimit: any,
    filter: string | null | undefined,
    sort: string | null | undefined,
  ): Promise<IListResponse<IVehicleAdminRead>> {
    const res = await ServerClient.get<IListResponse<IVehicleAdminRead>>('vehicles', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async approve(vehicleId: number, model: IApproveVehicle): Promise<any> {
    const res = await ServerClient.post<any>(`/vehicles/${vehicleId}/approve`, model);
    return res?.data;
  }

  async reject(vehicleId: number, model: IRejectVehicle): Promise<any> {
    const res = await ServerClient.post<any>(`/vehicles/${vehicleId}/reject`, model);
    return res?.data;
  }

  async importVehicles(residentId: number, addressId: number, file: any): Promise<IImportResult> {
    const data = new FormData();
    data.append('file', file);
    const res = await ServerClient.post<IImportResult>(`/residents/${residentId}/addresses/${addressId}/vehicles/import/csv`, data);
    return res?.data;
  }
}

export const vehicleService = new VehicleService();
export default vehicleService;
