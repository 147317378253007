import TableContextProvider, { useTableControls } from '../../../../../../../common/components/table/table-context';
import { Col, Row, Space } from 'antd';
import TableBase from '../../../../../../../common/components/table/table-base';
import React from 'react';
import { observer } from 'mobx-react-lite';
import addressesStore from 'features/eiq-manage/stores/addresses.store';
import { addressesResidentInitialColumns } from 'features/eiq-manage/pages/eiq-manage-residents/resident.config';

export const Residents = observer(() => {
  const tableControls = useTableControls({
    clientSide: true,
    data: addressesStore.selectedAddressResidents,
    initialColumns: addressesResidentInitialColumns,
  });

  return (
    <div>
      <TableContextProvider controls={tableControls}>
        <div>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col>
              <Space size={8}></Space>
            </Col>
          </Row>
          <TableBase onSelected={tableControls.setSelectedRow} />
        </div>
      </TableContextProvider>
    </div>
  );
});
