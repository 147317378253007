import { ICellRendererProps } from 'common/components/table/table.types';
import { IAddressOccupant } from 'common/services/guest-service/guest.service.types';
import { Tag } from 'antd';
import { ownershipTypeStatusColorMap } from 'common/constans/common.constants';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { camelCaseToRegularForm } from 'common/helpers/camel-case-to-regular-form.helper';

export const occupantsColumns = () => ({
  name: {
    key: 'name',
    title: 'Occupant name',
    dataKey: 'name',
    width: 274,
    fixed: 1,
    resizable: false,
    sortable: true,
  },
  ownershipType: {
    key: 'ownershipType',
    title: 'Occupant type',
    dataKey: 'ownershipType',
    width: 274,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IAddressOccupant>) => renderOwnershipType(cell?.rowData),
  },
  hostName: {
    key: 'host.name',
    title: 'Host name',
    dataKey: 'host.name',
    width: 274,
    resizable: true,
    sortable: false,
    fixed: 1,
    getValue: (rowData: IAddressOccupant) => rowData?.host?.name,
  },
  hostOwnershipType: {
    key: 'host.ownershipType',
    title: 'Host type',
    dataKey: 'host.ownershipType',
    width: 274,
    sortable: false,
    resizable: true,
    fixed: 1,
    getValue: (rowData: IAddressOccupant) => rowData?.host?.ownershipType,
    cellRenderer: (cell: ICellRendererProps<IAddressOccupant>) => renderHostOwnershipType(cell?.rowData?.host?.ownershipType),
  },
});

const renderOwnershipType = (ownershipAddress: IAddressOccupant) => {
  if (!ownershipAddress) {
    return '';
  }

  if (ownershipAddress.host?.ownershipType === ResidentTypesEnum.Renter) {
    return (
      <Tag className="eiq-tag" color={ownershipTypeStatusColorMap[ResidentTypesEnum.Renter]}>
        {camelCaseToRegularForm(ownershipAddress.ownershipType)}
      </Tag>
    );
  } else {
    return renderHostOwnershipType(ownershipAddress.ownershipType);
  }
};

const renderHostOwnershipType = (ownershipType?: ResidentTypesEnum) => {
  return (
    ownershipType && (
      <Tag className="eiq-tag" color={ownershipTypeStatusColorMap[ownershipType]}>
        {camelCaseToRegularForm(ownershipType)}
      </Tag>
    )
  );
};
