export const ValidationMessages = {
  Email: {
    RequiredMessage: 'Email is required!',
    NotValidMessage: 'The input is not a valid email!',
    ConfirmMessage: 'Please confirm your email!',
  },
  Password: {
    RequiredMessage: 'Password is required!',
    RequiredLengthMessage: 'Password must be at least  8 characters long!',
  },
};
