import React from 'react';
import { IQRCodeScanDetector } from './eiq-secure-qr-code-scan-detector.types';
import { startEndChar } from 'common/helpers/eiq-passport.helper';

const startEndCharCode = startEndChar.charCodeAt(0);

const timeBeforeScanCheck = 100;
const startChars = [startEndCharCode];
const endChars = [9, 13, startEndCharCode];
const length = 8;
const timeByChar = 30;

class QRCodeScanDetector extends React.Component<IQRCodeScanDetector, any> {
  firstCharTime: number;
  lastCharTime: number;
  resultString: string;
  isScanner: boolean;
  timer: any;
  isStartEndCharDetected: boolean;

  constructor(props: IQRCodeScanDetector) {
    super(props);

    this.firstCharTime = 0;
    this.lastCharTime = 0;
    this.resultString = '';
    this.isScanner = false;
    this.timer = null;
    this.isStartEndCharDetected = false;
  }

  componentDidMount() {
    window.document.addEventListener('keypress', this.handleKeyPress);
  }

  componentWillUnmount() {
    window.document.removeEventListener('keypress', this.handleKeyPress);
  }

  reset = () => {
    this.firstCharTime = 0;
    this.resultString = '';
    this.isStartEndCharDetected = false;
  };

  scannerDetectionTest = () => {
    const { onScan } = this.props;

    if (this.resultString.length >= length && this.lastCharTime - this.firstCharTime < this.resultString.length * timeByChar) {
      if (onScan) {
        onScan(this.resultString);
      }
    }

    this.reset();
  };

  handleKeyPress = (event: any) => {
    if (event.which === startEndCharCode) {
      this.isStartEndCharDetected = true;
    }
    if (this.isStartEndCharDetected) {
      event.preventDefault();
    }

    if (this.firstCharTime && endChars.indexOf(event.which) !== -1) {
      event.preventDefault();
      this.isScanner = true;
    } else if (!this.firstCharTime && startChars.indexOf(event.which) !== -1) {
      event.preventDefault();
      this.isScanner = false;
    } else {
      if (typeof event.which !== 'undefined') {
        this.resultString += String.fromCharCode(event.which);
      }
      this.isScanner = false;
    }

    if (!this.firstCharTime) {
      this.firstCharTime = Date.now();
    }
    this.lastCharTime = Date.now();

    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.isScanner) {
      this.scannerDetectionTest();
      this.timer = null;
    } else {
      this.timer = setTimeout(this.scannerDetectionTest, timeBeforeScanCheck);
    }
  };

  render() {
    return null;
  }
}

export default QRCodeScanDetector;
