import React, { useCallback } from 'react';
import './accounts.scss';
import { observer } from 'mobx-react-lite';
import { Layout, Menu } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { UserTypeEnum } from 'common/enums/user-type.enum';
const { Sider, Content } = Layout;

export const Accounts = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  const getTabs = useCallback(() => {
    const secureUsersTab = { label: 'EIQ-secure users', key: '/eiq-manage/account-management/security-officers' };
    const adminsTab = { label: 'Admins', key: '/eiq-manage/account-management/admins' };
    const accountInfoTab = { label: 'Account info', key: '/eiq-manage/account-management/account-info' };

    switch (userAuthStore.userType) {
      case UserTypeEnum.Admin:
        return [accountInfoTab, secureUsersTab];
      case UserTypeEnum.SecurityOfficer:
        return [];
      case UserTypeEnum.SuperAdmin:
        return [adminsTab, secureUsersTab];
      default:
        return [];
    }
  }, []);

  return (
    <Layout hasSider className="accounts-layout">
      <Sider className="sider-wrapper">
        <Menu
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={[location.pathname]}
          items={getTabs()}
          className="sider-tabs"
          onClick={(tab) => navigate(tab.key)}
        />
      </Sider>
      <Content className="content-style">
        <Outlet />
      </Content>
    </Layout>
  );
});
