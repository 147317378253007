import TableContextProvider, { useTableControls } from '../../../../../../../common/components/table/table-context';
import { Col, Row, Space } from 'antd';
import TableBase from '../../../../../../../common/components/table/table-base';
import React from 'react';
import { observer } from 'mobx-react-lite';
import addressesStore from 'features/eiq-manage/stores/addresses.store';
import { accessCardColumns } from 'features/eiq-manage/pages/eiq-manage-residents/components/info-block/access-cards/access-card-list.comfig';
import featureStore from 'common/stores/features/feature.store';

export const AccessCards = observer(() => {
  let columns = accessCardColumns;

  if (!featureStore.isCA3000ExpirationsEnabled) {
    columns = columns.filter((i) => i.key !== 'validityPeriod');
  }
  if (!featureStore.isCA3000CardClassesEnabled) {
    columns = columns.filter((i) => i.key !== 'cardClass');
  }

  const tableControls = useTableControls({
    clientSide: true,
    data: addressesStore.selectedAddressAccessCards,
    initialColumns: columns,
  });

  return (
    <div>
      <TableContextProvider controls={tableControls}>
        <div>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col>
              <Space size={8}></Space>
            </Col>
          </Row>
          <TableBase onSelected={tableControls.setSelectedRow} />
        </div>
      </TableContextProvider>
    </div>
  );
});
