import { DATE_YYYY_MM_DD, DATE_TIME_YYYY_MM_DD_HH_mm_ss } from 'common/constans/date.constants';
import dayjs from 'dayjs';
import { IGuestRead } from '../services/residents-service/residents.service.types';
import { ServerGuestValidity } from '../enums/guest-validity.enum';

export const DateToFormat = (date: string, format: string) => date && dayjs.utc(date, 'YYYY-MM-DD').format(format);
export const StrToDate = (date: string | null, format: string = DATE_YYYY_MM_DD) => date && dayjs.utc(date, format);
export const StrToDateTimeLocal = (date: string | null) => {
  if (!date) {
    return null;
  }

  return dayjs.utc(date, DATE_TIME_YYYY_MM_DD_HH_mm_ss).local();
};
export const formatCustomDateTime = (date: string | number | Date | null, includeTime: boolean = false) => {
  if (!date) {
    return null;
  }

  let parsedDate;

  if (typeof date === 'number') {
    parsedDate = new Date(date * 1000);
  } else if (typeof date === 'string') {
    parsedDate = dayjs(date).toDate();
  } else {
    parsedDate = date;
  }

  const formatString = includeTime ? 'MMM D, YYYY h:mma' : 'MMM D, YYYY';
  return dayjs(parsedDate).format(formatString);
};

export const getDateRangePeriod = (rowData: IGuestRead) =>
  rowData.validity === ServerGuestValidity.DateRange
    ? `${formatCustomDateTime(rowData?.startDate)} - ${formatCustomDateTime(rowData?.endDate)}`
    : rowData.validity;
