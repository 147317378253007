import { Button, Col, Row, Space } from 'antd';
import { formatAddressLabel } from 'common/helpers/address.helper';
import { IIncidentInvolvedPerson } from 'common/services/incident-report/incident-report.types';
import { IResidentAddressRead } from 'common/services/residents-service/residents.service.types';
import React, { useCallback } from 'react';
import { IIncidentInvolvedPersonsProps } from './incident-involved-persons.types';
import { IncidentInvolvedPersonEdit } from './incident-involved-person-edit/incident-involved-person-edit';
import { IncidentInvolvedPersonView } from './incident-involved-person-view/incident-involved-person-view';
import { IncidentInvolvedPersonSearch } from './incident-involved-person-search/incident-involved-person-search';
import { useIncidentInvolvedForm } from 'common/hooks/use-incident-involved-form/use-incident-involved-form.hook';
import { IInvolvedItem } from 'common/hooks/use-incident-involved-form/use-incident-involved-form.types';
import { PlusOutlined } from '@ant-design/icons';

export const IncidentInvolvedPersons = ({ onChange, initialValues }: IIncidentInvolvedPersonsProps) => {
  const {
    items,
    addMode,
    currentSelectedItem,
    switchToSearchMode,
    switchToManualMode,
    cancelAddMode,
    handleSelectedItem,
    handleAdd,
    handleSaveEdit,
    changeItemMode,
    deleteItem,
  } = useIncidentInvolvedForm<IIncidentInvolvedPerson, IResidentAddressRead>({
    initialValues: initialValues || [],
    onChange,
    isRequired: true,
  });

  const convertToInvolvedPerson = useCallback((): IIncidentInvolvedPerson | undefined => {
    if (!currentSelectedItem) {
      return undefined;
    }

    const { resident, address } = currentSelectedItem;
    return {
      firstName: resident.firstName,
      lastName: resident.lastName,
      phoneNumber: resident.phones[0]?.number || '',
      streetNumber: address.streetNumber,
      streetName: address.street.name,
      address: formatAddressLabel(address, { hideStreet: true }),
      involvementType: '',
    };
  }, [currentSelectedItem]);

  const renderAddSection = () => {
    switch (addMode) {
      case 'search':
        return <IncidentInvolvedPersonSearch onSelected={handleSelectedItem} onEnterManuallyClick={() => switchToManualMode(null)} />;
      case 'manual':
        return <IncidentInvolvedPersonEdit initialValue={convertToInvolvedPerson()} onSave={handleAdd} onCancel={cancelAddMode} />;
      default:
        return (
          <Button className="secondary-btn" onClick={switchToSearchMode} icon={<PlusOutlined />}>
            Add Person
          </Button>
        );
    }
  };

  const renderPersonItem = (item: IInvolvedItem<IIncidentInvolvedPerson>, index: number) => {
    if (item.mode === 'view') {
      return (
        <IncidentInvolvedPersonView person={item.item} onEdit={() => changeItemMode(index, 'edit')} onDelete={() => deleteItem(index)} />
      );
    }

    return (
      <IncidentInvolvedPersonEdit
        initialValue={item.item}
        onSave={(updatedPerson: IIncidentInvolvedPerson) => handleSaveEdit(index, updatedPerson)}
        onCancel={() => changeItemMode(index, 'view')}
      />
    );
  };

  return (
    <Space direction="vertical" size="middle" className="full-width">
      {items.length !== 0 && (
        <div>
          <Row gutter={[16, 16]}>
            {items.map((item, index) => (
              <Col xs={24} lg={item.mode === 'view' ? 20 : 24} key={index}>
                {renderPersonItem(item, index)}
              </Col>
            ))}
          </Row>
        </div>
      )}
      {renderAddSection()}
    </Space>
  );
};

export default IncidentInvolvedPersons;
