import React from 'react';
import { observer } from 'mobx-react-lite';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { Button, Card, Col, Layout, Menu, Row, Space } from 'antd';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import { vehicleApprovalsInitialColumns } from '../../vehicles.config';
import TableBase from 'common/components/table/table-base';
import { ColumnVisibility } from 'common/components/table/filters/column-visibility/column-visibility';
import { ReactComponent as ApproveIcon } from 'assets/icons/approve.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/reject.svg';
import styles from '../../vehicles.module.scss';
import { IVehicleAdminRead } from 'common/services/vehicle-service/vehicle.service.types';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import RejectVehicleModal from '../../reject-vehicle-modal/reject-vehicle-modal';
import ApproveVehicleModal from '../../approve-vehicle-modal/approve-vehicle-modal';
import configurationStore from 'common/stores/configuration/configuration.store';
import featureStore from 'common/stores/features/feature.store';
import { filterColumnsByPersonalization } from 'common/components/table/table-helpers';
import userPersonalizationStore from 'common/stores/user-personalization/user-personalization.store';
import dayjs from 'dayjs';

const TABLE_NAME = 'vehicle-approvals';

export const VehicleApprovals = observer(() => {
  let columns = vehicleApprovalsInitialColumns;

  if (!featureStore.isCardAccessEnabled) {
    columns = columns.filter((i) => i.key !== 'isNoTransponder');
  }

  const tableControls = useTableControls({
    onFetch: vehicleService.getVehicles,
    initialColumns: filterColumnsByPersonalization(columns, TABLE_NAME),
    onUpdateColumns: async (columns: any[]) => {
      let currentColumns = userPersonalizationStore.personalization?.tableColumns?.[TABLE_NAME];
      currentColumns = columns.filter((c) => !c.hidden).map((c) => c.key);

      await userPersonalizationStore.updateTableColumnsPersonalization(TABLE_NAME, currentColumns);
    },
    defaultAndFilter: { Status: { operator: FilterTypesEnum.Equals, value: 'Pending' } },
  });

  const defaultFacilityCode =
    (configurationStore.configuration?.facilityCodes?.length ?? 0) >= 0 ? configurationStore.configuration?.facilityCodes[0] : null;

  const approveVehicleModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const vehicle: IVehicleAdminRead = tableControls.getSelectedRow();
      if (!vehicle) {
        return;
      }
      const data = {
        vehicleId: vehicle.id,
        deviceId: vehicle.deviceId,
        facilityCode: vehicle.facilityCode || defaultFacilityCode,
        isNoTransponder: vehicle.isNoTransponder,
        validFrom: vehicle.validFrom ? dayjs(vehicle.validFrom) : null,
        validTo: vehicle.validTo ? dayjs(vehicle.validTo) : null,
        cardClass: vehicle.cardClass,
      };
      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        tableControls.refetchData();
      }
    },
  });

  const rejectVehicleModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow: IVehicleAdminRead = tableControls.getSelectedRow();

      if (!selectedRow) {
        return;
      }
      const data = {
        vehicleId: selectedRow.id,
      };
      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        tableControls.refetchData();
      }
    },
  });

  return (
    <>
      <TableContextProvider controls={tableControls}>
        <Card className="eiq-card eiq-card-border full-card">
          <Row className="eiq-card-header">
            <Col span={24}>
              <h1 className="h1">Vehicles Waiting for Approval</h1>
            </Col>
          </Row>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                placeholder="Search by owner name, address of registration"
                rulesForColumn={{
                  owner: FilterTypesEnum.Contains,
                  registrationAddress: FilterTypesEnum.Contains,
                  residentAddress_address_streetNumber: FilterTypesEnum.StartWith,
                  residentAddress_address_street_name: FilterTypesEnum.StartWith,
                }}
              />
            </Col>
            <Col>
              <Space size={8}>
                <Button icon={<ApproveIcon />} size="middle" type="primary" onClick={approveVehicleModal.showModal}>
                  Approve
                </Button>
                <Button
                  icon={<RejectIcon />}
                  size="middle"
                  type="primary"
                  className={styles.rejectButton}
                  onClick={rejectVehicleModal.showModal}
                  danger>
                  Reject
                </Button>
                <ColumnVisibility />
              </Space>
            </Col>
          </Row>
          <TableBase onSelected={tableControls.setSelectedRow} selectFirstRowByDefault />
        </Card>
      </TableContextProvider>
      <RejectVehicleModal
        initData={rejectVehicleModal?.initData}
        title="Reason for rejection"
        isOpen={rejectVehicleModal?.isOpenModal}
        onClose={rejectVehicleModal?.hideModal}
      />
      {approveVehicleModal.isOpenModal && (
        <ApproveVehicleModal
          isOpen={approveVehicleModal.isOpenModal}
          onClose={approveVehicleModal.hideModal}
          title="Approve vehicle"
          initData={approveVehicleModal.initData}
        />
      )}
    </>
  );
});
