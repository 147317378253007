import { Tag } from 'antd';
import { ICellRendererProps } from 'common/components/table/table.types';
import { ICitationRead } from 'common/services/citation-service/citation.service.types';
import dayjs from 'dayjs';
import {
  CitationStatus,
  citationStatusColorMap,
  citationStatusMap,
  citationStatusOptions,
} from 'features/eiq-manage/enums/citation-status.enum';
import { CitationImages } from './citation-images/citation-images';
import { IFilterConfig } from 'common/components/table/filters/column-filter/column-filter.types';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';

export const citationsInitialColumns = [
  {
    key: 'photosUrl',
    title: 'Photos',
    dataKey: 'photosUrl',
    width: 180,
    fixed: 1,
    resizable: false,
    sortable: false,
    cellRenderer: (cell: ICellRendererProps<ICitationRead>) => {
      return cell.cellData && <CitationImages photosUrl={[...cell.rowData.photosUrl, ...cell.rowData.licensePlatePhotosUrl]} />;
    },
  },
  {
    key: 'occurredAt',
    title: 'Date',
    dataKey: 'occurredAt',
    width: 190,
    fixed: 1,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: any) => {
      return cell.cellData && <span>{dayjs(cell.cellData).format('MMM D, YYYY hh:mm:ss a')}</span>;
    },
  },
  {
    key: 'externalId',
    title: 'Citation #',
    dataKey: 'externalId',
    width: 120,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  {
    key: 'residentName',
    title: 'Resident name',
    dataKey: 'residentName',
    width: 160,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  {
    key: 'status',
    title: 'Status',
    dataKey: 'status',
    width: 120,
    fixed: 1,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<ICitationRead>) => {
      return (
        <Tag className="eiq-tag" color={citationStatusColorMap[cell?.rowData?.status as CitationStatus]} style={{ color: '#292B2E' }}>
          {citationStatusMap[cell?.rowData?.status as CitationStatus]}
        </Tag>
      );
    },
  },
  {
    key: 'licensePlate',
    title: 'License plate',
    dataKey: 'licensePlate',
    width: 150,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  {
    key: 'licenseState',
    title: 'State',
    dataKey: 'licenseState',
    width: 100,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  {
    key: 'guestName',
    title: 'Guest name',
    dataKey: 'guestName',
    width: 160,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  {
    key: 'companyName',
    title: 'Company name',
    dataKey: 'companyName',
    width: 160,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  {
    key: 'speedLimit',
    title: 'Speed limit',
    dataKey: 'speedLimit',
    width: 180,
    fixed: 1,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<ICitationRead>) => {
      return cell.cellData && `${cell.cellData} mph`;
    },
  },
  {
    key: 'violatingSpeed',
    title: 'Actual speed',
    dataKey: 'violatingSpeed',
    width: 180,
    minWidth: 180,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<ICitationRead>) => {
      return cell.cellData && `${cell.cellData} mph`;
    },
  },
  {
    key: 'totalPaymentAmount',
    title: 'Fine Amount',
    dataKey: 'totalPaymentAmount',
    width: 170,
    fixed: 1,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<ICitationRead>) => {
      return `$${cell?.rowData?.totalPaymentAmount}`;
    },
  },
  {
    key: 'commissionAmount',
    title: 'Transaction Fees',
    dataKey: 'commissionAmount',
    width: 170,
    fixed: 1,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<ICitationRead>) => {
      return `$${cell?.rowData?.commissionAmount}`;
    },
  },
  {
    key: 'communityPaymentAmount',
    title: 'HOA Receives',
    dataKey: 'communityPaymentAmount',
    width: 150,
    fixed: 1,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<ICitationRead>) => {
      return `$${cell?.rowData?.communityPaymentAmount}`;
    },
  },
];

export const citationFilterConfig: IFilterConfig[] = [
  {
    name: 'status',
    filterType: FilterTypesEnum.Equals,
    label: 'Status',
    type: {
      options: citationStatusOptions,
      type: 'SELECT',
    },
  },
];
