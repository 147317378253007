import { Space, AutoComplete, Input, Button, Row, Col } from 'antd';
import residentsService from 'common/services/residents-service/residents.service';
import { IResidentAddressRead, IResidentAddressListResponse } from 'common/services/residents-service/residents.service.types';
import React, { useCallback, useState } from 'react';
import { IIncidentInvolvedPersonSearchProps } from './incident-involved-person-search.types';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { debounce } from 'lodash';
import { formatAddressLabel } from 'common/helpers/address.helper';

export const IncidentInvolvedPersonSearch = ({ onSelected, onEnterManuallyClick }: IIncidentInvolvedPersonSearchProps) => {
  const [residents, setResidents] = useState<IResidentAddressRead[]>([]);
  const [options, setOptions] = useState<any[]>([]);

  const buildSearchQuery = (search?: string) => {
    if (!search) {
      return null;
    }

    return `name:${search}* OR address:${search}*`;
  };

  const handleSearch = (newValue?: string) => {
    const formatResult = (items: IResidentAddressRead[]) => {
      const result = items.map((item) => ({
        text: `${item.resident.firstName} ${item.resident.lastName} | ${formatAddressLabel(item.address)}`,
        value: item.id.toString(),
      }));

      setOptions(result);
      setResidents(items);
    };

    if (!newValue || newValue.length < 3) {
      formatResult([]);
      return;
    }

    const pageLimit = { page: 1, limit: 100 };

    residentsService
      .getResidentAddresses(pageLimit, buildSearchQuery(newValue), null)
      .then((response: IResidentAddressListResponse) => formatResult(response.items));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 350), []);

  const handleSelect = (id: string) => {
    const selectedItem = residents.find((item) => item.id.toString() === id);

    if (selectedItem) {
      onSelected(selectedItem);
    }
  };

  return (
    <>
      <Space className="full-width" size={13}>
        <Row gutter={{ xs: 24, md: 8 }}>
          <Col xs={24} lg={8}>
            <AutoComplete
              className="full-width"
              defaultActiveFirstOption={false}
              onSearch={debouncedHandleSearch}
              onChange={handleSelect}
              dataSource={options}>
              <Input placeholder="Search by resident name, address" prefix={<SearchIcon />} />
            </AutoComplete>
          </Col>
        </Row>
      </Space>
      <Space className="full-width" size={13}>
        <p className="manual-info-add-label">
          If the resident or the guest involved in the incident is not found in EntranceIQ, please fill in the personal information
          manually.
        </p>
      </Space>

      <Space>
        <Button type="primary" className="primary-btn" icon={<EditIcon />} onClick={onEnterManuallyClick}>
          Enter Manually
        </Button>
      </Space>
    </>
  );
};
