import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import './anonymous-registration.scss';
import { Button, Card, Col, Row, Space, Tag } from 'antd';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import AnonymousRegistrationResidentAddEditModal from './anonymous-registration-add-resident-modal';
import { IResidentAddressRead, IResidentRead } from 'common/services/residents-service/residents.service.types';
import { PlusOutlined } from '@ant-design/icons';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import TableBase from 'common/components/table/table-base';
import { residentAddressesColumns } from 'common/list-configs/resident-addresses.config';
import { ICellRendererProps } from 'common/components/table/table.types';
import { ownershipTypeStatusColorMap, ownershipTypeStatusMap } from 'common/constans/common.constants';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { transformReadToForm } from '../eiq-manage-residents/residents';
import RefreshModal from '../eiq-manage-anonymous-registration/anonymous-registration-refresh-modal';

export const AnonymousRegistration = observer(() => {
  const [owners, setOwners] = useState<any[]>([]);

  const refreshModal = useModal({
    onClose: (isSuccessSaved) => {
      if (isSuccessSaved) {
        setOwners([]);
      }
    },
  });

  const addOwnerRenterModal = useModal({
    onBeforeShow: () => {},
    onClose: (isSuccessSaved, resident) => {
      if (isSuccessSaved) {
        setOwners([...owners, resident]);
      }
    },
  });

  const editOwnerRenterModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow() as IResidentRead;

      if (!selectedRow) {
        return;
      }
      const data = selectedRow.addresses[0];
      data.resident = selectedRow;
      const initState = transformReadToForm(data);
      setModalInitData(initState);
    },
    onClose: (isSuccessSaved, resident: IResidentRead) => {
      if (isSuccessSaved) {
        tableControls.updateSelectedRow(resident);
      }
    },
  });

  const addOccupantModal = useModal({
    onBeforeShow: () => {},
    onClose: (isSuccessSaved, resident) => {
      if (isSuccessSaved) {
        setOwners([...owners, resident]);
      }
    },
  });

  const { firstName, lastName, pin, email } = residentAddressesColumns();

  const getOwnerShipTypeToMap = (residentAddress: IResidentAddressRead) => {
    if (
      residentAddress.ownershipType === ResidentTypesEnum.Renter ||
      residentAddress.relatedTo?.ownershipType === ResidentTypesEnum.Renter
    ) {
      return ResidentTypesEnum.Renter;
    }
    return residentAddress.ownershipType;
  };

  const residentAddressInitialColumns = [
    {
      key: 'ownershipType',
      title: 'Type',
      dataKey: 'ownershipType',
      width: 100,
      minWidth: 100,
      resizable: true,
      sortable: true,
      cellRenderer: (cell: ICellRendererProps<IResidentRead>) => {
        return (
          cell?.rowData?.addresses[0].ownershipType && (
            <Tag
              className="eiq-tag"
              color={ownershipTypeStatusColorMap[getOwnerShipTypeToMap(cell.rowData.addresses[0])]}
              style={{ color: '#292B2E' }}>
              {ownershipTypeStatusMap[cell?.rowData?.addresses[0].ownershipType]}
            </Tag>
          )
        );
      },
    },
    { ...lastName, dataKey: 'lastName' },
    { ...firstName, dataKey: 'firstName' },
    { ...email, hidden: false, dataKey: 'email' },

    {
      key: 'address_streetNumber',
      title: 'Street number',
      dataKey: 'address.streetNumber',
      minWidth: 150,
      width: 200,
      resizable: true,
      sortable: true,
      cellRenderer: (cell: ICellRendererProps<IResidentRead>) => {
        return cell?.rowData?.addresses[0].address.streetNumber;
      },
    },
    {
      key: 'address_streetName',
      title: 'Street name',
      dataKey: 'address.streetName',
      minWidth: 150,
      width: 200,
      resizable: true,
      sortable: true,
      cellRenderer: (cell: ICellRendererProps<IResidentRead>) => {
        return cell?.rowData?.addresses[0].address.street.name;
      },
    },
    { ...pin, dataKey: 'pin' },
  ];

  const tableControls = useTableControls({
    clientSide: true,
    data: owners,
    initialColumns: residentAddressInitialColumns,
  });

  const isSelectedRowOwner = () => {
    const row = tableControls.getSelectedRow() as IResidentRead;
    const ownerShipType = row.addresses[0].ownershipType;
    return ownerShipType === ResidentTypesEnum.Renter || ownerShipType === ResidentTypesEnum.Owner;
  };

  return (
    <Card className="eiq-card eiq-card-border full-card anonymous-registration">
      <Row className="eiq-card-header">
        <Col span={24}>
          <h1 className="h1">Resident Creation Page</h1>
          <span>
            Welcome to your new visitor and resident management system. The data you are entering will be used in the new EntranceIQ system.
            Please fill in all the fields as much as possible. For each occupant in your address, please add this form one more time.
            <br /> <i>Please do not close this page until you add all the occupants.</i>
          </span>
        </Col>
      </Row>

      {!!owners.length && (
        <div>
          <TableContextProvider controls={tableControls}>
            <div>
              <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
                <Col>
                  <Space size={8}>
                    <Button size="middle" onClick={editOwnerRenterModal.showModal} disabled={!tableControls.getSelectedRow()}>
                      Edit
                    </Button>
                  </Space>
                </Col>
              </Row>
              <TableBase onSelected={tableControls.setSelectedRow} />
            </div>
          </TableContextProvider>
        </div>
      )}
      <Button
        type="primary"
        icon={<PlusOutlined />}
        size="middle"
        onClick={addOwnerRenterModal.showModal}
        className="anonymous-registration-btn">
        Add Owner or Renter
      </Button>
      <span className="btn-description">
        <i>You can add an Owner or Renter by clicking the button above.</i>
      </span>

      <Button
        type="primary"
        icon={<PlusOutlined />}
        size="middle"
        onClick={addOccupantModal.showModal}
        disabled={!owners?.length}
        className="anonymous-registration-btn">
        Add Occupant
      </Button>
      <span className="btn-description">
        <i>
          You can add an occupant like a spouse, child, roommate or partner by clicking the button above. Please don't forget to choose who
          this occupant is related to.
        </i>
      </span>

      <span>
        <i>
          If you have added all the owners, renters and occupants you can complete the process by clicking the button below. This will take
          you back to the start. Please keep in mind, emails can be used only once per resident, they can’t be used for another resident.
        </i>
      </span>

      <Button
        type="primary"
        size="middle"
        onClick={refreshModal.showModal}
        className="anonymous-registration-btn"
        disabled={!owners.length}>
        Complete Registration
      </Button>

      {editOwnerRenterModal.isOpenModal && (
        <AnonymousRegistrationResidentAddEditModal
          {...editOwnerRenterModal?.initData}
          title="Edit"
          isEdit={true}
          isOpen={editOwnerRenterModal.isOpenModal}
          onClose={editOwnerRenterModal.hideModal}
          isOwner={isSelectedRowOwner()}
          residents={owners.filter((item: IResidentRead) => {
            let result =
              item.addresses[0].ownershipType === ResidentTypesEnum.Renter || item.addresses[0].ownershipType === ResidentTypesEnum.Owner;
            if (!isSelectedRowOwner()) {
              const selected: IResidentRead = tableControls.getSelectedRow();
              result = result && item.addresses[0].address.id === selected.addresses[0].address.id;
            }
            return result;
          })}
        />
      )}

      {addOwnerRenterModal.isOpenModal && (
        <AnonymousRegistrationResidentAddEditModal
          title="Add"
          isEdit={false}
          isOpen={addOwnerRenterModal.isOpenModal}
          onClose={addOwnerRenterModal.hideModal}
          initData={addOwnerRenterModal?.initData}
          isOwner={true}
        />
      )}

      {addOccupantModal.isOpenModal && (
        <AnonymousRegistrationResidentAddEditModal
          title="Add"
          isEdit={false}
          isOpen={addOccupantModal.isOpenModal}
          onClose={addOccupantModal.hideModal}
          initData={addOccupantModal?.initData}
          isOwner={false}
          residents={owners.filter((i) => {
            const r = i.addresses[0].ownershipType === ResidentTypesEnum.Renter || i.addresses[0].ownershipType === ResidentTypesEnum.Owner;
            return r;
          })}
        />
      )}

      <RefreshModal title="Complete registration" isOpen={refreshModal?.isOpenModal} onClose={refreshModal?.hideModal} />
    </Card>
  );
});
