import { Button, Checkbox, Dropdown } from 'antd';
import React, { useState } from 'react';
import { useTableContext } from '../../table-context';
import './column-check-filter.scss';
import { FilterTypesEnum } from '../../../../enums/filter-types.enum';
import { ColumnCheckFilterProps } from './column-check-filter.types';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

export const ColumnCheckFilter = ({ options, name, contentClass }: ColumnCheckFilterProps) => {
  const tableContext = useTableContext();
  const [open, setOpen] = useState<boolean>(false);
  const [checkedValues, setCheckedValues] = useState<string[]>(options.map((opt) => opt.value));
  const [filterButtonClassName, setFilterButtonClassName] = useState('active-filter');

  const onCheckAllChange = (e: any) => {
    setCheckedValues(e.target.checked ? options.map((opt) => opt.value) : []);
  };

  const onGroupChange = (checkedValues: CheckboxValueType[]) => {
    setCheckedValues(checkedValues as string[]);
  };

  const onFinish = () => {
    const filters = checkedValues.map((value) => {
      return { operator: FilterTypesEnum.Equals, value: value };
    });

    if (checkedValues.length !== 0) {
      setFilterButtonClassName('active-filter');
    } else setFilterButtonClassName('');

    console.log(name, 'filters', filters);
    tableContext.setFilter(name, filters, 'AND');
    setOpen(false);
  };

  const content = () => (
    <div className={`column-check-filter ${contentClass}`}>
      <div className="column-visibility__header">
        <span>Filters</span>
        <Button onClick={onFinish} type="link" size={'small'}>
          Apply
        </Button>
      </div>
      <Checkbox onChange={onCheckAllChange} checked={checkedValues.length === options.length}>
        All guest types
      </Checkbox>
      <Checkbox.Group options={options} value={checkedValues} onChange={onGroupChange} />
    </div>
  );

  return (
    <Dropdown open={open} onOpenChange={setOpen} dropdownRender={content} trigger={['click']}>
      <Button type="default" size={'middle'} className={`mobile-btn ${filterButtonClassName}`} icon={<FilterIcon />}>
        Filter
      </Button>
    </Dropdown>
  );
};
