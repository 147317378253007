import { Row, Space, Card, Collapse } from 'antd';
import incidentReportService from 'common/services/incident-report/incident-report.service';
import {
  IIncidentAttachment,
  IIncidentInvolvedPerson,
  IIncidentInvolvedVehicle,
  IIncidentReportRead,
  IIncidentReportRequest,
} from 'common/services/incident-report/incident-report.types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IncidentInvolvedPersons from '../../components/incident-report/incident-involved-persons/incident-involved-persons';
import IncidentInvolvedVehicles from '../../components/incident-report/incident-involved-vehicles/incident-involved-vehicles';
import { IncidentAttachment } from '../../components/incident-report/incident-attachment/incident-attachment';
import { IReportingPersonFormValues } from '../../components/incident-report/incident-reporting-person/incident-reporting-person-edit/incident-reporting-person-edit.types';
import { IncidentReportingPerson } from '../../components/incident-report/incident-reporting-person/incident-reporting-person';
import IncidentLocation from '../../components/incident-report/incident-location/incident-location';
import { IIncidentLocationFormValues } from '../../components/incident-report/incident-location/incident-location-edit/incident-location-edit.types';
import IncidentDetails from '../../components/incident-report/incident-details/incident-details';
import { IIncidentDetailsFormValues } from '../../components/incident-report/incident-details/incident-details-edit/incident-details-edit.types';
import dayjs from 'dayjs';
import {
  ATTACHMENT_SECTION_NAME,
  DETAILS_SECTION_NAME,
  INVOLVED_PEOPLE_SECTION_NAME,
  INVOLVED_VEHICLES_SECTION_NAME,
  LOCATION_SECTION_NAME,
  REPORTING_PERSON_SECTION_NAME,
} from 'common/constans/incident-report.constants';
import { SH } from '../../components';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { IViewIncidentReportProps } from './view-incident-report.types';

const convertToRequest = (incident: IIncidentReportRead): IIncidentReportRequest => {
  return {
    reportingPerson: incident.reportingPerson,
    description: incident.description,
    gate: incident.gate,
    addressId: incident.address.id,
    incidentDate: incident.incidentDate,
    category: incident.category,
    subCategory: incident.subCategory,
    weatherCondition: incident.weatherCondition,
    isEmsResponseRequired: incident.isEmsResponseRequired,
    isFireDepartmentResponseRequired: incident.isFireDepartmentResponseRequired,
    isPoliceDepartmentResponseRequired: incident.isPoliceDepartmentResponseRequired,
    involvedPersons: incident.involvedPersons,
    involvedVehicles: incident.involvedVehicles,
    attachments: incident.attachments,
  };
};

const ViewIncidentReport = ({ baseRoute }: IViewIncidentReportProps) => {
  const [incidentReport, setIncidentReport] = useState<IIncidentReportRead>();
  const { reportId } = useParams<{ reportId: string }>();

  useEffect(() => {
    const id = reportId ? parseInt(reportId, 10) : undefined;
    if (id !== undefined) {
      incidentReportService.getIncidentById(id).then((report: IIncidentReportRead) => {
        setIncidentReport(report);
      });
    }
  }, [reportId]);

  const handleInvolvedPeopleChange = (values: IIncidentInvolvedPerson[]) => {
    updateIncidentReport({ ...convertToRequest(incidentReport!), involvedPersons: values });
  };

  const handleInvolvedVehiclesChange = (values: IIncidentInvolvedVehicle[]) => {
    updateIncidentReport({ ...convertToRequest(incidentReport!), involvedVehicles: values });
  };

  const handleAttachmentChange = (values: IIncidentAttachment[]) => {
    updateIncidentReport({ ...convertToRequest(incidentReport!), attachments: values });
  };

  const handleReportingPersonChange = (values: IReportingPersonFormValues) => {
    updateIncidentReport({ ...convertToRequest(incidentReport!), reportingPerson: values.reportingPerson });
  };

  const handleIncidentLocationChange = (values: IIncidentLocationFormValues) => {
    const request = {
      ...convertToRequest(incidentReport!),
      gate: values.gate,
      addressId: values?.address?.addressId,
      incidentDate: dayjs(values.incidentDate).unix(),
    };

    updateIncidentReport(request);
  };

  const handleIncidentDetailsChange = (values: IIncidentDetailsFormValues) => {
    const request = {
      ...convertToRequest(incidentReport!),
      description: values.description,
      category: values.category,
      subCategory: values.subCategory,
      weatherCondition: values.weatherCondition,
      isEmsResponseRequired: values.isEmsResponseRequired,
      isFireDepartmentResponseRequired: values.isFireDepartmentResponseRequired,
      isPoliceDepartmentResponseRequired: values.isPoliceDepartmentResponseRequired,
    };

    updateIncidentReport(request);
  };

  const updateIncidentReport = (request: IIncidentReportRequest) => {
    incidentReportService.updateIncident(incidentReport!.id, request).then((report: IIncidentReportRead) => {
      setIncidentReport(report);
    });
  };

  const items = [
    {
      key: 1,
      label: REPORTING_PERSON_SECTION_NAME,
      children: <IncidentReportingPerson incident={incidentReport!} onSave={handleReportingPersonChange} />,
    },
    {
      key: 2,
      label: LOCATION_SECTION_NAME,
      children: <IncidentLocation incident={incidentReport!} onSave={handleIncidentLocationChange} />,
    },
    {
      key: 3,
      label: DETAILS_SECTION_NAME,
      children: <IncidentDetails incident={incidentReport!} onSave={handleIncidentDetailsChange} />,
    },
    {
      key: 4,
      label: INVOLVED_PEOPLE_SECTION_NAME,
      children: <IncidentInvolvedPersons initialValues={incidentReport?.involvedPersons} onChange={handleInvolvedPeopleChange} />,
    },
    {
      key: 5,
      label: INVOLVED_VEHICLES_SECTION_NAME,
      children: <IncidentInvolvedVehicles initialValues={incidentReport?.involvedVehicles} onChange={handleInvolvedVehiclesChange} />,
    },
    {
      key: 6,
      label: ATTACHMENT_SECTION_NAME,
      children: <IncidentAttachment initialValue={incidentReport?.attachments} onChange={handleAttachmentChange} />,
    },
  ];

  const getBackLink = () => {};

  return (
    <>
      {incidentReport && (
        <div className="eiq-secure-incident-report-page">
          <Card className="eiq-card eiq-card-border" style={{ width: '100%' }}>
            <Row justify="space-between">
              <Space size={12}>
                <SH.HotkeyLink
                  to={`${baseRoute}/incident-reports`}
                  className="go-back"
                  label={
                    <>
                      <BackIcon />
                    </>
                  }
                />
                <h2 className="main-title create-report-title">Report {incidentReport?.caseNumber}</h2>
              </Space>
            </Row>
            <div className="collapse-container">
              <Collapse accordion={true} defaultActiveKey={1} items={items} expandIconPosition="end" size="large" />
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default ViewIncidentReport;
