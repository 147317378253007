import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { AddressesManagementTableInitialColumns } from '../../addresses.config';
import addressesService from 'common/services/addresses-service/addresses.service';
import { Card, Col, Row } from 'antd';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import TableBase from 'common/components/table/table-base';
import InfoBlock from 'common/components/info-block/info-block';
import EiqTabsTitleLeft from 'common/components/info-block/title-left/eiq-tabs-title-left';
import { TabsProps } from 'antd/lib';
import EiqTabs from 'common/components/tabs/eiq-tabs';
import addressesStore from 'features/eiq-manage/stores/addresses.store';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { IAddress, IStreetDetails } from 'common/services/street-service/street.service.types';
import { formatAddressLabel } from 'common/helpers/address.helper';
import { Residents } from '../../components/info-block/residents/residents';
import { Pets } from '../../components/info-block/pets/pets';
import { Vehicles } from '../../components/info-block/vehicles/vehicles';
import { AccessCards } from '../../components/info-block/access-cards/access-cards';
import featureStore from 'common/stores/features/feature.store';

const AddressesManagement = observer(() => {
  const tableControls = useTableControls({
    onFetch: addressesService.getAddresses,
    initialColumns: AddressesManagementTableInitialColumns,
  });

  const items: TabsProps['items'] = [
    {
      key: 'Residents',
      label: 'Residents',
      children: <Residents />,
    },
    {
      key: 'Vehicles',
      label: 'Vehicles',
      children: <Vehicles />,
    },
  ];

  if (featureStore.isCardAccessEnabled) {
    items.push({
      key: 'Devices',
      label: 'Other Devices',
      children: <AccessCards />,
    });
  }

  items.push({
    key: 'Pets',
    label: 'Pets',
    children: <Pets />,
  });

  const getStreetAddresses = () => {
    const selectedRow: IAddress = tableControls.getSelectedRow();
    if (selectedRow) {
      addressesService.getStreetDetails(selectedRow.street.id).then((data: IStreetDetails) => {
        addressesStore.setStreetDetails(data);
        addressesStore.setSelectedAddressId(selectedRow.id);
      });
    }
  };

  useEffect(() => {
    getStreetAddresses();
  }, [tableControls.getSelectedRow()]);

  const getTitle = () => {
    const address: IAddress = tableControls.getSelectedRow();

    if (!address) {
      return '';
    }
    return `${address.streetNumber} ${address.street.name}, ${formatAddressLabel(address, { hideStreet: true })}`;
  };

  return (
    <TableContextProvider controls={tableControls}>
      <Card className="eiq-card eiq-card-border">
        <Row className="eiq-card-header">
          <Col span={24}>
            <h1 className="h1">Address</h1>
          </Col>
        </Row>
        <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
          <Col flex="auto">
            <SearchFilter
              placeholder="Search by street number, street name, address, community"
              rulesForColumn={{
                streetNumber: FilterTypesEnum.Contains,
                street_name: FilterTypesEnum.Contains,
                street_community_name: FilterTypesEnum.Contains,
                blockNumber: FilterTypesEnum.Contains,
                lotNumber: FilterTypesEnum.Contains,
                unit: FilterTypesEnum.Contains,
                name: FilterTypesEnum.Contains,
                buildingNumber: FilterTypesEnum.Contains,
              }}
            />
          </Col>
        </Row>
        <TableBase onSelected={tableControls.setSelectedRow} selectFirstRowByDefault />
      </Card>

      <InfoBlock>
        <EiqTabs defaultActiveKey="Info" items={items} tabBarGutter={16} left={<EiqTabsTitleLeft title={getTitle()} />} />
      </InfoBlock>
    </TableContextProvider>
  );
});

export default AddressesManagement;
