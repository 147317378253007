import { Button, Space } from 'antd';
import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export const PasswordReset = () => {
  const navigate = useNavigate();
  return (
    <Space direction="vertical">
      <div className="title">Password reset</div>
      <span className="text ant-mb-32">
        Your password has been successfully reset. <br /> Click below to log in.
      </span>

      <div className="action-line">
        <Button type="primary" onClick={() => navigate('/login')} icon={<RightOutlined />} style={{ width: '216px' }}>
          Log in
        </Button>
      </div>
    </Space>
  );
};
