import { Button, Checkbox, Dropdown, Form } from 'antd';
import React, { useState } from 'react';
import { FormWrapper } from '../../../form-wrapper/form-wrapper';
import { ReactComponent as ColumnIcon } from 'assets/icons/columns.svg';
import { useTableContext } from '../../table-context';
import { ColumnVisibilityProps } from '../../table.types';
import './column-visibility.scss';

export const ColumnVisibility = ({ contentClass }: { contentClass?: string }) => {
  const tableContext = useTableContext();
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onFinish = (value: ColumnVisibilityProps) => {
    tableContext.hideColumns(value);
    setOpen(false);
    console.log(value);
  };

  const content = (
    <div className={`column-visibility ${contentClass ?? ''}`}>
      <FormWrapper onFinish={onFinish} form={form}>
        <div className="column-visibility__header">
          <span>Columns</span>
          <Button htmlType="submit" type="link" size={'small'}>
            Apply
          </Button>
        </div>

        {tableContext.columns &&
          tableContext.columns.map((column) => (
            <Form.Item key={column.key} name={column.key} valuePropName="checked" initialValue={!column.hidden}>
              <Checkbox>{column.title}</Checkbox>
            </Form.Item>
          ))}
      </FormWrapper>
    </div>
  );

  return (
    <Dropdown open={open} onOpenChange={setOpen} dropdownRender={() => content} trigger={['click']}>
      <Button type="default" icon={<ColumnIcon />} size={'middle'} className="mobile-btn">
        Columns
      </Button>
    </Dropdown>
  );
};
