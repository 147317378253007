import { Button, Form, notification } from 'antd';
import TextEditor from 'common/components/form-elements/editor/editor';
import { EditorProps } from 'common/components/form-elements/editor/editor.types';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import notificationService from 'common/services/notification-service/notification-service';
import Quill from 'quill';
import { useRef, useState } from 'react';

export const PushNotifications = () => {
  const [message, setMessage] = useState<string>('');
  const [textWithoutHtml, setTextWithoutHtml] = useState<string>('');

  const quillRef = useRef<Quill | null>(null);

  const handleTextChange = (content: string) => {
    setMessage(content);
  };

  const handleQuillChange = () => {
    if (quillRef.current) {
      const content = quillRef.current.root.innerHTML;
      setTextWithoutHtml(quillRef.current.getText().trim());
      handleTextChange(content);
    }
  };

  const handleSendNotification = async () => {
    try {
      await notificationService.sendPushMassNotification(message);
      notification.success({
        message: 'Success',
        description: 'Push notification sent successfully!',
        duration: 1.5,
      });
    } catch (error) {
      console.error('Failed to send push notification:', error);
    }
  };

  const PushEditorProps: EditorProps = {
    readOnly: false,
    onTextChange: handleQuillChange,
    maxLength: 225,
    toolbarOptions: {
      enableLink: true,
      enableClean: true,
    },
  };

  return (
    <FormWrapper layout="vertical">
      <Form.Item label="Push message">
        <TextEditor {...PushEditorProps} ref={quillRef} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" onClick={handleSendNotification} disabled={textWithoutHtml.length === 0}>
          Send push notification
        </Button>
      </Form.Item>
    </FormWrapper>
  );
};
