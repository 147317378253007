import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { Button, Form, Input, Modal, Space, Switch } from 'antd';
import { DatePicker } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { notification } from 'common/utils/notification';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { IResidentForm } from '../../../../eiq-manage/pages/eiq-manage-residents/residents.type';
import residentsService from 'common/services/residents-service/residents.service';
import { IResidentUpdate } from 'common/services/residents-service/residents.service.types';
import { DATE_FORMAT_MMMM_D_YYYY } from 'common/constans/date.constants';
import configurationStore from 'common/stores/configuration/configuration.store';
import { observer } from 'mobx-react-lite';

const initialValues = {};

const EditGeneralViewModal = observer(({ isOpen, onClose, isEdit, title, extraData, initState }: any) => {
  const [form] = Form.useForm();
  const isAlertMsgEditable = configurationStore.configuration?.default.isAlertMessageEditableForSecure;

  const hideModal = (...rest: any) => {
    notification.destroy();
    form.resetFields();
    setIsVacation(false);
    if (onClose) {
      onClose(...rest);
    }
  };

  const onFinish = async (values: IResidentForm) => {
    const { addressId, vacationPeriod, ...rest } = values;

    const body: IResidentUpdate = {
      id: initState?.id,
      ...rest,
      vacationPeriod:
        vacationPeriod?.startDate && vacationPeriod?.endDate
          ? {
              startDate: isVacation ? vacationPeriod?.startDate?.format('YYYY-MM-DD') ?? null : null,
              endDate: isVacation ? vacationPeriod?.endDate?.format('YYYY-MM-DD') ?? null : null,
            }
          : undefined,
    };

    const res = await residentsService.updateResident(body);
    hideModal(true, res);
  };

  // Vocation
  const [isVacation, setIsVacation] = useState(extraData?.isVacation);

  useEffect(() => {
    setIsVacation(!!extraData?.isVacation);
  }, [isOpen]);

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initState);
    }
  }, [isOpen, form, initState]);

  return (
    <Modal centered title={title} width={328} open={isOpen} wrapClassName="custom-modal custom-modal--so" closable={false}>
      <FormWrapper
        form={form}
        onFinish={onFinish}
        name={`residentForm+${title}`}
        layout="vertical"
        autoComplete="on"
        initialValues={initState ?? initialValues}>
        {isAlertMsgEditable && (
          <Space className="full-width">
            <Form.Item name="alertMessage" label="Alert Message">
              <Input.TextArea placeholder="Enter alert message" autoSize={{ minRows: 8 }} />
            </Form.Item>
          </Space>
        )}

        <Space className="full-width" size={13}>
          <Form.Item label="Vacation" valuePropName="checked">
            <Switch
              checked={isVacation}
              onChange={(checked) => {
                setIsVacation(checked);
              }}
            />
          </Form.Item>
        </Space>

        <Space className="full-width">
          <Form.Item name={['vacationPeriod', 'startDate']} label="From">
            <DatePicker disabled={!isVacation} format={DATE_FORMAT_MMMM_D_YYYY} placeholder="Start date" />
          </Form.Item>
        </Space>

        <Space className="full-width">
          <Form.Item name={['vacationPeriod', 'endDate']} label="To">
            <DatePicker disabled={!isVacation} format={DATE_FORMAT_MMMM_D_YYYY} placeholder="End date" />
          </Form.Item>
        </Space>

        <Space className="footer">
          <Button key="cancel" type="link" onClick={() => hideModal()} style={{ width: '146px' }}>
            Cancel
          </Button>

          <Button key="residentForm" htmlType="submit" type="primary" style={{ width: '146px' }} icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
});

export default React.memo(EditGeneralViewModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
