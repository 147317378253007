import { ServerClient } from 'common/utils/http/http-clients';

class ExportService {
  async processResidents(): Promise<any> {
    const res = await ServerClient.post<any>(`/export/csv/residents`, null, { responseType: 'blob' });
    return res?.data;
  }
  async processResidentGuests(): Promise<any> {
    const res = await ServerClient.post<any>(`/export/csv/residents/guests`, null, { responseType: 'blob' });
    return res?.data;
  }
  async processResidentVehicles(): Promise<any> {
    const res = await ServerClient.post<any>(`/export/csv/residents/vehicles`, null, { responseType: 'blob' });
    return res?.data;
  }
}

export const exportService = new ExportService();
export default exportService;
