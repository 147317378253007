import { ServerClient } from 'common/utils/http/http-clients';
import { ICitationMove, ICitationRead, IEditCitationLicensePlate } from './citation.service.types';

class CitationService {
  async getCitations(pageLimit: any, filter: string | null | undefined, sort: string | null | undefined): Promise<ICitationRead> {
    const res = await ServerClient.get<ICitationRead>('citations', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }
  async cancel(id: number): Promise<void> {
    const res = await ServerClient.post(`citations/${id}/actions/cancel`);
    return res?.data;
  }

  async approve(id: number): Promise<void> {
    const res = await ServerClient.post(`citations/${id}/actions/approve`);
    return res?.data;
  }

  async move(citationId: number, model: ICitationMove): Promise<ICitationRead> {
    const res = await ServerClient.post(`citations/${citationId}/move`, model);
    return res?.data;
  }

  async editLicensePlate(citationId: number, model: IEditCitationLicensePlate): Promise<ICitationRead> {
    const res = await ServerClient.put(`citations/${citationId}/license-plate`, model);
    return res?.data;
  }
}

export const citationService = new CitationService();
export default citationService;
