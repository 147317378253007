import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { Button, Card, Col, Row, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import TableBase from 'common/components/table/table-base';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { observer } from 'mobx-react-lite';
import { KeysManagementTableInitialColumns } from '../../addresses.config';
import React from 'react';
import { useModal } from '../../../../../../common/hooks/use-modal/use-modal.hook';
import KeyAddEditModal from '../../components/key-add-edit-modal/key-add-edit-modal';
import ConfirmDeleteModal, {
  ConfirmDeleteItemName,
} from '../../../../../../common/components/modal/confirm-delete-modal/confirm-delete-modal';
import { IConfirmDeleteModalProps } from '../../../../../../common/components/modal/confirm-delete-modal/confirm-delete-modal.types';
import addressesKeyService from '../../../../../../common/services/addresses-key-service/addresses-key.service';
import { IssuedKeyCannotBeRemoved } from 'common/constans/errorCodes.constants';
import { notification } from 'common/utils/notification';

export const KeysManagement = observer(() => {
  const tableControls = useTableControls({
    onFetch: addressesKeyService.getKeys,
    initialColumns: KeysManagementTableInitialColumns,
  });

  const addKeyModal = useModal({
    onClose: (isSuccess) => isSuccess && tableControls.refetchData(),
  });

  const editKeyModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }
      setModalInitData(selectedRow);
    },
    onClose: (isSuccess, updatedRow) => {
      if (isSuccess) {
        tableControls.updateSelectedRow(updatedRow);
      }
    },
  });

  const getDeleteMessage = (name: string) => {
    return (
      <span>
        <span>Are you sure you want to delete </span>
        <ConfirmDeleteItemName name={name} />
        <span> from a key list?</span>
      </span>
    );
  };

  const deleteKeyModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      const data: IConfirmDeleteModalProps = {
        message: getDeleteMessage(`${selectedRow.name} - ${selectedRow.keyNumber}`),
        onDelete: () => {
          addressesKeyService
            .deleteKey(selectedRow.address.id, selectedRow.keyNumber)
            .then((result) => {
              deleteKeyModal.hideModal(true);
            })
            .catch((error) => {
              if (error.response?.data?.errorCode === IssuedKeyCannotBeRemoved) {
                notification.destroy();
                notification.error({ message: 'The key cannot be deleted because it is currently given out' });
                return;
              }
              throw error;
            });
        },
      };

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        tableControls.setSelectedRow(null);
        tableControls.refetchData();
      }
    },
  });

  return (
    <>
      <TableContextProvider controls={tableControls}>
        <Card className="eiq-card eiq-card-border">
          <Row className="eiq-card-header">
            <Col span={24}>
              <h1 className="h1">Keys management</h1>
            </Col>
          </Row>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                placeholder="Search by key number, address"
                rulesForColumn={{
                  keyNumber: FilterTypesEnum.Contains,
                  address_name: FilterTypesEnum.Contains,
                  address_street_name: FilterTypesEnum.Contains,
                  address_streetNumber: FilterTypesEnum.Contains,
                  address_buildingNumber: FilterTypesEnum.Equals,
                  address_blockNumber: FilterTypesEnum.Equals,
                  address_lotNumber: FilterTypesEnum.Equals,
                  address_unit: FilterTypesEnum.Equals,
                }}
              />
            </Col>
            <Col>
              <Space size={8}>
                <Button
                  icon={<DeleteIcon stroke="#505762" />}
                  size="middle"
                  disabled={!tableControls.getSelectedRow()}
                  onClick={deleteKeyModal.showModal}>
                  Delete
                </Button>
                <Button icon={<EditIcon />} size="middle" disabled={!tableControls.getSelectedRow()} onClick={editKeyModal.showModal}>
                  Edit
                </Button>
                <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={addKeyModal.showModal}>
                  Add
                </Button>
              </Space>
            </Col>
          </Row>
          <TableBase onSelected={tableControls.setSelectedRow} selectFirstRowByDefault />
        </Card>
      </TableContextProvider>
      <ConfirmDeleteModal
        isEdit={false}
        initData={deleteKeyModal?.initData}
        title="Delete"
        isOpen={deleteKeyModal?.isOpenModal}
        onClose={deleteKeyModal?.hideModal}
      />
      <KeyAddEditModal title="Add" isEdit={false} isOpen={addKeyModal.isOpenModal} onClose={addKeyModal.hideModal} />
      <KeyAddEditModal
        title="Edit"
        isEdit={true}
        initData={editKeyModal?.initData}
        isOpen={editKeyModal.isOpenModal}
        onClose={editKeyModal.hideModal}
      />
    </>
  );
});
