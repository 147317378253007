import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Select, Space, TreeSelect } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { IModal } from 'common/models/model.interface';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { GuestValidity } from 'common/enums/guest-validity.enum';
import { DATE_SHORT_MMM_DD_YYYY, DATE_YYYY_MM_DD } from 'common/constans/date.constants';
import { ICreateGuest, IUpdateGuest } from 'common/services/guest-service/guest.service.types';
import guestService from 'common/services/guest-service/guest.service';
import { IAddEditGuestModal } from '../guest-list.types';
import './add-edit-guest-modal.scss';
import { guestTypeOptions, serviceGuestTypeOptions } from 'common/constans/guest-type-options.constant';
import { keyPermissionOptions } from 'features/eiq-manage/constants/guest-key-permission-options.constant';
import { validityOptions } from 'common/constans/guest-validity-options.constant';
import { daysOfWeekOptions } from 'features/eiq-manage/constants/guest-days-of-week.options.constant';
import { addEditGuestValidation } from '../../../../../../../../common/validation/guest.validation';
import { daysOfWeek } from 'common/constans/common.constants';
import { GuestType } from 'common/enums/guest-type.enum';
import { notification } from '../../../../../../../../common/utils/notification';
import { getVisitDateByGuestValidity, mapToServerGuestValidity } from 'common/helpers/guest-validity.helper';
import SelectState from 'common/components/form-elements/select-state/select-state';
import residentStore from 'features/eiq-manage/stores/resident.store';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';

const AddEditGuestModal: FC<IModal<IAddEditGuestModal, any>> = ({ isOpen, onClose, title, initData, isEdit }) => {
  const [form] = Form.useForm();
  const { SHOW_PARENT } = TreeSelect;
  const { TextArea } = Input;

  const [isShowDateRange, setIsShowDateRange] = useState<boolean>(false);
  const [isShowDaysOfVisits, setIsShowDaysOfVisits] = useState<boolean>(false);
  const [isShowCompanyName, setIsShowCompanyName] = useState<boolean>(false);
  const [isShowVisitDate, setIsShowVisitDate] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    let days = [];
    if (values.validity === GuestValidity.Permanent) {
      days = values.daysOfWeek[0] === 'SelectAll' ? daysOfWeek : values.daysOfWeek;
    }

    const getDatePeriod = (startDate: any, endDate: any) => {
      return {
        startDate: isShowDateRange ? startDate.format(DATE_YYYY_MM_DD) : null,
        endDate: isShowDateRange ? endDate.format(DATE_YYYY_MM_DD) : null,
      };
    };

    const guest: ICreateGuest = {
      firstName: values.firstName,
      lastName: values.lastName,
      type: values.type,
      validity: mapToServerGuestValidity(values.validity),
      isKeyPermissionAllowed: values.isKeyPermissionAllowed,
      daysOfWeek: isShowDaysOfVisits ? days : [],
      period: getDatePeriod(values.startDate, values.endDate),
      restrictions: values.restrictions,
      companyName: isShowCompanyName ? values.companyName : '',
      visitDate: getVisitDateByGuestValidity(values.validity, values.visitDate),
      carNumber: values.carNumber,
      carState: values.carState,
    };
    if (isEdit) {
      const guestToUpdate = guest as IUpdateGuest;
      guestToUpdate.id = initData!.id!;

      const result = await guestService.updateGuest(initData!.residentId, initData!.addressId, guestToUpdate);
      hideModal(true, result);
    } else {
      const result = await guestService.createGuest(initData!.residentId, initData!.addressId, guest);
      hideModal(true, result);
    }
  };

  const hideModal = (...rest: any) => {
    form.resetFields();
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };
  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData);
      if (initData?.validity) {
        validityChangeHandle(initData.validity);
      }
      if (initData?.type) {
        typeChangeHandle(initData.type);
      }
    }
  }, [isOpen, form, initData]);

  const validityChangeHandle = (validity: string) => {
    if (validity === GuestValidity.DateRange) {
      setIsShowDateRange(true);
    } else {
      setIsShowDateRange(false);
    }
    if (validity === GuestValidity.Permanent) {
      const guestDaysOfWeek = initData?.daysOfWeek;
      if (!guestDaysOfWeek || guestDaysOfWeek.length === 0) {
        form.setFieldsValue({ daysOfWeek: daysOfWeek });
      }
      setIsShowDaysOfVisits(true);
    } else {
      setIsShowDaysOfVisits(false);
    }
    if (validity === GuestValidity.OneTime) {
      setIsShowVisitDate(true);
    } else {
      setIsShowVisitDate(false);
    }
  };

  const typeChangeHandle = (guestType: GuestType) => {
    if (isServiceGuest(guestType)) {
      setIsShowCompanyName(true);
    } else {
      setIsShowCompanyName(false);
    }

    if (guestType === GuestType.UrgentService && !form.getFieldValue('validity')) {
      form.setFieldValue('validity', GuestValidity.Today);
    }
  };

  const getGuestTypes = useCallback(() => {
    if (residentStore.selectedResidentAddress?.ownershipType === ResidentTypesEnum.Developer) {
      return serviceGuestTypeOptions.filter((option) => option.value !== GuestType.UrgentService);
    } else {
      return guestTypeOptions;
    }
  }, []);

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} initialValues={initData} layout="vertical">
        <Space className="full-width" size={13}>
          <Form.Item name="type" label="Type" rules={addEditGuestValidation.type}>
            <Select options={getGuestTypes()} onChange={typeChangeHandle} placeholder="Select a type" />
          </Form.Item>
          <Form.Item name="validity" label="Validity" rules={addEditGuestValidation.validity}>
            <Select options={validityOptions} onChange={validityChangeHandle} placeholder="Select a validity" />
          </Form.Item>
        </Space>
        {isShowDateRange && (
          <Space className="full-width" size={13}>
            <Form.Item name="startDate" label="From" rules={addEditGuestValidation.startDate}>
              <DatePicker placeholder="Start date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
            <Form.Item name="endDate" label="To" rules={addEditGuestValidation.endDate} dependencies={['startDate']}>
              <DatePicker placeholder="End date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
          </Space>
        )}
        {(isShowVisitDate || isShowDaysOfVisits) && (
          <Space className="full-width" size={13}>
            {isShowVisitDate && (
              <Form.Item name="visitDate" label="Visit date" rules={addEditGuestValidation.visitDate}>
                <DatePicker placeholder="Visit date" format={DATE_SHORT_MMM_DD_YYYY} />
              </Form.Item>
            )}
            {isShowDaysOfVisits && (
              <Form.Item name="daysOfWeek" label="Days of week for visits" rules={addEditGuestValidation.daysOfWeek}>
                <TreeSelect
                  popupClassName="eiq-select-days"
                  treeDefaultExpandAll={true}
                  treeCheckable={true}
                  treeData={daysOfWeekOptions}
                  showCheckedStrategy={SHOW_PARENT}
                  maxTagCount={1}
                  showSearch={false}
                  placeholder="Select a days of week for visits"
                />
              </Form.Item>
            )}
          </Space>
        )}
        {isShowCompanyName && (
          <Space className="full-width" size={13}>
            <Form.Item name="companyName" label="Company name" rules={addEditGuestValidation.companyName}>
              <Input name="companyName" placeholder="Enter company name" />
            </Form.Item>
          </Space>
        )}
        <Space className="full-width" size={13}>
          <Form.Item name="firstName" label="First name" rules={!isShowCompanyName ? addEditGuestValidation.firstName : undefined}>
            <Input name="firstName" placeholder="Enter first name" />
          </Form.Item>
          <Form.Item name="lastName" label="Last name" rules={!isShowCompanyName ? addEditGuestValidation.lastName : undefined}>
            <Input name="lastName" placeholder="Enter last name" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="carNumber" label="License">
            <Input name="carNumber" placeholder="Enter license" />
          </Form.Item>
          <Form.Item name="carState" label="License state">
            <SelectState placeholder="Select a license state" allowClear={true} />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="isKeyPermissionAllowed" label="Key permission">
            <Select options={keyPermissionOptions} placeholder="Select key permission" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="restrictions" label="Restrictions">
            <TextArea name="restrictions" placeholder="Enter restrictions" />
          </Form.Item>
        </Space>
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className="eiq-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(AddEditGuestModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
