import React, { FC, ReactNode } from 'react';

interface IBaseCellText {
  children: ReactNode;
}

const BaseCellText: FC<IBaseCellText> = ({ children }) => {
  return <div className="BaseTable__row-cell-text">{children}</div>;
};

export default BaseCellText;
