import React, { FC } from 'react';
import { Form, TimePicker } from 'antd';
import styles from './input-time.module.scss';
import { IInputTimeProps } from './input-time.types';
import { TIME_h_mma } from '../../../../../common/constans/date.constants';

const InputTime: FC<IInputTimeProps> = ({ label, name, errorStatus }) => {
  return (
    <div>
      <span className={styles.label}>{label}</span>
      <div className={styles.wrapper}>
        <Form.Item name={name}>
          <TimePicker minuteStep={5} use12Hours format={TIME_h_mma} status={errorStatus} />
        </Form.Item>
      </div>
    </div>
  );
};

export default InputTime;
