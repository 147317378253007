export enum FilterTypesEnum {
  GreaterThanOrEqual = ':>=',
  LessThanOrEqual = ':<=',
  NotEqual = ':!',
  Contains = ':*',
  GreaterThan = ':>',
  LessThan = ':<',
  Equals = ':',
  StartWith = ':',
}
