import { formatAddressLabel } from '../helpers/address.helper';
import { ICellRendererProps } from '../components/table/table.types';
import { Tag } from 'antd';
import { ProtectText } from '../components/protect-text/protect-text';
import { IKeyRead } from '../services/addresses-key-service/addresses-key.service.types';

export const keysManagementColumns = () => ({
  key: {
    key: 'keyNumber',
    title: 'Key #',
    dataKey: 'keyNumber',
    width: 248,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  name: {
    key: 'name',
    title: 'Key name',
    dataKey: 'name',
    width: 154,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  status: {
    key: 'status',
    title: 'Key Out',
    dataKey: 'status',
    width: 141,
    resizable: true,
    sortable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IKeyRead>) => {
      const isIn = cell?.cellData === 'in';
      return (
        <Tag className="eiq-tag" color={isIn ? '#C1FFEA' : '#FFB68C'} style={{ color: '#292B2E' }}>
          {isIn ? 'No' : 'Yes'}
        </Tag>
      );
    },
  },
  address: {
    key: 'address',
    title: 'Address',
    dataKey: 'address',
    width: 520,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IKeyRead>) => formatAddressLabel(cell.cellData),
  },
  code: {
    key: 'alarmCode',
    title: 'Alarm code',
    dataKey: 'alarmCode',
    width: 80,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IKeyRead>) =>
      cell.cellData ? <ProtectText text={cell.cellData} className="alarm-code" /> : <></>,
  },
});
