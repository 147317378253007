import { Button, Form, Input, Modal, Space } from 'antd';
import React, { useEffect } from 'react';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ICommunityForm } from 'features/eiq-manage/pages/eiq-manage-addresses/addresses.type';
import { ICommunityCreateUpdate } from 'common/services/addresses-service/addresses.service.types';
import addressesService from 'common/services/addresses-service/addresses.service';
import { notification } from 'common/utils/notification';
import TextArea from 'antd/es/input/TextArea';

const initialValues = {
  name: '',
  description: '',
};

const CommunityAddEditModal = ({ isOpen, onClose, isEdit, title, initState }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initState);
    }
  }, [isOpen, form, initState]);

  const onFinish = async (values: ICommunityForm) => {
    const body: ICommunityCreateUpdate = {
      name: values.name,
      description: values.description,
    };
    if (isEdit) {
      const res = await addressesService.updateCommunity(initState.id, body);
      hideModal(true, res);
      notification.success({ message: 'Community name was successfully changed' });
    } else {
      const res = await addressesService.addCommunity(body);
      hideModal(true, res);
    }
  };
  const hideModal = (...rest: any) => {
    form.resetFields();
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} layout="vertical" autoComplete="on" initialValues={initState ?? initialValues}>
        <Space className="full-width" size={13}>
          <Form.Item name="name" label="Community" rules={[{ required: true, message: 'Community name is required!' }]}>
            <Input name="name" placeholder="Enter community name" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="description" label="Description">
            <TextArea name="description" placeholder="Enter community description" />
          </Form.Item>
        </Space>
        <Space className="footer">
          <Button key="cancel" type="link" onClick={() => hideModal()} style={{ width: '159px' }}>
            Cancel
          </Button>

          <Button key="streetForm" htmlType="submit" type="primary" style={{ width: '159px' }} icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(CommunityAddEditModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
