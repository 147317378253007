import React, { FC } from 'react';
import { Button, Modal, Space } from 'antd';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { IModal } from 'common/models/model.interface';
import addressesService from 'common/services/addresses-service/addresses.service';
import { notification } from 'common/utils/notification';
import { ICommunity } from 'common/services/street-service/street.service.types';

const CommunityDeleteModal: FC<IModal<{ community: ICommunity }, any>> = ({ isOpen, onClose, title, initData }) => {
  const hideModal = (isSuccess: boolean) => {
    notification.destroy();
    if (onClose) {
      onClose(isSuccess);
    }
  };

  const handleOnDeleteButtonClick = () => {
    addressesService
      .deleteCommunity(initData!.community.id)
      .then((_res) => {
        hideModal(true);
        notification.success({ message: `Community was successfully deleted` });
      })
      .catch((error) => {});
  };

  return (
    <Modal centered title={title} width={450} open={isOpen} wrapClassName="custom-modal general-info-delete-modal" closable={false}>
      <Space className="body">
        <span>
          <span>Are you sure you want to delete </span>
          {initData?.community?.name ? <span className="streetName">{initData?.community?.name}</span> : <span>this community </span>}
          <span> from a community list?</span>
        </span>
      </Space>
      <Space className="footer">
        <Button type="link" onClick={() => hideModal(false)} className="button">
          Cancel
        </Button>
        <Button type="primary" onClick={handleOnDeleteButtonClick} className="button" icon={<DeleteIcon stroke="#F6F8FA" />}>
          Delete
        </Button>
      </Space>
    </Modal>
  );
};

export default React.memo(CommunityDeleteModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
