import React, { FC } from 'react';
import { IInputPhoneProps } from './input-phone.types';
import { phoneMask } from 'common/constans/common.constants';
import InputMask from 'react-input-mask';
import { Input } from 'antd';

const InputPhone: FC<IInputPhoneProps> = ({ value, onChange, placeholder = 'Enter phone number', onBlur, disabled }) => {
  const handleChange = (e: any) => {
    if (e.type !== 'change') {
      return;
    }

    if (onChange) {
      const inputValue = e.target.value;
      const sanitizedValue = inputValue.replace(/\D/g, '');
      onChange(sanitizedValue);
    }
  };

  return (
    <InputMask mask={phoneMask} placeholder={placeholder} value={value} onChange={handleChange} onBlur={onBlur} disabled={disabled}>
      <Input />
    </InputMask>
  );
};

export default InputPhone;
