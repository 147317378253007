import './contact-phones.scss';
import React from 'react';
import { ContactPhonesProps } from './contact-phones.types';
import EiqPhone from '../info-block/contacts/phone/phone-field';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { ownershipTypeStatusMap } from 'common/constans/common.constants';

export const ContactPhones = ({ phones }: ContactPhonesProps) => {
  return (
    <div className="contact-phones">
      <h2 className="contact-phone__title">Contacts</h2>
      {phones.map((phone, index) => {
        const order = phone.ownershipType ? null : phone.order + 1;
        const labels = [{ value: phone.label, class: phone.label.toLocaleLowerCase() }];
        if (phone.ownershipType) {
          const className =
            phone.ownershipType === ResidentTypesEnum.Renter || phone.ownershipType === ResidentTypesEnum.Owner
              ? phone.ownershipType
              : phone.hostOwnershipType;
          labels.push({
            value: ownershipTypeStatusMap[phone.ownershipType as ResidentTypesEnum],
            class: className?.toLocaleLowerCase() ?? '',
          });
        }
        return <EiqPhone phone={phone.number} labels={labels} key={`notification-phone-${index}`} order={order} />;
      })}
    </div>
  );
};
