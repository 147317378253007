import { ICellRendererProps } from 'common/components/table/table.types';
import { formatPhone } from 'common/helpers/phone.helper';

export const commonColumns = () => ({
  email: {
    key: 'email',
    title: 'Email',
    dataKey: 'email',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  phone: {
    key: 'phone',
    title: 'Phone',
    dataKey: 'phone',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<any>) => {
      return formatPhone(cell?.cellData);
    },
  },
});
