import { IEiqSecureKeyInfoRowProps } from './eiq-secure-key-info-row.types';
import './eiq-secure-key-info-row.scss';

export const EiqSecureKeyInfoRow = ({ title, value }: IEiqSecureKeyInfoRowProps) => {
  return (
    <div className="eiq-secure-key-info-row">
      <div className="eiq-secure-key-info-row__title">{title}</div>
      <div className="eiq-secure-key-info-row__text">{value}</div>
    </div>
  );
};
