import React, { FC, useState } from 'react';
import styles from './input-time.module.scss';
import InputTime from './input-time';
import { validateTimePeriod } from './input-time.validation';
import { Form, Space } from 'antd';
import { ErrorStatus, IInputTimePeriodProps } from './input-time.types';

const InputTimePeriod: FC<IInputTimePeriodProps> = ({ label, name, startTimeName, endTimeName }) => {
  const [errorStatus, setErrorStatus] = useState<ErrorStatus>();

  return (
    <div className={styles.periodWrapper}>
      <h3 className="h3">{label}</h3>
      <Form.Item noStyle name={name} rules={[validateTimePeriod(startTimeName, endTimeName, setErrorStatus)]}>
        <Space direction="vertical" size={0}>
          <InputTime label="Allow service entry after" name={startTimeName} errorStatus={errorStatus} />
          <InputTime label="Deny service entry after" name={endTimeName} errorStatus={errorStatus} />
        </Space>
      </Form.Item>
    </div>
  );
};

export default InputTimePeriod;
