import { IIncidentReportRead } from 'common/services/incident-report/incident-report.types';
import { IIncidentReportingPersonEditProps, IReportingPersonFormValues } from './incident-reporting-person-edit.types';
import { Row, Col, Input, Form } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import IncidentEditModeActions from '../../incident-edit-mode-actions/incident-edit-mode-actions';
import { reportingPersonFormRules } from './incident-reporting-person-edit.validation';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { useCallback } from 'react';

export const convertIncidentToFormValues = (incident?: IIncidentReportRead): IReportingPersonFormValues | undefined => {
  if (!incident) {
    return undefined;
  }

  return {
    reportingPerson: incident.reportingPerson,
  };
};

const IncidentReportingPersonEdit = ({ onSave, onCancel, form, incident }: IIncidentReportingPersonEditProps) => {
  const getInitialValue = useCallback(() => {
    if (incident) {
      return undefined;
    }

    return userAuthStore.user?.name;
  }, [incident]);

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <FormWrapper form={form} autoComplete="false" layout="vertical" initialValues={convertIncidentToFormValues(incident)} onFinish={onSave}>
      <Row gutter={{ lg: 16, md: 8, xs: 0 }}>
        <Col xs={16} lg={8}>
          <Form.Item
            name="reportingPerson"
            label="Reporting Officer"
            rules={reportingPersonFormRules.reportingPerson}
            initialValue={getInitialValue()}>
            <Input name="reportingPerson" placeholder="Enter Full Name" />
          </Form.Item>
        </Col>
      </Row>
      {incident && <IncidentEditModeActions onCancel={handleCancel} />}
    </FormWrapper>
  );
};

export default IncidentReportingPersonEdit;
