import React, { FC, useEffect } from 'react';
import { Button, Checkbox, Form, Modal, Space } from 'antd';
import { IModal } from 'common/models/model.interface';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import { ReactComponent as RejectIcon } from 'assets/icons/reject.svg';
import styles from '../vehicles.module.scss';
import { rejectVehicleValidation } from './reject-vehicle.validation';
import { notification } from 'common/utils/notification';
import { observer } from 'mobx-react-lite';
import configurationStore from 'common/stores/configuration/configuration.store';

const initValues = {
  reasons: [],
};

const RejectVehicleModal: FC<IModal<{ vehicleId: number }, any>> = observer(({ isOpen, onClose, title, initData }) => {
  const [form] = Form.useForm();

  const hideModal = (isSuccess: boolean) => {
    form.resetFields();
    notification.destroy();
    if (onClose) {
      onClose(isSuccess);
    }
  };

  const onFinish = async (values: any) => {
    if (!initData?.vehicleId) {
      console.error('Vehicle id is empty');
      return;
    }

    const reasons = values.reasons?.map((i: number) => configurationStore.configuration?.rejectVehicleReasons[i]);

    await vehicleService.reject(initData.vehicleId, { reasons });
    hideModal(true);
  };

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initValues);
    }
  }, [isOpen, form, initData]);

  return (
    <Modal centered title={title} width={750} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} initialValues={initValues} layout="vertical">
        <Space className="full-width">
          <Form.Item name="reasons" rules={rejectVehicleValidation.reason}>
            <Checkbox.Group
              className="vertical-checkbox-group"
              options={configurationStore.configuration?.rejectVehicleReasons?.map((item, index) => ({
                value: index,
                label: (
                  <span>
                    <span className={styles.rejectReason}>{item.reason}</span>: {item.description}
                  </span>
                ),
              }))}
              style={{ gap: '10px' }}
            />
          </Form.Item>
        </Space>
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className={styles.modalButton}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<RejectIcon />} className={`${styles.rejectButton} ${styles.modalButton}`} danger>
            Reject
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
});

export default React.memo(RejectVehicleModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
