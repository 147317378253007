import { TIME_HH_mm_ss } from 'common/constans/date.constants';
import configurationStore from 'common/stores/configuration/configuration.store';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export const IsServiceHoursPeriodValid = (): boolean => {
  const serviceHours = configurationStore.configuration?.serviceHours;
  const dayOfWeek = dayjs().day();

  //Sunday
  if (dayOfWeek === 0) {
    const { sundayStartTime, sundayEndTime } = serviceHours || {};

    if (!sundayStartTime || !sundayEndTime) {
      return true;
    }
    return validate(sundayStartTime, sundayEndTime);
  }
  //Monday to Friday
  if (dayOfWeek > 0 && dayOfWeek < 6) {
    const { mondayToFridayStartTime, mondayToFridayEndTime } = serviceHours || {};

    if (!mondayToFridayStartTime || !mondayToFridayEndTime) {
      return true;
    }
    return validate(mondayToFridayStartTime, mondayToFridayEndTime);
  }
  //Saturday
  if (dayOfWeek === 6) {
    const { saturdayStartTime, saturdayEndTime } = serviceHours || {};

    if (!saturdayStartTime || !saturdayEndTime) {
      return true;
    }
    return validate(saturdayStartTime, saturdayEndTime);
  }
  return true;
};

const validate = (startTimeStr: string, endTimeStr: string): boolean => {
  const startTime = dayjs(startTimeStr, TIME_HH_mm_ss);
  const endTime = dayjs(endTimeStr, TIME_HH_mm_ss);
  const currentTime = dayjs();

  return currentTime.isBetween(startTime, endTime);
};
