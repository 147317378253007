export const addEditAccessCardValidation = {
  owner: [{ max: 255, message: 'Value should be less than 255 characters' }],
  deviceId: [
    { required: true, message: 'Device id is required!' },
    {
      pattern: /^(?:\d*)$/,
      message: 'Value should contain only number',
    },
  ],
  facilityCode: [
    { required: true, message: 'Facility code is required!' },
    { max: 255, message: 'Value should be less than 255 characters' },
  ],
  additionalInformation: [{ max: 2000, message: 'Value should be less than 2000 characters' }],
  registrationAddress: [{ max: 500, message: 'Value should be less than 500 characters' }],
  deactivationReason: [{ required: true, message: 'Deactivation reason is required!' }],
  validTo: [
    ({ getFieldValue }: any) => ({
      validator: (_: any, value: string) => {
        const startDate = getFieldValue('validFrom');
        if (value && value < startDate) {
          return Promise.reject(new Error('End date must be greater than start date'));
        }
        return Promise.resolve();
      },
    }),
  ],
};
