import configurationService from 'common/services/configuration-service/configuration.service';
import { ICommunitySettings, ISystemConfigurationRead } from 'common/services/configuration-service/configuration.service.types';
import { makeAutoObservable } from 'mobx';
import { SYSTEM_CONFIGURATION, COMMUNITY_SETTINGS } from './configuration.constants';
import React from 'react';

class ConfigurationStore {
  configuration: ISystemConfigurationRead | null = null;
  communitySettings: ICommunitySettings | null = null;

  constructor() {
    makeAutoObservable(this);
    this.restoreConfiguration();
  }

  setConfiguration(configuration: ISystemConfigurationRead | null) {
    localStorage.setItem(SYSTEM_CONFIGURATION, JSON.stringify(configuration));
    this.configuration = configuration;
  }

  setCommunitySettings(communitySettings: ICommunitySettings | null) {
    localStorage.setItem(COMMUNITY_SETTINGS, JSON.stringify(communitySettings));
    this.communitySettings = communitySettings;
  }

  restoreConfiguration() {
    const configurationSource = localStorage.getItem(SYSTEM_CONFIGURATION);
    this.configuration = configurationSource ? JSON.parse(configurationSource) : null;

    const communitySettings = localStorage.getItem(COMMUNITY_SETTINGS);
    this.communitySettings = communitySettings ? JSON.parse(communitySettings) : null;
  }

  clearConfiguration() {
    localStorage.removeItem(SYSTEM_CONFIGURATION);
    this.configuration = null;

    localStorage.removeItem(COMMUNITY_SETTINGS);
    this.communitySettings = null;
  }

  async requestConfiguration() {
    const configuration = await configurationService.getSystemConfiguration();
    this.setConfiguration(configuration);

    const communitySettings = await configurationService.getCommunitySettings();
    this.setCommunitySettings(communitySettings);
  }
}

const configurationStore = new ConfigurationStore();
export const ConfigurationStoreContext = React.createContext<ConfigurationStore>(configurationStore);
export default configurationStore;
