import React from 'react';
import { petsColumns } from 'common/list-configs/pets.config';
import { vehiclesColumns } from 'common/list-configs/vehicles.config';
import { occupantsColumns } from 'common/list-configs/occupants.config';
import { occupantsEIQManageColumns } from '../../../../../list-config/occupants.config';

const { owner, make, model, licenseNumber, licenseState, color, year } = vehiclesColumns();
export const vehiclePrintColumns = [owner, year, make, model, color, licenseNumber, licenseState];

const petsPrintColumns = () => {
  const { name, type, breed, chipNumber, color, photo, tag } = petsColumns();
  const columns = [
    {
      ...photo,
      width: 215,
      height: 215,
      cellRenderer: (cell: any) => {
        return <img src={cell.cellData} width="100%" height="auto" alt="" />;
      },
    },
    name,
    { ...type, width: 125 },
    breed,
    { ...color, width: 125 },
    { ...chipNumber, width: 125 },
    { ...tag, width: 125 },
  ];

  return columns;
};
export const petPrintColumns = [...petsPrintColumns()];

const occupantsPrintColumns = () => {
  const { name, ownershipType, hostName, hostOwnershipType } = occupantsColumns();
  const { isSharedGuestList, isSharedVehiclesList } = occupantsEIQManageColumns();

  const columns = [name, ownershipType, hostName, hostOwnershipType, isSharedGuestList, isSharedVehiclesList];

  columns.forEach((i) => (i.width = 200));

  return columns;
};
export const occupantPrintColumns = [...occupantsPrintColumns()];
