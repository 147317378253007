import { phoneValidationRule } from 'common/validation/phone-number-rule';
import { ValidationMessages } from 'common/validation/validation-messages';
import { RuleType } from 'rc-field-form/lib/interface';

export const addEditAccountValidation = {
  firstName: [{ required: true, message: 'First name is required!' }],
  lastName: [{ required: true, message: 'Last name is required!' }],
  accountName: [{ required: true, message: 'Account name is required!' }],
  phone: [{ required: true, message: 'Phone is required!' }, phoneValidationRule],
  email: [
    { required: true, message: ValidationMessages.Email.RequiredMessage },
    { message: ValidationMessages.Email.NotValidMessage, type: 'email' as RuleType },
  ],
  password: [{ min: 8, message: ValidationMessages.Password.RequiredLengthMessage }],
  defaultGate: [{ required: true, message: 'Default gate is required!' }],
};
