import { ServerClient } from 'common/utils/http/http-clients';
import { IUserPersonalization } from './user-personalization.service.types';

class UserPersonalizationService {
  async get(): Promise<IUserPersonalization> {
    const res = await ServerClient.get<IUserPersonalization>(`/user-personalizations/me`);
    return res?.data;
  }

  async updateTableColumnsPersonalization(tableName: string, tableColumns: string[]): Promise<IUserPersonalization> {
    const res = await ServerClient.put<IUserPersonalization>(`/user-personalizations/me`, { tableName, tableColumns });
    return res?.data;
  }
}

export const personalizationService = new UserPersonalizationService();
export default personalizationService;
