import { GuestTypeSource } from '../enums/guest-type-source.enum';

export const guestTypeMap: Record<GuestTypeSource, string> = {
  [GuestTypeSource.Undefined]: 'Undefined',
  [GuestTypeSource.User]: 'Management',
  [GuestTypeSource.SecurityOfficer]: 'Security',
  [GuestTypeSource.Resident]: 'Resident',
  [GuestTypeSource.Imported]: 'Imported',
  [GuestTypeSource.EIQVoice]: 'Resident via EIQ-Voice',
};

export const sourceOptions = [
  { value: GuestTypeSource.User, label: guestTypeMap[GuestTypeSource.User] },
  { value: GuestTypeSource.SecurityOfficer, label: guestTypeMap[GuestTypeSource.SecurityOfficer] },
];
