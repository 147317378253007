import { Row, Col, Input, Select, Form } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { IIncidentInvolvedPerson } from 'common/services/incident-report/incident-report.types';
import { IIncidentInvolvedPersonEditProps } from './incident-involved-person-edit.types';
import InputPhone from 'common/components/form-elements/input-phone/input-phone';
import { incidentInvolvedPersonFormRules } from './incident-involved-person-edit.validation';
import React from 'react';
import IncidentEditModeActions from '../../incident-edit-mode-actions/incident-edit-mode-actions';

export const IncidentInvolvedPersonEdit = ({ initialValue, onSave, onCancel }: IIncidentInvolvedPersonEditProps) => {
  const involvementTypes = ['Complainant', 'Witness', 'Suspect', 'Victim'];

  const involvementTypeOptions = involvementTypes.map((i) => ({
    label: i,
    value: i,
  }));

  const [form] = Form.useForm<IIncidentInvolvedPerson>();

  const handleSave = (values: IIncidentInvolvedPerson) => {
    onSave(values);
  };

  return (
    <FormWrapper form={form} onFinish={handleSave} autoComplete="false" initialValues={initialValue} layout="vertical">
      <Row gutter={16}>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 8, order: 1 }}>
          <Form.Item name="firstName" label="First Name" rules={incidentInvolvedPersonFormRules.firstName}>
            <Input name="firstName" placeholder="First Name" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 8, order: 4 }}>
          <Form.Item name="lastName" label="Last Name" rules={incidentInvolvedPersonFormRules.lastName}>
            <Input name="lastName" placeholder="Enter Last Name" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, order: 3 }} lg={{ span: 8, order: 7 }}>
          <Form.Item name="involvementType" label="Type of involvement" rules={incidentInvolvedPersonFormRules.involvementType}>
            <Select placeholder="Select type of involvement" options={involvementTypeOptions} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, order: 4 }} lg={{ span: 8, order: 2 }}>
          <Form.Item name="phoneNumber" label="Phone Number" rules={incidentInvolvedPersonFormRules.phoneNumber}>
            <InputPhone placeholder="Enter phone number" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, order: 5 }} lg={{ span: 8, order: 5 }}>
          <Form.Item name="streetName" label="Street Name" rules={incidentInvolvedPersonFormRules.streetName}>
            <Input name="streetName" placeholder="Enter street name" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, order: 6 }} lg={{ span: 8, order: 3 }}>
          <Form.Item name="streetNumber" label="Street Number" rules={incidentInvolvedPersonFormRules.streetNumber}>
            <Input name="streetNumber" placeholder="Enter street number" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, order: 7 }} lg={{ span: 8, order: 6 }}>
          <Form.Item name="address" label="Address Details">
            <Input name="address" placeholder="Enter Address Details" />
          </Form.Item>
        </Col>
        {/* Empty Columns to fill the row on desktop */}
        <Col xs={0} lg={{ span: 8, order: 8 }} />
        <Col xs={0} lg={{ span: 8, order: 9 }} />
      </Row>
      <IncidentEditModeActions onCancel={onCancel} />
    </FormWrapper>
  );
};
