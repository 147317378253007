import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import { Card, Drawer } from 'antd';
import { ReactComponent as CornerArrowIcon } from 'assets/icons/corner-arrow.svg';
import './info-block.scss';
import Portal from '../portal/portal';

const InfoBlock: FC<PropsWithChildren> = ({ children }) => {
  const { isMobileOrTable } = useMedia();
  const [isOpenDrawer, setIsOpenDrawe] = useState(false);

  const getOpenClosePanel = (isOpen: boolean) => (
    <div className="open-close-title-container" onClick={() => setIsOpenDrawe(!isOpenDrawer)}>
      <div className="open-close-title">
        <span className="info">Info</span>
        <CornerArrowIcon className={`${isOpen ? 'open' : 'close'}`} />
      </div>
    </div>
  );

  const [drawerHeight, setDrawerHeight] = useState('auto');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Defer the calculation until after render
    setTimeout(() => {
      if (contentRef.current) {
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const contentHeight = contentRef.current.offsetHeight;

        setDrawerHeight(Math.min(contentHeight + 50, windowHeight - 42) + 'px');
      }
    }, 0);
  }, [isOpenDrawer]); // Recalculate the height when the drawer visibility changes

  const onClose = () => {
    setIsOpenDrawe(false);
  };
  return (
    <div className="info-block-conteiner">
      {isMobileOrTable ? (
        <Portal selector="#info-block">
          <div className="open-close-container">{getOpenClosePanel(true)}</div>
          <Drawer
            headerStyle={headerStyles}
            bodyStyle={bodyStyles}
            title={getOpenClosePanel(false)}
            placement="bottom"
            closable={false}
            onClose={onClose}
            open={isOpenDrawer}
            height={`90%`}
            //height={drawerHeight}
          >
            <div ref={contentRef} className="drawer-content-wrapper">
              {children}
            </div>
          </Drawer>
        </Portal>
      ) : (
        <Card className="card-wrapper" bodyStyle={cardBodyStyles}>
          {children}
        </Card>
      )}
    </div>
  );
};

export default InfoBlock;

const cardBodyStyles: React.CSSProperties = {
  padding: '13px 12px',
};

const headerStyles: React.CSSProperties = {
  padding: 0,
  border: 'none',
};

const bodyStyles: React.CSSProperties = {
  backgroundColor: '#F6F8FA',
  padding: '0px 24px',
};
