import { IResidentAddresses } from 'common/services/residents-service/residents.service.types';
import { Tag } from 'antd';
import { camelCaseToRegularForm } from 'common/helpers/camel-case-to-regular-form.helper';
import { ICellRendererProps } from 'common/components/table/table.types';
import { ownershipTypeStatusColorMap } from 'common/constans/common.constants';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { DateToFormat } from 'common/helpers/date.helper';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import BaseCellText from 'common/components/table/base-cell-text';

const getRentingPeriod = (rowData: IResidentAddresses) => {
  const isPeriod = rowData?.rentStartDate && rowData?.rentEndDate;

  return isPeriod
    ? `${DateToFormat(rowData?.rentStartDate, DATE_SHORT_MMM_DD_YYYY)} - ${DateToFormat(rowData?.rentEndDate, DATE_SHORT_MMM_DD_YYYY)}`
    : '';
};

export const addressesColumns = () => ({
  name: {
    key: 'name',
    title: 'Address name',
    dataKey: 'name',
    width: 248,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  streetName: {
    key: 'address.street.name',
    title: 'Street name',
    dataKey: 'address.street.name',
    width: 154,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    getValue: (rowData: IResidentAddresses) => rowData.address.street.name,
  },
  streetNumber: {
    key: 'streetNumber',
    title: 'Street number',
    dataKey: 'streetNumber',
    width: 141,
    resizable: true,
    sortable: true,
    fixed: 1,
  },
  building: {
    key: 'buildingNumber',
    title: 'Building',
    dataKey: 'buildingNumber',
    width: 95,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  block: {
    key: 'blockNumber',
    title: 'Block',
    dataKey: 'blockNumber',
    width: 80,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  lot: {
    key: 'lotNumber',
    title: 'Lot',
    dataKey: 'lotNumber',
    width: 70,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  unit: {
    key: 'unit',
    title: 'Unit',
    dataKey: 'unit',
    width: 80,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  ownershipType: {
    key: 'ownershipType',
    title: 'Type',
    dataKey: 'ownershipType',
    width: 248,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IResidentAddresses>) => {
      return (
        <Tag className="eiq-tag" color={ownershipTypeStatusColorMap[cell?.cellData as ResidentTypesEnum]} style={{ color: '#292B2E' }}>
          {camelCaseToRegularForm(cell?.cellData)}
        </Tag>
      );
    },
  },
  rantingDates: {
    key: 'rantingDates',
    title: 'Renting dates',
    dataKey: 'rantingDates',
    width: 210,
    minWidth: 150,
    resizable: true,
    sortable: false,
    getValue: getRentingPeriod,
    cellRenderer: (cell: ICellRendererProps<IResidentAddresses>) => {
      return <BaseCellText>{getRentingPeriod(cell?.rowData)}</BaseCellText>;
    },
    flexGrow: 1,
  },
  community: {
    key: 'community',
    title: 'Community',
    dataKey: 'address.street.community.name',
    width: 180,
    sortable: true,
    resizable: true,
    fixed: 1,
    getValue: (rowData: IResidentAddresses) => rowData.address.street.community?.name,
  },
});
