import { Row, Col, Space } from 'antd';
import InfoField from 'common/components/info-block/info-field/info-field';
import { IncidentEditButton } from '../../incident-edit-button/incident-edit-button';
import { IIncidentDetailsViewProps } from './incident-details-view.types';

const getRequiredValue = (value: boolean) => (value ? 'Required' : 'Not Required');

const IncidentDetailsView = ({ incident, onEdit }: IIncidentDetailsViewProps) => {
  return (
    <div style={{ border: '1px solid #d9d9d9', borderRadius: '8px', padding: '16px' }}>
      <Row>
        <Col xs={24} lg={8}>
          <InfoField value={incident.category} label="Classification" />
        </Col>
        <Col xs={24} lg={8}>
          <InfoField value={incident.subCategory} label="Sub-Incident classification" />
        </Col>
        <Col xs={24} lg={8}>
          <InfoField value={incident.weatherCondition} label="Weather Condition" />
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={8}>
          <InfoField value={getRequiredValue(incident.isEmsResponseRequired)} label="EMS response required" />
        </Col>
        <Col xs={24} lg={8}>
          <InfoField value={getRequiredValue(incident.isFireDepartmentResponseRequired)} label="Fire department response required" />
        </Col>
        <Col xs={24} lg={8}>
          <InfoField value={getRequiredValue(incident.isPoliceDepartmentResponseRequired)} label="Police department response required" />
        </Col>
      </Row>
      <Space className="full-width" size={20}>
        <p className="incident-description-view">{incident.description}</p>
      </Space>

      <IncidentEditButton onEdit={onEdit} />
    </div>
  );
};

export default IncidentDetailsView;
