import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { IGeneralInfoProps } from './general-info.types';
import './general-info.scss';
import GeneralInfoAddEditModal from './general-info-add-edit-modal/general-info-add-edit-modal';
import TableContextProvider, { useTableContext } from 'common/components/table/table-context';
import { Button, Col, Row, Space } from 'antd';
import TableBase from 'common/components/table/table-base';
import GeneralDeleteModal from './general-info-delete-modal/general-info-delete-modal';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import { IAddressesByStreetForm, IStreetForm } from '../../../addresses.type';
import { IStreetRead } from 'common/services/addresses-service/addresses.service.types';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import addressesStore from 'features/eiq-manage/stores/addresses.store';
import addressesService from 'common/services/addresses-service/addresses.service';
import { DeleteModalVariantsEnum } from './general-info-delete-modal/delete-modal-variants.enum';
import { IDeleteModalWithTablesInitData } from 'common/components/delete-modal-with-tables/delete-modal-with-tables.types';
import {
  petsInitialColumnsForDeleteModal,
  residentInitialColumnsForDeleteModal,
  vehicleInitialColumnsForDeleteModal,
} from './general-info-delete-modal/delete-modal-tables.config';
import { notification } from 'common/utils/notification';
import DeleteModalWithTables from 'common/components/delete-modal-with-tables/delete-modal-with-tables';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { accessCardColumns } from 'features/eiq-manage/pages/eiq-manage-residents/components/info-block/access-cards/access-card-list.comfig';
import featureStore from 'common/stores/features/feature.store';

const GeneralInfo: FC<IGeneralInfoProps> = ({ selectedParentRow, getData }) => {
  const tableContext = useTableContext();

  const addGeneralInfoModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedStreetRow = selectedParentRow;

      if (!selectedStreetRow) {
        return;
      }

      const streetFromData: IStreetForm = transformGeneralInfoReadToAddForm(selectedStreetRow);
      setModalInitData(streetFromData);
    },
    onClose: (isSuccessSaved) => isSuccessSaved && getData(),
  });

  const editGeneralInfoModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableContext.getSelectedRow();
      const selectedStreetRow = selectedParentRow;

      if (!selectedRow || !selectedStreetRow) {
        return;
      }

      const streetFromData: IStreetForm = transformGeneralInfoReadToForm(selectedRow, selectedStreetRow);
      setModalInitData(streetFromData);
    },
    onClose: (isSuccessSaved, updatedRow) => {
      if (isSuccessSaved) {
        tableContext.updateSelectedRow(updatedRow);
      }
    },
  });

  const deleteGeneralInfoModal = useModal({
    onBeforeShow: (setModalInitData, deleteModalVariant) => {
      const selectedStreetRow = selectedParentRow;
      const selectedAddressRow = tableContext.getSelectedRow();
      if (!selectedStreetRow || !selectedAddressRow) {
        return;
      }
      const data = {
        id: selectedStreetRow.id,
        addressId: selectedAddressRow.id,
        addressName: selectedAddressRow.name,
        streetName: selectedStreetRow.name,
        deleteModalVariant,
        getAddressesTableData: getData,
      };
      setModalInitData(data);
    },
    onClose: (isSuccess, variant) => {
      if (isSuccess) {
        tableContext.setSelectedRow(null);
        getData();
      }

      if (variant === DeleteModalVariantsEnum.WithEntities) {
        deleteGeneralInfoModalStepTwo.showModal();
      }
    },
  });

  const deleteAddress = () => {
    addressesService
      .deleteAddresses(addressesStore.streetDetails!.id, addressesStore.selectedAddress!.id)
      .then((_res) => {
        deleteGeneralInfoModalStepTwo.hideModal(true);
        notification.success({ message: `Address was successfully deleted` });
      })
      .catch((error) => {});
  };

  const deleteGeneralInfoModalStepTwo = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableContext.getSelectedRow();

      const data: IDeleteModalWithTablesInitData = {
        modalLabels: [
          { title: 'Address', value: `${selectedRow.streetNumber} ${selectedParentRow.name}` },
          { title: 'Community', value: selectedParentRow.community.name },
        ],
        onDeleteClick: deleteAddress,
        tablesConfigs: [
          {
            title: 'Residents',
            clientSide: true,
            data: addressesStore.selectedAddressResidents,
            initialColumns: residentInitialColumnsForDeleteModal,
          },
          {
            title: 'Pets',
            clientSide: true,
            data: addressesStore.selectedAddressPets,
            initialColumns: petsInitialColumnsForDeleteModal,
          },
          {
            title: 'Vehicles',
            clientSide: true,
            data: addressesStore.selectedAddressVehicles,
            initialColumns: vehicleInitialColumnsForDeleteModal,
          },
        ],
      };

      if (featureStore.isCardAccessEnabled) {
        data.tablesConfigs.push({
          title: 'Other Devices',
          clientSide: true,
          data: addressesStore.selectedAddressAccessCards,
          initialColumns: accessCardColumns,
        });
      }

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        tableContext.setSelectedRow(null);
        getData();
      }
    },
  });

  const onDeleteButtonClick = () => {
    deleteGeneralInfoModal.showModal(
      addressesStore.selectedAddress?.residents.length ? DeleteModalVariantsEnum.WithEntities : DeleteModalVariantsEnum.WithoutEntities,
    );
  };

  return (
    <div className="general-info-container">
      <TableContextProvider controls={tableContext}>
        <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
          <Col flex="auto">
            <SearchFilter
              adaptiveSearch={true}
              enableProgressiveSearch={true}
              placeholder="Search by street number, address name, unit"
              rulesForColumn={{
                streetNumber: FilterTypesEnum.Contains,
                unit: FilterTypesEnum.Contains,
                name: FilterTypesEnum.Contains,
              }}
              rightSide={
                <Space size={8}>
                  <Button
                    icon={<DeleteIcon stroke="#505762" />}
                    size="middle"
                    onClick={onDeleteButtonClick}
                    disabled={!tableContext.getSelectedRow()}>
                    Delete
                  </Button>
                  <Button
                    icon={<EditIcon />}
                    size="middle"
                    onClick={editGeneralInfoModal.showModal}
                    disabled={!tableContext.getSelectedRow()}>
                    Edit
                  </Button>
                  <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={addGeneralInfoModal.showModal}>
                    Add
                  </Button>
                </Space>
              }
            />
          </Col>
        </Row>
        <TableBase onSelected={tableContext.setSelectedRow} />
      </TableContextProvider>
      <GeneralInfoAddEditModal
        {...addGeneralInfoModal.initData}
        title="Add"
        isEdit={false}
        isOpen={addGeneralInfoModal.isOpenModal}
        onClose={addGeneralInfoModal.hideModal}
      />
      <GeneralInfoAddEditModal
        {...editGeneralInfoModal.initData}
        title="Edit"
        isEdit={true}
        isOpen={editGeneralInfoModal.isOpenModal}
        onClose={editGeneralInfoModal.hideModal}
      />
      <GeneralDeleteModal
        initData={deleteGeneralInfoModal?.initData}
        title="Delete"
        isOpen={deleteGeneralInfoModal?.isOpenModal}
        onClose={deleteGeneralInfoModal?.hideModal}
      />
      <DeleteModalWithTables
        initData={deleteGeneralInfoModalStepTwo?.initData}
        title="Delete"
        isOpen={deleteGeneralInfoModalStepTwo?.isOpenModal}
        onClose={deleteGeneralInfoModalStepTwo?.hideModal}
      />
    </div>
  );
};

export default observer(GeneralInfo);

export const transformGeneralInfoReadToForm = (readData: any, streetReadData: IStreetRead): any => {
  const { id, unit, lotNumber, blockNumber, name, buildingNumber, instructions, streetNumber } = readData;

  const form: IAddressesByStreetForm = {
    id,
    streetName: streetReadData.name,
    streetNumber: streetNumber,
    communityName: streetReadData.community!.name,
    unit: unit,
    block: blockNumber,
    building: buildingNumber,
    lot: lotNumber,
    name: name,
    streetId: streetReadData.id,
    instructions: instructions,
  };

  return {
    initState: form,
  };
};

export const transformGeneralInfoReadToAddForm = (streetReadData: IStreetRead): any => {
  return {
    initState: {
      streetName: streetReadData.name,
      communityName: streetReadData.community!.name,
      streetId: streetReadData.id,
    },
  };
};
