import { Button, Form, Input, Modal, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { IModal } from '../../../../../../common/models/model.interface';
import { IEditKeyModal } from '../../tabs-content-pages/keys-management/keys-management.type';
import { formatAddressLabel } from '../../../../../../common/helpers/address.helper';
import InfoField from '../../../../../../common/components/info-block/info-field/info-field';
import styles from '../../../../../../common/components/info-block/contact-block/contact-block.module.scss';
import { addEditKeyValidation } from './key-add-edit-modal.validation';
import AddressSelect from '../../../eiq-manage-residents/components/address-select/address-select';
import addressesKeyService from '../../../../../../common/services/addresses-key-service/addresses-key.service';
import { notification } from '../../../../../../common/utils/notification';
import { IAddress } from 'common/services/street-service/street.service.types';

const KeyAddEditModal: FC<IModal<IEditKeyModal, any>> = ({ isOpen, onClose, isEdit, title, initData }) => {
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);
  const [addressSelectResetTrigger, setAddressSelectResetTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    if (isEdit && initData) {
      const res = await addressesKeyService.updateKey(initData.address.id, initData.keyNumber.toString(), values);
      hideModal(true, res);
    } else if (selectedAddressId) {
      const res = await addressesKeyService.createKey(selectedAddressId, values);
      hideModal(true, res);
    }
  };

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData);
    }
  }, [isOpen, form, initData]);

  const hideModal = (...rest: any) => {
    notification.destroy();
    form.resetFields();
    if (onClose) {
      onClose(...rest);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setAddressSelectResetTrigger(!addressSelectResetTrigger);
    }
  }, [isOpen]);

  const handleAddressChanged = (value: IAddress | null) => {
    const addressId = value ? value.id : null;
    setSelectedAddressId(addressId);
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} initialValues={initData} layout="vertical">
        <Space className="full-width" size={13}>
          <Form.Item name="keyNumber" label="Key #" rules={addEditKeyValidation.keyNumber}>
            <Input name="keyNumber" placeholder="Enter key number" />
          </Form.Item>
          <Form.Item name="name" label="Key name" rules={addEditKeyValidation.name}>
            <Input name="name" placeholder="Enter key name" />
          </Form.Item>
          <Form.Item name="alarmCode" label="Alarm code">
            <Input.Password autoComplete="new-password" size="small" name="alarmCode" placeholder="Enter alarm code" />
          </Form.Item>
        </Space>

        {isEdit ? (
          <Space className="full-width" size={13} style={{ marginBottom: '10px' }}>
            <InfoField value={formatAddressLabel(initData!.address!)} label="Address" bodyClassName={styles.infoBlockField} />
          </Space>
        ) : (
          <AddressSelect value={selectedAddressId} setAddress={handleAddressChanged} isOpen={addressSelectResetTrigger} form={form} />
        )}

        <Space className="footer">
          <Button type="link" onClick={() => hideModal()} style={{ width: '159px' }}>
            Cancel
          </Button>

          <Button htmlType="submit" type="primary" style={{ width: '159px' }} icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(KeyAddEditModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
