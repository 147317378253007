import { vehiclesColumns } from 'common/list-configs/vehicles.config';

const {
  photo,
  owner,
  facilityCode,
  deviceId,
  year,
  make,
  model,
  cardClass,
  licenseNumber,
  licenseState,
  color,
  rantingDates,
  validityPeriod: validationDates,
  status,
  additionalInformation,
  parkSpaceNumber,
  registrationAddress,
  isShared,
  transponder,
} = vehiclesColumns();
export const vehicleInitialColumns = [
  photo,
  owner,
  facilityCode,
  deviceId,
  year,
  make,
  model,
  color,
  cardClass,
  licenseNumber,
  licenseState,
  rantingDates,
  validationDates,
  registrationAddress,
  parkSpaceNumber,
  additionalInformation,
  status,
  isShared,
  transponder,
];
