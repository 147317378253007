import React, { FC } from 'react';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { IEiqEmailProps } from './email-field.types';
import styles from './email-field.module.scss';

const EiqEmail: FC<IEiqEmailProps> = ({ email, className }) => {
  return (
    <div className={`${styles.eiqEmailField} ${className ?? ''}`}>
      <EmailIcon />
      <span>{email}</span>
    </div>
  );
};

export default EiqEmail;
