import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as VehicleIcon } from 'assets/icons/vehicle.svg';
import eiqSecureStore from '../../stores/eiq-secure.store';
import './eiq-secure-vehicles-info.scss';
import { vehicleInfoBlock } from '../../../../common/components/vehicle-info-block/vehicle-info-block';

export const EiqSecureVehiclesInfo = observer(() => {
  const vehicles = useMemo(() => {
    return eiqSecureStore.residentDetails?.addresses.find((addressItem) => addressItem?.address?.id === eiqSecureStore.selectedAddressId)
      ?.vehicles;
  }, [eiqSecureStore.selectedAddressId, eiqSecureStore.residentDetails]);

  const accessCards = useMemo(() => {
    return eiqSecureStore.residentDetails?.addresses.find((addressItem) => addressItem?.address?.id === eiqSecureStore.selectedAddressId)
      ?.accessCards;
  }, [eiqSecureStore.selectedAddressId, eiqSecureStore.residentDetails]);

  return (
    <div className="eiq-secure__vehicles-list">
      {vehicles?.length! > 0 && <div className="eiq-secure__title eiq-secure-subtitle">Vehicles info</div>}
      {vehicles?.map((vehicle) => {
        return (
          <div className="eiq-secure__vehicles-item" key={vehicle.id}>
            <VehicleIcon />
            <span>{vehicleInfoBlock(vehicle)}</span>
          </div>
        );
      })}
      {accessCards?.length! > 0 && <div className="eiq-secure__title eiq-secure-subtitle">Other devices info</div>}
      {accessCards?.map((i) => {
        return (
          <div className="eiq-secure__vehicles-item" key={i.id}>
            Other Device - Transponder ID {i.deviceId}
          </div>
        );
      })}
    </div>
  );
});
