import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Upload, Space } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { notification } from '../../../../../../../../common/utils/notification';
import { DatePicker } from 'antd/lib';
import { IModal } from '../../../../../../../../common/models/model.interface';
import { DATE_SHORT_MMM_DD_YYYY } from '../../../../../../../../common/constans/date.constants';
import { ReactComponent as PetPhotoAdd } from 'assets/icons/pet-photo-add.svg';
import { PlusOutlined } from '@ant-design/icons';
import petService from '../../../../../../service/pet-service/pet.service';
import FileService from '../../../../../../../../common/services/file-service/file.service';
import { addEditPetValidation } from './add-edit-pet-modal.validation';
import residentStore from '../../../../../../stores/resident.store';
import styles from './add-edit-pet-modal.module.scss';
import { IAddEditPetModal } from '../pet.types';
import { MAX_FILE_SIZE_BYTES } from '../../../../../../../../common/constans/common.constants';

const AddEditPetModal: FC<IModal<IAddEditPetModal, any>> = ({ title, isEdit, initData, isOpen, onClose }) => {
  const [form] = Form.useForm();
  const [petImage, setPetImage] = useState<Blob | null>();
  const [petPreviewImage, setPetPreviewImage] = useState<string>('');

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData);
    }
    if (initData?.photoUrl) {
      setPetPreviewImage(initData?.photoUrl);
    } else {
      setPetPreviewImage('');
      setPetImage(null);
    }
  }, [isOpen, form, initData]);

  const hideModal = (...rest: any) => {
    notification.destroy();
    form.resetFields();
    if (onClose) {
      onClose(...rest);
    }
  };

  const onFinish = async (values: any) => {
    const residentId = residentStore.residentId;
    const addressId = residentStore.addressId;

    if (!residentId || !addressId) {
      return;
    }

    if (petImage) {
      const photoUrl = await FileService.uploadPhoto(petImage);
      values.photoUrl = photoUrl.absoluteUri;
    }

    values.dateOfBirth = values.dateOfBirth?.format('YYYY-MM-DD');
    values.tagExpirationDate = values.tagExpirationDate?.format('YYYY-MM-DD');

    if (isEdit) {
      const petId = initData!.id!;
      const result = await petService.updatePet(residentId, addressId, petId, values);
      hideModal(true, result);
    } else {
      const result = await petService.createPet(residentId, addressId, values);
      hideModal(true, result);
    }
  };

  const readImageFile = (file: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target?.result as string);
      reader.onerror = (e) => reject(e);
      reader.readAsDataURL(file);
    });
  };

  const uploadProps = {
    beforeUpload: async (file: Blob) => {
      if (file.size >= MAX_FILE_SIZE_BYTES) {
        notification.error({ message: 'The image size must be less than 5 MB!' });
      } else {
        setPetImage(file);
        try {
          const dataURL = await readImageFile(file);
          setPetPreviewImage(dataURL);
        } catch (error) {
          console.error('Error reading file');
        }
      }
      return false;
    },
    maxCount: 1,
    accept: 'image/png, image/jpeg, image/jpg',
    showUploadList: false,
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} initialValues={initData} onFinish={onFinish} layout="vertical">
        <>
          <Form.Item label="Photo">
            <Upload {...uploadProps} className={styles.photoContainer}>
              {petPreviewImage ? <img className={styles.petImage} src={petPreviewImage} alt="pet img" /> : <PetPhotoAdd />}
              <Button style={{ width: '96px' }} className="secondary-btn" icon={<PlusOutlined />} size="middle">
                Add
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={addEditPetValidation.name}>
            <Input name="name" placeholder="Enter name" />
          </Form.Item>
          <Form.Item name="type" label="Type">
            <Input name="type" placeholder="Enter type" />
          </Form.Item>
          <Form.Item name="breed" label="Breed" rules={addEditPetValidation.breed}>
            <Input name="breed" placeholder="Enter breed" />
          </Form.Item>
          <Form.Item name="color" label="Color" rules={addEditPetValidation.color}>
            <Input name="color" placeholder="Enter color" />
          </Form.Item>
          <Form.Item name="dateOfBirth" label="Date of birth">
            <DatePicker className="full-width" placeholder="Date of birth" format={DATE_SHORT_MMM_DD_YYYY} />
          </Form.Item>
          <Space className="full-width" size={13}>
            <Form.Item name="tag" label="Tag" rules={addEditPetValidation.tag}>
              <Input name="tag" placeholder="Enter tag" />
            </Form.Item>
            <Form.Item name="tagExpirationDate" label="Shots expiration date" rules={addEditPetValidation.tagExpirationDate}>
              <DatePicker placeholder="Shots expiration date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
          </Space>
          <Form.Item name="chipNumber" label="Chip number">
            <Input name="chipNumber" placeholder="Enter chip number" />
          </Form.Item>
        </>
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className="eiq-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(AddEditPetModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
