import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Space, Col, Row, Radio, Checkbox } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { IModal } from 'common/models/model.interface';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { GuestValidity } from 'common/enums/guest-validity.enum';
import { DATE_FORMAT_MMMM_D_YYYY, DATE_YYYY_MM_DD } from 'common/constans/date.constants';
import './eiq-secure-guests-add-edit-modal.scss';
import { addEditGuestValidation } from 'common/validation/guest.validation';
import { IAddEditEiqSecureGuestModal, IEditEiqSecureGuestModalForm } from './eiq-secure-guest-add-edit-modal-types.types';
import { ICreateGuest, IUpdateGuest } from 'common/services/guest-service/guest.service.types';
import guestService from 'common/services/guest-service/guest.service';
import {
  guestTypeRadioOptions,
  validityRadioOptions,
  daysOfWeekSelectionRadioOption,
} from 'features/eiq-secure/constans/common.eiq-secure';
import { daysOfWeekCheckboxOptions } from 'common/constans/days-of-week.constants';
import { DaysOfWeekSelection } from 'features/eiq-secure/enums/days-of-week-selection.enum';
import configurationStore from 'common/stores/configuration/configuration.store';
import { getVisitDateByGuestValidity, mapToServerGuestValidity } from 'common/helpers/guest-validity.helper';
import { notification } from '../../../../../common/utils/notification';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';
import { GuestType } from 'common/enums/guest-type.enum';
import SelectState from 'common/components/form-elements/select-state/select-state';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import eiqSecureStore from 'features/eiq-secure/stores/eiq-secure.store';

const defaultConfiguration = configurationStore.configuration?.default;

const initialValues: IEditEiqSecureGuestModalForm = {
  firstName: null,
  lastName: null,
  type: defaultConfiguration?.guestType ?? null,
  validity: defaultConfiguration?.guestValidity ?? null,
  startDate: null,
  endDate: null,
  visitDate: null,
  daysOfWeek: [],
  companyName: null,
};

const EiqSecureAddEditGuestModal: FC<IModal<IAddEditEiqSecureGuestModal, any>> = ({ isOpen, onClose, title, initData, isEdit }) => {
  const [form] = Form.useForm();
  const [daysOfWeekRange, setDaysOfWeekRange] = useState<string | null>(null);
  const [validityValue, setValidityValue] = useState(initData?.formInitialValues?.validity ?? initialValues.validity);
  const [guestTypeValue, setGuestTypeValue] = useState(initData?.formInitialValues?.type ?? initialValues.type);

  useEffect(() => {
    if (isEdit) {
      setDaysOfWeekRange(
        initData?.formInitialValues?.daysOfWeek.length === daysOfWeekCheckboxOptions.length
          ? daysOfWeekSelectionRadioOption[0].value
          : daysOfWeekSelectionRadioOption[1].value,
      );
    }
  }, [initData]);

  useEffect(() => {
    if (isEdit && validityValue === GuestValidity.Permanent) {
      const daysOfWeekLength = initData?.formInitialValues?.daysOfWeek?.length;

      if (daysOfWeekLength === daysOfWeekCheckboxOptions.length || daysOfWeekLength === 0) {
        setDaysOfWeekRange(DaysOfWeekSelection.AllDays);
      } else {
        setDaysOfWeekRange(DaysOfWeekSelection.Selected);
      }
    } else if (validityValue === GuestValidity.Permanent) {
      setDaysOfWeekRange(DaysOfWeekSelection.AllDays);
    } else {
      setDaysOfWeekRange(null);
    }
  }, [validityValue]);

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData?.formInitialValues);
    }
  }, [isOpen, form, initData]);

  useEffect(() => {
    if (validityValue === GuestValidity.Permanent) {
      if (daysOfWeekRange === DaysOfWeekSelection.AllDays) {
        form.setFieldValue(
          'daysOfWeek',
          daysOfWeekCheckboxOptions.map((day) => day.value),
        );
      }
    } else {
      form.setFieldValue('daysOfWeek', []);
    }
  }, [daysOfWeekRange]);

  const getDatePeriod = (startDate: any, endDate: any, validity: GuestValidity) => {
    if (validity !== GuestValidity.DateRange) {
      return null;
    }
    if (!startDate || !endDate) {
      return null;
    }
    return {
      startDate: startDate.format(DATE_YYYY_MM_DD),
      endDate: endDate.format(DATE_YYYY_MM_DD),
    };
  };

  const onFinish = async (values: any) => {
    const guest: ICreateGuest = {
      firstName: values.firstName,
      lastName: values.lastName,
      carState: values.carState,
      carNumber: values.carNumber,
      type: values.type,
      validity: mapToServerGuestValidity(values.validity),
      daysOfWeek: values.daysOfWeek,
      period: getDatePeriod(values.startDate, values.endDate, values.validity),
      companyName: isServiceGuest(guestTypeValue) ? values.companyName : '',
      visitDate: getVisitDateByGuestValidity(values.validity, values.visitDate),
    };

    if (isEdit) {
      const guestToUpdate = guest as IUpdateGuest;
      guestToUpdate.id = initData!.id!;
      const result = await guestService.updateGuest(initData!.residentId, initData!.addressId, guestToUpdate);
      hideModal(true, result);
    } else {
      const result = await guestService.createGuest(initData!.residentId, initData!.addressId, guest);
      hideModal(true, result);
    }
  };

  const hideModal = (...rest: any) => {
    form.resetFields();
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };

  const onGuestTypeChanged = (guestType: GuestType) => {
    setGuestTypeValue(guestType);

    if (guestType === GuestType.UrgentService && !form.getFieldValue('validity')) {
      form.setFieldValue('validity', GuestValidity.Today);
    }
  };

  const getGuestTypes = useCallback(() => {
    let ownershipType = eiqSecureStore.residentDetails?.addresses.find(
      (address) => address?.address?.id === eiqSecureStore.selectedAddressId,
    )?.ownershipType;

    if (ownershipType === ResidentTypesEnum.Developer) {
      return guestTypeRadioOptions.filter((guestType) => isServiceGuest(guestType.value) && guestType.value !== GuestType.UrgentService);
    } else {
      return guestTypeRadioOptions;
    }
  }, []);

  return (
    <Modal
      centered
      title={title}
      width={750}
      open={isOpen}
      wrapClassName="custom-modal custom-modal--so eiq-secure-guest-add-edit-modal"
      closable={false}>
      <FormWrapper form={form} onFinish={onFinish} initialValues={initData?.formInitialValues ?? initialValues} layout="vertical">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="firstName"
              label="First name"
              rules={!isServiceGuest(guestTypeValue) ? addEditGuestValidation.firstName : undefined}>
              <Input name="firstName" placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="lastName"
              label="Last name"
              rules={!isServiceGuest(guestTypeValue) ? addEditGuestValidation.lastName : undefined}>
              <Input name="lastName" placeholder="Enter last name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item name="carNumber" label="License">
              <Input name="carNumber" placeholder="Enter license" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="carState" label="License state">
              <SelectState placeholder="Select a license state" popupClassName="so-select" allowClear={true} />
            </Form.Item>
          </Col>
        </Row>
        <Space className="full-width" size={13}>
          <Row gutter={16} className="checkbox-group">
            <Col xs={24} sm={12} md={8}>
              <Form.Item name="type" label="Type" rules={addEditGuestValidation.type}>
                <Radio.Group
                  className="vertical-radio-group"
                  options={getGuestTypes()}
                  onChange={(e) => onGuestTypeChanged(e.target.value)}
                />
              </Form.Item>
              {isServiceGuest(guestTypeValue) && (
                <Form.Item name="companyName" label="Company name" rules={addEditGuestValidation.companyName}>
                  <Input placeholder="Enter company name" />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item name="validity" label="Validity" rules={addEditGuestValidation.validity}>
                <Radio.Group
                  className="vertical-radio-group"
                  options={validityRadioOptions}
                  onChange={(e) => {
                    setValidityValue(e.target.value);
                  }}
                />
              </Form.Item>
              {validityValue === GuestValidity.DateRange && (
                <div>
                  <Form.Item name="startDate" label="From" rules={addEditGuestValidation.startDate}>
                    <DatePicker format={DATE_FORMAT_MMMM_D_YYYY} placeholder="Start date" />
                  </Form.Item>

                  <Form.Item name="endDate" label="To" rules={addEditGuestValidation.endDate} dependencies={['startDate']}>
                    <DatePicker format={DATE_FORMAT_MMMM_D_YYYY} placeholder="End date" />
                  </Form.Item>
                </div>
              )}
              {validityValue === GuestValidity.OneTime && (
                <Form.Item name="visitDate" label="Visit date" rules={addEditGuestValidation.visitDate}>
                  <DatePicker format={DATE_FORMAT_MMMM_D_YYYY} placeholder="Visit date" />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} md={8}>
              <div className="range-group">
                <Form.Item label="Range">
                  <Radio.Group
                    className="vertical-radio-group"
                    options={daysOfWeekSelectionRadioOption}
                    disabled={validityValue !== GuestValidity.Permanent}
                    defaultValue={daysOfWeekSelectionRadioOption[0].value}
                    value={daysOfWeekRange}
                    onChange={(e) => {
                      setDaysOfWeekRange(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item name="daysOfWeek" className="day-weeks">
                  <Checkbox.Group
                    className="vertical-checkbox-group"
                    options={daysOfWeekCheckboxOptions}
                    disabled={daysOfWeekRange === DaysOfWeekSelection.AllDays || !daysOfWeekRange}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Space>
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className="eiq-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(EiqSecureAddEditGuestModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
