import { ServerClient } from 'common/utils/http/http-clients';
import { IAuthResponse, IAuthRefreshTokenResponse } from './login.types';

class LoginService {
  async authenticate(login: string, password: string): Promise<IAuthResponse> {
    const res = await ServerClient.post<IAuthResponse>('/auth/authenticate', { login, password });
    return res?.data;
  }

  async refreshToken(refreshToken: string): Promise<IAuthRefreshTokenResponse> {
    const res = await ServerClient.post<IAuthRefreshTokenResponse>('/auth/refresh-token', { refreshToken });
    return res.data;
  }

  async resetPassword(email: string): Promise<any> {
    const res = await ServerClient.post<any>('/users/reset-password', { email });
    return res;
  }

  async confirmPassword(password: string, token: string): Promise<any> {
    const res = await ServerClient.post<any>('/users/reset-password/confirm', { password, token });
    return res;
  }
}

export const loginService = new LoginService();
export default loginService;
