import React, { FC } from 'react';
import { Radio, Space } from 'antd';
import { IRadioGroupProps } from './radio-group.types';

const RadioGroup: FC<IRadioGroupProps> = ({ onChange, value, options }) => {
  return (
    <Radio.Group onChange={onChange} value={value}>
      <Space direction="vertical">
        {options.map((item, index) => (
          <Radio value={item.value} key={`eiq-radio-group-${item.value}-${index}`}>
            {item.label}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default RadioGroup;
