import dayjs from 'dayjs';
import { useMemo } from 'react';
import { IncidentEditButton } from '../../incident-edit-button/incident-edit-button';
import { IIncidentLocationViewProps } from './incident-location-view.types';
import { Row, Col } from 'antd';
import InfoField from 'common/components/info-block/info-field/info-field';
import { formatAddressLabel } from 'common/helpers/address.helper';

const IncidentLocationView = ({ incident, onEdit }: IIncidentLocationViewProps) => {
  const getDate = useMemo(() => {
    const date = dayjs(new Date(incident.incidentDate * 1000));
    return date.format('MM/DD/YYYY');
  }, [incident.incidentDate]);

  const getTime = useMemo(() => {
    const time = dayjs(new Date(incident.incidentDate * 1000));
    return time.format('hh:mm A');
  }, [incident.incidentDate]);

  return (
    <div style={{ border: '1px solid #d9d9d9', borderRadius: '8px', padding: '16px' }}>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 8, order: 3 }}>
          <InfoField value={incident.address.street.community?.name} label="Community" />
        </Col>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 8, order: 4 }}>
          <InfoField value={incident.gate} label="Gate" />
        </Col>
        <Col xs={{ span: 24, order: 3 }} lg={{ span: 4, order: 1 }}>
          <InfoField value={getDate} label="Date" />
        </Col>
        <Col xs={{ span: 24, order: 4 }} lg={{ span: 4, order: 2 }}>
          <InfoField value={getTime} label="Time" />
        </Col>
        <Col xs={{ span: 24, order: 5 }} lg={{ span: 8, order: 5 }}>
          <InfoField value={incident.address.street.name} label="Street Name" />
        </Col>
        <Col xs={{ span: 24, order: 6 }} lg={{ span: 8, order: 6 }}>
          <InfoField value={incident.address.streetNumber} label="Street Number" />
        </Col>
        <Col xs={{ span: 24, order: 7 }} lg={{ span: 8, order: 7 }}>
          <InfoField value={formatAddressLabel(incident.address, { hideStreet: true })} label="Address Details" />
        </Col>
      </Row>
      <IncidentEditButton onEdit={onEdit} />
    </div>
  );
};

export default IncidentLocationView;
