import { Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { loginValidation } from '../../validation/login.validation';
import { observer } from 'mobx-react-lite';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';

const LoginPage = () => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    await userAuthStore.requestToken(values.login, values.password);
  };

  if (userAuthStore.isLogin) {
    return <Navigate to="/" />;
  }

  return (
    <FormWrapper form={form} onFinish={onFinish} name="validateOnly" layout="vertical" autoComplete="on" initialValues={{ remember: true }}>
      <div className="title">Log in</div>
      <span className="text ant-mb-32">Please enter your details to log in.</span>

      <Form.Item name="login" label="Email or account name" rules={loginValidation.login}>
        <Input name="login" placeholder="Enter email or account name" />
      </Form.Item>

      <Form.Item name="password" label="Password" rules={loginValidation.password}>
        <Input.Password name="password" placeholder="Enter password" />
      </Form.Item>

      <div className="forgot-password" style={{ textAlign: 'right' }}>
        <Link to="/forgot-password">Forgot the password?</Link>
      </div>

      <div className="action-line">
        <Button htmlType="submit" type="primary" icon={<RightOutlined />} style={{ width: '216px' }}>
          Log In
        </Button>
      </div>
    </FormWrapper>
  );
};

export const Login = observer(LoginPage);
