import React, { FC } from 'react';
import { Space } from 'antd';
import { IAddressSelectProps } from './address-select.types';
import '../../../eiq-manage-addresses/components/street-add-edit-modal/street-add-edit-modal.scss';
import { useAddressSelect } from 'common/hooks/use-address-select/use-address-select.hook';

const AddressSelect: FC<IAddressSelectProps> = ({ value, setAddress, isOpen, form, initData, disabled }) => {
  const addressSelect = useAddressSelect({ value, onAddressChange: setAddress, isOpen, form, initData, disabled });

  return (
    <>
      <Space className="full-width" size={13}>
        {addressSelect.StreetSelect}
        {addressSelect.StreetNumberSelect}
      </Space>
      {addressSelect.AddressDetailsSelect}
    </>
  );
};

export default AddressSelect;
