import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { Button, Checkbox, Col, DatePickerProps, Form, Input, Modal, Select, Space, Switch } from 'antd';
import { DatePicker } from 'antd/lib';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { notification } from 'common/utils/notification';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { IResidentForm, IResidentFormDates } from '../../residents.type';
import { IResidentCreate, IResidentPhone, IResidentRead, IResidentUpdate } from 'common/services/residents-service/residents.service.types';
import residentsService from 'common/services/residents-service/residents.service';
import { residentTypeOptions } from '../../../../constants/resident-type-options.constant';
import { ResidentTypesEnum } from '../../../../../../common/enums/resident-types.enum';
import AddressSelect from '../address-select/address-select';
import InputPhone from 'common/components/form-elements/input-phone/input-phone';
import { addEditResidentValidation } from './resident-add-edit-modal.validation';
import { IAddress } from 'common/services/street-service/street.service.types';
import { IDatePeriod } from 'common/models/date-period.interface';
import { phoneTypeOptions } from 'common/constans/phone-type.options';
import { PhoneType } from 'common/enums/phone-type.enum';
import { getPhoneComparer } from 'features/eiq-secure/heplers/phone.helper';
import { isOccupant } from 'common/helpers/resident-address.helper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ResidentSourceType } from 'common/enums/resident-source-type.enum';

const initialValues = {
  name: '',
  //vacationStartDate: dayjs('2023-05-08', 'YYYY-MM-DD'),
  //phones: [],
  phones: [{ isNotificationEnabled: true, label: 'Primary', number: null, order: 0 }],
};

const customFormat: DatePickerProps['format'] = (value) => `${value.format('MMMM D, YYYY')}`;

const getVacationPeriod = (
  vacationPeriod: IResidentFormDates | undefined,
  isChild: boolean,
  isVacation: boolean,
): IDatePeriod | undefined => {
  if (isChild || !isVacation) {
    return {
      startDate: null,
      endDate: null,
    };
  }
  if (!vacationPeriod?.startDate || !vacationPeriod?.endDate) {
    return;
  }
  return {
    startDate: vacationPeriod.startDate.format('YYYY-MM-DD') ?? null,
    endDate: vacationPeriod.endDate.format('YYYY-MM-DD') ?? null,
  };
};

const ResidentAddEditModal = ({ isOpen, onClose, isEdit, title, extraData, initState, initData }: any) => {
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);
  const [addressSelectResetTrigger, setAddressSelectResetTrigger] = useState<boolean>(false);
  const [ownerType, setOwnerType] = useState<string | null>(initState?.ownershipType);

  const disabled = isEdit && extraData?.source === ResidentSourceType.NorthStar;

  const hideModal = (...rest: any) => {
    notification.destroy();
    form.resetFields();
    setIsVacation(false);
    if (onClose) {
      onClose(...rest);
    }
  };

  // Form
  const [form] = Form.useForm();

  // Here you can transform from data before sent, also resolve custom form error
  const onFinish = async (values: IResidentForm) => {
    const { addressId, vacationPeriod, phones, ...rest } = values;
    const filledPhones = phones.filter((phone) => phone.number?.length > 0);

    if (filledPhones.every((i) => !i.isNotificationEnabled)) {
      filledPhones.forEach((i) => {
        i.isNotificationEnabled = false;
      });
      const primaryPhone = filledPhones.find((i) => i.label === 'Primary');

      if (primaryPhone) {
        primaryPhone.isNotificationEnabled = true;
      }
    }

    const body: IResidentCreate = {
      ...rest,
      vacationPeriod: getVacationPeriod(vacationPeriod, isChild, isVacation),
      phones: filledPhones,
    };

    body['address'].addressId = selectedAddressId;
    body['address'].rentStartDate = rest.address?.rentStartDate?.format('YYYY-MM-DD') ?? null;
    body['address'].rentEndDate = rest.address.rentEndDate?.format('YYYY-MM-DD') ?? null;

    if (isEdit) {
      const toUpdate = body as IResidentUpdate;
      toUpdate.id = initState?.id;
      const res = await residentsService.updateResident(toUpdate);
      hideModal(true, res);
    } else {
      const res = await residentsService.createResident(body);
      hideModal(true, res);
    }

    // The 422 form error will catch automatically in onFinish wrapper in FormWrapper
  };

  // [TODO] move the List to the separate component
  // List
  const [bulkEditMode, setBulkEditMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const listOperationsRef = useRef<any>(null);

  const handleBulkEditClick = () => {
    setBulkEditMode(!bulkEditMode);
    setSelectedItems([]);
  };

  const handleBulkDeleteClick = () => {
    selectedItems
      .sort((a: any, b: any) => b - a)
      .forEach((index: any) => {
        form.getFieldValue('phones').splice(index, 1);
      });

    const updatedPhones = form.getFieldValue('phones').map((phone: IResidentPhone, index: number) => ({
      ...phone,
      order: index,
    }));

    form.setFieldsValue({
      phones: updatedPhones,
    });

    setSelectedItems([]);
    setBulkEditMode(false);
  };

  const handleAddPhone = () => {
    const phones = form.getFieldValue('phones');
    const maxOrder = phones?.slice().sort((a: any, b: any) => b.order - a.order);
    const order = maxOrder[0] ? maxOrder[0].order + 1 : 0;
    listOperationsRef.current.add({ label: PhoneType.Primary, number: '', isNotificationEnabled: true, order: order });
  };

  const handlePhoneOder = (result: any) => {
    if (!result.destination) return;

    const phones = form.getFieldValue('phones');
    const [reorderedItem] = phones.splice(result.source.index, 1);
    phones.splice(result.destination.index, 0, reorderedItem);

    const updatedPhones = phones.map((phone: IResidentPhone, index: number) => ({
      ...phone,
      order: index,
    }));
    form.setFieldValue('phones', updatedPhones);
  };

  const handleSelectItem = (index: number) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item: any) => item !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  };

  // Vocation
  const [isVacation, setIsVacation] = useState(extraData?.isVacation);

  useEffect(() => {
    if (extraData) {
      setIsVacation(!!extraData?.isVacation);
    }
    if (initState?.address?.ownershipType) {
      setOwnerType(initState.address.ownershipType);
      setSelectedAddressId(initState.address.addressId);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isEdit && initData) {
      form.setFieldValue(['address', 'addressId'], initData.address.id);
      form.setFieldValue(['address', 'streetName'], initData.address.street.name);
      form.setFieldValue(['address', 'streetNumber'], initData.address.streetNumber);
    }
  }, [initData]);

  // Example how check outer state, for an example during editing.
  useEffect(() => {
    if (form && isOpen) {
      if (initState?.phones) {
        initState.phones = initState.phones.sort(getPhoneComparer());
      }

      form.setFieldsValue(initState);
    }
  }, [isOpen, form, initState]);

  const isOccupantType = useMemo(() => {
    return ownerType && isOccupant(ownerType as ResidentTypesEnum);
  }, [ownerType]);

  const isChild = useMemo(() => ownerType === ResidentTypesEnum.UnderAgeChild || ownerType === ResidentTypesEnum.AdultChild, [ownerType]);
  const isRenter = useMemo(() => ownerType === ResidentTypesEnum.Renter, [ownerType]);

  // Select owner type
  const handleOwnerType = (value: any) => {
    setOwnerType(value);
  };

  // Related To
  const [residents, setResidents] = useState<IResidentRead[]>([]);

  const handleResidentSearch = (newValue?: string) => {
    const filter = `addresses_ownershipType:Renter OR addresses_ownershipType:Owner,addresses_address_id:${selectedAddressId}`;

    residentsService
      .getResidents(null, newValue ? filter + `,firstName:*${newValue} OR lastName:*${newValue}` : filter, '+lastName')
      .then((options) => {
        setResidents(options.items.filter((i) => i.id !== initState?.id));
      })
      .catch((e) => {
        setResidents([]);
        notification.error({ message: 'Can not fetch street list' });
        console.log(e);
      });
  };

  useEffect(() => {
    if (selectedAddressId && isOpen) {
      handleResidentSearch();
    }
  }, [selectedAddressId, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setAddressSelectResetTrigger(!addressSelectResetTrigger);
    }
  }, [isOpen]);

  const residentOptions = useMemo(
    () => residents?.map((res) => ({ value: res.id, label: `${res.firstName} ${res.lastName}` })),
    [residents],
  );

  const handleAddressChanged = (value: IAddress | null) => {
    const addressId = value ? value.id : null;
    setSelectedAddressId(addressId);
  };

  const handleClickOnGeneratePin = () => {
    residentsService.generatePin().then((pin) => {
      form.setFieldValue('pin', pin.toString());
    });
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper
        form={form}
        onFinish={onFinish}
        name={`residentForm+${title}`}
        layout="vertical"
        autoComplete="on"
        initialValues={initState ?? initialValues}>
        <Space className="full-width" size={13}>
          <Form.Item name="firstName" label="First name" rules={addEditResidentValidation.firstName}>
            <Input name="name" placeholder="Enter first name" disabled={disabled} />
          </Form.Item>

          <Form.Item name="lastName" label="Last name" rules={addEditResidentValidation.lastName}>
            <Input name="lastName" placeholder="Enter last name" disabled={disabled} />
          </Form.Item>
        </Space>

        <Space className="full-width">
          <Form.Item name="email" label="Email address" validateTrigger="onBlur" rules={addEditResidentValidation.email}>
            <Input name="email" placeholder="Enter email" disabled={disabled} />
          </Form.Item>
          <Form.Item
            name={['address', 'ownershipType']}
            className="eiq-select"
            label="Type"
            rules={[{ required: true, message: 'Ownership type is required!' }]}>
            <Select showSearch options={residentTypeOptions} onChange={handleOwnerType} disabled={disabled} />
          </Form.Item>
        </Space>

        {isRenter && (
          <Space className="full-width" size={13}>
            <Form.Item name={['address', 'rentStartDate']} label="From" rules={addEditResidentValidation.rentStartDate}>
              <DatePicker format={customFormat} placeholder="Start date" disabled={disabled} />
            </Form.Item>
            <Form.Item name={['address', 'rentEndDate']} label="To" rules={addEditResidentValidation.rentEndDate}>
              <DatePicker format={customFormat} placeholder="End date" disabled={disabled} />
            </Form.Item>
          </Space>
        )}

        {!isEdit && (
          <AddressSelect
            value={selectedAddressId}
            setAddress={handleAddressChanged}
            isOpen={addressSelectResetTrigger}
            form={form}
            initData={initData?.address}
            disabled={disabled}
          />
        )}

        {isOccupantType && (
          <Space className="full-width" size={13}>
            <Form.Item
              className="eiq-select"
              name={['address', 'relatedResidentId']}
              label="Related to"
              rules={addEditResidentValidation.relatedTo}>
              <Select
                showSearch
                allowClear
                disabled={!selectedAddressId && disabled}
                defaultActiveFirstOption={false}
                onSearch={handleResidentSearch}
                onDropdownVisibleChange={(isOpen) => isOpen && residents?.length === 0 && handleResidentSearch()}
                filterOption={(inputValue, option) => option?.label?.toString().toLowerCase().includes(inputValue.toLowerCase()) ?? false}
                options={residentOptions}
              />
            </Form.Item>
          </Space>
        )}

        <Space className="full-width" size={13}>
          <Form.Item name="clubId" label="Club ID">
            <Input placeholder="Enter Club ID" disabled={disabled} />
          </Form.Item>

          {ownerType !== ResidentTypesEnum.UnderAgeChild && (
            <Form.Item name="pin" label="Pin" rules={addEditResidentValidation.pin}>
              <Input
                placeholder="Enter Pin"
                addonAfter={
                  <Button
                    type="link"
                    size="small"
                    onClick={handleClickOnGeneratePin}
                    style={{ padding: 0, height: 'auto', lineHeight: 'normal' }}>
                    Generate
                  </Button>
                }
              />
            </Form.Item>
          )}
        </Space>

        {!isChild && (
          <Space className="full-width" size={13}>
            <Form.Item label="Vacation" valuePropName="checked">
              <Switch
                checked={isVacation}
                onChange={(checked) => {
                  setIsVacation(checked);
                }}
              />
            </Form.Item>
          </Space>
        )}

        {!isChild && isVacation && (
          <Space className="full-width" size={13}>
            <Form.Item name={['vacationPeriod', 'startDate']} label="From" rules={addEditResidentValidation.vacationStartDate}>
              <DatePicker format={customFormat} placeholder="Start date" />
            </Form.Item>
            <Form.Item name={['vacationPeriod', 'endDate']} label="To" rules={addEditResidentValidation.vacationEndDate}>
              <DatePicker format={customFormat} placeholder="End date" />
            </Form.Item>
          </Space>
        )}

        <Space style={{ marginTop: '4px', marginBottom: '16px' }} className="full-width" size={13}>
          <span className="eiq-text">Contacts</span>

          <Space size={8} direction="horizontal" align="end" style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button type="link" size={'small'} onClick={handleBulkEditClick} disabled={disabled}>
              {bulkEditMode ? 'Cancel' : 'Bulk Edit / Order'}
            </Button>
            {bulkEditMode && (
              <Button onClick={handleBulkDeleteClick} type="link" size="small">
                Delete
              </Button>
            )}
            {!bulkEditMode && (
              <Button onClick={handleAddPhone} type="link" size="small" disabled={disabled}>
                Add
              </Button>
            )}
          </Space>
        </Space>
        <DragDropContext onDragEnd={handlePhoneOder}>
          <Droppable droppableId="phones" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Form.List name="phones">
                  {(fields, listOperations) => {
                    // Keep a reference to listOperations
                    listOperationsRef.current = listOperations;

                    return (
                      <>
                        {fields.map((field, index) => (
                          <Draggable isDragDisabled={!bulkEditMode} key={field.key} draggableId={field.key.toString()} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <div
                                  style={{ display: 'flex', gap: '13px' }}
                                  className={` ${bulkEditMode ? 'bulk-edit' : ''}`}
                                  key={field.key}>
                                  {bulkEditMode && (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                      <DragIcon />
                                      <Checkbox checked={selectedItems.includes(index)} onChange={() => handleSelectItem(index)} />
                                    </div>
                                  )}

                                  <Form.Item
                                    style={{ flex: '1' }}
                                    {...field}
                                    name={[field.name, 'number']}
                                    label="Phone number"
                                    validateTrigger="onBlur"
                                    rules={addEditResidentValidation.phone}>
                                    <InputPhone disabled={disabled} />
                                  </Form.Item>

                                  <Form.Item
                                    style={{ flex: '1' }}
                                    {...field}
                                    key={field.key + 'label'}
                                    name={[field.name, 'label']}
                                    label="Label">
                                    <Select options={phoneTypeOptions} disabled={disabled} />
                                  </Form.Item>

                                  {/*<Form.Item*/}
                                  {/*  {...field}*/}
                                  {/*  style={{ display: 'none' }}*/}
                                  {/*  key={field.key + 'isNotificationEnabled'}*/}
                                  {/*  initialValue={true}*/}
                                  {/*  name={[field.name, 'isNotificationEnabled']}*/}
                                  {/*  hidden>*/}
                                  {/*  <Input />*/}
                                  {/*</Form.Item>*/}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </>
                    );
                  }}
                </Form.List>
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Space className="full-width">
          <Form.Item name="alertMessage" label="Alert Message">
            <Input.TextArea placeholder="Enter alert message" autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Space>

        <Space className="footer">
          <Button key="cancel" type="link" onClick={() => hideModal()} style={{ width: '159px' }}>
            Cancel
          </Button>

          <Button key="residentForm" htmlType="submit" type="primary" style={{ width: '159px' }} icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(ResidentAddEditModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
