import React, { FC, useEffect } from 'react';
import { Button, Form, Modal, Space, Input, DatePicker, Select } from 'antd';
import { IModal } from 'common/models/model.interface';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import { notification } from 'common/utils/notification';
import { IApproveVehicleModalProps } from './approve-vehicle-modal.types';
import { ReactComponent as ApproveIcon } from 'assets/icons/approve.svg';
import { IApproveVehicle } from 'common/services/vehicle-service/vehicle.service.types';
import styles from '../vehicles.module.scss';
import { addEditVehicleValidation } from '../../eiq-manage-residents/components/info-block/vehicles/add-edit-vehicle-modal/add-edit-vehicle-modal.validation';
import featureStore from 'common/stores/features/feature.store';
import { observer } from 'mobx-react-lite';
import { addEditAccessCardValidation } from '../../eiq-manage-residents/components/info-block/access-cards/add-edit-access-card-modal/add-edit-access-card-modal.validation';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import configurationStore from 'common/stores/configuration/configuration.store';

const ApproveVehicleModal: FC<IModal<IApproveVehicleModalProps, any>> = observer(({ isOpen, onClose, title, initData }) => {
  const [form] = Form.useForm();
  const cardClassOptions = configurationStore.configuration?.cardClasses?.map((i) => ({ value: i.name, label: i.name })) ?? [];

  const hideModal = (isSuccess: boolean) => {
    form.resetFields();
    notification.destroy();
    if (onClose) {
      onClose(isSuccess);
    }
  };

  const onFinish = async (values: any) => {
    if (!initData?.vehicleId) {
      console.error('Vehicle id is empty');
      return;
    }

    const data: IApproveVehicle = {
      deviceId: values.deviceId,
      facilityCode: values.facilityCode,
      validFrom: values.validFrom?.format('YYYY-MM-DD') ?? null,
      validTo: values.validTo?.format('YYYY-MM-DD') ?? null,
      cardClass: values.cardClass,
    };

    await vehicleService.approve(initData.vehicleId, data);
    hideModal(true);
  };

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData);
    }
  }, [isOpen, form, initData]);
  return (
    <Modal centered title={title} width={364} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} initialValues={initData} layout="vertical">
        <Space className="full-width" size={13}>
          <Form.Item
            name="deviceId"
            label="Device ID"
            rules={featureStore.isCardAccessEnabled && !initData?.isNoTransponder ? addEditVehicleValidation.deviceId : undefined}>
            <Input placeholder="Enter device ID" type="number" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item
            name="facilityCode"
            label="Facility code"
            rules={featureStore.isCardAccessEnabled && !initData?.isNoTransponder ? addEditVehicleValidation.facilityCode : undefined}>
            <Input placeholder="Enter facility code" />
          </Form.Item>
        </Space>
        {featureStore.isCA3000ExpirationsEnabled && !initData?.isNoTransponder && (
          <>
            <Space className="full-width" size={13}>
              <Form.Item name="validFrom" label="Valid from">
                <DatePicker placeholder="Start date" format={DATE_SHORT_MMM_DD_YYYY} />
              </Form.Item>
            </Space>
            <Space className="full-width" size={13}>
              <Form.Item name="validTo" label="Valid to" dependencies={['validFrom']} rules={addEditAccessCardValidation.validTo}>
                <DatePicker placeholder="End date" format={DATE_SHORT_MMM_DD_YYYY} />
              </Form.Item>
            </Space>
          </>
        )}
        {featureStore.isCA3000CardClassesEnabled && (
          <Space className="full-width" size={13}>
            <Form.Item name="cardClass" label="Card class">
              <Select options={cardClassOptions} placeholder="Select a card class" />
            </Form.Item>
          </Space>
        )}

        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className="eiq-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<ApproveIcon />} className={`${styles.approveButton} eiq-button`}>
            Approve
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
});

export default React.memo(ApproveVehicleModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
