import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { CheckYourEmail } from './components/check-your-email/check-your-email';
import { loginService } from '../../service/login.service';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { forgotPasswordValidation } from '../../validation/login.validation';
import { IForgotPasswordForm } from './forgot-password.types';
import { notification } from 'common/utils/notification';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IForgotPasswordForm>();
  const [isSuccessSentEmail, setSuccessSentEmail] = useState<boolean>(false);

  const onFinish = async (values: IForgotPasswordForm) => {
    try {
      await loginService.resetPassword(values.email);
      setSuccessSentEmail(true);
    } catch (error: any) {
      if (error?.response?.status === 404) {
        notification.error({ message: `User with email '${values.email}' not found` });
        return;
      }
      throw error;
    }
  };

  return (
    <>
      {isSuccessSentEmail && <CheckYourEmail email={form.getFieldValue('email')} onResend={() => setSuccessSentEmail(false)} />}
      {!isSuccessSentEmail && (
        <FormWrapper onFinish={onFinish} form={form} name="validateOnly" layout="vertical" autoComplete="off">
          <div className="title">Forgot the password?</div>
          <span className="text ant-mb-32">Enter your email below to receive password reset instruction.</span>

          <Form.Item className="ant-mb-32" name="email" label="Email" rules={forgotPasswordValidation.email}>
            <Input placeholder="Enter email" />
          </Form.Item>

          <div className="action-two-lines">
            <Button type="link" onClick={() => navigate('/login')}>
              Back to log in
            </Button>
            <Space />
            <Button type="primary" htmlType="submit" icon={<RightOutlined />}>
              Reset password
            </Button>
          </div>
        </FormWrapper>
      )}
    </>
  );
};
