import { ServerClient } from 'common/utils/http/http-clients';
import { ICommunitySettings, ISystemConfigurationRead, ISystemConfigurationUpdate } from './configuration.service.types';

class ConfigurationService {
  async getSystemConfiguration(): Promise<ISystemConfigurationRead> {
    const res = await ServerClient.get<ISystemConfigurationRead>(`/configurations/system`);
    return res?.data;
  }
  async updateSystemConfiguration(model: ISystemConfigurationUpdate): Promise<ISystemConfigurationRead> {
    const res = await ServerClient.patch<ISystemConfigurationRead>(`/configurations/system`, model);
    return res?.data;
  }
  async getCommunitySettings(): Promise<ICommunitySettings> {
    const res = await ServerClient.get<ICommunitySettings>(`/configurations/community`);
    return res?.data;
  }
}

export const configurationService = new ConfigurationService();
export default configurationService;
