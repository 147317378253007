import React, { FC } from 'react';
import './eiq-tabs-title-left.scss';
import { IEIQTabsTitleProps } from './eiq-tabs-title.types';

const EiqTabsTitleLeft: FC<IEIQTabsTitleProps> = ({ title }) => {
  return (
    <div className="tabs-title-wrapper">
      <span>Info</span>
      <span>{title}</span>
    </div>
  );
};

export default EiqTabsTitleLeft;
