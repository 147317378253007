import React, { FC, useState } from 'react';
import { Button, Col, Form, Modal, Row, Select, Space, notification } from 'antd';
import { IModal } from 'common/models/model.interface';
import { IOccupantMoveModalProps } from './resident-move-modal.types';
import { ReactComponent as MoveOut } from 'assets/icons/move-white.svg';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { occupantsMoveModalOccupantsInitialColumns } from './resident-move-modal-tables.config';
import TableBase from 'common/components/table/table-base';
import residentsService from 'common/services/residents-service/residents.service';
import { IAddressOccupant } from 'common/services/guest-service/guest.service.types';
import { baseModalTableHeightConfig, calculateTableHeight } from 'common/components/table/table-helpers';

const { Option } = Select;

const OccupantMoveModal: FC<IModal<IOccupantMoveModalProps, any>> = ({ isOpen, onClose, title, initData }) => {
  const [newOwnerOrRenterId, setNewOwnerOrRenterId] = useState<number | null>(null);

  const occupantsTableControls = useTableControls({
    clientSide: true,
    data: initData?.notSelectedOccupants,
    initialColumns: occupantsMoveModalOccupantsInitialColumns,
  });

  const hideModal = (...rest: any) => {
    setNewOwnerOrRenterId(null);
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };

  const handleMoveButton = () => {
    if (newOwnerOrRenterId) {
      residentsService
        .moveResidentToAddress(initData!.residentId, initData!.addressFromId, {
          ...initData!.requestBody,
          hostReplacementId: newOwnerOrRenterId,
        })
        .then((_res) => {
          hideModal(true);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  return (
    <Modal centered title={title} width={700} open={isOpen} wrapClassName="custom-modal resident-move-modal" closable={false}>
      <Space className="full-width">
        <Col span={24}>
          <p>Before changing the address of this owner, you must change status for these occupants</p>
        </Col>
      </Space>
      <Space className="full-width" size={13}>
        <Form.Item>
          <div>Change status</div>
        </Form.Item>
      </Space>
      <Space className="full-width">
        <Select onChange={setNewOwnerOrRenterId} defaultValue={null} placeholder="Choose new owner or renter">
          {initData?.notSelectedOccupants.map((option: IAddressOccupant) => (
            <Option className="eiq-select-options" key={option.residentId} value={option.residentId}>
              {option.name}
            </Option>
          ))}
        </Select>
      </Space>
      <TableContextProvider controls={occupantsTableControls}>
        <Row>
          <Col span={24}>
            <h3 className="table-title">{'Not selected occupants'}</h3>
          </Col>
        </Row>
        <Row
          className="table-wrapper"
          style={{
            height: calculateTableHeight({
              ...baseModalTableHeightConfig,
              tableDataLength: initData?.notSelectedOccupants!.length || 0,
            }),
          }}>
          <TableBase disableSelection />
        </Row>
      </TableContextProvider>
      <Space className="footer">
        <Button key="cancel" type="link" onClick={() => hideModal()} className="eiq-button">
          Cancel
        </Button>
        <Button key="residentMove" type="primary" className="eiq-button" icon={<MoveOut />} onClick={handleMoveButton}>
          Move
        </Button>
      </Space>
    </Modal>
  );
};

export default React.memo(OccupantMoveModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
