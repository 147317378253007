import React from 'react';
import { ICellRendererProps } from '../components/table/table.types';
import { IGuestRead } from '../services/residents-service/residents.service.types';
import { formatCustomDateTime } from '../helpers/date.helper';
import { Image } from 'antd';

export const petsColumns = () => ({
  photo: {
    key: 'photoUrl',
    title: 'Photo',
    dataKey: 'photoUrl',
    width: 152,
    fixed: 1,
    resizable: false,
    sortable: false,
    cellRenderer: (cell: any) => {
      return cell.cellData && <Image src={cell.cellData} width={24} height={24} />;
    },
  },
  name: {
    key: 'name',
    title: 'Name',
    dataKey: 'name',
    width: 152,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  type: {
    key: 'type',
    title: 'Type',
    dataKey: 'type',
    width: 152,
    minWidth: 150,
    resizable: true,
    sortable: true,
    fixed: 1,
  },
  breed: {
    key: 'breed',
    title: 'Breed',
    dataKey: 'breed',
    width: 152,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  color: {
    key: 'color',
    title: 'Color',
    dataKey: 'color',
    width: 152,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  dateOfBirth: {
    key: 'dateOfBirth',
    title: 'Date of birth',
    dataKey: 'dateOfBirth',
    width: 152,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      return formatCustomDateTime(cell.cellData);
    },
  },
  tag: {
    key: 'tag',
    title: 'Tag',
    dataKey: 'tag',
    width: 152,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  tagExpirationDate: {
    key: 'tagExpirationDate',
    title: 'Shots expiration date',
    dataKey: 'tagExpirationDate',
    width: 230,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      return formatCustomDateTime(cell.cellData);
    },
  },
  chipNumber: {
    key: 'chipNumber',
    title: 'Chip number',
    dataKey: 'chipNumber',
    width: 152,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
});
