import { FilterTypesEnum } from '../../../../common/enums/filter-types.enum';
import { residentTypeOptions } from '../../constants/resident-type-options.constant';
import residentsService from '../../../../common/services/residents-service/residents.service';
import { rentStatusOptions } from '../../constants/rent-status-options.constant';
import dayjs from 'dayjs';
import { IFilterConfig } from '../../../../common/components/table/filters/column-filter/column-filter.types';
import { residentAddressesColumns } from 'common/list-configs/resident-addresses.config';

const { firstName, lastName, phones, pin, clubId, altPhones, email, type, streetName, streetNumber, addressLabel, community } =
  residentAddressesColumns();

export const residentAddressInitialColumns = [
  lastName,
  firstName,
  { ...email, hidden: false },
  type,
  streetNumber,
  streetName,
  addressLabel,
  phones,
  pin,
  community,
  clubId,
  altPhones,
];

export const addressesResidentInitialColumns = [
  { ...firstName, dataKey: 'firstName' },
  { ...lastName, dataKey: 'lastName' },
  type,
  { ...phones, dataKey: 'phones' },
  { ...pin, dataKey: 'pin' },
  { ...clubId, dataKey: 'clubId' },
  { ...altPhones, dataKey: 'altPhones' },
  { ...email, dataKey: 'email' },
];

export const residentFilterConfig: IFilterConfig[] = [
  // {
  //   name: 'residentAlternativePhone',
  //   filterType: FilterTypesEnum.StartWith,
  //   label: 'Alternate phone number',
  //   type: 'INPUT',
  // },
  // {
  //   name: 'resident_email',
  //   filterType: FilterTypesEnum.Equals,
  //   label: 'Email Address',
  //   type: 'INPUT',
  // },
  {
    name: 'ownershipType',
    filterType: FilterTypesEnum.Equals,
    label: 'Type of ownership',
    type: {
      options: residentTypeOptions,
      type: 'SELECT',
    },
  },
  // {
  //   name: 'relatedTo_residentId',
  //   filterType: FilterTypesEnum.Equals,
  //   label: 'Related to',
  //   type: {
  //     optionValue: 'id',
  //     optionLabel: 'name',
  //     searchField: 'label',
  //     onSearch: (searchFilter) => {
  //       return residentsService.getResidents(null, searchFilter, '+lastName');
  //     },
  //     type: 'SELECT_ASYNC',
  //   },
  // },
  {
    name: 'status',
    filterType: FilterTypesEnum.Equals,
    label: 'Rent status',
    type: {
      options: rentStatusOptions,
      onChange: (filterValue: string, tableContext) => {
        const today = dayjs().format('YYYY-MM-DD');

        if (filterValue === 'active') {
          tableContext.setFilter('rentEndDate', { operator: FilterTypesEnum.GreaterThan, value: today }, 'AND');
        } else {
          tableContext.setFilter('rentEndDate', { operator: FilterTypesEnum.LessThanOrEqual, value: today }, 'AND');
        }
      },
      type: 'SELECT',
    },
  },
  {
    name: 'rentStartDate',
    filterType: FilterTypesEnum.Equals,
    label: 'Rental dates',
    type: {
      nameEnd: 'rentEndDate',
      type: 'DATE_RANGE',
    },
  },
];
