export const formatPhone = (phoneNumber?: any) => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
};

export const isPhoneNumber = (value: any) => {
  return /^\d{10}$/.test(value);
};
