import React, { FC } from 'react';
import { IResidentInfo, IResidentInfoProps } from './resident-info.types';
import './resident-info.scss';
import AlertBlock from 'common/components/info-block/alert-block/alert-block';
import { observer } from 'mobx-react-lite';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { Checkbox } from 'antd';
import ContactBlock from 'common/components/info-block/contact-block/contact-block';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IResidentAddressRead, IResidentUpdate } from 'common/services/residents-service/residents.service.types';
import residentsService from 'common/services/residents-service/residents.service';
import { useTableContext } from 'common/components/table/table-context';
import { trackPromise } from 'react-promise-tracker';
import { formatPhone } from 'common/helpers/phone.helper';
import dayjs from 'dayjs';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import { getPhoneComparer } from 'features/eiq-secure/heplers/phone.helper';

const ResidentInfo: FC<IResidentInfoProps> = ({ actionBlock }) => {
  const context = useTableContext();
  const { ownershipType, relatedTo } = context.getSelectedRow() ?? ({} as IResidentAddressRead);

  const {
    id,
    alertMessage,
    email,
    clubId,
    pin,
    isGuestListEnabled,
    isVisibleInGuestManagement,
    vacationStartDate,
    vacationEndDate,
    updatedAt,
    phones = [],
  }: IResidentInfo = context.getSelectedRow()?.resident ?? ({} as IResidentInfo);

  const isVisibleInGuestManagementClickHandle = (event: CheckboxChangeEvent) => {
    const residentToUpdate: IResidentUpdate = {
      id: id,
      isVisibleInGuestManagement: event.target.checked,
    };

    trackPromise(
      residentsService.updateResident(residentToUpdate).then(() => {
        const selectedRow = context.getSelectedRow();
        context.updateSelectedRow({ ...selectedRow, isVisibleInGuestManagement: residentToUpdate.isVisibleInGuestManagement });
      }),
    );
  };

  const isGuestListEnabledClickHandle = (event: CheckboxChangeEvent) => {
    const residentToUpdate: IResidentUpdate = {
      id: id,
      isGuestListEnabled: event.target.checked,
    };

    trackPromise(
      residentsService.updateResident(residentToUpdate).then(() => {
        const selectedRow = context.getSelectedRow();
        context.updateSelectedRow({ ...selectedRow, isGuestListEnabled: residentToUpdate.isGuestListEnabled });
      }),
    );
  };

  return (
    <div className="resident-info-container">
      <div className="resident-info-update">
        <span className="resident-info-update-date">Record last update {dayjs(updatedAt).format(DATE_SHORT_MMM_DD_YYYY)}</span>
        {actionBlock}
      </div>
      <div className="resident-info-contacts">
        <div className="resident-info-alert">
          {alertMessage && <AlertBlock isMedical={false} alertMessage={alertMessage} />}
          <ContactBlock
            email={email}
            clubId={clubId}
            vacationEndDate={vacationEndDate}
            vacationStartDate={vacationStartDate}
            pin={pin}
            ownershipType={ownershipType}
            relatedTo={relatedTo}
          />
        </div>
        <div className="contacts-info-container">
          <div>
            <span>Contacts</span>
            {phones.sort(getPhoneComparer()).map((item, index) => (
              <div className="contact-phone" key={`contact-phone-${index}`}>
                <PhoneIcon />
                <span>{formatPhone(item.number)}</span>
                {/* TODO: set the correct label color when BE will have a full list of phone labels  */}
                <span className="eiq-label primary">{item.label}</span>
              </div>
            ))}
          </div>
          {/* TODO :: moved to post-MVP */}
          <div style={{ display: 'none' }}>
            <Checkbox onChange={isVisibleInGuestManagementClickHandle} checked={isVisibleInGuestManagement}>
              Visible in CSS Guest Management
            </Checkbox>
            <Checkbox onChange={isGuestListEnabledClickHandle} checked={isGuestListEnabled}>
              Resident's Guest List
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ResidentInfo);
