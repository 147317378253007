import { Tag } from 'antd';
import BaseCellText from 'common/components/table/base-cell-text';
import { ICellRendererProps } from 'common/components/table/table.types';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import { DateToFormat } from 'common/helpers/date.helper';
import { IAccessCardRead } from 'common/services/residents-service/residents.service.types';

const getValidPeriod = (rowData: IAccessCardRead) => {
  const isPeriod = rowData?.validFrom && rowData?.validTo;

  return isPeriod
    ? `${DateToFormat(rowData.validFrom, DATE_SHORT_MMM_DD_YYYY)} - ${DateToFormat(rowData.validTo, DATE_SHORT_MMM_DD_YYYY)}`
    : '';
};

export const accessCardColumns = [
  {
    key: 'owner',
    title: 'Owner',
    dataKey: 'owner',
    width: 140,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  {
    key: 'facilityCode',
    title: 'Facility code',
    dataKey: 'facilityCode',
    width: 140,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  {
    key: 'deviceId',
    title: 'Device ID',
    dataKey: 'deviceId',
    width: 120,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  {
    key: 'cardClass',
    title: 'Card Class',
    dataKey: 'cardClass',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IAccessCardRead>) => {
      return (
        cell?.rowData?.cardClass && (
          <Tag className="eiq-tag" color="#C1FFEA" style={{ color: '#292B2E' }}>
            {cell?.rowData.cardClass}
          </Tag>
        )
      );
    },
  },
  {
    key: 'additionalInformation',
    title: 'Description',
    dataKey: 'additionalInformation',
    width: 245,
    fixed: 1,
    resizable: true,
    sortable: false,
  },
  {
    key: 'registrationAddress',
    title: 'Address of registration',
    dataKey: 'registrationAddress',
    width: 200,
    fixed: 1,
    resizable: true,
    sortable: false,
  },
  {
    key: 'ValidityPeriod',
    title: 'Validity period',
    dataKey: 'ValidityPeriod',
    width: 195,
    minWidth: 150,
    resizable: true,
    sortable: false,
    getValue: getValidPeriod,
    cellRenderer: (cell: ICellRendererProps<IAccessCardRead>) => {
      return <BaseCellText>{getValidPeriod(cell?.rowData)}</BaseCellText>;
    },
    flexGrow: 1,
  },
];
