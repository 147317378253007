import { Row, Col, Input, Form } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { IIncidentInvolvedVehicle } from 'common/services/incident-report/incident-report.types';
import { IIncidentInvolvedVehicleEditProps } from './incident-involved-vehicle-edit.types';
import { incidentInvolvedVehicleFormRules } from './incident-involved-vehicle-edit.validation';
import React from 'react';
import IncidentEditModeActions from '../../incident-edit-mode-actions/incident-edit-mode-actions';

export const IncidentInvolvedVehicleEdit = ({ initialValue, onSave, onCancel }: IIncidentInvolvedVehicleEditProps) => {
  const [form] = Form.useForm<IIncidentInvolvedVehicle>();

  const handleSave = (values: IIncidentInvolvedVehicle) => {
    onSave(values);
  };

  return (
    <FormWrapper form={form} onFinish={handleSave} autoComplete="false" initialValues={initialValue} layout="vertical">
      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Form.Item
            className="licenseNumberItem"
            name="licenseNumber"
            label="License plate"
            rules={incidentInvolvedVehicleFormRules.licenseNumber}>
            <Input name="licenseNumber" placeholder="Enter license plate" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item className="licenseStateItem" name="licenseState" label="State" rules={incidentInvolvedVehicleFormRules.licenseState}>
            <Input name="licenseState" placeholder="Enter license state" />
          </Form.Item>
        </Col>
      </Row>
      <IncidentEditModeActions onCancel={onCancel} />
    </FormWrapper>
  );
};
