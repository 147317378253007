import { Upload, UploadFile } from 'antd';
import { ReactComponent as PictureIcon } from 'assets/icons/picture.svg';
import accountService from 'common/services/file-service/file.service';
import { UploadFileTypes } from 'common/services/file-service/file.service.types';
import { IIncidentAttachment } from 'common/services/incident-report/incident-report.types';
import { useState } from 'react';
import {
  convertIncidentAttachmentToUploadFile,
  convertToIncidentAttachment,
  IIncidentAttachmentUploadFormProps,
} from './incident-attachment.types';
import { notification } from 'common/utils/notification';

export const IncidentAttachment = ({ onChange, initialValue }: IIncidentAttachmentUploadFormProps) => {
  const [fileList, setFileList] = useState<UploadFile<IIncidentAttachment>[]>(
    initialValue?.map(convertIncidentAttachmentToUploadFile) || [],
  );

  const handleCustomRequest = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      const response = await accountService.uploadPhoto(file as Blob, UploadFileTypes.IncidentReport, 'temporary:true');

      const fileData: IIncidentAttachment = {
        fileUri: response.absoluteUri,
        fileName: file.name,
        contentType: file.type || '',
      };

      const updatedFile = { ...file, response: fileData };

      if (onProgress) {
        onProgress({ percent: 100, url: response.absoluteUri }, file);
      }

      onSuccess(fileData);

      updateFileList([...fileList, updatedFile]);
    } catch (error: any) {
      const errorMessage = error.details?.[0]?.errors?.[0] || undefined;

      if (errorMessage) {
        notification.error({ message: errorMessage });
      }

      onError(error);
    }
  };

  const handleRemove = async (file: UploadFile) => {
    if (!file.response || !file.response.fileUri) {
      updateFileList(
        fileList.filter((f) => f.uid !== file.uid),
        false,
      );
      return true;
    }

    try {
      await accountService.deletePhoto(file.response.fileUri);
      updateFileList(fileList.filter((f) => f.uid !== file.uid));
      return true;
    } catch (error: any) {
      return false;
    }
  };

  const updateFileList = (value: UploadFile[], triggerOnChange: boolean = true) => {
    setFileList(value);

    if (triggerOnChange) {
      onChange(value.map(convertToIncidentAttachment));
    }
  };

  return (
    <>
      <Upload
        accept="image/png, image/jpeg, image/jpg"
        name="incidentAttachments"
        multiple={false}
        customRequest={handleCustomRequest}
        listType="picture"
        onRemove={handleRemove}
        defaultFileList={fileList}>
        <span className="ant-upload-drag-icon">
          <PictureIcon />
        </span>
        <p className="ant-upload-text">Click or tap here to upload photos of the incident</p>
      </Upload>
    </>
  );
};
