import { ServerClient } from '../../utils/http/http-clients';
import { IResponseList } from '../../models/response-list.interface';
import { ITrafficRead } from './traffic-service.types';
import { IParamsLimit } from 'common/models/params-limit.interface';

class TrafficService {
  async getTraffic(
    pageLimit: IParamsLimit | null | undefined,
    filter: string | null | undefined,
    sort: string | null | undefined,
  ): Promise<IResponseList<ITrafficRead>> {
    const res = await ServerClient.get<IResponseList<ITrafficRead>>('/traffic-logs', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async getTrafficByResidentId(
    pageLimit: IParamsLimit | null | undefined,
    filter: string | null | undefined,
    sort: string | null | undefined,
    residentId: number,
  ): Promise<IResponseList<ITrafficRead>> {
    const res = await ServerClient.get<IResponseList<ITrafficRead>>(`/traffic-logs/residents/${residentId}`, {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }
}

export const trafficService = new TrafficService();
export default trafficService;
