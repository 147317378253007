import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { NavLink, NavLinkProps, useNavigate } from 'react-router-dom';
import { HotkeyLinkProps } from './hotkey-link.types';
import { Key } from '../key/key';
import './hotkey-link.scss';
export const HotkeyLink = ({ to, hotkey = '', className, keyLabel, label, ...rest }: HotkeyLinkProps) => {
  const navigate = useNavigate();
  const handleClick = (keyboardEvent: KeyboardEvent) => {
    keyboardEvent.preventDefault();
    keyboardEvent.stopPropagation();
    keyboardEvent.stopImmediatePropagation();
    navigate(to);
  };

  useHotkeys(hotkey, handleClick);

  return (
    <NavLink className={`hotkey-link ${className ?? ''}`} to={to} {...rest}>
      <span className={'hotkey-link__label'}>{label}</span>
      {keyLabel && <Key className={'hotkey-link__keybox'} value={keyLabel} />}
    </NavLink>
  );
};
