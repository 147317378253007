export const rentStatusOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
];
