export enum AdmitResidentEnum {
  ADMIT_RESIDENT = 'ADMIT_RESIDENT',
  SELECT_ADDRESS = 'ADMIT_RESIDENT:SELECT_ADDRESS',
  LICENSE = 'ADMIT_RESIDENT:LICENSE',
}

export enum AdmitGuestEnum {
  ADMIT_GUEST = 'ADMIT_GUEST',
  SELECT_GUEST = 'ADMIT_GUEST:SELECT_GUEST',
  SELECT_ADDRESS = 'ADMIT_GUEST:SELECT_ADDRESS',
  LICENSE = 'ADMIT_GUEST:LICENSE',
  ADD = 'ADMIT_GUEST:ADD',
}
