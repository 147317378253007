import { guestColumns } from 'common/list-configs/guest-list.config';

const {
  lastName,
  firstName,
  type,
  validity,
  companyName,
  daysOfWeek,
  keyPermission,
  isShared,
  source,
  dateGuestAdded,
  restrictions,
  visitDate,
  phone,
  carNumber,
  carState,
} = guestColumns();
export const guestInitialColumns = [
  lastName,
  firstName,
  companyName,
  type,
  restrictions,
  validity,
  visitDate,
  daysOfWeek,
  keyPermission,
  isShared,
  source,
  dateGuestAdded,
  phone,
  carNumber,
  carState,
];
