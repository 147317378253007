import { addressesColumns } from 'common/list-configs/addresses.config';
import { petsColumns } from 'common/list-configs/pets.config';
import { residentsColumns } from 'common/list-configs/residents.config';
import { vehiclesColumns } from 'common/list-configs/vehicles.config';

const { name, type, breed, color, tag } = petsColumns();
const { firstName: residentFirstName, lastName: residentLastName, phones, pin, clubId } = residentsColumns();
const { owner, year, deviceId, color: vehicleColor, make, model, licenseNumber } = vehiclesColumns();
const { name: addressName, block, building, unit, streetNumber } = addressesColumns();

export const residentInitialColumnsForDeleteModal = [
  { ...residentFirstName, width: 202, resizable: false },
  { ...residentLastName, width: 202, resizable: false },
  {
    ...phones,
    width: 202,
    resizable: false,
    sortable: true,
    getValue: (cell: any) => cell.phones.find((phone: any) => phone.label === 'Primary')?.number,
  },
  { ...pin, width: 80, resizable: false },
  { ...clubId, width: 202 },
];

export const petsInitialColumnsForDeleteModal = [
  { ...name, width: 170, resizable: false },
  { ...type, width: 170, resizable: false },
  { ...breed, width: 170, resizable: false },
  { ...color, width: 169, resizable: false },
  { ...tag, width: 169, resizable: false },
];

export const vehicleInitialColumnsForDeleteModal = [
  { ...owner, width: 136, minWidth: 136, resizable: false },
  { ...year, width: 50, minWidth: 50, resizable: false },
  { ...deviceId, width: 133, minWidth: 133, resizable: false },
  { ...vehicleColor, width: 136, minWidth: 136, resizable: false },
  { ...make, width: 136, minWidth: 136, resizable: false },
  { ...model, width: 136, minWidth: 136, resizable: false },
  { ...licenseNumber, width: 120, minWidth: 120, resizable: false },
];

export const addressesInitialColumnsForDeleteModal = [
  { ...streetNumber, width: 180, resizable: false },
  { ...addressName, width: 200, resizable: false },
  { ...building, width: 120, resizable: false },
  { ...block, width: 120, resizable: false },
  { ...unit, width: 120, resizable: false },
];
