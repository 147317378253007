export enum GuestValidity {
  Undefined = 'Undefined',
  OneTime = 'OneTime',
  Permanent = 'Permanent',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  DateRange = 'DateRange',
}

export enum ServerGuestValidity {
  Undefined = 'Undefined',
  OneTime = 'OneTime',
  Permanent = 'Permanent',
  DateRange = 'DateRange',
}
