import { useMediaQuery } from 'react-responsive';
import { IUseMedia } from './use-media.type';

// Extend if need it. Main purpose save all media configuration in one place and the easiest way to using.
// Main case: hide/show some elements. If you need to change color or behavior should use css.
export const useMedia = (): IUseMedia => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 992 });
  const isDesktop = useMediaQuery({ minWidth: 993 });

  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isBigScreen = useMediaQuery({ minWidth: 1824 });

  return {
    isMobile,
    isTablet,
    isDesktop,

    isMobileOrTable: isMobile || isTablet,
    isNotMobile,
    isPortrait,
    isBigScreen,
  };
};
