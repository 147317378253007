import { IAddress } from '../services/street-service/street.service.types';

export interface IFormatAddressOptions {
  hideStreet?: boolean;
}

export const formatAddressLabel = (address: IAddress | undefined | null, options: IFormatAddressOptions | null = null): string => {
  if (!address) {
    return '';
  }
  const addressParts: string[] = [];

  if (!options?.hideStreet && address?.streetNumber && address?.street?.name) {
    addressParts.push(`${address?.streetNumber} ${address?.street.name}`);
  }

  if (address?.unit?.length) {
    addressParts.push(`Unit ${address?.unit}`);
  }

  if (address?.blockNumber?.length) {
    addressParts.push(`Block ${address?.blockNumber}`);
  }

  if (address?.lotNumber?.length) {
    addressParts.push(`Lot ${address?.lotNumber}`);
  }

  if (address?.buildingNumber) {
    addressParts.push(`Building ${address?.buildingNumber}`);
  }

  const result = addressParts.join(', ');

  return result?.length > 0 ? result : ' ';
};

export const formatZoning = (address: IAddress | undefined | null): string => {
  if (!address) {
    return '';
  }

  const addressParts: string[] = [];

  if (address?.blockNumber?.length) {
    addressParts.push(`Block ${address?.blockNumber}`);
  }

  if (address?.lotNumber?.length) {
    addressParts.push(`Lot ${address?.lotNumber}`);
  }

  if (address?.buildingNumber) {
    addressParts.push(`Building ${address?.buildingNumber}`);
  }

  if (address?.unit?.length) {
    addressParts.push(`Unit ${address?.unit}`);
  }

  const result = addressParts.join(', ');

  return result?.length > 0 ? result : ' ';
};

export const formatStreet = (address?: IAddress) => {
  if (!address) {
    return '';
  }

  if (address?.streetNumber && address?.street?.name) {
    return `${address?.streetNumber} ${address?.street.name}`;
  }

  return ' ';
};
