export const changePasswordValidation = {
  currentPassword: [{ required: true, message: 'Current password is required!' }],
  newPassword: [
    { required: true, message: 'New password is required' },
    { min: 8, message: 'Password must be at least  8 characters long' },
  ],
  confirmPassword: [
    { required: true, message: 'Confirm password is required' },
    ({ getFieldValue }: any) => ({
      validator: (_: any, value: string) => {
        const passwordValue = getFieldValue('newPassword');
        if (!value || passwordValue === value) {
          return Promise.resolve();
        }
        return Promise.reject("Passwords don't match.");
      },
    }),
  ],
};
