import { forwardRef } from 'react';
import { IIncidentLocationProps } from './incident-location.types';
import useIncidentForm from 'common/hooks/use-incident-form/use-incident-form.hook';
import IncidentLocationView from './incident-location-view/incident-location-view';
import IncidentLocationEdit from './incident-location-edit/incident-location-edit';

const IncidentLocation = forwardRef(({ onSave, incident }: IIncidentLocationProps, ref) => {
  const { mode, form, switchToEditMode, switchToViewMode, onSubmit } = useIncidentForm({ initialValues: incident, onSave, ref });

  if (mode === 'view') {
    return incident ? <IncidentLocationView onEdit={switchToEditMode} incident={incident} /> : <></>;
  } else {
    return <IncidentLocationEdit form={form} onSave={onSubmit} onCancel={switchToViewMode} incident={incident} />;
  }
});

export default IncidentLocation;
