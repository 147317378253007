import { ResidentTypesEnum } from 'common/enums/resident-types.enum';

export const isOccupant = (ownerType?: ResidentTypesEnum) => {
  return (
    ownerType !== ResidentTypesEnum.Renter &&
    ownerType !== ResidentTypesEnum.Owner &&
    ownerType !== ResidentTypesEnum.Manager &&
    ownerType !== ResidentTypesEnum.NonResident &&
    ownerType !== ResidentTypesEnum.Developer &&
    ownerType !== ResidentTypesEnum.Employee &&
    ownerType !== ResidentTypesEnum.ClubMember
  );
};

export const isHost = (ownerType?: ResidentTypesEnum) => {
  return ownerType === ResidentTypesEnum.Renter || ownerType === ResidentTypesEnum.Owner;
};
