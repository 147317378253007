import React, { forwardRef } from 'react';
import { IEiqPrint } from './eiq-print.types';
import styles from './eiq-print.module.scss';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import TableBase from 'common/components/table/table-base';
import { formatAddressLabel } from 'common/helpers/address.helper';

const EiqPrint = forwardRef(({ firstName, lastName, address, data, columns, rowHeight }: IEiqPrint, ref: any) => {
  const tableControls = useTableControls({
    clientSide: true,
    data: data,
    initialColumns: columns,
  });

  return (
    <div className={styles.print}>
      <div className={styles.printBody} ref={ref}>
        <div className={styles.printContent}>
          <div>
            <span className={styles.name}>Name:</span>{' '}
            <span>
              {firstName} {lastName}
            </span>
          </div>
          <div>
            <span className={styles.name}>Address:</span>
            <span>{` ${formatAddressLabel(address)}`}</span>
          </div>
        </div>
        <TableContextProvider controls={tableControls}>
          <TableBase rowHeight={rowHeight} />
        </TableContextProvider>
      </div>
    </div>
  );
});

export default EiqPrint;
