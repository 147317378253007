import userPersonalizationService from 'common/services/user-personalization-service/user-personalization.service';
import { makeAutoObservable } from 'mobx';
import React from 'react';
import { IUserPersonalization } from 'common/services/user-personalization-service/user-personalization.service.types';
import { USER_PERSONALIZATION_DATA } from './user-personalization.constants';

class UserPersonalizationStore {
  personalization: IUserPersonalization | null = null;

  constructor() {
    makeAutoObservable(this);
    this.restoreUserPersonalization();
  }

  setUserPersonalization(personalization: IUserPersonalization | null) {
    localStorage.setItem(USER_PERSONALIZATION_DATA, JSON.stringify(personalization));
    this.personalization = personalization;
  }

  restoreUserPersonalization() {
    const data = localStorage.getItem(USER_PERSONALIZATION_DATA);
    this.personalization = data ? JSON.parse(data) : null;
  }

  clearUserPersonalization() {
    localStorage.removeItem(USER_PERSONALIZATION_DATA);
    this.personalization = null;
  }

  async requestUserPersonalization() {
    const data = await userPersonalizationService.get();
    this.setUserPersonalization(data);
  }

  async updateTableColumnsPersonalization(tableName: string, tableColumns: string[]) {
    const data = await userPersonalizationService.updateTableColumnsPersonalization(tableName, tableColumns);
    this.setUserPersonalization(data);
  }
}

const userPersonalizationStore = new UserPersonalizationStore();
export const UserPersonalizationStoreContext = React.createContext<UserPersonalizationStore>(userPersonalizationStore);
export default userPersonalizationStore;
