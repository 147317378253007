import { Button, Form, Input, Modal, Select, Space } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { IModal } from 'common/models/model.interface';
import configurationStore from 'common/stores/configuration/configuration.store';
import { notification } from 'common/utils/notification';
import { toGateOptions } from 'features/eiq-secure/constans/common.eiq-secure';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { addVisitorValidation } from '../add-visitor/add-visitor.validation';
import SelectState from 'common/components/form-elements/select-state/select-state';
import { IQRCodeAdmitModalProps } from './eiq-secure-qr-code-admit-modal.types';
import guestService from 'common/services/guest-service/guest.service';
import { EntryType } from 'common/enums/entry-type.enum';
import { IAdmitResident } from 'common/services/guest-service/guest.service.types';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';

const QRCodeAdmitModal: FC<IModal<IQRCodeAdmitModalProps, any>> = observer(({ isOpen, onClose, initData }) => {
  const [form] = Form.useForm();
  const gateOptions = toGateOptions(configurationStore?.configuration?.gates || []);

  const getFormInitData = (data?: IQRCodeAdmitModalProps) => {
    return {
      addressId: data?.addressId,
      residentId: data?.residentId,
      guestId: data?.guest?.id,
      gate: data?.gate,
      carExpirationDate: data?.carExpirationDate,
      carNumber: data?.carNumber,
      carState: data?.carState ?? configurationStore.configuration?.default.defaultLicenseState,
      deviceId: data?.deviceId,
      firstName: data?.guest?.firstName,
      lastName: data?.guest?.lastName,
      company: data?.guest?.companyName,
    };
  };

  useEffect(() => {
    if (form && isOpen && initData) {
      form.setFieldsValue(getFormInitData(initData));
    }
  }, [isOpen, form, initData]);

  const hideModal = (...rest: any) => {
    form.resetFields();
    if (onClose) {
      onClose(...rest);
    }
  };

  const onFinish = (values: any) => {
    if (!initData) {
      return;
    }

    const { residentId, addressId, guest, carExpirationDate, deviceId } = initData;

    const admitData: IAdmitResident = {
      gate: values.gate,
      entryType: EntryType.Driver,
      carState: values.carState,
      carLicensePlate: values.carNumber,
      carExpirationDate: carExpirationDate,
      deviceId: deviceId,
      firstName: values.firstName ? values.firstName : null,
      lastName: values.lastName ? values.lastName : null,
    };

    if (guest) {
      return guestService.admitGuest(residentId, addressId, guest.id, admitData).then((result) => {
        notification.destroy();
        notification.success({ message: 'Guest has been admitted' });
        hideModal();
      });
    } else {
      return guestService.admitResident(residentId, addressId, admitData).then((result) => {
        notification.destroy();
        notification.success({ message: 'The resident  has been admitted' });
        hideModal();
      });
    }
  };

  return (
    <Modal centered width={400} open={isOpen} wrapClassName="custom-modal custom-modal--so" closable={false} title="Admit">
      <FormWrapper form={form} onFinish={onFinish} initialValues={getFormInitData(initData)} layout="vertical">
        {isServiceGuest(initData?.guest?.type) && (
          <>
            <Space className="full-width" size={13}>
              <Form.Item name="firstName" label="First name">
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Space>
            <Space className="full-width" size={13}>
              <Form.Item name="lastName" label="Last name">
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Space>
            <Space className="full-width" size={13}>
              <Form.Item name="company" label="Company">
                <Input disabled />
              </Form.Item>
            </Space>
          </>
        )}
        <Space className="full-width" size={13}>
          <Form.Item name="carNumber" label="License" rules={addVisitorValidation.carLicensePlate}>
            <Input placeholder="Enter license" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="carState" label="License state" rules={addVisitorValidation.carState}>
            <SelectState placeholder="Select a license state" popupClassName="so-select" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="gate" label="Gate" rules={addVisitorValidation.gate}>
            <Select options={gateOptions} placeholder="Select Gate" popupClassName="so-select" />
          </Form.Item>
        </Space>

        <Space className="footer">
          <Button className="eiq-button" type="link" onClick={hideModal}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" className="primary-btn eiq-button">
            Approve
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
});

export default React.memo(QRCodeAdmitModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
