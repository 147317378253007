import { DatePicker, Form, Radio } from 'antd';
import React, { useState } from 'react';
import './date-range.scss';
import { DATE_FORMAT_MMMM_D_YYYY } from '../../../constans/date.constants';
import { DateRangeProps } from './date-range.types';

export const DateRange = ({ name, allText = 'All dates', label = 'Date range', onChange }: DateRangeProps) => {
  const [selectedRadio, setSelectedRadio] = useState<string | null>('all');

  const handleDateChanges = () => {
    setSelectedRadio('range');
  };

  return (
    <div className="range-date">
      {label?.length && <span className="range-date__label">{label}</span>}

      <Radio.Group
        value={selectedRadio}
        onChange={(e) => {
          setSelectedRadio(e.target.value);
          if (e.target.value === 'all' && onChange) {
            onChange(null);
          }
        }}>
        <Radio value="all">{allText}</Radio>
        <Radio value="range">Set range</Radio>
      </Radio.Group>

      <div className="range-date__content">
        <Form.Item name={[name, 'from']} label="From">
          <DatePicker format={DATE_FORMAT_MMMM_D_YYYY} placeholder="Start date" onChange={handleDateChanges} />
        </Form.Item>

        <Form.Item name={[name, 'to']} label="To">
          <DatePicker format={DATE_FORMAT_MMMM_D_YYYY} placeholder="End date" onChange={handleDateChanges} />
        </Form.Item>
      </div>
    </div>
  );
};
