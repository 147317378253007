import { commonColumns } from 'common/list-configs/common-list.config';

const { email, phone } = commonColumns();

export const accountsTableInitialColumns = [
  {
    key: 'firstName',
    title: 'First name',
    dataKey: 'firstName',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  {
    key: 'lastName',
    title: 'Last name',
    dataKey: 'lastName',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  email,
  {
    key: 'accountName',
    title: 'Account name',
    dataKey: 'accountName',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  phone,
  {
    key: 'defaultGate',
    title: 'Default gate',
    dataKey: 'defaultGate',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
];
