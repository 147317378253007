import TableContextProvider, { useTableControls } from '../../../../../../../common/components/table/table-context';
import { Button, Col, Row, Space } from 'antd';
import { SearchFilter } from '../../../../../../../common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from '../../../../../../../common/enums/filter-types.enum';
import { PlusOutlined } from '@ant-design/icons';
import TableBase from '../../../../../../../common/components/table/table-base';
import React, { useRef, useEffect } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { IPetRead } from '../../../../../../../common/services/residents-service/residents.service.types';
import residentStore from '../../../../../stores/resident.store';
import { petsInitialColumns } from './pets.config';
import { observer } from 'mobx-react-lite';
import { useModal } from '../../../../../../../common/hooks/use-modal/use-modal.hook';
import AddEditPetModal from './add-edit-pet-modal/add-edit-pet-modal';
import { useReactToPrint } from 'react-to-print';
import EiqPrint from '../print/eiq-print';
import { petPrintColumns } from '../print/eiq-print.config';
import { ICreatePet, IUpdatePet } from '../../../../../service/pet-service/pet.service.types';
import { StrToDate } from '../../../../../../../common/helpers/date.helper';
import ConfirmDeleteModal, {
  ConfirmDeleteItemName,
} from '../../../../../../../common/components/modal/confirm-delete-modal/confirm-delete-modal';
import { IConfirmDeleteModalProps } from '../../../../../../../common/components/modal/confirm-delete-modal/confirm-delete-modal.types';
import PetService from '../../../../../service/pet-service/pet.service';

const initPetData: ICreatePet = {
  name: '',
  photoUrl: '',
  type: '',
  breed: '',
  color: '',
  dateOfBirth: null,
  tag: '',
  tagExpirationDate: null,
  chipNumber: null,
};

export const Pets = observer(() => {
  const tableControls = useTableControls({
    clientSide: true,
    data: residentStore.pets,
    initialColumns: petsInitialColumns,
  });

  const printRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const addPetModal = useModal({
    onBeforeShow: (setModalInitData) => {
      if (!residentStore.selectedResidentAddress) {
        console.error(`Resident is not selected`);
        return;
      }

      setModalInitData(initPetData);
    },
    onClose: (isSuccessSaved, pet: IPetRead) => {
      if (isSuccessSaved) {
        residentStore.addPet(pet);
      }
    },
  });

  const editPetModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow: IPetRead = tableControls.getSelectedRow();

      if (!selectedRow) {
        return;
      }
      if (!residentStore.selectedResidentAddress) {
        console.error(`Resident is not selected`);
        return;
      }
      const res = toUpdatePet(selectedRow);
      setModalInitData(res);
    },
    onClose: (isSuccessSaved, pet: IPetRead) => {
      if (isSuccessSaved) {
        residentStore.updatePet(pet);
        tableControls.updateSelectedRow(pet);
      }
    },
  });

  const getDeleteMessage = (name: string) => {
    return (
      <span>
        <span>Are you sure you want to delete </span>
        <ConfirmDeleteItemName name={name} />
        <span> from a pet list?</span>
      </span>
    );
  };

  const deletePetModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow: IPetRead = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      if (!residentStore.selectedResidentAddress) {
        console.error(`Resident is not selected`);
        return;
      }

      const data: IConfirmDeleteModalProps = {
        message: getDeleteMessage(`${selectedRow.name}`),
        onDelete: () => {
          PetService.deletePet(
            residentStore.selectedResidentAddress?.resident?.id!,
            residentStore.selectedResidentAddress?.address?.id!,
            selectedRow.id,
          ).then((result) => {
            deletePetModal.hideModal(true);
          });
        },
      };

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        const selectedRow: IPetRead = tableControls.getSelectedRow();
        residentStore.deletePet(selectedRow.id);
        tableControls.setSelectedRow(null);
      }
    },
  });

  return (
    <div>
      <EiqPrint
        firstName={residentStore.selectedResident?.firstName}
        lastName={residentStore.selectedResident?.lastName}
        address={
          residentStore.selectedResident?.addresses?.find((i) => i.address.id === residentStore.selectedResidentAddress?.address.id)
            ?.address
        }
        data={residentStore.pets}
        ref={printRef}
        columns={petPrintColumns}
        rowHeight={150}
      />
      <TableContextProvider controls={tableControls}>
        <div>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                adaptiveSearch={true}
                enableProgressiveSearch={true}
                placeholder="Search by name, type, breed, color, tag, chipNumber"
                rulesForColumn={{
                  type: FilterTypesEnum.Contains,
                  breed: FilterTypesEnum.Contains,
                  color: FilterTypesEnum.Contains,
                  name: FilterTypesEnum.Contains,
                  tag: FilterTypesEnum.Contains,
                  chipNumber: FilterTypesEnum.Contains,
                }}
                rightSide={
                  <Space size={8}>
                    <Button type="default" icon={<PrintIcon />} size={'middle'} onClick={handlePrint} className="mobile-btn">
                      Print
                    </Button>
                    <Button
                      icon={<DeleteIcon stroke="#505762" />}
                      size="middle"
                      className="mobile-btn"
                      onClick={deletePetModal.showModal}
                      disabled={!tableControls.getSelectedRow()}>
                      Delete
                    </Button>
                    <Button
                      icon={<EditIcon />}
                      size="middle"
                      disabled={!tableControls.getSelectedRow()}
                      onClick={editPetModal?.showModal}
                      className="mobile-btn">
                      Edit
                    </Button>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      size="middle"
                      className="mobile-btn"
                      disabled={!residentStore.selectedResidentAddress}
                      onClick={addPetModal?.showModal}>
                      Add
                    </Button>
                  </Space>
                }
              />
            </Col>
          </Row>
          <TableBase selectFirstRowByDefault />
        </div>
      </TableContextProvider>
      <AddEditPetModal
        title="Add"
        isEdit={false}
        initData={addPetModal?.initData}
        isOpen={addPetModal?.isOpenModal}
        onClose={addPetModal?.hideModal}
      />
      <AddEditPetModal
        title="Edit"
        isEdit={true}
        initData={editPetModal?.initData}
        isOpen={editPetModal?.isOpenModal}
        onClose={editPetModal?.hideModal}
      />
      <ConfirmDeleteModal
        isEdit={false}
        initData={deletePetModal?.initData}
        title="Delete"
        isOpen={deletePetModal?.isOpenModal}
        onClose={deletePetModal?.hideModal}
      />
    </div>
  );
});

const toUpdatePet = (pet: IPetRead): IUpdatePet => {
  const res: IUpdatePet = {
    id: pet.id,
    name: pet.name,
    photoUrl: pet.photoUrl,
    type: pet.type,
    breed: pet.breed,
    color: pet.color,
    dateOfBirth: pet.dateOfBirth ? StrToDate(pet.dateOfBirth) : undefined,
    tag: pet.tag,
    tagExpirationDate: pet.tagExpirationDate ? StrToDate(pet.tagExpirationDate) : undefined,
    chipNumber: pet.chipNumber,
  };
  return res;
};
