import { addressesColumns } from 'common/list-configs/addresses.config';
import { communitiesColumns } from 'common/list-configs/communities.config';
import { streetsColumns } from 'common/list-configs/streets.config';
import { keysManagementColumns } from '../../../../common/list-configs/keys-management.config';
import { addressesManagementColumns } from 'common/list-configs/addresses-management.config';

const { name: streetName, community } = streetsColumns();

export const StreetsAtStreetsTabInitialColumns = [streetName, community];

const { name, building, block, lot, unit, streetNumber } = addressesColumns();

export const GeneralAddressesTableInitialColumns = [
  { ...streetNumber, width: 150, minWidth: 150 },
  { ...name, width: 600, minWidth: 150 },
  { ...building, width: 100, minWidth: 100 },
  { ...block, width: 100, minWidth: 100 },
  { ...lot, width: 100, minWidth: 100 },
  { ...unit, width: 100, minWidth: 100 },
];

const { name: communityName, description } = communitiesColumns();

export const CommunitiesTabInitialColumn = [communityName, description];

export const CommunityStreetsTableInitialColumns = [
  { ...streetName, key: 'streetName', dataKey: 'streetName', width: 165, minWidth: 150 },
  { ...streetNumber, width: 143, minWidth: 130 },
  { ...building, width: 143, minWidth: 130 },
  { ...block, width: 143, minWidth: 130 },
  { ...lot, width: 143, minWidth: 130 },
  { ...unit, width: 143, minWidth: 130 },
];

export const CommunityStreetsDeleteModalTable = [
  { ...streetName, key: 'streetName', dataKey: 'streetName', width: 133, minWidth: 123, resizable: false },
  { ...streetNumber, width: 143, minWidth: 123, resizable: false },
  { ...building, width: 113, minWidth: 123, resizable: false },
  { ...block, width: 108, minWidth: 123, resizable: false },
  { ...lot, width: 108, minWidth: 123, resizable: false },
  { ...unit, width: 108, minWidth: 123, resizable: false },
];

const { name: keyName, key: keyNumber, code: alarmCode, status: keyStatus, address: address } = keysManagementColumns();

export const KeysManagementTableInitialColumns = [
  { ...keyNumber, width: 200, minWidth: 160 },
  { ...keyName, width: 200, minWidth: 160 },
  { ...keyStatus, width: 200, minWidth: 160 },
  { ...address, width: 550, minWidth: 520 },
  { ...alarmCode, width: 200, minWidth: 160 },
];

const { addressDetails, streetName: addressStreetName } = addressesManagementColumns();

export const AddressesManagementTableInitialColumns = [
  { ...streetNumber, width: 200 },
  addressStreetName,
  addressDetails,
  { ...communityName, key: 'street_community_name', dataKey: 'street.community.name', width: 200 },
  { ...name, width: 200 },
];
