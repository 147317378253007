import { IResponseList } from 'common/models/response-list.interface';
import { IAccountRead, IAccountCreate, IAccountUpdate, IChangePassword } from './account.service.types';
import { ServerClient } from 'common/utils/http/http-clients';

class AccountService {
  async getAdmins(
    pageLimit: any,
    filter: string | null | undefined,
    sort: string | null | undefined,
  ): Promise<IResponseList<IAccountRead>> {
    const res = await ServerClient.get<IResponseList<IAccountRead>>('/users/admins', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async getSecurityOfficers(
    pageLimit: any,
    filter: string | null | undefined,
    sort: string | null | undefined,
  ): Promise<IResponseList<IAccountRead>> {
    const res = await ServerClient.get<IResponseList<IAccountRead>>('/users/security-officers', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async deleteAdmin(id: number): Promise<any> {
    const res = await ServerClient.delete(`/users/admins/${id}`);
    return res?.data;
  }

  async deleteSecurityOfficer(id: number): Promise<any> {
    const res = await ServerClient.delete(`/users/security-officers/${id}`);
    return res?.data;
  }

  async addAdminAccount(model: IAccountCreate): Promise<IAccountRead> {
    const res = await ServerClient.post<IAccountRead>('/users/admins', model);
    return res?.data;
  }

  async addSecurityOfficerAccount(model: IAccountCreate): Promise<IAccountRead> {
    const res = await ServerClient.post<IAccountRead>('/users/security-officers', model);
    return res?.data;
  }

  async updateAdminAccount(id: number, model: IAccountUpdate): Promise<IAccountRead> {
    const res = await ServerClient.patch<IAccountRead>(`/users/admins/${id}`, model);
    return res?.data;
  }

  async updateSecurityOfficerAccount(id: number, model: IAccountUpdate): Promise<IAccountRead> {
    const res = await ServerClient.patch<IAccountRead>(`/users/security-officers/${id}`, model);
    return res?.data;
  }

  async getCurrentAccount(): Promise<IAccountRead> {
    const res = await ServerClient.get<IAccountRead>('/users/me');
    return res?.data;
  }

  async changePassword(model: IChangePassword): Promise<void> {
    const res = await ServerClient.put('/users/me/password', model);
    return res?.data;
  }
}

export const accountService = new AccountService();
export default accountService;
