import React from 'react';
import { makeAutoObservable, toJS } from 'mobx';
import { ICommunityStreet } from '../../../common/services/addresses-service/addresses.service.types';
import { IStreetDetails } from 'common/services/street-service/street.service.types';

class AddressesStore {
  communityStreets: ICommunityStreet[] | null;
  selectedAddressId: number | null;
  streetDetails: IStreetDetails | null;

  constructor() {
    makeAutoObservable(this);
    this.communityStreets = [];
    this.selectedAddressId = null;
    this.streetDetails = null;
  }

  setSelectedAddressId(selectedAddressId: number | null) {
    this.selectedAddressId = selectedAddressId;
  }

  setCommunityStreets(communityStreets: ICommunityStreet[] | null) {
    this.communityStreets = communityStreets;
  }

  setStreetDetails(street: IStreetDetails | null) {
    this.streetDetails = street;
  }

  public get selectedAddressPets() {
    const pets = this.selectedAddress?.residents.reduce((acc, curr) => acc.concat(curr.pets as any), []);

    return pets ? toJS(pets) : [];
  }

  public get selectedAddressVehicles() {
    const vehicles = this.selectedAddress?.residents.reduce((acc, curr) => acc.concat(curr.vehicles as any), []);

    return vehicles ? toJS(vehicles) : [];
  }

  public get selectedAddressAccessCards() {
    const accessCards = this.selectedAddress?.residents.reduce((acc, curr) => acc.concat(curr.accessCards as any), []);

    return accessCards ? toJS(accessCards) : [];
  }

  public get selectedAddressResidents() {
    const residents = this.selectedAddress?.residents.map((i) => {
      return {
        ...i,
        address: {
          id: this.selectedAddress?.id,
          blockNumber: this.selectedAddress?.blockNumber,
          buildingNumber: this.selectedAddress?.buildingNumber,
          name: this.selectedAddress?.name,
          lotNumber: this.selectedAddress?.lotNumber,
          unit: this.selectedAddress?.unit,
          instruction: this.selectedAddress?.instructions,
          streetNumber: this.selectedAddress?.streetNumber,
          street: {
            id: this.streetDetails?.id,
            name: this.streetDetails?.name,
            community: this.streetDetails?.community,
          },
        },
      };
    });

    return residents ? toJS(residents) : [];
  }

  public get selectedAddress() {
    return this.streetDetails?.addresses.find((i) => i.id === this.selectedAddressId);
  }
}

const addressesStore = new AddressesStore();
export const AddressesStoreContext = React.createContext<AddressesStore>(addressesStore);
export default addressesStore;
