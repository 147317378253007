import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Modal, Space, Switch } from 'antd';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { notification } from '../../../../../../../../common/utils/notification';
import { IModal } from '../../../../../../../../common/models/model.interface';
import InfoField from '../../../../../../../../common/components/info-block/info-field/info-field';
import styles from '../../../../../../../../common/components/info-block/contact-block/contact-block.module.scss';
import guestService from '../../../../../../../../common/services/guest-service/guest.service';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import residentStore from '../../../../../../stores/resident.store';
import { IAddressOccupant } from '../../../../../../../../common/services/guest-service/guest.service.types';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';

const EditOccupantModal: FC<IModal<IAddressOccupant, any>> = ({ title, initData, isOpen, onClose, extraData }) => {
  const [form] = Form.useForm();
  const [isHostSharedGuestList, setIsHostSharedGuestList] = useState(false);
  const [isHostSharedVehicleList, setIsHostSharedVehicleList] = useState(false);

  useEffect(() => {
    if (initData) {
      setIsHostSharedGuestList(
        !!initData.sharedResidentAddressData?.some(
          (i) => i.sharedResidentAddressId === residentStore.selectedResidentAddress?.id && i.isGuestShared,
        ),
      );
      setIsHostSharedVehicleList(
        !!initData.sharedResidentAddressData?.some(
          (i) => i.sharedResidentAddressId === residentStore.selectedResidentAddress?.id && i.isVehicleShared,
        ),
      );
    }
  }, [isOpen, form, initData]);

  const hideModal = (...rest: any) => {
    notification.destroy();
    form.resetFields();
    if (onClose) {
      onClose(...rest);
    }
  };

  const onFinish = async () => {
    const gestHostId: number = extraData?.residentId;
    const addressId = residentStore.selectedResidentAddress?.address.id;

    if (!gestHostId || !addressId || !initData) {
      console.error(`SelectedAddressId or SelectedResidentId is empty`);
      return;
    }

    const res = await guestService.updateResidentAddressDataSharing(gestHostId, addressId, {
      residentId: initData?.residentId,
      shareGuestList: isHostSharedGuestList,
      shareVehicleList: isHostSharedVehicleList,
    });

    hideModal(true, res);
  };

  const isRenterRelated = (occupant: IAddressOccupant | undefined): boolean => {
    return occupant?.ownershipType === ResidentTypesEnum.Renter || occupant?.host?.ownershipType === ResidentTypesEnum.Renter;
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} layout="vertical">
        <Space className="full-width" size={13}>
          <InfoField value={initData?.host?.name} label="Host name" bodyClassName={styles.infoBlockField} />
          <InfoField
            value={initData?.host?.ownershipType}
            label="Host type"
            valueClassName={
              initData?.host?.ownershipType
                ? `eiq-label ${initData?.host?.ownershipType === ResidentTypesEnum.Renter ? 'renter' : 'secondary'}`
                : undefined
            }
            bodyClassName={styles.infoBlockField}
          />
        </Space>
        <Space className="full-width" size={13} style={{ marginTop: '16px', marginBottom: '22px' }}>
          <InfoField value={initData?.name} label="Occupant name" bodyClassName={styles.infoBlockField} />
          <InfoField
            value={initData?.ownershipType}
            label="Occupant type"
            valueClassName={`eiq-label ${isRenterRelated(initData) ? 'renter' : 'secondary'}`}
            bodyClassName={styles.infoBlockField}
          />
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item label="Share guest list" valuePropName="checked">
            <Switch
              checked={isHostSharedGuestList}
              onChange={(checked) => {
                setIsHostSharedGuestList(checked);
              }}
            />
          </Form.Item>
          <Form.Item label="Share vehicle list" valuePropName="checked">
            <Switch
              checked={isHostSharedVehicleList}
              onChange={(checked) => {
                setIsHostSharedVehicleList(checked);
              }}
            />
          </Form.Item>
        </Space>
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className="eiq-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(EditOccupantModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
