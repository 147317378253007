import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTableContext } from '../../table-context';
import './search-filter.scss';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { HotkeyInput } from '../../../hotkey-input/hotkey-input';
import { HotkeyInputProps } from '../../../hotkey-input/hotkey-input.types';
import { FilterValue } from '../../table.types';
import { debounce } from 'lodash';
import { useMedia } from '../../../../hooks/use-media/use-media.hook';
import { InputRef } from 'antd';

export const SearchFilter = ({
  rulesForColumn,
  enableProgressiveSearch,
  rightSide,
  classWrapper,
  adaptiveSearch,
  shouldFocus,
  ...rest
}: SearchFilterProps) => {
  const tableContext = useTableContext();
  const [searchIsActive, setSearchIsActive] = useState(false);
  const { isMobileOrTable } = useMedia();
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputRef.current && shouldFocus) {
      inputRef.current.focus();
    }
  }, [shouldFocus]);

  const onSearch = (value: string) => {
    tableContext.setSearchTerm(value);
    let searchRules: Record<string, null | FilterValue> = {};

    Object.keys(rulesForColumn).forEach((columnKey) => {
      searchRules[columnKey] =
        value?.length > 0 ? { operator: rulesForColumn[columnKey], value: formatValue(value, rulesForColumn[columnKey]) } : null;
    });

    tableContext.updateFilters(searchRules, 'OR');
  };

  const handleOnClickSearch = (value: string) => {
    if (isMobileOrTable && inputRef.current) {
      inputRef.current.focus();
      return;
    }

    onSearch(value);
  };

  const formatValue = (value: string, operator: FilterTypesEnum) => {
    if (operator === FilterTypesEnum.StartWith) {
      return `${value}*`;
    }

    return value;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProgressiveSearch = useCallback(
    debounce((value: string) => {
      if (value.length >= 3 || value.length === 0) {
        onSearch(value);
      }
    }, 350),
    [rulesForColumn, tableContext],
  );

  const handleProgressiveSearch = (event: any) => {
    if (!enableProgressiveSearch) {
      return;
    }

    const value = event?.target?.value?.trim() ?? '';
    debouncedProgressiveSearch(value);
  };

  const handleFocus = () => {
    setSearchIsActive(true);
  };

  const handleBlur = () => {
    setSearchIsActive(false);
  };

  const hideRideSide = isMobileOrTable && searchIsActive;

  return (
    <div className={`search-filter-wrapper ${classWrapper ?? ''}`}>
      <HotkeyInput
        inputRef={inputRef}
        className={`search-filter ${adaptiveSearch ? 'adaptive-search' : ''}`}
        {...rest}
        onSearch={onSearch}
        onSearchClick={handleOnClickSearch}
        onInput={handleProgressiveSearch}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {rightSide && !hideRideSide && <div>{rightSide}</div>}
    </div>
  );
};

export interface SearchFilterProps extends HotkeyInputProps {
  rulesForColumn: Record<string, FilterTypesEnum>;
  enableProgressiveSearch?: boolean;
  rightSide?: ReactNode;
  classWrapper?: string;
  adaptiveSearch?: boolean;
  shouldFocus?: boolean;
}
