import { Space } from 'antd';
import InfoField from 'common/components/info-block/info-field/info-field';
import { IIncidentInvolvedVehicleViewProps } from './incident-involved-vehicle-view.types';
import React from 'react';
import IncidentViewModeActions from '../../incident-view-mode-actions/incident-view-mode-actions';

export const IncidentInvolvedVehicleView = ({ vehicle, onDelete, onEdit }: IIncidentInvolvedVehicleViewProps) => {
  return (
    <div style={{ border: '1px solid #d9d9d9', borderRadius: '8px', padding: '16px' }}>
      <Space className="full-width">
        <InfoField value={`${vehicle.licenseNumber} (${vehicle.licenseState})`} label="License Plate" />
      </Space>
      <IncidentViewModeActions onEdit={onEdit} onDelete={onDelete} />
    </div>
  );
};
