import { vehiclesColumns } from 'common/list-configs/vehicles.config';

const {
  owner,
  facilityCode,
  deviceId,
  year,
  make,
  model,
  licenseNumber,
  licenseState,
  color,
  rantingDates,
  validityPeriod: validationDates,
  status,
  additionalInformation,
  parkSpaceNumber,
  registrationAddress,
} = vehiclesColumns();
export const vehicleInitialColumns = [
  owner,
  facilityCode,
  deviceId,
  year,
  make,
  model,
  licenseNumber,
  licenseState,
  { ...color, hidden: true },
  rantingDates,
  validationDates,
  status,
  additionalInformation,
  parkSpaceNumber,
  registrationAddress,
];
