import React, { useEffect } from 'react';
import { Button, Form, Input, Modal, Space } from 'antd';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { IAddressesByStreetForm } from 'features/eiq-manage/pages/eiq-manage-addresses/addresses.type';
import styles from './general-info-add-edit-modal.module.scss';
import addressesService from 'common/services/addresses-service/addresses.service';
import { IAddressCreateUpdate } from 'common/services/addresses-service/addresses.service.types';
import TextArea from 'antd/es/input/TextArea';
import { notification } from '../../../../../../../../common/utils/notification';
import { addEditGeneralInfoValidation } from './genetal-info-add-edit-modal.validation';

const initialValues = {
  name: '',
  unit: '',
  block: '',
  lot: '',
  building: '',
  instructions: '',
  streetNumber: '',
};

const GeneralInfoAddEditModal = ({ isOpen, onClose, title, isEdit, initState }: any) => {
  const [form] = Form.useForm();

  const onFinish = async (values: IAddressesByStreetForm) => {
    const body: IAddressCreateUpdate = {
      blockNumber: values.block,
      lotNumber: values.lot,
      unit: values.unit,
      name: values.name,
      buildingNumber: values.building,
      instructions: values.instructions,
      streetNumber: values.streetNumber,
    };
    if (isEdit) {
      const res = await addressesService.updateAddresses(initState.streetId, initState.id, body);
      hideModal(true, res);
    } else {
      const res = await addressesService.addAddresses(initState.streetId, body);
      hideModal(true, res);
    }
  };

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initState);
    }
  }, [isOpen, form, initState]);

  const hideModal = (...rest: any) => {
    form.resetFields();
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper
        form={form}
        onFinish={onFinish}
        name={`generalInfoForm+${title}`}
        layout="vertical"
        autoComplete="on"
        initialValues={initState ?? initialValues}>
        <Space className="full-width" size={13}>
          <Form.Item name="streetName" label="Street name">
            <span className={styles.titleValue}>{initState?.streetName}</span>
          </Form.Item>
          <Form.Item name="communityName" label="Community">
            <span className={styles.titleValue}>{initState?.communityName}</span>
          </Form.Item>
        </Space>
        <Space className={`full-width ${styles.streetNumberWrapper}`} size={13}>
          <Form.Item name="streetNumber" label="Street number" rules={addEditGeneralInfoValidation.streetNumber}>
            <Input name="streetNumber" placeholder="Enter street number" />
          </Form.Item>
          <Form.Item name="name" label="Name">
            <Input name="name" placeholder="Enter address name" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="unit" label="Unit">
            <Input name="unit" placeholder="Enter unit" />
          </Form.Item>
          <Form.Item name="block" label="Block">
            <Input name="block" placeholder="Enter block" />
          </Form.Item>
          <Form.Item name="lot" label="Lot">
            <Input name="lot" placeholder="Enter lot" />
          </Form.Item>
          <Form.Item name="building" label="Building">
            <Input name="building" placeholder="Enter building" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="instructions" label="Instructions">
            <TextArea className={styles.modaltextarea} placeholder="For instructions" name="instructions" />
          </Form.Item>
        </Space>
        <Space className="footer">
          <Button key="cancel" type="link" onClick={() => hideModal()} style={{ width: '159px' }}>
            Cancel
          </Button>

          <Button key="generalInfoForm" htmlType="submit" type="primary" style={{ width: '159px' }} icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(GeneralInfoAddEditModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
