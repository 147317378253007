import { ServerClient } from 'common/utils/http/http-clients';
import {
  IAddressOccupant,
  IAdmitGuest,
  IAdmitResident,
  IAdmitVisitor,
  ICreateGuest,
  ITurnbackGuest,
  ITurnbackVisitor,
  IUpdateGuest,
  IUpdateResidentAddressDataSharing,
} from './guest.service.types';
import { IImportResult } from 'common/models/import-result.interface';

class GuestService {
  async createGuest(residentId: number, addressId: number, data: ICreateGuest): Promise<ICreateGuest> {
    const res = await ServerClient.post<ICreateGuest>(`/residents/${residentId}/addresses/${addressId}/guests`, data);
    return res?.data;
  }

  async updateGuest(residentId: number, addressId: number, data: IUpdateGuest): Promise<IUpdateGuest> {
    const res = await ServerClient.patch<IUpdateGuest>(`/residents/${residentId}/addresses/${addressId}/guests/${data.id}`, data);
    return res?.data;
  }

  async deleteGuest(residentId: number, addressId: number, guestId: number): Promise<any> {
    const res = await ServerClient.delete(`/residents/${residentId}/addresses/${addressId}/guests/${guestId}`);
    return res?.data;
  }

  async updateResidentAddressDataSharing(
    residentId: number,
    addressId: number,
    data: IUpdateResidentAddressDataSharing,
  ): Promise<IAddressOccupant> {
    const res = await ServerClient.patch<IAddressOccupant>(`/residents/${residentId}/addresses/${addressId}/data-sharing`, data);
    return res?.data;
  }

  async admitGuest(residentId: number, addressId: number, guestId: number, data: IAdmitGuest): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/admittance/guests/${guestId}`, data);
    return res?.data;
  }

  async admitVisitor(residentId: number, addressId: number, data: IAdmitVisitor): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/admittance/visitors`, data);
    return res?.data;
  }

  async admitResident(residentId: number, addressId: number, data: IAdmitResident): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/admittance`, data);
    return res?.data;
  }

  async turnbackVisitor(residentId: number, addressId: number, data: ITurnbackVisitor): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/turn-back/visitors`, data);
    return res?.data;
  }

  async turnbackGuest(residentId: number, addressId: number, guestId: number, data: ITurnbackGuest): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/turn-back/guests/${guestId}`, data);
    return res?.data;
  }

  async admitVisitorToVacantAddress(addressId: number, data: IAdmitGuest): Promise<void> {
    const res = await ServerClient.post<void>(`/addresses/${addressId}/admittance/visitors`, data);
    return res?.data;
  }

  async importGuests(residentId: number, addressId: number, file: any): Promise<IImportResult> {
    const data = new FormData();
    data.append('file', file);
    const res = await ServerClient.post<IImportResult>(`/residents/${residentId}/addresses/${addressId}/guests/import/csv`, data);
    return res?.data;
  }
}

export const guestService = new GuestService();
export default guestService;
