import React, { FC } from 'react';
import styles from './confirm-delete-modal.module.scss';
import { IConfirmDeleteModalProps } from './confirm-delete-modal.types';
import { IModal } from 'common/models/model.interface';
import { Button, Modal, Space, notification } from 'antd';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

export const ConfirmDeleteItemName = ({ name }: { name: string }) => {
  return <span className={styles.name}>{name}</span>;
};

const ConfirmDeleteModal: FC<IModal<IConfirmDeleteModalProps, any>> = ({ isOpen, onClose, title, initData, className = null }) => {
  const hideModal = (isSuccess: boolean) => {
    notification.destroy();
    if (onClose) {
      onClose(isSuccess);
    }
  };

  const handleOnDeleteButtonClick = () => {
    if (!initData?.onDelete) {
      return;
    }
    initData.onDelete();
  };

  return (
    <Modal centered title={title} width={350} open={isOpen} wrapClassName={`custom-modal ${className ?? ''}`} closable={false}>
      <Space className={`${styles.modalBody} confirm-modal-body`}>{initData?.message}</Space>
      <Space className="footer">
        <Button type="link" onClick={() => hideModal(false)} className={styles.button}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleOnDeleteButtonClick} className={styles.button} icon={<DeleteIcon stroke="#F6F8FA" />}>
          Delete
        </Button>
      </Space>
    </Modal>
  );
};

export default ConfirmDeleteModal;
