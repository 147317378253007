import React, { FC, useEffect } from 'react';
import { IModal } from 'common/models/model.interface';
import { Button, Form, Input, Modal, Select, Space, notification } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { addEditAccountValidation } from './add-edit-account-modal.validation';
import { IAddEditAccountModal } from '../user-accounts.types';
import { IAccountCreate, IAccountUpdate } from 'features/eiq-manage/service/account-service/account.service.types';
import accountService from 'features/eiq-manage/service/account-service/account.service';
import { AccountTypes } from 'features/eiq-manage/enums/account-type.enum';
import InputPhone from 'common/components/form-elements/input-phone/input-phone';
import configurationStore from 'common/stores/configuration/configuration.store';
import { toGateOptions } from 'features/eiq-secure/constans/common.eiq-secure';

const AccountAddEditModal: FC<IModal<IAddEditAccountModal, any>> = ({ isOpen, onClose, title, initData, isEdit }) => {
  const [form] = Form.useForm();

  const isAdmin = initData?.accountType === AccountTypes.Admin;

  const onFinish = async (values: any) => {
    const model: IAccountCreate = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      accountName: values.accountName,
      phone: values.phone,
      password: values.password ? values.password : null,
      defaultGate: values.defaultGate,
    };

    if (isEdit) {
      const accountToUpdate = model as IAccountUpdate;
      const updateAccount = isAdmin
        ? accountService.updateAdminAccount(initData!.id!, accountToUpdate)
        : accountService.updateSecurityOfficerAccount(initData!.id!, accountToUpdate);

      const res = await updateAccount;
      hideModal(true, res);
    } else {
      const createAccount = isAdmin ? accountService.addAdminAccount(model) : accountService.addSecurityOfficerAccount(model);
      const res = await createAccount;
      hideModal(true, res);
    }
  };

  const hideModal = (...rest: any) => {
    form.resetFields();
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData);
    }
  }, [isOpen, form, initData]);

  const getGateOptions = () => toGateOptions(configurationStore?.configuration?.gates || []);

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} initialValues={initData} layout="vertical">
        <Space className="full-width" size={13}>
          <Form.Item name="firstName" label="First name" rules={addEditAccountValidation.firstName}>
            <Input name="firstName" placeholder="Enter first name" />
          </Form.Item>
          <Form.Item name="lastName" label="Last name" rules={addEditAccountValidation.lastName}>
            <Input name="lastName" placeholder="Enter last name" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <div className="account-name">
            <Form.Item name="accountName" label="Account name" rules={addEditAccountValidation.accountName}>
              <Input name="accountName" placeholder="Enter account name" />
            </Form.Item>
            {configurationStore.communitySettings?.identifier && !isAdmin && (
              <div className="community-identifier">@{configurationStore.communitySettings.identifier}</div>
            )}
          </div>
          <Form.Item name="phone" label="Phone" validateTrigger="onBlur" rules={addEditAccountValidation.phone}>
            <InputPhone />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="email" label="Email" validateTrigger="onBlur" rules={addEditAccountValidation.email}>
            <Input name="email" placeholder="Enter email" />
          </Form.Item>
          {!isAdmin && (
            <Form.Item name="password" label="Password" rules={addEditAccountValidation.password}>
              <Input.Password name="password" placeholder="Enter password" />
            </Form.Item>
          )}
        </Space>
        {!isAdmin && (
          <Space className="full-width" size={13}>
            <Form.Item name="defaultGate" label="Default gate" rules={addEditAccountValidation.defaultGate}>
              <Select options={getGateOptions()} />
            </Form.Item>
          </Space>
        )}
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className="eiq-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(AccountAddEditModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
