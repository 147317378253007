import { Select, SelectProps } from 'antd';
import addressesService from 'common/services/addresses-service/addresses.service';
import React, { FC, useState, useEffect } from 'react';

const SelectState: FC<SelectProps> = (rest) => {
  const [stateOptions, setStateOptions] = useState<SelectProps['options']>([]);

  useEffect(() => {
    addressesService.getStates().then((result) => {
      const options = result?.map((item) => ({ value: item.name, label: item.name }));
      setStateOptions([{ value: null, label: '-' }, ...options]);
    });
  }, []);

  return <Select options={stateOptions} {...rest} />;
};

export default SelectState;
