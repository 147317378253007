import { Button, Modal, Space, Input, Form, Select } from 'antd';
import React, { FC } from 'react';
import { IModal } from '../../../../common/models/model.interface';
import { ReactComponent as KeyIcon } from 'assets/icons/collect-key.svg';
import { FormWrapper } from '../../../../common/components/form-wrapper/form-wrapper';
import eiqSecureStore from '../../stores/eiq-secure.store';
import { notification } from '../../../../common/utils/notification';
import addressesKeyService from '../../../../common/services/addresses-key-service/addresses-key.service';
import { ValidationMessages } from 'common/validation/validation-messages';
import userAuthStore from 'common/stores/user-auth/user-auth.store';

const KeyActionModal: FC<IModal<any, any>> = ({ isOpen, onClose, title, initData }) => {
  const [form] = Form.useForm();

  const hideModal = (...rest: any) => {
    notification.destroy();
    if (onClose) {
      form.resetFields();
      onClose(...rest);
    }
  };

  const onFinish = async ({ password, key }: { password: string; key: string }) => {
    if (title === 'Collect') {
      try {
        await addressesKeyService.collectKey(initData?.keyNumber || key!, { password, gate: userAuthStore.defaultGate });
        hideModal(true);
        notification.success({ message: 'Key has been collected' });
      } catch (error: any) {
        if (error.response.status === 403) {
          notification.error({ message: 'Password is invalid' });
          return;
        }
        throw error;
      }
    } else hideModal(true, password);
  };

  const handleOnKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      form.submit();
    }
  };

  return (
    <div onKeyDown={handleOnKeyDown}>
      <Modal centered title={`${title} key`} width={362} open={isOpen} wrapClassName="custom-modal custom-modal--so" closable={false}>
        <FormWrapper form={form} onFinish={onFinish} layout="vertical">
          <Space className="full-width mb-12" size={8} direction="vertical">
            <Form.Item
              name="password"
              label="Re-enter your password"
              rules={[{ required: true, message: ValidationMessages.Password.RequiredMessage }]}>
              <Input.Password autoComplete="new-password" name="password" placeholder="Enter password" />
            </Form.Item>
          </Space>
          {title === 'Collect' && !initData?.keyNumber && (
            <Space className="full-width mb-12" size={8} direction="vertical">
              <Form.Item name="key" label="Key" rules={[{ required: true, message: ValidationMessages.Password.RequiredMessage }]}>
                <Select
                  placeholder="Select a key"
                  options={eiqSecureStore.selectedGuest?.issuedKeys.map((i) => {
                    return {
                      label: i,
                      value: i,
                    };
                  })}
                />
              </Form.Item>
            </Space>
          )}
          <Space className="footer">
            <Button className="eiq-button" type="link" onClick={() => hideModal(false)}>
              Cancel
            </Button>
            <Button type="primary" className="primary-btn eiq-button" icon={<KeyIcon />} onClick={() => form.submit()}>
              {title}
            </Button>
          </Space>
        </FormWrapper>
      </Modal>
    </div>
  );
};

export default React.memo(KeyActionModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
