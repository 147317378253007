import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, Space, Row, Col, Select, Form } from 'antd';
import { IModal } from 'common/models/model.interface';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import TableBase from 'common/components/table/table-base';
import { calculateTableHeight, baseModalTableHeightConfig } from 'common/components/table/table-helpers';
import { IAddressOccupant } from 'common/services/guest-service/guest.service.types';
import addressesService from 'common/services/addresses-service/addresses.service';
import { isOccupant } from 'common/helpers/resident-address.helper';
import styles from './resident-delete-modal.module.scss';
import { residentDeleteModalOccupantsInitialColumns } from './resident-delete-modal-tables.config';
import { notification } from 'common/utils/notification';
import residentsService from 'common/services/residents-service/residents.service';
import { IDeleteResidentModalProps } from './resident-delete-modal.types';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';

const ResidentDeleteModal: FC<IModal<IDeleteResidentModalProps, any>> = ({ isOpen, onClose, title, initData }) => {
  const [occupants, setOccupants] = useState<IAddressOccupant[]>([]);
  const [residents, setResidents] = useState<IAddressOccupant[]>([]);
  const [isTableError, setIsTableError] = useState(false);
  const [newGuestOwnerId, setNewGuestOwnerId] = useState();

  const tableControls = useTableControls({
    clientSide: true,
    data: occupants,
    initialColumns: residentDeleteModalOccupantsInitialColumns,
  });

  useEffect(() => {
    if (!initData) {
      return;
    }
    const { addressId, residentId } = initData;

    addressesService
      .getOccupantsByAddressId(addressId!)
      .then((res) => {
        setResidents(res.filter((i) => i.residentId !== residentId));

        if (initData?.ownershipType === ResidentTypesEnum.Owner) {
          setOccupants(
            res
              ?.filter((item) => item?.host?.residentId === residentId && isOccupant(item.ownershipType))
              .map((item, index) => ({
                ...item,
                id: index,
              })),
          );
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [initData]);

  const hideModal = (...rest: any) => {
    notification.destroy();

    if (onClose) {
      onClose(...rest);
    }
  };

  const handleOnDeleteClick = () => {
    if (!initData) {
      return;
    }
    const { addressId, residentId } = initData;

    residentsService.deleteResidentAddress(residentId, addressId, undefined, newGuestOwnerId).then(() => {
      tableControls.setSelectedRow(null);
      hideModal(true, initData.ownershipType === ResidentTypesEnum.Owner);
    });
  };

  const handleSelectNewOwnerClick = () => {
    if (!initData) {
      return;
    }
    const { addressId, residentId } = initData;
    const selectedRow: IAddressOccupant = tableControls.getSelectedRow();

    if (!selectedRow) {
      notification.error({ message: 'Please select a new owner' });
      setIsTableError(true);
    } else {
      residentsService.deleteResidentAddress(residentId, addressId, selectedRow.residentId, newGuestOwnerId).then((result) => {
        hideModal(true);
      });
    }
  };

  return (
    <>
      <Modal
        centered
        title={title}
        width={occupants?.length ? 898 : 350}
        open={isOpen}
        wrapClassName="custom-modal resident-move-modal"
        closable={false}>
        <Space className={styles.residentDeleteModalBody}>
          <span>
            <span>Are you sure you want to delete </span>
            <span className={styles.residentName}>{initData?.name}</span>
            <span> from a residents list? All related users will be deleted as well.</span>
          </span>
        </Space>
        <Space className={`full-width ${styles.newOwner}`}>
          <span>Select a new owner for guests and vehicles</span>
          <Select
            value={newGuestOwnerId}
            style={{ width: 120 }}
            onChange={setNewGuestOwnerId}
            options={residents.map((i) => ({ label: i.name, value: i.residentId }))}
            placeholder="Select a new owner for guests and vehicles"
          />
        </Space>
        {occupants?.length > 0 && (
          <TableContextProvider controls={tableControls}>
            <Row>
              <Col span={24}>
                <h3 className="table-title">Occupants</h3>
              </Col>
            </Row>
            <Row
              className="table-wrapper"
              style={{ height: calculateTableHeight({ ...baseModalTableHeightConfig, tableDataLength: occupants!.length }) }}>
              <TableBase disableSelection wrapperClass={`cc-table ${isTableError ? styles.tableError : ''}`} />
            </Row>
          </TableContextProvider>
        )}
        <Space className="footer" style={occupants?.length ? { justifyContent: 'space-around' } : {}}>
          <Button key="cancel" type="link" onClick={() => hideModal()} className="eiq-button">
            Cancel
          </Button>
          {occupants?.length > 0 ? (
            <>
              <Button onClick={handleOnDeleteClick} type="primary" className="eiq-button">
                Delete all
              </Button>
              <Button onClick={handleSelectNewOwnerClick} type="primary" className="eiq-button">
                Select new owner
              </Button>
            </>
          ) : (
            <Button onClick={handleOnDeleteClick} htmlType="submit" type="primary" className="eiq-button">
              Delete
            </Button>
          )}
        </Space>
      </Modal>
    </>
  );
};

export default React.memo(ResidentDeleteModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
