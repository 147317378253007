import { Space, AutoComplete, Input, Button, Row, Col } from 'antd';
import { IVehicleRead } from 'common/services/residents-service/residents.service.types';
import React, { useCallback, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { IIncidentInvolvedVehicleSearchProps } from './incident-involved-vehicle-search.types';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import { IListResponse, IVehicleAdminRead } from 'common/services/vehicle-service/vehicle.service.types';
import { debounce } from 'lodash';

export const IncidentInvolvedVehicleSearch = ({ onSelected, onEnterManuallyClick }: IIncidentInvolvedVehicleSearchProps) => {
  const [vehicles, setVehicles] = useState<IVehicleRead[]>([]);
  const [options, setOptions] = useState<any[]>([]);

  const buildSearchQuery = (search?: string) => {
    if (!search) {
      return null;
    }

    return `licenseNumber:${search}*`;
  };

  const formatText = (item: IVehicleAdminRead) => {
    let text = `${item.licenseNumber} (${item.licenseState})`;

    if (item.owner) {
      text += ` | ${item.owner}`;
    }

    if (item.registrationAddress) {
      text += ` | ${item.registrationAddress}`;
    }

    return text;
  };

  const handleSearch = (newValue?: string) => {
    const formatResult = (items: IVehicleAdminRead[]) => {
      const result = items.map((item) => ({
        text: formatText(item),
        value: item.id.toString(),
      }));

      setOptions(result);
      setVehicles(items);
    };

    if (!newValue || newValue.length < 3) {
      formatResult([]);
      return;
    }

    const pageLimit = { page: 1, limit: 100 };

    vehicleService
      .getVehicles(pageLimit, buildSearchQuery(newValue), null)
      .then((response: IListResponse<IVehicleAdminRead>) => formatResult(response.items.map((item) => item)));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 350), []);

  const handleSelect = (id: string) => {
    const selectedItem = vehicles.find((item) => item.id.toString() === id);

    if (selectedItem) {
      onSelected(selectedItem);
    }
  };

  return (
    <>
      <Space className="full-width" size={13}>
        <Row gutter={{ xs: 24, md: 8 }}>
          <Col xs={24}>
            <AutoComplete
              className="full-width"
              defaultActiveFirstOption={false}
              onSearch={debouncedHandleSearch}
              onChange={handleSelect}
              dataSource={options}>
              <Input placeholder="Search license place" prefix={<SearchIcon />} />
            </AutoComplete>
          </Col>
        </Row>
      </Space>
      <Space className="full-width" size={13}>
        <p className="manual-info-add-label">
          If the resident vehicle or the guest vehicle involved in the incident is not found in EntranceIQ, please fill in the vehicle
          information manually.
        </p>
      </Space>

      <Space>
        <Button type="primary" className="primary-btn" icon={<EditIcon />} onClick={onEnterManuallyClick}>
          Enter Manually
        </Button>
      </Space>
    </>
  );
};
