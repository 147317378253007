import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Menu, MenuProps } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import configurationService from 'common/services/configuration-service/configuration.service';
import configurationStore from 'common/stores/configuration/configuration.store';
import featureStore from 'common/stores/features/feature.store';
const { Sider, Content } = Layout;

export const Configurations: FC = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  const accountsSiderItems: MenuProps['items'] = [
    // {
    //   label: 'General',
    //   key: '/eiq-manage/system-configuration/general',
    // },
    {
      label: 'Default and local',
      key: '/eiq-manage/system-configuration/default',
    },
    {
      label: 'Service hours',
      key: '/eiq-manage/system-configuration/service-hours',
    },
    {
      label: 'Export',
      key: '/eiq-manage/system-configuration/export',
    },
  ];

  if (featureStore.isTrafficLogixEnabled) {
    accountsSiderItems.push({
      label: 'Citation Settings',
      key: '/eiq-manage/system-configuration/citations',
    });
  }

  useEffect(() => {
    configurationService
      .getSystemConfiguration()
      .then((resutl) => {
        configurationStore.setConfiguration(resutl);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Layout hasSider className="accounts-layout">
      <Sider className="sider-wrapper">
        <Menu
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={[location.pathname]}
          items={accountsSiderItems}
          className="sider-tabs"
          onClick={(tab) => navigate(tab.key)}
        />
      </Sider>
      <Content className="content-style">
        <Outlet />
      </Content>
    </Layout>
  );
});
