import { addressesColumns } from 'common/list-configs/addresses.config';
import { IResidentAddresses } from 'common/services/residents-service/residents.service.types';

const { name, streetName, streetNumber, building, block, lot, unit, ownershipType, rantingDates, community } = addressesColumns();

export const addressesInitialColumns = [
  { ...name, key: 'address.name', dataKey: 'address.name', getValue: (rowData: IResidentAddresses) => rowData.address.name },
  streetName,
  {
    ...streetNumber,
    key: 'address.streetNumber',
    dataKey: 'address.streetNumber',
    getValue: (rowData: IResidentAddresses) => rowData.address.streetNumber,
  },
  {
    ...building,
    key: 'address.buildingNumber',
    dataKey: 'address.buildingNumber',
    getValue: (rowData: IResidentAddresses) => rowData.address.buildingNumber,
  },
  {
    ...block,
    key: 'address.blockNumber',
    dataKey: 'address.blockNumber',
    getValue: (rowData: IResidentAddresses) => rowData.address.blockNumber,
  },
  { ...lot, key: 'address.lotNumber', dataKey: 'address.lotNumber', getValue: (rowData: IResidentAddresses) => rowData.address.lotNumber },
  { ...unit, key: 'address.unit', dataKey: 'address.unit', getValue: (rowData: IResidentAddresses) => rowData.address.unit },
  community,
  { ...ownershipType, width: 150 },
  rantingDates,
];
