export const generateOption = (enumType: any, labelMap: any) => {
  return (Object.values(enumType) as any[])
    .filter((item) => typeof item === 'number' && item !== 0)
    .map((value) => ({
      label: labelMap[value],
      value,
    }));
};

export const generateOptionFromZero = (enumType: any, labelMap: any) => {
  return (Object.values(enumType) as any[])
    .filter((item) => typeof item === 'number')
    .map((value) => ({
      label: labelMap[value],
      value,
    }));
};
