import { DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const DATE_FORMAT_MMMM_D_YYYY: DatePickerProps['format'] = (value) => `${value.format('MMMM D, YYYY')}`;

export const DATE_MMM_DD_YYYY = 'MMMM DD, YYYY';
export const DATE_SHORT_MMM_DD_YYYY = 'MMM DD, YYYY';
export const DATE_DD_MMM_YYYY_HH_MM = 'DD MMM, YYYY HH:mm';
export const DATE_YYYY_MM_DD = 'YYYY-MM-DD';
export const TIME_HH_mm_ss = 'HH:mm:ss';
export const TIME_h_mma = 'h:mma';
export const DATE_TIME_YYYY_MM_DD_HH_mm_ss = 'YYYY-MM-DDTHH:mm:ss';
