import { ResidentTypesEnum } from '../../../common/enums/resident-types.enum';
import { camelCaseToRegularForm } from '../../../common/helpers/camel-case-to-regular-form.helper';

// map enum keys to their values
export const residentTypeOptions = Object.entries(ResidentTypesEnum).map(([key, value]) => ({
  label: camelCaseToRegularForm(key),
  value: value,
}));

//
// export const residentTypeOptions = Object.entries(ResidentTypesEnum)
//   .filter(([key, value]) => typeof value === 'number' && value !== 0)
//   .map(([key, value]) => ({ label: key, value: value }));
