import { Form, Input, notification, Select, SelectProps } from 'antd';
import { formatZoning } from 'common/helpers/address.helper';
import streetService from 'common/services/street-service/street.service';
import { IStreet, IAddress } from 'common/services/street-service/street.service.types';
import { addressSelectValidation } from 'features/eiq-manage/pages/eiq-manage-residents/components/address-select/address-select.validation';
import React, { useState, useEffect, useMemo } from 'react';
import { IUseAddressSelectProps } from './use-address-select.types';
import addressesService from 'common/services/addresses-service/addresses.service';

// TODO We need to remove isOpen logic from here;
export const useAddressSelect = ({
  value,
  onAddressChange: setAddress,
  isOpen,
  form,
  initData,
  disabled,
  requireCommunity,
  communityProps,
}: IUseAddressSelectProps) => {
  const [selectedStreet, setSelectedStreet] = useState<IStreet | undefined>();
  const [streets, setStreets] = useState<IStreet[]>([]);
  const [streetSearch, setStreetSearch] = useState<string>('');

  const [selectedStreetNumber, setSelectedStreetNumber] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [addressOptions, setAddressOptions] = useState<SelectProps['options']>([]);
  const [communityOptions, setCommunityOptions] = useState<SelectProps['options']>([]);
  const [selectedCommunity, setSelectedCommnunity] = useState<any>();
  const [showAddressDetails, setShowAddressDetails] = useState(true);

  useEffect(() => {
    if (initData) {
      setForm(initData);
      setSelectedCommnunity({ value: initData.street.community?.id, label: initData.street.community?.name });
      setSelectedStreet(initData.street);
      setSelectedStreetNumber(initData.streetNumber);
      setAddress(initData);
    }
    if (!isOpen) {
      resetStreet();
    }
  }, [isOpen, initData]);

  const setForm = (initData: IAddress) => {
    form.setFieldValue(['address', 'streetName'], initData.street.name);
    form.setFieldValue(['address', 'communityName'], initData.street.community?.name);
    form.setFieldValue(['address', 'streetNumber'], initData.streetNumber);
    form.setFieldValue(['address', 'addressId'], initData.id);
  };

  const handleCommunitiesSearch = (newValue: string) => {
    addressesService
      .getCommunitiesOptions(newValue ? `name:*` + newValue : null)
      .then((options) => {
        setCommunityOptions(options);
      })
      .catch((e) => {
        setSelectedCommnunity(undefined);
        setCommunityOptions([]);
        notification.error({
          message: 'Can not fetch community list',
        });
        console.log(e);
      })
      .finally(() => {});
  };

  const handleCommunityChange = (community: any) => {
    setSelectedCommnunity(community);
    resetStreet();
  };

  const handleStreetSearch = (newValue: string) => {
    if (requireCommunity && !selectedCommunity) {
      return;
    }

    let query = requireCommunity ? `communityId:${selectedCommunity.value}` : null;

    if (newValue) {
      query = query ? `${query},name:*${newValue}` : `name:*${newValue}`;
    }

    streetService
      .getStreets(query)
      .then((response) => {
        setStreetSearch(newValue);
        setStreets(response.items);
      })
      .catch((e) => {
        notification.error({
          message: 'Can not fetch street list',
        });
        console.log(e);
      })
      .finally(() => {
        resetStreetNumber();
      });
  };

  const formatStreetName = (street: IStreet) => {
    return `${street.name}-${street.community?.name}`;
  };

  const handleStreetChanged = (streetName: string) => {
    const selectedStreet = streets.find((i) => formatStreetName(i) === streetName);
    setSelectedStreet(selectedStreet!);
    resetStreetNumber();
  };

  const resetStreet = () => {
    form.setFieldValue(['address', 'streetName'], undefined);
    setStreetSearch('');
    setAddresses([]);
    setSelectedStreet(undefined);
    resetStreetNumber();
  };

  const resetStreetNumber = () => {
    setSelectedStreetNumber(undefined);
    form.setFieldValue(['address', 'streetNumber'], undefined);
    handleResetAddress();
  };

  const handleStreetNumberChanged = (streetNumber: string) => {
    setSelectedStreetNumber(streetNumber);
    handleResetAddress();
  };

  const handleResetAddress = () => {
    form.setFieldValue(['address', 'addressId'], undefined);
    setAddress(null);
  };

  useEffect(() => {
    if (selectedStreet) {
      streetService.getAddresses(selectedStreet?.id).then((data) => {
        setAddresses(data);

        // Select address by default
        if (data?.length === 1) {
          setAddress(data[0]);
          form.setFieldsValue({
            address: {
              addressId: data[0].id,
            },
          });
        }
      });
    } else {
      setAddresses([]);
    }
  }, [selectedStreet]);

  useEffect(() => {
    if (selectedCommunity) {
      let query = requireCommunity ? `communityId:${selectedCommunity.value}` : null;
      streetService.getStreets(query).then((response) => {
        setStreets(response.items);
      });
    } else {
      setStreets([]);
    }
  }, [requireCommunity, selectedCommunity]);

  useEffect(() => {
    const result = addresses.filter((i) => i.streetNumber === selectedStreetNumber);
    const isSingleStreetAddress =
      result?.length === 1 && !result[0].unit && !result[0].blockNumber && !result[0].buildingNumber && !result[0].lotNumber;

    if (isSingleStreetAddress) {
      setAddress(result[0]);
      form.setFieldValue(['address', 'addressId'], result[0].id);
      setShowAddressDetails(false);
    } else {
      setShowAddressDetails(true);
    }

    const options = result.map((addr) => ({ value: addr.id, label: formatZoning(addr) }));
    setAddressOptions(options);
  }, [selectedStreetNumber, addresses]);

  const streetNumberOptions = useMemo(
    () =>
      addresses
        .map((i) => ({ label: i.streetNumber, value: i.streetNumber }))
        .filter((item, index, self) => index === self.findIndex((o) => o.value === item.value)),
    [addresses],
  );

  const renderCommunitySelect = () => {
    if (!requireCommunity) {
      return (
        <Form.Item name={['address', 'communityName']} rules={addressSelectValidation.community} hidden>
          <Input name="address_communityName" hidden />
        </Form.Item>
      );
    }

    return (
      <Form.Item name={['address', 'communityName']} className="eiq-select" label="Community" rules={addressSelectValidation.community}>
        <Select
          defaultValue={selectedCommunity?.label}
          labelInValue
          onDropdownVisibleChange={(isOpen) => isOpen && communityOptions?.length === 0 && handleCommunitiesSearch('')}
          {...communityProps}
          options={communityOptions}
          onChange={(_id, community) => handleCommunityChange(community)}
        />
      </Form.Item>
    );
  };

  const renderAddressDetailsSelect = () => {
    if (!showAddressDetails) {
      return (
        <Form.Item name={['address', 'addressId']} rules={addressSelectValidation.address} hidden>
          <Input name="address_addressId" hidden />
        </Form.Item>
      );
    }

    return (
      <Form.Item className="eiq-select" label="Address details" name={['address', 'addressId']} rules={addressSelectValidation.address}>
        <Select
          showSearch
          value={value}
          disabled={disabled && (!selectedStreetNumber || !!initData)}
          allowClear
          onClear={handleResetAddress}
          filterOption={(inputValue, option: any) =>
            inputValue ? option?.label?.toString()?.toLowerCase()?.includes(inputValue.toLowerCase()) ?? false : false
          }
          options={addressOptions}
          onChange={(val) => {
            const found = addresses.find((i) => i.id === val);
            setAddress(found ?? null);
          }}
        />
      </Form.Item>
    );
  };

  return {
    StreetSelect: (
      <Form.Item
        className="eiq-select select-wrapper"
        name={['address', 'streetName']}
        label="Street name"
        rules={addressSelectValidation.streetName}>
        <Select
          value={selectedStreet?.name}
          disabled={!!initData && disabled}
          showSearch
          allowClear
          onClear={resetStreet}
          onSearch={handleStreetSearch}
          onDropdownVisibleChange={(isOpen) => isOpen && handleStreetSearch(streetSearch)}
          onChange={handleStreetChanged}
          options={streets.map((i) => ({
            label: i.name,
            value: formatStreetName(i),
          }))}
        />
      </Form.Item>
    ),
    StreetNumberSelect: (
      <Form.Item
        className="eiq-select select-wrapper"
        name={['address', 'streetNumber']}
        label="Street number"
        rules={addressSelectValidation.streetNumber}>
        <Select
          showSearch
          disabled={disabled && (!selectedStreet || !!initData)}
          allowClear
          onClear={resetStreetNumber}
          onChange={handleStreetNumberChanged}
          options={streetNumberOptions}
        />
      </Form.Item>
    ),
    AddressDetailsSelect: renderAddressDetailsSelect(),
    CommunitySelect: renderCommunitySelect(),
  };
};
