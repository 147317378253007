import { IIncidentReportRead } from 'common/services/incident-report/incident-report.types';
import TableContextProvider, { useTableControls } from '../../table/table-context';
import { Button, Col, Row, Space } from 'antd';
import incidentReportService from 'common/services/incident-report/incident-report.service';
import { incidentReportColumns, incidentReportListFilter } from './incident-report-list.config';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { SearchFilter } from '../../table/filters/search-filter/search-filter';
import { PlusOutlined } from '@ant-design/icons';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { observer } from 'mobx-react-lite';
import TableBase from 'common/components/table/table-base';
import { ColumnFilter } from '../../table/filters/column-filter/column-filter';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import React, { useState } from 'react';
import { IIncidentReportListProps } from './incident-report-list.types';
import { IConfirmDeleteModalProps } from 'common/components/modal/confirm-delete-modal/confirm-delete-modal.types';
import ConfirmDeleteModal from 'common/components/modal/confirm-delete-modal/confirm-delete-modal';

const { caseNumber, incidentDate, reportingPersion, category, community, location, description } = incidentReportColumns();
export const columns = [caseNumber, incidentDate, reportingPersion, category, community, location, description];

export const IncidentReportList = observer(({ showCreateButton, showDeleteButton, baseRoute }: IIncidentReportListProps) => {
  const navigate = useNavigate();
  const [currentSelectedRow, setCurrentSelectedRow] = useState<IIncidentReportRead | undefined>(undefined);

  const tableControls = useTableControls({
    onSelectedRow: (selectedRow: IIncidentReportRead) => {
      setCurrentSelectedRow(selectedRow);
    },
    onFetch: incidentReportService.getIncidents,
    initialColumns: columns,
  });

  const handleEditCliick = () => {
    if (!currentSelectedRow?.id) {
      return;
    }

    navigate(`${baseRoute}/incident-reports/${currentSelectedRow.id}`);
  };

  const deleteReportModal = useModal({
    onBeforeShow: (setModalInitData) => {
      if (!currentSelectedRow?.id) {
        return;
      }

      const data: IConfirmDeleteModalProps = {
        message: 'Are you sure you want to delete this incident report?',
        onDelete: () => {
          incidentReportService.deleteIncident(currentSelectedRow.id).then(() => {
            deleteReportModal.hideModal(true);
          });
        },
      };

      setModalInitData(data);
    },
    onClose: (isSuccess: boolean | undefined) => {
      if (isSuccess) {
        tableControls.setSelectedRow(null);
        tableControls.refetchData();
      }
    },
  });

  return (
    <>
      <TableContextProvider controls={tableControls}>
        <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
          <Col flex="auto">
            <SearchFilter
              adaptiveSearch={true}
              enableProgressiveSearch={true}
              placeholder="Search by name, category, license plate, address, description"
              rulesForColumn={{
                category: FilterTypesEnum.StartWith,
                subCategory: FilterTypesEnum.StartWith,
                description: FilterTypesEnum.Contains, // TODO Discuss about removing this since it might be a performance issue
                address_street_name: FilterTypesEnum.StartWith,
                address_streetNumber: FilterTypesEnum.StartWith,
                involvedVehicle: FilterTypesEnum.StartWith,
                involvedPerson: FilterTypesEnum.StartWith,
              }}
              rightSide={
                <Space size={8}>
                  <ColumnFilter filterConfig={incidentReportListFilter} />

                  <Button icon={<EditIcon />} size="middle" onClick={handleEditCliick}>
                    Edit
                  </Button>

                  {showDeleteButton && (
                    <Button
                      icon={<DeleteIcon stroke="#505762" />}
                      size="middle"
                      onClick={deleteReportModal.showModal}
                      className="mobile-btn">
                      Delete
                    </Button>
                  )}

                  {showCreateButton && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      size="middle"
                      onClick={() => navigate(`${baseRoute}/incident-reports/create`)}
                      className="mobile-btn">
                      Create Report
                    </Button>
                  )}
                </Space>
              }
            />
          </Col>
        </Row>
        <div style={{ height: 'calc(100vh - 206px)', overflowY: 'auto' }}>
          <TableBase selectFirstRowByDefault />
        </div>
      </TableContextProvider>
      <ConfirmDeleteModal
        isEdit={false}
        initData={deleteReportModal?.initData}
        title="Delete"
        isOpen={deleteReportModal?.isOpenModal}
        onClose={deleteReportModal?.hideModal}
      />
    </>
  );
});
