import { IResidentPhone } from 'common/services/residents-service/residents.service.types';

export const editNotificationsValidation = {
  phones: [
    {
      validator: (_: any, value: IResidentPhone[]) => {
        const hasEnabledPhone = value?.some((i) => i.isNotificationEnabled);
        if (!hasEnabledPhone) {
          return Promise.reject('At least one phone must be selected for notifications');
        }
        return Promise.resolve();
      },
    },
  ],
};
