import React from 'react';
import { Outlet } from 'react-router-dom';
import './login-layout.scss';

export const LoginLayout = () => {
  return (
    <div className="login-layout">
      <div className="auth-wrapper">
        <Outlet />
      </div>
    </div>
  );
};
