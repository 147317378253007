import { ICellRendererProps } from 'common/components/table/table.types';
import { formatAddressLabel } from 'common/helpers/address.helper';
import { vehiclesColumns } from 'common/list-configs/vehicles.config';
import { IVehicleAdminRead } from 'common/services/vehicle-service/vehicle.service.types';

const {
  owner,
  ownershipType,
  year,
  make,
  model,
  color,
  rantingDates,
  registrationAddress,
  photo,
  transponder,
  deviceId,
  status,
  cardClass,
  validityPeriod: validationDates,
} = vehiclesColumns();

export const vehicleAdminInitialColumns = [
  {
    key: 'resident',
    title: 'Resident Name',
    dataKey: 'resident',
    width: 170,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleAdminRead>) => {
      return `${cell.rowData.resident.firstName} ${cell.rowData.resident.lastName}`;
    },
  },
  {
    key: 'address',
    title: 'Address',
    dataKey: 'address',
    width: 340,
    minWidth: 300,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleAdminRead>) => {
      return formatAddressLabel(cell.rowData.address);
    },
  },
  owner,
  ownershipType,
  cardClass,
  {
    key: 'license',
    title: 'License plate & State',
    dataKey: 'license',
    width: 170,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleAdminRead>) => {
      return `${cell.rowData.licenseNumber} (${cell.rowData.licenseState})`;
    },
  },
  deviceId,
  transponder,
  year,
  { ...make, width: 100, minWidth: 100 },
  { ...model, width: 100, minWidth: 100 },
  { ...color, width: 100, minWidth: 100 },
  rantingDates,
  validationDates,
  { ...registrationAddress, hidden: false },
  status,
];

export const vehicleApprovalsInitialColumns = [
  {
    key: 'resident',
    title: 'Resident Name',
    dataKey: 'resident',
    width: 170,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleAdminRead>) => {
      return `${cell.rowData.resident.firstName} ${cell.rowData.resident.lastName}`;
    },
  },
  {
    key: 'address',
    title: 'Address',
    dataKey: 'address',
    width: 340,
    minWidth: 300,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleAdminRead>) => {
      return formatAddressLabel(cell.rowData.address);
    },
  },
  photo,
  owner,
  {
    key: 'license',
    title: 'License plate & State',
    dataKey: 'license',
    width: 170,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleAdminRead>) => {
      return `${cell.rowData.licenseNumber} (${cell.rowData.licenseState})`;
    },
  },
  deviceId,
  year,
  { ...make, width: 100, minWidth: 100 },
  { ...model, width: 100, minWidth: 100 },
  { ...color, width: 100, minWidth: 100 },
  rantingDates,
  { ...registrationAddress, hidden: false },
];
