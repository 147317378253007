import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import addressesService from 'common/services/addresses-service/addresses.service';
import { Button, Card, Col, Row, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import TableBase from 'common/components/table/table-base';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { observer } from 'mobx-react-lite';
import { CommunitiesTabInitialColumn, CommunityStreetsTableInitialColumns } from '../../addresses.config';
import { useEffect } from 'react';
import { ICommunity } from 'common/services/street-service/street.service.types';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import CommunityAddEditModal from '../../components/community-add-edit-modal/community-add-edit.modal';
import { ICommunityForm } from '../../addresses.type';
import './communities.scss';
import addressesStore from 'features/eiq-manage/stores/addresses.store';
import { notification } from 'common/utils/notification';
import CommunityDeleteModal from './community-delete-modal';

export const Communities = observer(() => {
  const tableControls = useTableControls({
    onFetch: addressesService.getCommunities,
    initialColumns: CommunitiesTabInitialColumn,
  });

  const communityStreetsTableControls = useTableControls({
    clientSide: true,
    data: addressesStore.communityStreets,
    initialColumns: CommunityStreetsTableInitialColumns,
  });

  const deleteCommunityModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const data = {
        community: tableControls.getSelectedRow(),
      };
      setModalInitData(data);
    },
    onClose: (isSuccess, variant) => {
      if (isSuccess) {
        tableControls.setSelectedRow(null);
        addressesStore.setCommunityStreets([]);
        tableControls.refetchData();
      }
    },
  });

  useEffect(() => {
    const selectedCommunityStreets = tableControls.getSelectedRow()?.streets;

    let mappedStreets;
    if (selectedCommunityStreets?.length) {
      if (selectedCommunityStreets.some((street: any) => street.addresses.length)) {
        mappedStreets = selectedCommunityStreets
          .map((street: any) => {
            return {
              ...street,
              addresses: street.addresses.map((address: any) => ({ ...address, streetName: street.name })),
            };
          })
          .reduce((acc: any, curr: any) => acc.concat(curr.addresses), []);
      } else {
        mappedStreets = selectedCommunityStreets.map((street: any) => {
          return {
            id: street.id,
            streetName: street.name,
          };
        });
      }
    } else {
      mappedStreets = [];
    }
    addressesStore.setCommunityStreets(mappedStreets);
  }, [tableControls.getSelectedRow()?.id]);

  const addCommunityModal = useModal({
    onClose: (isSuccessSaved) => isSuccessSaved && tableControls.refetchData(),
  });

  const editCommunityModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();

      if (!selectedRow) {
        return;
      }

      const communityFromData: ICommunityForm = transformReadToForm(selectedRow);
      setModalInitData(communityFromData);
    },
    onClose: (isSuccessSaved, updatedRow) => {
      if (isSuccessSaved) {
        tableControls.updateSelectedRow(updatedRow);
      }
    },
  });

  const handleDelete = () => {
    if (addressesStore.communityStreets?.length) {
      notification.error({ message: 'This community has streets and addresses. Please remove all associated streets at first.' });
    } else {
      deleteCommunityModal.showModal();
    }
  };

  return (
    <div className="communities-tab">
      <TableContextProvider controls={tableControls}>
        <Card className="table-card table-card-border">
          <Row className="eiq-card-header">
            <Col span={24}>
              <h1 className="h1">Communities</h1>
            </Col>
          </Row>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                placeholder="Search by community"
                rulesForColumn={{
                  name: FilterTypesEnum.Contains,
                }}
              />
            </Col>
            <Col>
              <Space size={8}>
                <Button icon={<DeleteIcon stroke="#505762" />} size="middle" onClick={handleDelete}>
                  Delete
                </Button>
                <Button icon={<EditIcon />} size="middle" onClick={editCommunityModal.showModal}>
                  Edit
                </Button>
                <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={addCommunityModal.showModal}>
                  Add
                </Button>
              </Space>
            </Col>
          </Row>
          <TableBase selectFirstRowByDefault />
          <CommunityAddEditModal title="Add" isEdit={false} isOpen={addCommunityModal.isOpenModal} onClose={addCommunityModal.hideModal} />
          <CommunityAddEditModal
            {...editCommunityModal?.initData}
            title="Edit"
            isEdit={true}
            isOpen={editCommunityModal?.isOpenModal}
            onClose={editCommunityModal?.hideModal}
          />
          <CommunityDeleteModal
            initData={deleteCommunityModal?.initData}
            title="Delete"
            isOpen={deleteCommunityModal?.isOpenModal}
            onClose={deleteCommunityModal?.hideModal}
          />
        </Card>
      </TableContextProvider>
      <TableContextProvider controls={communityStreetsTableControls}>
        <Card className="table-card table-card-border">
          <Row className="eiq-card-header">
            <Col span={24}>
              <h1 className="h1">Streets</h1>
            </Col>
          </Row>
          {<TableBase />}
        </Card>
      </TableContextProvider>
    </div>
  );
});

export const transformReadToForm = (readData: ICommunity): any => {
  const { id, name, description } = readData;

  const form: ICommunityForm = {
    id,
    name: name,
    description: description,
  };

  return {
    initState: form,
  };
};
