import { ServerClient } from 'common/utils/http/http-clients';
import {
  IAddStreetRequestBody,
  IStreetRead,
  IUpdateStreetRequestBody,
  IAddressCreateUpdate,
  ICommunityCreateUpdate,
  IStateRead,
} from './addresses.service.types';
import { IResponseList } from 'common/models/response-list.interface';
import { IAddress, ICommunity } from 'common/services/street-service/street.service.types';
import { SelectProps } from 'antd';
import { IParamsLimit } from 'common/models/params-limit.interface';
import { IStreetDetails } from 'common/services/street-service/street.service.types';
import { IAddressOccupant } from '../guest-service/guest.service.types';

class AddressesService {
  async getStreets(
    pageLimit: any,
    filter: string | null | undefined,
    sort: string | null | undefined,
  ): Promise<IResponseList<IStreetRead>> {
    const res = await ServerClient.get<IResponseList<IStreetRead>>('/streets', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async getCommunities(
    pageLimit?: any,
    filter?: string | null | undefined,
    sort?: string | null | undefined,
  ): Promise<IResponseList<ICommunity>> {
    const res = await ServerClient.get<IResponseList<ICommunity>>('/communities', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async addCommunity(requestBody: ICommunityCreateUpdate): Promise<ICommunity> {
    const res = await ServerClient.post<ICommunity>(`/communities`, requestBody);
    return res?.data;
  }

  async updateCommunity(communityId: number, requestBody: ICommunityCreateUpdate): Promise<ICommunity> {
    const res = await ServerClient.patch<ICommunity>(`/communities/${communityId}`, requestBody);
    return res?.data;
  }

  async deleteCommunity(communityId: number): Promise<any> {
    const res = await ServerClient.delete<IStreetRead>(`/communities/${communityId}`);
    return res?.data;
  }

  async getCommunitiesOptions(filter?: string | null | undefined, pageLimit?: IParamsLimit): Promise<SelectProps['options']> {
    const rest = await this.getCommunities(pageLimit, filter);
    return rest.items
      .map((community) => ({ label: community.name, value: community.id }))
      .filter((option, index, self) => index === self.findIndex((o) => o.value === option.value && o.label === option.label));
  }

  async addAddresses(streetId: number, requestBody: IAddressCreateUpdate): Promise<IStreetRead> {
    const res = await ServerClient.post<IStreetRead>(`/streets/${streetId}/addresses`, requestBody);
    return res?.data;
  }

  async updateAddresses(streetId: number, addressesId: number, requestBody: IAddressCreateUpdate): Promise<IStreetRead> {
    const res = await ServerClient.patch<IStreetRead>(`/streets/${streetId}/addresses/${addressesId}`, requestBody);
    return res?.data;
  }

  async deleteAddresses(streetId: number, addressesId: number): Promise<any> {
    const res = await ServerClient.delete<IStreetRead>(`/streets/${streetId}/addresses/${addressesId}`);
    return res?.data;
  }

  async addStreet(requestBody: IAddStreetRequestBody): Promise<IStreetRead> {
    const res = await ServerClient.post<IStreetRead>(`/streets`, requestBody);
    return res?.data;
  }

  async updateStreet(streetId: number, requestBody: IUpdateStreetRequestBody): Promise<IStreetRead> {
    const res = await ServerClient.patch<IStreetRead>(`/streets/${streetId}`, requestBody);
    return res?.data;
  }

  async deleteStreet(streetId: number): Promise<any> {
    const res = await ServerClient.delete<IStreetRead>(`/streets/${streetId}`);
    return res?.data;
  }

  async getStreetDetails(streetId: number): Promise<IStreetDetails> {
    const res = await ServerClient.get<IStreetDetails>(`/streets/${streetId}`);
    return res?.data;
  }

  async getStates(): Promise<IStateRead[]> {
    const res = await ServerClient.get<IStateRead[]>('/states');
    return res.data;
  }

  async getOccupantsByAddressId(addressId: number): Promise<IAddressOccupant[]> {
    const res = await ServerClient.get<IAddressOccupant[]>(`/addresses/${addressId}/occupants`);
    return res?.data;
  }

  async getVacantAddresses(
    pageLimit: any,
    filter: string | null | undefined,
    sort: string | null | undefined,
  ): Promise<IResponseList<IAddress>> {
    const res = await ServerClient.get<IResponseList<IAddress>>('/addresses/vacant', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async getAddresses(pageLimit: any, filter: string | null | undefined, sort: string | null | undefined): Promise<IResponseList<IAddress>> {
    const res = await ServerClient.get<IResponseList<IAddress>>('/addresses', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }
}

export const addressesService = new AddressesService();
export default addressesService;
