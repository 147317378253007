import React, { useEffect } from 'react';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import accountService from 'features/eiq-manage/service/account-service/account.service';
import { observer } from 'mobx-react-lite';
import { accountsTableInitialColumns } from '../accounts.config';
import { Button, Card, Col, Row, Space } from 'antd';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { PlusOutlined } from '@ant-design/icons';
import TableBase from 'common/components/table/table-base';
import { IAddEditAccountModal, IUserAccountsProps } from './user-accounts.types';
import ConfirmDeleteModal, { ConfirmDeleteItemName } from 'common/components/modal/confirm-delete-modal/confirm-delete-modal';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import { IConfirmDeleteModalProps } from 'common/components/modal/confirm-delete-modal/confirm-delete-modal.types';
import AccountAddEditModal from './add-edit-account-modal/add-edit-account-modal';
import { AccountTypes } from 'features/eiq-manage/enums/account-type.enum';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { UserTypeEnum } from 'common/enums/user-type.enum';

export const UserAccounts = observer(({ accountType }: IUserAccountsProps) => {
  const isSuperAdmin = userAuthStore.userType === UserTypeEnum.SuperAdmin;

  const tableControls = useTableControls({
    onFetch: getSourceService(accountType),
    initialColumns: accountsTableInitialColumns,
  });

  useEffect(() => {
    if (accountType === AccountTypes.Admin) {
      tableControls.updateColumns(accountsTableInitialColumns.filter((i) => i.key !== 'defaultGate'));
    } else {
      tableControls.updateColumns(accountsTableInitialColumns);
    }
    tableControls.refetchData();
  }, [accountType]);

  const deleteAccountModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      const data: IConfirmDeleteModalProps = {
        message: getDeleteMessage(`${selectedRow.firstName} ${selectedRow.lastName}`, accountType),
        onDelete: () => {
          const isAdmin = accountType === AccountTypes.Admin;
          accountService[isAdmin ? 'deleteAdmin' : 'deleteSecurityOfficer'](selectedRow.id).then((result) => {
            deleteAccountModal.hideModal(true);
          });
        },
      };

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        tableControls.setSelectedRow(null);
        tableControls.refetchData();
      }
    },
  });

  const editAccountModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();

      if (!selectedRow) {
        return;
      }

      const streetFromData: IAddEditAccountModal = {
        id: selectedRow.id,
        firstName: selectedRow.firstName,
        lastName: selectedRow.lastName,
        accountName: getAccountName(selectedRow.accountName, accountType),
        email: selectedRow.email,
        phone: selectedRow.phone,
        password: null,
        accountType: accountType,
        defaultGate: selectedRow.defaultGate,
      };
      setModalInitData(streetFromData);
    },
    onClose: (isSuccessSaved, updatedRow) => {
      if (isSuccessSaved) {
        tableControls.updateSelectedRow(updatedRow);
      }
    },
  });

  const addAccountModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const streetFromData = { accountType } as IAddEditAccountModal;
      setModalInitData(streetFromData);
    },
    onClose: (isSuccessSaved) => isSuccessSaved && tableControls.refetchData(),
  });

  return (
    <>
      <TableContextProvider controls={tableControls}>
        <Card className="eiq-card eiq-card-border">
          <Row className="eiq-card-header">
            <Col span={24}>
              <h1 className="h1">{getTitle(accountType)}</h1>
            </Col>
          </Row>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                placeholder="Search by email"
                rulesForColumn={{
                  email: FilterTypesEnum.StartWith,
                }}
              />
            </Col>
            <Col>
              {isSuperAdmin && (
                <Space size={8}>
                  <Button icon={<DeleteIcon stroke="#505762" />} size="middle" onClick={deleteAccountModal.showModal}>
                    Delete
                  </Button>
                  <Button icon={<EditIcon />} size="middle" onClick={editAccountModal.showModal}>
                    Edit
                  </Button>
                  <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={addAccountModal.showModal}>
                    Add
                  </Button>
                </Space>
              )}
            </Col>
          </Row>
          <TableBase onSelected={tableControls.setSelectedRow} selectFirstRowByDefault />
        </Card>
      </TableContextProvider>
      <ConfirmDeleteModal
        isEdit={false}
        initData={deleteAccountModal?.initData}
        title="Delete"
        isOpen={deleteAccountModal?.isOpenModal}
        onClose={deleteAccountModal?.hideModal}
      />
      <AccountAddEditModal
        isEdit={false}
        initData={addAccountModal?.initData}
        title="Add"
        isOpen={addAccountModal?.isOpenModal}
        onClose={addAccountModal?.hideModal}
      />
      <AccountAddEditModal
        isEdit={true}
        initData={editAccountModal?.initData}
        title="Edit"
        isOpen={editAccountModal?.isOpenModal}
        onClose={editAccountModal?.hideModal}
      />
    </>
  );
});

const getTitle: any = (accountType: AccountTypes) => (accountType === AccountTypes.Admin ? 'Admins' : 'EIQ-secure users');

const getSourceService: any = (accountType: AccountTypes) =>
  accountType === AccountTypes.Admin ? accountService.getAdmins : accountService.getSecurityOfficers;

const getDeleteMessage = (name: string, accountType: AccountTypes) => {
  return (
    <span>
      <span>Are you sure you want to delete </span>
      <ConfirmDeleteItemName name={name} />
      <span> from the {getTitle(accountType)} list?</span>
    </span>
  );
};

const getAccountName = (name: string, accountType: AccountTypes) => {
  if (accountType === AccountTypes.SecurityOfficer) {
    return name.split('@')[0];
  }

  return name;
};
