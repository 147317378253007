import { ICommunity } from 'common/services/street-service/street.service.types';
import React from 'react';

export const streetsColumns = () => ({
  name: {
    key: 'name',
    title: 'Street name',
    dataKey: 'name',
    width: 600,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  community: {
    key: 'community',
    title: 'Community',
    dataKey: 'community',
    sortable: true,
    width: 600,
    minWidth: 150,
    resizable: true,
    fixed: 1,
    cellRenderer: ({ cellData }: { cellData: ICommunity }) => <p style={{ whiteSpace: 'nowrap' }}>{cellData.name}</p>,
  },
});
