import { ServerClient } from 'common/utils/http/http-clients';
import { IFastAccessPassRead } from './fast-access-service.types';

class FastAccessService {
  async getFastAccessPass(id: string): Promise<IFastAccessPassRead> {
    const res = await ServerClient.get<IFastAccessPassRead>(`/eiq-passport/${id}`);
    return res?.data;
  }
}

export const fastAccessService = new FastAccessService();
export default fastAccessService;
