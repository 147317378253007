import React, { useCallback, useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import './reports.scss';
import reportsService from 'common/services/reports-service/reports.service';
import { IReportData } from 'common/services/reports-service/reports.service.types';

const REPORT_STORAGE_KEY = 'REPORT_EMBED_DATA';
const TOKEN_EXPIRATED_MESSAGE = 'TokenExpired';

export const Reports = () => {
  const [reportId, setReportId] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();
  const [embedUrl, setEmbedUrl] = useState<string>();
  const [enableFilterPane, setEnableFilterPane] = useState<boolean>(false);

  const refreshReportData = useCallback(() => {
    reportsService.getReportData().then((data) => {
      setReportData(data);
      localStorage.setItem(REPORT_STORAGE_KEY, JSON.stringify(data));
    });
  }, []);

  useEffect(() => {
    const reportEmbedData = localStorage.getItem(REPORT_STORAGE_KEY);

    if (reportEmbedData) {
      const parsedData = JSON.parse(reportEmbedData);

      if (!needToRequestToken(parsedData.expiration)) {
        setReportData(parsedData);
        return;
      }
    }

    refreshReportData();
  }, [refreshReportData]);

  const setReportData = (data: IReportData) => {
    setReportId(data.reportId);
    setAccessToken(data.token);
    setEmbedUrl(data.embedUrl);
    setEnableFilterPane(data.enableFilterPane);
  };

  const needToRequestToken = (utcDate: Date) => {
    if (!utcDate) {
      return true;
    }

    const now = new Date();
    const expiration = new Date(utcDate);

    // need to request if token is expired or will expire in 30 minutes
    return now > expiration || now > new Date(expiration.getTime() - 30 * 60 * 1000);
  };

  const handleReportLoadError = (event: any) => {
    console.log(event);

    if (event.message && (event.message === TOKEN_EXPIRATED_MESSAGE || event?.detail?.message === TOKEN_EXPIRATED_MESSAGE)) {
      refreshReportData();
    }
  };

  return (
    <>
      {reportId && (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: reportId,
            embedUrl: embedUrl,
            accessToken: accessToken,
            tokenType: models.TokenType.Embed,
            permissions: models.Permissions.All,
            settings: {
              filterPaneEnabled: enableFilterPane,
              navContentPaneEnabled: true,
            },
          }}
          eventHandlers={
            new Map([
              [
                'loaded',
                function () {
                  console.log('Report loaded');
                },
              ],
              [
                'rendered',
                function () {
                  console.log('Report rendered');
                },
              ],
              ['error', handleReportLoadError],
            ])
          }
          cssClassName={'bi-embedded'}
        />
      )}
    </>
  );
};
