import { ReactComponent as TurnLeftIcon } from 'assets/icons/directions/turn-left.svg';
import { ReactComponent as TurnRightIcon } from 'assets/icons/directions/turn-right.svg';
import { ReactComponent as UTurnRightIcon } from 'assets/icons/directions/u-turn-right.svg';
import { ReactComponent as UTurnLeftIcon } from 'assets/icons/directions/u-turn-left.svg';
import { ReactComponent as TurnSlightRightIcon } from 'assets/icons/directions/turn-slight-right.svg';
import { ReactComponent as MergeIcon } from 'assets/icons/directions/merge.svg';
import { ReactComponent as RoundaboutLeftIcon } from 'assets/icons/directions/roundabout-left.svg';
import { ReactComponent as RoundaboutRightIcon } from 'assets/icons/directions/roundabout-right.svg';
import { ReactComponent as TurnSlightLeftIcon } from 'assets/icons/directions/turn-slight-left.svg';
import { ReactComponent as RampRightIcon } from 'assets/icons/directions/ramp-right.svg';
import { ReactComponent as ForkRightIcon } from 'assets/icons/directions/fork-right.svg';
import { ReactComponent as StraightIcon } from 'assets/icons/directions/straight.svg';
import { ReactComponent as ForkLeftIcon } from 'assets/icons/directions/fork-left.svg';
import { ReactComponent as FerryTrainIcon } from 'assets/icons/directions/ferry-train.svg';
import { ReactComponent as TurnSharpRightIcon } from 'assets/icons/directions/turn-sharp-right.svg';
import { ReactComponent as TurnSharpLeftIcon } from 'assets/icons/directions/turn-sharp-left.svg';
import { ReactComponent as RampLeftIcon } from 'assets/icons/directions/ramp-left.svg';
import { ReactComponent as FerryIcon } from 'assets/icons/directions/ferry.svg';
import { ReactComponent as KeepLeftIcon } from 'assets/icons/directions/keep-left.svg';
import { ReactComponent as KeepRightIcon } from 'assets/icons/directions/keep-right.svg';
import React from 'react';

export type DirectionTypeProps =
  | 'turn-left'
  | 'turn-right'
  | 'uturn-right'
  | 'uturn-left'
  | 'turn-slight-right'
  | 'merge'
  | 'roundabout-left'
  | 'roundabout-right'
  | 'turn-slight-left'
  | 'ramp-right'
  | 'fork-right'
  | 'straight'
  | 'fork-left'
  | 'ferry-train'
  | 'turn-sharp-right'
  | 'turn-sharp-left'
  | 'ramp-left'
  | 'ferry'
  | 'keep-left'
  | 'keep-right';

export const GetDirectionIcon = (type: DirectionTypeProps) => {
  switch (type) {
    case 'turn-left':
      return <TurnLeftIcon />;
    case 'turn-right':
      return <TurnRightIcon />;
    case 'uturn-right':
      return <UTurnRightIcon />;
    case 'uturn-left':
      return <UTurnLeftIcon />;
    case 'turn-slight-right':
      return <TurnSlightRightIcon />;
    case 'merge':
      return <MergeIcon />;
    case 'roundabout-left':
      return <RoundaboutLeftIcon />;
    case 'roundabout-right':
      return <RoundaboutRightIcon />;
    case 'turn-slight-left':
      return <TurnSlightLeftIcon />;
    case 'ramp-right':
      return <RampRightIcon />;
    case 'fork-right':
      return <ForkRightIcon />;
    case 'straight':
      return <StraightIcon />;
    case 'fork-left':
      return <ForkLeftIcon />;
    case 'ferry-train':
      return <FerryTrainIcon />;
    case 'turn-sharp-right':
      return <TurnSharpRightIcon />;
    case 'ramp-left':
      return <RampLeftIcon />;
    case 'ferry':
      return <FerryIcon />;
    case 'keep-left':
      return <KeepLeftIcon />;
    case 'keep-right':
      return <KeepRightIcon />;
    case 'turn-sharp-left':
      return <TurnSharpLeftIcon />;
    default:
      return '';
  }
};
