import { Form, FormProps, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { notification } from '../../utils/notification';
import { IServerError } from '../../utils/http/http-client.types';
import _ from 'lodash';
import './form-wrapper.scss';
import { LoadingOutlined } from '@ant-design/icons';

interface FormWrapperProps extends FormProps {}

// Wrapper allow hide common rules of validation where need to display error in toaster
export const FormWrapper = ({ form, children, onFinish, onValuesChange, onFieldsChange, ...restProps }: FormWrapperProps) => {
  const handleValidationOnSubmit = () => {
    if (!form) {
      return;
    }

    form
      .validateFields()
      .then(() => {
        notification.destroy();
      })
      .catch((error) => {
        const { errorFields } = error;

        // Display error messages in toaster or notification component
        errorFields.forEach((field: any) => {
          notification.error({
            key: field.name.join('.'),
            duration: null,
            message: field.errors[0],
          });
        });
      });
  };

  const handleValidationOnFormValuesChange = (changedValues: any, allValues: any) => {
    // if (!form) {
    //   return;
    // }

    // // We will store the path of the changed field as an array
    // let changedFieldPath: Array<string | number> = [];

    // // Recursive function to find the path of the changed field within the changedValues object
    // const findChangedFieldPath = (value: any, path: Array<string | number> = []) => {
    //   if (_.isObject(value) && !_.isArray(value)) {
    //     // If the value is an object (but not an array), we continue searching its properties
    //     _.forOwn(value, (v, k) => {
    //       findChangedFieldPath(v, [...path, k]);
    //     });
    //   } else if (_.isArray(value)) {
    //     value.forEach((v, i) => {
    //       findChangedFieldPath(v, [...path, i]);
    //     });
    //   } else if (changedFieldPath.length === 0) {
    //     // We have reached a value that is not an object, so we have found the path of the changed field
    //     // Only set the path if it hasn't been set yet (to stop the recursion once the first value is found)
    //     changedFieldPath = path;
    //   }
    // };

    // findChangedFieldPath(changedValues);

    // const changedFieldName = changedFieldPath;

    // notification.destroy(changedFieldName.join('.'));

    // form.setFields([{ name: changedFieldName, errors: [] }]);

    // form.validateFields(changedFieldName).catch((error) => {
    //   const { errorFields } = error;

    //   // Display error messages in toaster or notification component
    //   errorFields.forEach((field: any) => {
    //     notification.error({
    //       key: changedFieldName.join('.'),
    //       duration: null,
    //       message: field.errors[0],
    //     });
    //   });
    // });
    if (onValuesChange) {
      onValuesChange(changedValues, allValues);
    }
  };

  // const handleOnFieldsChange = (changedFields: any[], allFields: any[]) => {
  //   changedFields
  //     .filter((field) => field.validated)
  //     .forEach((field) => {
  //       const name = field.name.join('.');
  //       notification.destroy(name);
  //       if (field.errors?.length) {
  //         notification.error({
  //           key: name,
  //           duration: null,
  //           message: field.errors[0],
  //         });
  //       }
  //     });
  //
  //   if (onFieldsChange) {
  //     onFieldsChange(changedFields, allFields);
  //   }
  // };

  // const handleValidationOnFormValuesChange = (changedValues: any, allValues: any) => {
  //   if (!form) {
  //     return;
  //   }
  //   // Identify the name path of the changed field
  //   let changedFieldPath: (string | number)[] = [];
  //   const traverse = (object: any, path: (string | number)[]) => {
  //     for (const key in object) {
  //       if (typeof object[key] === 'object' && object[key] !== null) {
  //         traverse(object[key], [...path, key]);
  //       } else {
  //         changedFieldPath = [...path, key];
  //       }
  //     }
  //   };
  //   traverse(changedValues, []);
  //
  //   const changedFieldName = changedFieldPath;
  //
  //   notification.destroy(changedFieldName.join('.'));
  //   console.log('Key destroy', changedFieldName);
  //
  //   form.setFields([{ name: changedFieldName, errors: [] }]);
  //
  //   form.validateFields(changedFieldName).catch((error: any) => {
  //     const { errorFields } = error;
  //
  //     // Display error messages in toaster or notification component
  //     errorFields.forEach((field: any) => {
  //       notification.error({
  //         key: changedFieldName.join('.'),
  //         duration: null,
  //         message: field.errors[0],
  //       });
  //     });
  //   });
  // };

  const handleOnInvalid = (props: any) => {
    console.log('handleOnInvalid', props);
  };

  const [loading, setLoading] = useState(false);

  const handleOnFinish = async (value: any) => {
    if (!onFinish || !form) {
      return;
    }

    setLoading(true);
    try {
      await onFinish(value);
    } catch (e) {
      const serverError = e as IServerError;

      if (serverError?.details) {
        serverError.details.forEach((field: any) => {
          const key = Array.isArray(field.name) ? field.name.join('.') : field.name;
          console.log('key onFinish', key);
          notification.error({
            key: key,
            duration: null,
            message: field.errors[0],
          });
        });

        form.setFields(serverError.details as any);
      } else {
        console.log(e);
      }
    }

    setLoading(false);
  };

  return (
    <Form
      {...restProps}
      onInvalidCapture={handleOnInvalid}
      onInvalid={handleOnInvalid}
      form={form}
      onFinish={handleOnFinish}
      onValuesChange={handleValidationOnFormValuesChange}
      onSubmitCapture={handleValidationOnSubmit}>
      {loading && (
        <div className="form-loading">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 32, color: '#AEB5BE' }} spin />} />
        </div>
      )}
      {loading && <div className="form-loading-wrapper"></div>}
      {children as React.ReactNode}
    </Form>
  );
};
