import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { Button, Checkbox, DatePickerProps, Form, Input, Modal, Select, Space, Switch } from 'antd';
import { DatePicker } from 'antd/lib';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { notification } from 'common/utils/notification';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { IResidentCreate, IResidentPhone, IResidentRead, IResidentUpdate } from 'common/services/residents-service/residents.service.types';
import residentsService from 'common/services/residents-service/residents.service';
import InputPhone from 'common/components/form-elements/input-phone/input-phone';
import { IAddress } from 'common/services/street-service/street.service.types';
import { IDatePeriod } from 'common/models/date-period.interface';
import { phoneTypeOptions } from 'common/constans/phone-type.options';
import { PhoneType } from 'common/enums/phone-type.enum';
import { getPhoneComparer } from 'features/eiq-secure/heplers/phone.helper';
import { isOccupant } from 'common/helpers/resident-address.helper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ResidentSourceType } from 'common/enums/resident-source-type.enum';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { IResidentForm, IResidentFormDates } from '../eiq-manage-residents/residents.type';
import { ValidationMessages } from 'common/validation/validation-messages';
import { phoneValidationRule } from 'common/validation/phone-number-rule';
import { RuleType } from 'rc-field-form/lib/interface';
import AnonymousRegistrationAddressSelect from './anonymous-registration-address-select';
import { camelCaseToRegularForm } from 'common/helpers/camel-case-to-regular-form.helper';

const initialValues = {
  name: '',
  phones: [{ isNotificationEnabled: true, label: 'Primary', number: null, order: 0 }],
};

const customFormat: DatePickerProps['format'] = (value) => `${value.format('MMMM D, YYYY')}`;

const getVacationPeriod = (
  vacationPeriod: IResidentFormDates | undefined,
  isChild: boolean,
  isVacation: boolean,
): IDatePeriod | undefined => {
  if (isChild || !isVacation) {
    return {
      startDate: null,
      endDate: null,
    };
  }
  if (!vacationPeriod?.startDate || !vacationPeriod?.endDate) {
    return;
  }
  return {
    startDate: vacationPeriod.startDate.format('YYYY-MM-DD') ?? null,
    endDate: vacationPeriod.endDate.format('YYYY-MM-DD') ?? null,
  };
};

const AnonymousRegistrationResidentAddEditModal = ({
  isOpen,
  onClose,
  isEdit,
  title,
  extraData,
  initState,
  initData,
  isOwner,
  residents,
}: any) => {
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);
  const [addressSelectResetTrigger, setAddressSelectResetTrigger] = useState<boolean>(false);
  const [ownerType, setOwnerType] = useState<string | null>(initState?.ownershipType);

  const disabled = isEdit && extraData?.source === ResidentSourceType.NorthStar;

  const hideModal = (...rest: any) => {
    notification.destroy();
    form.resetFields();
    setIsVacation(false);
    if (onClose) {
      onClose(...rest);
    }
  };

  // Form
  const [form] = Form.useForm();

  // Here you can transform from data before sent, also resolve custom form error
  const onFinish = async (values: IResidentForm) => {
    const { addressId, vacationPeriod, phones, ...rest } = values;
    const filledPhones = phones.filter((phone) => phone.number?.length > 0);

    if (filledPhones.every((i) => !i.isNotificationEnabled)) {
      filledPhones.forEach((i) => {
        i.isNotificationEnabled = false;
      });
      const primaryPhone = filledPhones.find((i) => i.label === 'Primary');

      if (primaryPhone) {
        primaryPhone.isNotificationEnabled = true;
      }
    }

    const body: IResidentCreate = {
      ...rest,
      vacationPeriod: getVacationPeriod(vacationPeriod, isChild, isVacation),
      phones: filledPhones,
    };

    body['address'].addressId = selectedAddressId;
    body['address'].rentStartDate = rest.address?.rentStartDate?.format('YYYY-MM-DD') ?? null;
    body['address'].rentEndDate = rest.address.rentEndDate?.format('YYYY-MM-DD') ?? null;

    if (isEdit) {
      const toUpdate = body as IResidentUpdate;
      toUpdate.id = initState?.id;
      const res = await residentsService.updateResident(toUpdate);
      hideModal(true, res);
    } else {
      const res = await residentsService.createResident(body);
      hideModal(true, res);
    }
  };

  // [TODO] move the List to the separate component
  // List
  const [bulkEditMode, setBulkEditMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const listOperationsRef = useRef<any>(null);

  const handleBulkEditClick = () => {
    setBulkEditMode(!bulkEditMode);
    setSelectedItems([]);
  };

  const handleBulkDeleteClick = () => {
    selectedItems
      .sort((a: any, b: any) => b - a)
      .forEach((index: any) => {
        form.getFieldValue('phones').splice(index, 1);
      });

    const updatedPhones = form.getFieldValue('phones').map((phone: IResidentPhone, index: number) => ({
      ...phone,
      order: index,
    }));

    form.setFieldsValue({
      phones: updatedPhones,
    });

    setSelectedItems([]);
    setBulkEditMode(false);
  };

  const handleAddPhone = () => {
    const phones = form.getFieldValue('phones');
    const maxOrder = phones?.slice().sort((a: any, b: any) => b.order - a.order);
    const order = maxOrder[0] ? maxOrder[0].order + 1 : 0;
    listOperationsRef.current.add({ label: PhoneType.Primary, number: '', isNotificationEnabled: true, order: order });
  };

  const handlePhoneOder = (result: any) => {
    if (!result.destination) return;

    const phones = form.getFieldValue('phones');
    const [reorderedItem] = phones.splice(result.source.index, 1);
    phones.splice(result.destination.index, 0, reorderedItem);

    const updatedPhones = phones.map((phone: IResidentPhone, index: number) => ({
      ...phone,
      order: index,
    }));
    form.setFieldValue('phones', updatedPhones);
  };

  const handleSelectItem = (index: number) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item: any) => item !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  };

  // Vocation
  const [isVacation, setIsVacation] = useState(extraData?.isVacation);

  useEffect(() => {
    if (extraData) {
      setIsVacation(!!extraData?.isVacation);
    }
    if (initState?.address?.ownershipType) {
      setOwnerType(initState.address.ownershipType);
      setSelectedAddressId(initState.address.addressId);
    }
  }, [isOpen]);

  //   useEffect(() => {
  //     if (!isEdit && initData) {
  //       form.setFieldValue(['address', 'addressId'], initData.address.id);
  //       form.setFieldValue(['address', 'streetName'], initData.address.street.name);
  //       form.setFieldValue(['address', 'streetNumber'], initData.address.streetNumber);
  //     }
  //   }, [initData]);

  // Example how check outer state, for an example during editing.
  useEffect(() => {
    if (form && isOpen) {
      if (initState?.phones) {
        initState.phones = initState.phones.sort(getPhoneComparer());
      }

      form.setFieldsValue(initState);
    }
  }, [isOpen, form, initState]);

  const isOccupantType = useMemo(() => {
    return ownerType && isOccupant(ownerType as ResidentTypesEnum);
  }, [ownerType]);

  const isChild = useMemo(() => ownerType === ResidentTypesEnum.UnderAgeChild || ownerType === ResidentTypesEnum.AdultChild, [ownerType]);
  const isRenter = useMemo(() => ownerType === ResidentTypesEnum.Renter, [ownerType]);

  // Select owner type
  const handleOwnerType = (value: any) => {
    setOwnerType(value);
  };

  useEffect(() => {
    if (isOpen) {
      setAddressSelectResetTrigger(!addressSelectResetTrigger);
    }
  }, [isOpen]);

  const residentOptions = useMemo(
    () => residents?.map((res: any) => ({ value: res.id, label: `${res.firstName} ${res.lastName}` })),
    [residents],
  );

  const handleAddressChanged = (value: IAddress | null) => {
    const addressId = value ? value.id : null;
    setSelectedAddressId(addressId);
  };

  const residentTypeOptions = (isOwner: boolean) => {
    if (isOwner) {
      return [
        { label: camelCaseToRegularForm(ResidentTypesEnum.Owner), value: ResidentTypesEnum.Owner },
        { label: camelCaseToRegularForm(ResidentTypesEnum.Renter), value: ResidentTypesEnum.Renter },
      ];
    } else {
      return [
        { label: camelCaseToRegularForm(ResidentTypesEnum.Spouse), value: ResidentTypesEnum.Spouse },
        { label: camelCaseToRegularForm(ResidentTypesEnum.Occupant), value: ResidentTypesEnum.Occupant },
        { label: camelCaseToRegularForm(ResidentTypesEnum.AdultChild), value: ResidentTypesEnum.AdultChild },
        { label: camelCaseToRegularForm(ResidentTypesEnum.UnderAgeChild), value: ResidentTypesEnum.UnderAgeChild },
        { label: camelCaseToRegularForm(ResidentTypesEnum.Roommate), value: ResidentTypesEnum.Roommate },
        { label: camelCaseToRegularForm(ResidentTypesEnum.Partner), value: ResidentTypesEnum.Partner },
      ];
    }
  };

  const onRelatedToChanged = (value: any) => {
    const resident = residents.find((i: any) => i.id === value);
    const address = resident?.addresses[0].address;

    if (address) {
      setSelectedAddressId(address.id);
      form.setFieldValue(['address', 'addressId'], address.id);
      form.setFieldValue(['address', 'streetName'], address.street.name);
      form.setFieldValue(['address', 'streetNumber'], address.streetNumber);
    }
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper
        form={form}
        onFinish={onFinish}
        name={`residentForm+${title}`}
        layout="vertical"
        autoComplete="on"
        initialValues={initState ?? initialValues}>
        {!isOwner && (
          <Space className="full-width" size={13}>
            <Form.Item className="eiq-select" name={['address', 'relatedResidentId']} label="Related to" rules={validationRules.relatedTo}>
              <Select
                allowClear
                disabled={!selectedAddressId && disabled}
                defaultActiveFirstOption={false}
                filterOption={(inputValue, option) => option?.label?.toString().toLowerCase().includes(inputValue.toLowerCase()) ?? false}
                options={residentOptions}
                onChange={onRelatedToChanged}
              />
            </Form.Item>
          </Space>
        )}

        <Space className="full-width" size={13}>
          <Form.Item name="firstName" label="First name" rules={validationRules.firstName}>
            <Input name="name" placeholder="Enter first name" disabled={disabled} />
          </Form.Item>

          <Form.Item name="lastName" label="Last name" rules={validationRules.lastName}>
            <Input name="lastName" placeholder="Enter last name" disabled={disabled} />
          </Form.Item>
        </Space>
        <Space style={{ marginTop: 20 }}>
          <i>
            Please choose the correct type for this resident. Any kind of owners, should be added as <b>Owner</b>, tenants should choose{' '}
            <b>Renter</b>, and if you are not sure, you can choose <b>Occupant</b>.
          </i>
        </Space>
        <Space className="full-width">
          <Form.Item
            name={['address', 'ownershipType']}
            className="eiq-select"
            label="Type"
            rules={[{ required: true, message: 'Ownership type is required!' }]}>
            <Select showSearch options={residentTypeOptions(isOwner)} onChange={handleOwnerType} disabled={disabled} />
          </Form.Item>
        </Space>

        <Space style={{ marginTop: 20 }}>
          <i>
            Please enter a <b>valid email address</b>. As soon as you finish adding the information, this email will receive a welcome email
            with your new PIN number and instructions to download your new resident’s app to be able to add/update your guests, vehicles and
            pets.
          </i>
        </Space>
        <Space className="full-width">
          <Form.Item
            name="email"
            label="Email address"
            validateTrigger="onBlur"
            rules={isOwner ? validationRules.requiredEmail : validationRules.email}>
            <Input
              name="email"
              placeholder="Enter email"
              disabled={disabled || ownerType === ResidentTypesEnum.UnderAgeChild}
              autoCapitalize="off"
            />
          </Form.Item>
        </Space>

        {isRenter && (
          <Space className="full-width" size={13}>
            <Form.Item name={['address', 'rentStartDate']} label="From" rules={validationRules.rentStartDate}>
              <DatePicker format={customFormat} placeholder="Start date" disabled={disabled} />
            </Form.Item>
            <Form.Item name={['address', 'rentEndDate']} label="To" rules={validationRules.rentEndDate}>
              <DatePicker format={customFormat} placeholder="End date" disabled={disabled} />
            </Form.Item>
          </Space>
        )}

        {!isEdit && (
          <>
            <Space style={{ marginTop: 20 }}>
              <i>Please make sure to select the correct address as your home.</i>
            </Space>
            <AnonymousRegistrationAddressSelect
              value={selectedAddressId}
              setAddress={handleAddressChanged}
              isOpen={addressSelectResetTrigger}
              form={form}
              initData={initData?.address}
              disabled={!isOwner}
            />
          </>
        )}

        <Space className="full-width" size={13}>
          <Form.Item name="clubId" label="Club ID (Optional)">
            <Input placeholder="Enter Club ID" disabled={disabled} />
          </Form.Item>
        </Space>

        {/* {!isChild && (
          <Space className="full-width" size={13}>
            <Form.Item label="Vacation" valuePropName="checked">
              <Switch
                checked={isVacation}
                onChange={(checked) => {
                  setIsVacation(checked);
                }}
              />
            </Form.Item>
          </Space>
        )}

        {!isChild && isVacation && (
          <Space className="full-width" size={13}>
            <Form.Item name={['vacationPeriod', 'startDate']} label="From" rules={validationRules.vacationStartDate}>
              <DatePicker format={customFormat} placeholder="Start date" />
            </Form.Item>
            <Form.Item name={['vacationPeriod', 'endDate']} label="To" rules={validationRules.vacationEndDate}>
              <DatePicker format={customFormat} placeholder="End date" />
            </Form.Item>
          </Space>
        )} */}

        <Space style={{ marginTop: '4px', marginBottom: '16px' }} className="full-width" size={13}>
          <span className="eiq-text">Contacts</span>

          <Space size={8} direction="horizontal" align="end" style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button type="link" size={'small'} onClick={handleBulkEditClick} disabled={disabled}>
              {bulkEditMode ? 'Cancel' : 'Bulk Edit / Order'}
            </Button>
            {bulkEditMode && (
              <Button onClick={handleBulkDeleteClick} type="link" size="small">
                Delete
              </Button>
            )}
            {!bulkEditMode && (
              <Button onClick={handleAddPhone} type="link" size="small" disabled={disabled}>
                Add
              </Button>
            )}
          </Space>
        </Space>

        <DragDropContext onDragEnd={handlePhoneOder}>
          <Droppable droppableId="phones" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Form.List name="phones">
                  {(fields, listOperations) => {
                    // Keep a reference to listOperations
                    listOperationsRef.current = listOperations;

                    return (
                      <>
                        {fields.map((field, index) => (
                          <Draggable isDragDisabled={!bulkEditMode} key={field.key} draggableId={field.key.toString()} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <div
                                  style={{ display: 'flex', gap: '13px' }}
                                  className={` ${bulkEditMode ? 'bulk-edit' : ''}`}
                                  key={field.key}>
                                  {bulkEditMode && (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                      <DragIcon />
                                      <Checkbox checked={selectedItems.includes(index)} onChange={() => handleSelectItem(index)} />
                                    </div>
                                  )}

                                  <Form.Item
                                    style={{ flex: '1' }}
                                    {...field}
                                    name={[field.name, 'number']}
                                    label="Phone number"
                                    validateTrigger="onBlur"
                                    rules={validationRules.phone}>
                                    <InputPhone disabled={disabled} />
                                  </Form.Item>

                                  <Form.Item
                                    style={{ flex: '1' }}
                                    {...field}
                                    key={field.key + 'label'}
                                    name={[field.name, 'label']}
                                    label="Label">
                                    <Select options={phoneTypeOptions} disabled={disabled} />
                                  </Form.Item>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </>
                    );
                  }}
                </Form.List>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Space>
          <i>
            Please enter at least one <b>primary phone number</b> for your HOA and security guards to be able to reach you easily.
          </i>
        </Space>

        <Space className="footer">
          <Button key="cancel" type="link" onClick={() => hideModal()} style={{ width: '159px' }}>
            Cancel
          </Button>

          <Button key="residentForm" htmlType="submit" type="primary" style={{ width: '159px' }} icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(AnonymousRegistrationResidentAddEditModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});

const validationRules = {
  firstName: [{ required: true, message: 'First name is required!' }],
  lastName: [{ required: true, message: 'Last name is required!' }],
  type: [{ required: true, message: 'Type is required!' }],
  requiredEmail: [{ required: true, message: ValidationMessages.Email.NotValidMessage, type: 'email' as RuleType }],
  email: [{ message: ValidationMessages.Email.NotValidMessage, type: 'email' as RuleType }],
  pin: [{ required: true, message: 'Pin is required!' }],
  phone: [{ required: false, message: 'Phone number is required!' }, phoneValidationRule],
  rentStartDate: [{ required: true, message: 'Rent start date is required!' }],
  rentEndDate: [{ required: true, message: 'Rent end date is required!' }],
  relatedTo: [{ required: true, message: 'Parent resident is required!' }],
  vacationStartDate: [{ required: true, message: 'Vacation start date is required!' }],
  vacationEndDate: [{ required: true, message: 'Vacation end date is required!' }],
};
