import React from 'react';
import { observer } from 'mobx-react-lite';
import { TableContextProvider, useTableControls } from 'common/components/table/table-context';
import { Col, Row } from 'antd';
import TableBase from 'common/components/table/table-base';
import { SearchFilter } from '../../../../common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from '../../../../common/enums/filter-types.enum';

import { eiqSecureVehiclesInitialColumns } from '../../eiq-secure.config';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import AlertBlock from 'common/components/info-block/alert-block/alert-block';

export const EiqSecureVehiclesList = observer(() => {
  const tableControls = useTableControls({
    onFetch: vehicleService.getVehicles,
    initialColumns: eiqSecureVehiclesInitialColumns,
  });

  return (
    <>
      <Row>
        <Col xs={24}>
          <AlertBlock
            isMedical={false}
            alertMessage={
              'This search is only for resident vehicles. If you are searching for a guest vehicle, please use the Traffic tab.'
            }
          />
        </Col>
      </Row>
      <br />
      <TableContextProvider controls={tableControls}>
        <>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                className="full-width"
                placeholder="Search by license plate number, address, resident, transponder ID"
                rulesForColumn={{
                  owner: FilterTypesEnum.Contains,
                  licenseNumber: FilterTypesEnum.Contains,
                  registrationAddress: FilterTypesEnum.Contains,
                  rfid: FilterTypesEnum.Contains,
                  residentAddress_address_streetNumber: FilterTypesEnum.StartWith,
                  residentAddress_address_street_name: FilterTypesEnum.StartWith,
                }}
              />
            </Col>
          </Row>
          <TableBase selectFirstRowByDefault minHeight={'70vh'} />
        </>
      </TableContextProvider>
    </>
  );
});
