import { formatAddressLabel } from '../helpers/address.helper';
import { ICellRendererProps } from '../components/table/table.types';
import { IAddress } from 'common/services/street-service/street.service.types';

export const addressesManagementColumns = () => ({
  addressDetails: {
    key: 'address',
    title: 'Address details',
    dataKey: 'address',
    width: 520,
    sortable: false,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IAddress>) => formatAddressLabel(cell.rowData, { hideStreet: true }),
  },
  streetName: {
    key: 'street_name',
    title: 'Street name',
    dataKey: 'street.name',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
});
