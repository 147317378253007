import React, { FC } from 'react';
import { Button, Modal, Space } from 'antd';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { IModal } from 'common/models/model.interface';
import './general-info-delete-modal.scss';
import addressesService from 'common/services/addresses-service/addresses.service';
import { notification } from 'common/utils/notification';
import { DeleteModalVariantsEnum } from './delete-modal-variants.enum';
import { IGeneralInfoDeleteModalInitData } from '../general-info.types';

const GeneralDeleteModal: FC<IModal<IGeneralInfoDeleteModalInitData, any>> = ({ isOpen, onClose, title, initData }) => {
  const hideModal = (isSuccess: boolean, variant?: DeleteModalVariantsEnum) => {
    notification.destroy();
    if (onClose) {
      onClose(isSuccess, variant);
    }
  };

  const handleOnDeleteButtonClick = () => {
    addressesService
      .deleteAddresses(initData!.id, initData!.addressId)
      .then((_res) => {
        hideModal(true);
        notification.success({ message: `Address was successfully deleted` });
      })
      .catch((error) => {});
  };

  return (
    <Modal centered title={title} width={450} open={isOpen} wrapClassName="custom-modal general-info-delete-modal" closable={false}>
      <Space className="body">
        {initData!.deleteModalVariant === DeleteModalVariantsEnum.WithoutEntities ? (
          <span>
            <span>Are you sure you want to delete </span>
            {initData?.addressName ? <span className="streetName">{initData?.addressName}</span> : <span>this address </span>}
            <span> from an addresses list?</span>
          </span>
        ) : (
          <span>You can't delete a street address when there are residents, vehicles or pets linked to it.</span>
        )}
      </Space>
      <Space className="footer">
        {initData!.deleteModalVariant === DeleteModalVariantsEnum.WithoutEntities ? (
          <>
            <Button type="link" onClick={() => hideModal(false)} className="button">
              Cancel
            </Button>
            <Button type="primary" onClick={handleOnDeleteButtonClick} className="button" icon={<DeleteIcon stroke="#F6F8FA" />}>
              Delete
            </Button>
          </>
        ) : (
          <>
            <Button type="link" onClick={() => hideModal(false, initData!.deleteModalVariant)} className="button">
              Delete with linked entities
            </Button>
            <Button type="primary" onClick={() => hideModal(false)} className="button">
              Cancel
            </Button>
          </>
        )}
      </Space>
    </Modal>
  );
};

export default React.memo(GeneralDeleteModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
