import React, { FC } from 'react';
import { Button, Modal, Space } from 'antd';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import styles from './street-delete-modal.module.scss';
import { IModal } from 'common/models/model.interface';
import addressesService from 'common/services/addresses-service/addresses.service';
import { notification } from 'common/utils/notification';
import { DeleteModalVariantsEnum } from '../info-block/general-info/general-info-delete-modal/delete-modal-variants.enum';

interface IStreetDeleteModalInitData {
  id: number;
  name: string;
  deleteModalVariant: DeleteModalVariantsEnum;
}

const StreetDeleteModal: FC<IModal<IStreetDeleteModalInitData, any>> = ({ isOpen, onClose, title, initData }) => {
  const hideModal = (isSuccess: boolean, variant?: DeleteModalVariantsEnum) => {
    notification.destroy();
    if (onClose) {
      onClose(isSuccess, variant);
    }
  };

  const handleOnDeleteButtonClick = () => {
    addressesService
      .deleteStreet(initData!.id)
      .then((_res) => {
        hideModal(true);
        notification.success({ message: `${initData?.name} street was successfully deleted` });
      })
      .catch((error) => {});
  };

  return (
    <Modal
      centered
      title={title}
      width={initData!.deleteModalVariant === DeleteModalVariantsEnum.WithoutEntities ? 350 : 450}
      open={isOpen}
      wrapClassName="custom-modal"
      closable={false}>
      <Space className={styles.streetDeleteModalBody}>
        {initData!.deleteModalVariant === DeleteModalVariantsEnum.WithoutEntities ? (
          <span>
            <span>Are you sure you want to delete </span>
            <span className={styles.streetName}>{initData?.name}</span>
            <span> from the streets list?</span>
          </span>
        ) : (
          <span>You can't delete a street when there are addresses, residents, vehicles or pets linked to it.</span>
        )}
      </Space>
      <Space className="footer">
        {initData!.deleteModalVariant === DeleteModalVariantsEnum.WithoutEntities ? (
          <>
            <Button type="link" onClick={() => hideModal(false)} className={styles.button}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleOnDeleteButtonClick} className={styles.button} icon={<DeleteIcon stroke="#F6F8FA" />}>
              Delete
            </Button>
          </>
        ) : (
          <>
            <Button type="link" onClick={() => hideModal(false, initData!.deleteModalVariant)} className={styles.buttonFullWidth}>
              Delete with linked entities
            </Button>
            <Button type="primary" onClick={() => hideModal(false)} className={styles.buttonFullWidth}>
              Cancel
            </Button>
          </>
        )}
      </Space>
    </Modal>
  );
};

export default React.memo(StreetDeleteModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
