import { Button, DatePicker, Form, Input, Modal, Select, Space, Switch } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { IModal } from 'common/models/model.interface';
import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { notification } from 'common/utils/notification';
import { observer } from 'mobx-react-lite';
import { IAddEditAccessCardModal } from '../access-card-list.types';
import { addEditAccessCardValidation } from './add-edit-access-card-modal.validation';
import accessCardService from 'common/services/access-card-service/access-card.service';
import { ICreateUpdateAccessCard } from 'common/services/access-card-service/access-card.service.types';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import configurationStore from 'common/stores/configuration/configuration.store';
import featureStore from 'common/stores/features/feature.store';

const AddEditAccessCardModal: FC<IModal<IAddEditAccessCardModal, any>> = observer(({ isOpen, onClose, title, initData, isEdit }) => {
  const [form] = Form.useForm();

  const deactivationOptions =
    configurationStore.configuration?.vehicleDeactivationReasons.map((reason) => ({ value: reason, label: reason })) ?? [];
  const cardClassOptions = configurationStore.configuration?.cardClasses?.map((i) => ({ value: i.name, label: i.name })) ?? [];
  const [isDeactivated, setIsDeactivated] = useState<boolean>();

  const hideModal = (...rest: any) => {
    notification.destroy();
    form.resetFields();
    if (onClose) {
      onClose(...rest);
    }
  };

  useEffect(() => {
    setIsDeactivated(initData?.isDeactivated ?? false);
  }, [initData?.isDeactivated, isOpen]);

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData);
    }
  }, [isOpen, form, initData]);

  const { TextArea } = Input;

  const onFinish = async (values: any) => {
    const modal: ICreateUpdateAccessCard = {
      owner: values.owner,
      deviceId: values.deviceId,
      facilityCode: values.facilityCode,
      additionalInformation: values.additionalInformation,
      registrationAddress: values.registrationAddress,
      validFrom: values.validFrom?.format('YYYY-MM-DD') ?? null,
      validTo: values.validTo?.format('YYYY-MM-DD') ?? null,
      isDeactivated: isDeactivated || false,
      deactivationReason: values.deactivationReason || null,
      cardClass: values.cardClass,
    };

    if (isEdit) {
      const result = await accessCardService.update(initData!.residentId, initData!.addressId, initData!.id!, modal);
      hideModal(true, result);
    } else {
      const result = await accessCardService.create(initData!.residentId, initData!.addressId, modal);
      hideModal(true, result);
    }
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} initialValues={initData} layout="vertical">
        <Space className="full-width" size={13}>
          <Form.Item name="owner" label="Owner" rules={addEditAccessCardValidation.owner}>
            <Input name="owner" placeholder="Enter owner" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="facilityCode" label="Facility code" rules={addEditAccessCardValidation.facilityCode}>
            <Input name="facilityCode" placeholder="Enter facility code" />
          </Form.Item>
          <Form.Item name="deviceId" label="Device ID" rules={addEditAccessCardValidation.deviceId}>
            <Input name="deviceId" placeholder="Enter device ID" type="number" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="additionalInformation" label="Description" rules={addEditAccessCardValidation.additionalInformation}>
            <TextArea name="additionalInformation" placeholder="Enter description" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="registrationAddress" label="Address of registration" rules={addEditAccessCardValidation.registrationAddress}>
            <Input name="registrationAddress" placeholder="Enter address of registration" />
          </Form.Item>
        </Space>
        {isEdit && (
          <Space className="full-width" size={13}>
            <Form.Item name="isDeactivated" label="Deactivated" valuePropName="checked">
              <Switch
                checked={isDeactivated}
                onChange={(isDeactivated) => {
                  setIsDeactivated(isDeactivated);
                }}
              />
            </Form.Item>
            {isDeactivated && (
              <Form.Item name="deactivationReason" label="Reason" rules={addEditAccessCardValidation.deactivationReason}>
                <Select options={deactivationOptions} />
              </Form.Item>
            )}
          </Space>
        )}
        {featureStore.isCA3000ExpirationsEnabled && (
          <Space className="full-width" size={13}>
            <Form.Item name="validFrom" label="Valid from">
              <DatePicker placeholder="Start date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
            <Form.Item name="validTo" label="Valid to" dependencies={['validFrom']} rules={addEditAccessCardValidation.validTo}>
              <DatePicker placeholder="End date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
          </Space>
        )}
        {featureStore.isCA3000CardClassesEnabled && (
          <Space className="full-width" size={13}>
            <Form.Item name="cardClass" label="Card class">
              <Select options={cardClassOptions} placeholder="Select a card class" />
            </Form.Item>
          </Space>
        )}

        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className="eiq-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
});

export default React.memo(AddEditAccessCardModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
