import { observer } from 'mobx-react-lite';
import { Layout, Menu } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MenuProps } from 'antd/lib';
const { Sider, Content } = Layout;

export const AdminVehicles = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const menuItems: MenuProps['items'] = [
    {
      label: 'All Vehicles',
      key: '/eiq-manage/vehicles/all',
    },
    {
      label: 'Vehicles Waiting for Approval',
      key: '/eiq-manage/vehicles/approvals',
    },
    {
      label: 'Other devices',
      key: '/eiq-manage/vehicles/devices',
    },
  ];

  return (
    <Layout hasSider className="accounts-layout">
      <Sider className="sider-wrapper">
        <Menu
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={[location.pathname]}
          items={menuItems}
          className="sider-tabs"
          onClick={(tab) => navigate(tab.key)}
        />
      </Sider>
      <Content className="content-style">
        <Outlet />
      </Content>
    </Layout>
  );
});
