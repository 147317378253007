import React from 'react';
import { makeAutoObservable, toJS } from 'mobx';

import {
  IAccessCardRead,
  IGuestRead,
  IPetRead,
  IResidentAddressRead,
  IResidentAddresses,
  IResidentDetails,
  IVehicleRead,
} from '../../../common/services/residents-service/residents.service.types';
import { IAddressOccupant } from '../../../common/services/guest-service/guest.service.types';

class ResidentStore {
  selectedResidentAddress: IResidentAddressRead | null;
  selectedResident: IResidentDetails | null;
  occupantsByAddress: IAddressOccupant[] | null;

  constructor() {
    makeAutoObservable(this);
    this.selectedResidentAddress = null;
    this.occupantsByAddress = [];
    this.selectedResident = null;
  }

  setCurrentRow(resident: IResidentAddressRead | null) {
    this.selectedResidentAddress = resident;
  }

  setResident(resident: IResidentDetails | null) {
    this.selectedResident = resident;
  }

  setOccupantsByAddress(occupantsByAddress: IAddressOccupant[] | null) {
    this.occupantsByAddress = occupantsByAddress;
  }

  get residentId() {
    return this.selectedResidentAddress?.resident.id;
  }

  get addressId() {
    return this.selectedResidentAddress?.address.id;
  }

  get accessCard() {
    return toJS(
      this.selectedResident?.addresses?.find((a) => a.address.id === this.selectedResidentAddress?.address.id)?.accessCards || [],
    );
  }

  get pets() {
    return toJS(this.selectedResident?.addresses?.find((a) => a.address.id === this.selectedResidentAddress?.address.id)?.pets || []);
  }

  get guests() {
    return toJS(this.selectedResident?.addresses?.find((a) => a.address.id === this.selectedResidentAddress?.address.id)?.guests || []);
  }

  get vehicles() {
    return toJS(this.selectedResident?.addresses?.find((a) => a.address.id === this.selectedResidentAddress?.address.id)?.vehicles || []);
  }

  get occupants() {
    return this.occupantsByAddress?.map((item, index) => ({
      ...item,
      id: index,
    }));
  }

  get addresses() {
    return this.selectedResident?.addresses?.map((item, index) => ({
      ...item,
      id: index,
    }));
  }

  get currentAddress() {
    return this.selectedResident?.addresses?.find((a) => a.address.id === this.selectedResidentAddress?.address?.id);
  }

  updateGuest(guest: IGuestRead) {
    if (!this.currentAddress) {
      return;
    }

    const index = this.currentAddress.guests.findIndex((i) => i.id === guest.id);
    if (index === -1) {
      return;
    }
    this.currentAddress.guests[index] = guest;
  }

  addGuest(guest: IGuestRead) {
    if (this.currentAddress?.guests) {
      this.currentAddress.guests.push(guest);
    }
  }

  deleteGuest(id: number) {
    if (!this.currentAddress) {
      return;
    }
    this.currentAddress.guests = this.currentAddress.guests.filter((i) => i.id !== id);
  }

  updatePet(pet: IPetRead) {
    if (!this.currentAddress) {
      return;
    }
    const index = this.currentAddress.pets.findIndex((i) => i.id === pet.id);
    if (index === -1) {
      return;
    }
    this.currentAddress.pets[index] = pet;
  }

  addPet(pet: IPetRead) {
    if (this.currentAddress?.pets) {
      this.currentAddress.pets.push(pet);
    }
  }

  deletePet(id: number) {
    if (!this.currentAddress) {
      return;
    }
    this.currentAddress.pets = this.currentAddress.pets.filter((i) => i.id !== id);
  }

  updateVehicle(vehicle: IVehicleRead) {
    if (!this.currentAddress) {
      return;
    }

    const index = this.currentAddress.vehicles.findIndex((i) => i.id === vehicle.id);
    if (index === -1) {
      return;
    }
    this.currentAddress.vehicles[index] = vehicle;
  }

  addVehicle(vehicle: IVehicleRead) {
    if (this.currentAddress?.vehicles) {
      this.currentAddress.vehicles.push(vehicle);
    }
  }

  deleteVehicle(id: number) {
    if (!this.currentAddress) {
      return;
    }
    this.currentAddress.vehicles = this.currentAddress.vehicles.filter((i) => i.id !== id);
  }

  deleteAddress(id: number) {
    if (!this.selectedResident?.addresses) {
      return;
    }
    this.selectedResident.addresses = this.selectedResident.addresses.filter((i) => i.address.id !== id);
  }

  addAddress(address: IResidentAddresses) {
    if (this.selectedResident?.addresses) {
      this.selectedResident.addresses.push(address);
    }
  }

  updateAccessCard(accessCard: IAccessCardRead) {
    if (!this.currentAddress) {
      return;
    }
    const index = this.currentAddress.accessCards.findIndex((i) => i.id === accessCard.id);
    if (index === -1) {
      return;
    }
    this.currentAddress.accessCards[index] = accessCard;
  }

  addAccessCard(accessCard: IAccessCardRead) {
    if (this.currentAddress?.accessCards) {
      this.currentAddress.accessCards.push(accessCard);
    }
  }

  deleteAccessCard(id: number) {
    if (!this.currentAddress) {
      return;
    }
    this.currentAddress.accessCards = this.currentAddress.accessCards.filter((i) => i.id !== id);
  }
}

const residentStore = new ResidentStore();
export const ResidentStoreContext = React.createContext<ResidentStore>(residentStore);
export default residentStore;
