import { phoneValidationRule } from '../../../../common/validation/phone-number-rule';

export const eiqSecureGuestLicenseValidation = {
  gate: [{ required: true, message: 'Gate is required!' }],
  phoneNumber: [{ required: true, message: 'Phone number is required!' }, phoneValidationRule],
  keyNumber: [{ required: true, message: 'Key number is required!' }],
  carLicensePlate: [{ required: true, message: 'License is required!' }],
  carState: [{ required: true, message: 'License state is required!' }],
  driverCompanyName: [{ required: true, message: 'Company name is required!' }],
};
