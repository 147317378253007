import { occupantsColumns } from 'common/list-configs/occupants.config';
import { petsColumns } from 'common/list-configs/pets.config';
import { vehiclesColumns } from 'common/list-configs/vehicles.config';

const { name, ownershipType } = occupantsColumns();
const { owner, year, deviceId, color, make, model, licenseNumber } = vehiclesColumns();
const { name: petName, type, breed, color: petColor, tag } = petsColumns();

export const residentMoveModalOccupantsInitialColumns = [
  { ...name, width: 380 },
  { ...ownershipType, width: 380 },
];

export const occupantsMoveModalOccupantsInitialColumns = [
  { ...name, width: 300 },
  { ...ownershipType, width: 300 },
];

export const residentMoveModalVehiclesInitialColumns = [
  { ...owner, width: 130 },
  { ...year, width: 50 },
  { ...deviceId, width: 129 },
  { ...color, width: 129 },
  { ...make, width: 129 },
  { ...model, width: 129 },
  { ...licenseNumber, width: 130 },
];

export const residentMoveModalPetsInitialColumns = [
  { ...petName, width: 164 },
  { ...type, width: 164 },
  { ...breed, width: 164 },
  { ...petColor, width: 164 },
  { ...tag, width: 164 },
];
