import { Button, Form } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

export const CheckYourEmail = ({ onResend, email }: any) => {
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    console.log('Received values:', values);
  };

  return (
    <Form onFinish={onFinish} name="validateOnly" layout="vertical" autoComplete="off" initialValues={{ remember: true }}>
      <div className="title">Check your email</div>
      <span className="text ant-mb-32">We sent a password link to {email}</span>

      <div className="action-line ant-mb-32">
        <Button type="primary" style={{ width: '216px' }} onClick={() => navigate('/login')} htmlType="submit" icon={<ArrowLeftOutlined />}>
          Back to log in
        </Button>
      </div>

      <span className="text text-center">
        Didn’t receive the link?{' '}
        <Button type="link" size="small" onClick={onResend}>
          Click to resend
        </Button>
      </span>
    </Form>
  );
};
