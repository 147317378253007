import { validityMap } from 'common/constans/common.constants';
import { GuestValidity } from 'common/enums/guest-validity.enum';

export const validityOptions = [
  { value: GuestValidity.OneTime, label: validityMap[GuestValidity.OneTime] },
  { value: GuestValidity.Permanent, label: validityMap[GuestValidity.Permanent] },
  { value: GuestValidity.Today, label: validityMap[GuestValidity.Today] },
  { value: GuestValidity.Tomorrow, label: validityMap[GuestValidity.Tomorrow] },
  { value: GuestValidity.DateRange, label: validityMap[GuestValidity.DateRange] },
];
