import { UserTypeEnum } from 'common/enums/user-type.enum';
import { ServerClient } from 'common/utils/http/http-clients';

declare global {
  interface Window {
    zE: (operation: string | Function, ...args: any[]) => void;
  }
}

class ZendeskService {
  logout(): void {
    if (!window.zE) {
      return;
    }

    window.zE('messenger', 'logoutUser');
    window.zE('messenger', 'hide');
  }

  async login(userType: UserTypeEnum, community: string): Promise<void> {
    if (!window.zE) {
      return;
    }

    const res = await ServerClient.get<string>(`/zendesk/messaging/jwt`);
    let token = res?.data;

    window.zE('messenger', 'loginUser', function (callback: any) {
      callback(token);

      window.zE('messenger:set', 'conversationFields', [
        { id: process.env.REACT_APP_ZENDESK_COMMUNITY_FIELD_ID, value: community || '' },
        { id: process.env.REACT_APP_ZENDESK_USER_TYPE_FIELD_ID, value: userType?.toString() || '' },
      ]);

      const siteType = userType === UserTypeEnum.Admin || userType === UserTypeEnum.SuperAdmin ? 'manage' : 'secure';

      window.zE('messenger:set', 'conversationTags', [siteType]);
      window.zE('messenger', 'show');
    });
  }
}

export const zenDeskService = new ZendeskService();
export default zenDeskService;
