export const addEditGuestValidation = {
  firstName: [{ required: true, message: 'First name is required!' }],
  lastName: [{ required: true, message: 'Last name is required!' }],
  type: [{ required: true, message: 'Type is required!' }],
  validity: [{ required: true, message: 'Validity is required!' }],
  startDate: [{ required: true, message: 'Start date is required!' }],
  endDate: [
    { required: true, message: 'End date is required!' },
    ({ getFieldValue }: any) => ({
      validator: (_: any, value: string) => {
        const startDate = getFieldValue('startDate');
        if (value && value < startDate) {
          return Promise.reject(new Error('End date must be greater than start date'));
        }
        return Promise.resolve();
      },
    }),
  ],
  daysOfWeek: [{ required: true, message: 'Days of week for visits is required!' }],
  companyName: [{ required: true, message: 'Company name is required!' }],
  visitDate: [{ required: true, message: 'Visit date is required!' }],
  addressId: [{ required: true, message: 'Address is required!' }],
};
