import { PhoneType } from 'common/enums/phone-type.enum';

export const phoneTypeMap: Record<PhoneType, string> = {
  [PhoneType.Primary]: 'Primary',
  [PhoneType.Mobile]: 'Mobile',
  [PhoneType.Work]: 'Work',
  [PhoneType.Emergency]: 'Emergency',
  [PhoneType.Home]: 'Home',
  [PhoneType.Other]: 'Other',
};

export const phoneTypeOptions = [
  { value: PhoneType.Primary, label: phoneTypeMap[PhoneType.Primary] },
  { value: PhoneType.Mobile, label: phoneTypeMap[PhoneType.Mobile] },
  { value: PhoneType.Home, label: phoneTypeMap[PhoneType.Home] },
  { value: PhoneType.Work, label: phoneTypeMap[PhoneType.Work] },
  { value: PhoneType.Emergency, label: phoneTypeMap[PhoneType.Emergency] },
  { value: PhoneType.Other, label: phoneTypeMap[PhoneType.Other] },
];
