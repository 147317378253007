import React, { PropsWithChildren } from 'react';
import { IFeatureProps } from './feature.types';
import featureStore from 'common/stores/features/feature.store';
import { observer } from 'mobx-react-lite';

const Feature = ({ name, children }: PropsWithChildren<IFeatureProps>) => {
  return <>{featureStore.IsEnabled(name) && children}</>;
};

export default observer(Feature);
