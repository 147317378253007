import { UploadFile } from 'antd';
import { IIncidentAttachment } from 'common/services/incident-report/incident-report.types';

export interface IIncidentAttachmentUploadFormProps {
  onChange: (attachments: IIncidentAttachment[]) => void;
  initialValue?: IIncidentAttachment[];
}

export const convertIncidentAttachmentToUploadFile = (attachemnt: IIncidentAttachment): UploadFile<IIncidentAttachment> => {
  return {
    uid: attachemnt.fileUri,
    name: attachemnt.fileName,
    status: 'done',
    response: attachemnt,
    url: attachemnt.fileUri,
    thumbUrl: attachemnt.fileUri,
  };
};

export const convertToIncidentAttachment = (file: UploadFile<IIncidentAttachment>): IIncidentAttachment => {
  return file.response as IIncidentAttachment;
};
