import React, { FC } from 'react';
import { IAlertBlock } from './alert-block.types';
import './alert-block.scss';

const AlertBlock: FC<IAlertBlock> = ({ isMedical, alertMessage }) => {
  return (
    <div className={`alert-block-container ${isMedical ? 'med' : ''}`}>
      <span>{isMedical ? 'Medical alert' : 'Alert'}</span>
      <span className={`${alertMessage && alertMessage?.length > 0 ? '' : ''}`}>{alertMessage ?? 'No alert'}</span>
    </div>
  );
};

export default AlertBlock;
