import React, { FC } from 'react';
import { IEiqPhoneProps } from './phone-field.types';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import styles from './phone-field.module.scss';
import { formatPhone } from 'common/helpers/phone.helper';

const EiqPhone: FC<IEiqPhoneProps> = ({ phone, labels, className, order }) => {
  return (
    <div className={`${styles.eiqPhoneField} ${className ?? ''}`}>
      <PhoneIcon />
      {order && <span className={`${styles.orderNumber}`}>[{order}]</span>}
      <span className="phone-number">{formatPhone(phone)}</span>
      {labels.map((label, index) => {
        return (
          <span className={`eiq-label ${label.class}`} key={`eiq-phone-label-${index}`}>
            {label.value}
          </span>
        );
      })}
    </div>
  );
};

export default EiqPhone;
