import TableContextProvider, { useTableContext, useTableControls } from '../../../../../../../common/components/table/table-context';
import { Button, Col, Row, Space } from 'antd';
import { SearchFilter } from '../../../../../../../common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from '../../../../../../../common/enums/filter-types.enum';
import { PlusOutlined } from '@ant-design/icons';
import TableBase from '../../../../../../../common/components/table/table-base';
import React from 'react';
import { IResidentAddresses, IResidentDetails } from 'common/services/residents-service/residents.service.types';
import { addressesInitialColumns } from './addresses.config';
import { observer } from 'mobx-react-lite';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { useModal } from '../../../../../../../common/hooks/use-modal/use-modal.hook';
import ConfirmDeleteModal from 'common/components/modal/confirm-delete-modal/confirm-delete-modal';
import { IConfirmDeleteModalProps } from '../../../../../../../common/components/modal/confirm-delete-modal/confirm-delete-modal.types';
import residentStore from '../../../../../stores/resident.store';
import residentsService from '../../../../../../../common/services/residents-service/residents.service';
import AddAddressModal from './add-address-modal/add-address-modal';
import featureStore from 'common/stores/features/feature.store';

export const Addresses = observer(() => {
  const context = useTableContext();
  const isEditable = !featureStore.isNorthStar;

  // Table config
  const tableControls = useTableControls({
    clientSide: true,
    data: residentStore.addresses,
    initialColumns: addressesInitialColumns,
  });

  const getDeleteMessage = () => {
    return <span>Are you sure you want to delete this address from the addresses list?</span>;
  };

  const deleteAddressModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      const residentId = residentStore.residentId;
      const addressId = residentStore.addressId;

      if (!residentId || !addressId) {
        console.error(`Resident is not selected`);
        return;
      }

      const data: IConfirmDeleteModalProps = {
        message: getDeleteMessage(),
        onDelete: () => {
          residentsService.deleteResidentAddress(residentId, addressId).then((result) => {
            deleteAddressModal.hideModal(true);
          });
        },
      };

      setModalInitData(data);
    },
    onClose: (isSuccess: boolean | undefined) => {
      if (isSuccess) {
        const selectedRow: IResidentAddresses = tableControls.getSelectedRow();
        residentStore.deleteAddress(selectedRow.address.id);
        tableControls.setSelectedRow(null);
        context.refetchData();
      }
    },
  });

  const addAddressModal = useModal({
    onBeforeShow: () => {
      const selectedRow: IResidentAddresses = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }
      if (!residentStore.residentId) {
        console.error('Resident must be selected');
        return;
      }
    },
    onClose: (isSuccessSaved, details: IResidentDetails) => {
      if (isSuccessSaved) {
        const addressToAdd = details.addresses.filter(
          (i) => !residentStore.selectedResident?.addresses.map((x) => x.address.id).includes(i.address.id),
        )[0];
        residentStore.addAddress(addressToAdd);
        context.refetchData();
      }
    },
  });

  return (
    <div>
      <TableContextProvider controls={tableControls}>
        <div>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                adaptiveSearch={true}
                enableProgressiveSearch={true}
                placeholder="Search by address"
                rulesForColumn={{
                  'address.street.name': FilterTypesEnum.Contains,
                  'address.streetNumber': FilterTypesEnum.Contains,
                  'address.blockNumber': FilterTypesEnum.Contains,
                  'address.unit': FilterTypesEnum.Contains,
                  'address.lotNumber': FilterTypesEnum.Contains,
                  'address.buildingNumber': FilterTypesEnum.Contains,
                  'address.name': FilterTypesEnum.Contains,
                }}
                rightSide={
                  isEditable ? (
                    <Space size={8}>
                      <Button
                        icon={<DeleteIcon stroke="#505762" />}
                        size="middle"
                        className="mobile-btn"
                        onClick={deleteAddressModal.showModal}
                        disabled={!tableControls.getSelectedRow()}>
                        Delete
                      </Button>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        size="middle"
                        className="mobile-btn"
                        disabled={!residentStore.selectedResidentAddress}
                        onClick={addAddressModal?.showModal}>
                        Add
                      </Button>
                    </Space>
                  ) : null
                }
              />
            </Col>
          </Row>
          <TableBase onSelected={tableControls.setSelectedRow} selectFirstRowByDefault />
        </div>
      </TableContextProvider>
      <ConfirmDeleteModal
        isEdit={false}
        initData={deleteAddressModal?.initData}
        title="Delete"
        isOpen={deleteAddressModal?.isOpenModal}
        onClose={deleteAddressModal?.hideModal}
      />
      <AddAddressModal title="Add" isOpen={addAddressModal?.isOpenModal} onClose={addAddressModal?.hideModal} />
    </div>
  );
});
