import { ServerClient } from 'common/utils/http/http-clients';
import { IKeyRead, IKeyCreateUpdate, IKeyActivitiesRead } from './addresses-key.service.types';
import { IResponseList } from 'common/models/response-list.interface';

class AddressesKeyService {
  async getKeys(pageLimit: any, filter: string | null | undefined, sort: string | null | undefined): Promise<IResponseList<IKeyRead>> {
    const res = await ServerClient.get<IResponseList<IKeyRead>>('/keys', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async createKey(addressId: number, requestBody: IKeyCreateUpdate): Promise<IKeyRead> {
    const res = await ServerClient.post<IKeyRead>(`/addresses/${addressId}/keys`, requestBody);
    return res?.data;
  }

  async updateKey(addressId: number, keyNumber: string, requestBody: IKeyCreateUpdate): Promise<IKeyRead> {
    const res = await ServerClient.patch<IKeyRead>(`/addresses/${addressId}/keys/${keyNumber}`, requestBody);
    return res?.data;
  }

  async deleteKey(addressId: number, keyNumber: string): Promise<void> {
    const res = await ServerClient.delete(`/addresses/${addressId}/keys/${keyNumber}`);
    return res?.data;
  }

  async collectKey(keyNumber: string, requestBody: any): Promise<any> {
    const res = await ServerClient.put<any>(`/keys/${keyNumber}/collect`, requestBody);
    return res?.data;
  }

  async getKeysActivities(
    pageLimit: any,
    filter: string | null | undefined,
    sort: string | null | undefined,
  ): Promise<IResponseList<IKeyActivitiesRead>> {
    const res = await ServerClient.get<IResponseList<IKeyActivitiesRead>>('/keys/activities', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }
}

export const addressesService = new AddressesKeyService();
export default addressesService;
