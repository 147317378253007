import { ValidationMessages } from 'common/validation/validation-messages';

export const loginValidation = {
  login: [{ required: true, message: 'Please enter your email or account name!' }],
  password: [{ required: true, message: 'Please enter your password!' }],
};

export const forgotPasswordValidation = {
  email: [{ required: true, message: 'Please enter your email!' }],
};

export const setNewPasswordValidation = {
  password: [
    { required: true, message: 'Please enter your password!' },
    { min: 8, message: ValidationMessages.Password.RequiredLengthMessage },
  ],
  password2: [
    { required: true, message: 'Please enter your password!' },
    ({ getFieldValue }: any) => ({
      validator: (_: any, value: string) => {
        const passwordValue = getFieldValue('password');
        if (!value || passwordValue === value) {
          return Promise.resolve();
        }
        return Promise.reject("Passwords don't match.");
      },
    }),
  ],
};
