import { Row, Col, Space } from 'antd';
import InfoField from 'common/components/info-block/info-field/info-field';
import { IncidentEditButton } from '../../incident-edit-button/incident-edit-button';
import { IIncidentReportingPersionViewProps } from './incident-reporting-person-view.types';

const IncidentReportingPersonView = ({ incident, onEdit }: IIncidentReportingPersionViewProps) => {
  return (
    <div style={{ border: '1px solid #d9d9d9', borderRadius: '8px', padding: '16px' }}>
      <Row gutter={[32, 16]}>
        <Col xs={24} sm={12}>
          <Space direction="vertical" size="middle" className="full-width">
            <InfoField value={incident.reportingPerson} label="Reporting Officer" bodyClassName="reporting-person-field" />
          </Space>
        </Col>
      </Row>

      <IncidentEditButton onEdit={onEdit} />
    </div>
  );
};

export default IncidentReportingPersonView;
