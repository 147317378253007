import { Row, Space, Button } from 'antd';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import React from 'react';
import { IIncidentViewModeActionsProps } from './incident-view-mode-actions.types';

const IncidentViewModeActions = ({ onDelete, onEdit }: IIncidentViewModeActionsProps) => {
  return (
    <Row className="incidentViewModeActions" justify="start">
      <Space size={12}>
        <Button type="primary" danger icon={<DeleteIcon stroke="#F6F8FA" />} onClick={onDelete}>
          Delete
        </Button>
        <Button icon={<EditIcon />} onClick={onEdit}>
          Edit
        </Button>
      </Space>
    </Row>
  );
};

export default IncidentViewModeActions;
