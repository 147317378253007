import TableContextProvider, { useTableControls } from '../../../../../../../common/components/table/table-context';
import { Button, Col, Row, Space } from 'antd';
import { SearchFilter } from '../../../../../../../common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from '../../../../../../../common/enums/filter-types.enum';
import { PlusOutlined } from '@ant-design/icons';
import TableBase from '../../../../../../../common/components/table/table-base';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { IGuestRead } from '../../../../../../../common/services/residents-service/residents.service.types';
import residentStore from '../../../../../stores/resident.store';
import { guestInitialColumns } from './guest-list.config';
import { observer } from 'mobx-react-lite';
import { ColumnCheckFilter } from '../../../../../../../common/components/table/filters/column-check-filter/column-check-filter';
import { guestTypeOptions } from '../../../../../../../common/constans/guest-type-options.constant';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import AddEditGuestModal from './add-edit-guest-madal/add-edit-guest-modal';
import { IAddEditGuestModal } from './guest-list.types';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import ConfirmDeleteModal, { ConfirmDeleteItemName } from 'common/components/modal/confirm-delete-modal/confirm-delete-modal';
import { IConfirmDeleteModalProps } from 'common/components/modal/confirm-delete-modal/confirm-delete-modal.types';
import guestService from 'common/services/guest-service/guest.service';
import { StrToDate, StrToDateTimeLocal } from 'common/helpers/date.helper';
import { mapToGuestValidity } from 'common/helpers/guest-validity.helper';
import configurationStore from 'common/stores/configuration/configuration.store';
import { ReactComponent as ImportIcon } from 'assets/icons/import.svg';
import { notification } from 'common/utils/notification';
import residentsService from 'common/services/residents-service/residents.service';

export const getDeleteMessage = (name: string) => {
  return (
    <span>
      <span>Are you sure you want to delete </span>
      <ConfirmDeleteItemName name={name} />
      <span> from the guest list?</span>
    </span>
  );
};

export const GuestList = observer(() => {
  const inputRef = useRef<any>(null);

  // Table config
  const tableControls = useTableControls({
    clientSide: true,
    data: residentStore.guests,
    initialColumns: guestInitialColumns,
  });

  useEffect(() => {
    tableControls.setSelectedRow(null);
  }, [residentStore.selectedResident?.id]);

  const deleteGuestModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      if (!residentStore.selectedResidentAddress) {
        console.error(`Resident is not selected`);
        return;
      }

      const guestName =
        selectedRow.firstName && selectedRow.lastName ? `${selectedRow.firstName} ${selectedRow.lastName}` : selectedRow.companyName || '';

      const data: IConfirmDeleteModalProps = {
        message: getDeleteMessage(guestName),
        onDelete: () => {
          guestService.deleteGuest(residentStore.residentId!, residentStore.addressId!, selectedRow.id).then((result) => {
            deleteGuestModal.hideModal(true);
          });
        },
      };

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        const selectedRow: IGuestRead = tableControls.getSelectedRow();
        residentStore.deleteGuest(selectedRow.id);
        tableControls.setSelectedRow(null);
      }
    },
  });

  const editGuestModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow: IGuestRead = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      if (!residentStore.selectedResidentAddress) {
        console.error(`Resident is not selected`);
        return;
      }

      const initData: IAddEditGuestModal = {
        id: selectedRow.id,
        firstName: selectedRow.firstName,
        lastName: selectedRow.lastName,
        type: selectedRow.type,
        validity: mapToGuestValidity(selectedRow.validity, StrToDateTimeLocal(selectedRow.visitDate)),
        isKeyPermissionAllowed: selectedRow.isKeyPermissionAllowed,
        daysOfWeek: selectedRow.daysOfWeek,
        startDate: StrToDate(selectedRow.startDate),
        endDate: StrToDate(selectedRow.endDate),
        visitDate: StrToDateTimeLocal(selectedRow.visitDate),
        restrictions: selectedRow.restrictions,
        companyName: selectedRow.companyName,
        addressId: residentStore.addressId!,
        residentId: residentStore.residentId!,
        carNumber: selectedRow?.carLicensePlates.find((i) => i.isPrimary)?.number,
        carState: selectedRow?.carLicensePlates.find((i) => i.isPrimary)?.state,
      };

      setModalInitData(initData);
    },
    onClose: (isSuccess, guest: IGuestRead) => {
      if (isSuccess) {
        const selectedRow: IGuestRead = tableControls.getSelectedRow();
        residentStore.updateGuest({ ...guest, isShared: selectedRow?.isShared });
        tableControls.updateSelectedRow({ ...guest, isShared: selectedRow?.isShared });
      }
    },
  });

  const addGuestModal = useModal({
    onBeforeShow: (setModalInitData) => {
      if (!residentStore.selectedResidentAddress) {
        console.error(`Resident is not selected`);
        return;
      }

      const defaultConfiguration = configurationStore.configuration?.default;

      const initData: IAddEditGuestModal = {
        id: null,
        firstName: '',
        lastName: '',
        type: defaultConfiguration?.guestType ?? null,
        validity: defaultConfiguration?.guestValidity ?? null,
        isKeyPermissionAllowed: false,
        daysOfWeek: [],
        startDate: null,
        endDate: null,
        restrictions: null,
        companyName: null,
        visitDate: null,
        addressId: residentStore.addressId!,
        residentId: residentStore.residentId!,
      };

      setModalInitData(initData);
    },
    onClose: (isSuccess, guest: IGuestRead) => {
      if (isSuccess) {
        residentStore.addGuest(guest);
      }
    },
  });

  const handleFileChange = (event: any) => {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    guestService
      .importGuests(residentStore.residentId!, residentStore.addressId!, event.target.files[0])
      .then((result) => {
        if (!result.importedCount) {
          notification.error({ message: 'Import failed. Please refer to CSV file requirements' });
          return;
        }
        notification.success({ message: 'Guests were imported successfully' });

        if (result?.skipped?.length) {
          notification.warning({ message: `${result.skipped.length} records were skipped` });
        }
        residentsService.getResidentDetails(residentStore.residentId!).then((data) => {
          residentStore.setResident(data);
        });
      })
      .catch((error) => {
        notification.error({ message: error.details[0].errors[0] });
      });
  };

  return (
    <div>
      <TableContextProvider controls={tableControls}>
        <div>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                adaptiveSearch={true}
                enableProgressiveSearch={true}
                placeholder="Search by first name, last name, company name"
                rulesForColumn={{
                  firstName: FilterTypesEnum.Contains,
                  lastName: FilterTypesEnum.Contains,
                  daysOfWeek: FilterTypesEnum.Contains,
                  companyName: FilterTypesEnum.Contains,
                }}
                rightSide={
                  <Space size={8}>
                    <Button
                      type="default"
                      onClick={() => {
                        inputRef.current?.click();
                      }}
                      icon={<ImportIcon />}
                      size={'middle'}
                      className="mobile-btn">
                      Import
                    </Button>
                    <ColumnCheckFilter name="type" options={guestTypeOptions} />
                    <Button
                      icon={<DeleteIcon stroke="#505762" />}
                      size="middle"
                      className="mobile-btn"
                      onClick={deleteGuestModal.showModal}>
                      Delete
                    </Button>
                    <Button icon={<EditIcon />} size="middle" onClick={editGuestModal?.showModal} className="mobile-btn">
                      Edit
                    </Button>
                    <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={addGuestModal?.showModal} className="mobile-btn">
                      Add
                    </Button>
                  </Space>
                }
              />
            </Col>
          </Row>
          <TableBase selectFirstRowByDefault />
        </div>
      </TableContextProvider>
      <AddEditGuestModal
        isEdit={true}
        initData={editGuestModal?.initData}
        title="Edit"
        isOpen={editGuestModal?.isOpenModal}
        onClose={editGuestModal?.hideModal}
      />
      <AddEditGuestModal
        isEdit={false}
        initData={addGuestModal?.initData}
        title="Add"
        isOpen={addGuestModal?.isOpenModal}
        onClose={addGuestModal?.hideModal}
      />
      <ConfirmDeleteModal
        isEdit={false}
        initData={deleteGuestModal?.initData}
        title="Delete"
        isOpen={deleteGuestModal?.isOpenModal}
        onClose={deleteGuestModal?.hideModal}
      />
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        onClick={() => {
          inputRef.current.value = null;
        }}
      />
    </div>
  );
});
