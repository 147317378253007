import { Tag } from 'antd';
import { ICellRendererProps } from 'common/components/table/table.types';
import { ownershipTypeStatusColorMap, ownershipTypeStatusMap } from 'common/constans/common.constants';
import { formatAddressLabel } from 'common/helpers/address.helper';
import { formatPhone } from 'common/helpers/phone.helper';
import { IResidentAddressRead, IResidentPhone } from 'common/services/residents-service/residents.service.types';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import React from 'react';
import BaseCellText from '../components/table/base-cell-text';

export const residentAddressesColumns = () => ({
  firstName: {
    key: 'resident_firstName',
    title: 'First name',
    dataKey: 'resident.firstName',
    minWidth: 130,
    width: 150,
    resizable: true,
    sortable: true,
  },
  lastName: {
    key: 'resident_lastName',
    title: 'Last name',
    dataKey: 'resident.lastName',
    minWidth: 130,
    width: 150,
    resizable: true,
    sortable: true,
  },
  phones: {
    key: 'resident_phones_number',
    title: 'Phone',
    dataKey: 'resident.phones',
    width: 200,
    minWidth: 110,
    fixed: 1,
    resizable: true,
    sortable: false, // enable sorting for this column
    cellRenderer: ({ cellData }: { cellData: IResidentPhone[] }) => {
      const phone = cellData?.find((phone) => phone.label === 'Primary')?.number ?? '';
      return <BaseCellText>{formatPhone(phone)}</BaseCellText>;
    },
  },
  pin: {
    key: 'resident_pin',
    title: 'PIN',
    dataKey: 'resident.pin',
    width: 100,
    minWidth: 100,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  clubId: {
    key: 'resident_clubId',
    title: 'Club ID',
    dataKey: 'resident.clubId',
    minWidth: 105,
    width: 150,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  altPhones: {
    key: 'phones1',
    title: 'Alternate phone numbers',
    dataKey: 'resident.phones',
    minWidth: 195,
    width: 300,
    cellRenderer: ({ cellData }: any) => cellData.map((phone: IResidentPhone) => formatPhone(phone.number)).join(','),
    hidden: true,
    sortable: false,
    resizable: true,
    fixed: 1,
  },
  email: {
    key: 'resident_email',
    title: 'Email',
    dataKey: 'resident.email',
    minWidth: 95,
    width: 200,
    hidden: true,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  type: {
    key: 'ownershipType',
    title: 'Type',
    dataKey: 'ownershipType',
    width: 150,
    minWidth: 100,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<IResidentAddressRead>) => {
      return (
        cell?.rowData?.ownershipType && (
          <Tag className="eiq-tag" color={ownershipTypeStatusColorMap[getOwnerShipTypeToMap(cell.rowData)]} style={{ color: '#292B2E' }}>
            {ownershipTypeStatusMap[cell?.rowData?.ownershipType]}
          </Tag>
        )
      );
    },
  },
  streetName: {
    key: 'address_street_name',
    title: 'Street name',
    dataKey: 'address.street.name',
    minWidth: 150,
    width: 200,
    resizable: true,
    sortable: true,
  },
  streetNumber: {
    key: 'address_streetNumber',
    title: 'Street number',
    dataKey: 'address.streetNumber',
    minWidth: 150,
    width: 200,
    resizable: true,
    sortable: true,
  },
  addressLabel: {
    key: 'addressLabel',
    title: 'Address details',
    dataKey: 'address',
    minWidth: 110,
    width: 350,
    resizable: true,
    sortable: false,
    cellRenderer: ({ cellData }: any) => formatAddressLabel(cellData, { hideStreet: true }),
  },
  community: {
    key: 'address_street_community_name',
    title: 'Community',
    dataKey: 'address.street.community.name',
    minWidth: 150,
    width: 150,
    resizable: true,
    sortable: true,
  },
});

const getOwnerShipTypeToMap = (residentAddress: IResidentAddressRead) => {
  if (residentAddress.ownershipType === ResidentTypesEnum.Renter || residentAddress.relatedTo?.ownershipType === ResidentTypesEnum.Renter) {
    return ResidentTypesEnum.Renter;
  }
  return residentAddress.ownershipType;
};
