import { Row, Space, Button } from 'antd';
import { IIncidentEditModeActionsProps } from './incident-edit-mode-actions.types';
import { SaveOutlined } from '@ant-design/icons';
import React from 'react';

const IncidentEditModeActions = ({ onCancel }: IIncidentEditModeActionsProps) => {
  return (
    <Row className="incidentEditModeActions" justify="start">
      <Space size={12}>
        <Button htmlType="submit" type="primary" icon={<SaveOutlined />}>
          Save
        </Button>
        <Button type="link" onClick={onCancel}>
          Cancel
        </Button>
      </Space>
    </Row>
  );
};

export default IncidentEditModeActions;
