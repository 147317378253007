import { Card, Col, Row, Space } from 'antd';
import React from 'react';
import { TableContextProvider, useTableControls } from 'common/components/table/table-context';
import TableBase from 'common/components/table/table-base';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { keysInitialColumns } from './keys-list.config';
import addressesKeyService from '../../../../common/services/addresses-key-service/addresses-key.service';
import { ColumnVisibility } from '../../../../common/components/table/filters/column-visibility/column-visibility';

export const Keys = () => {
  const tableControls = useTableControls({
    defaultSort: {
      key: 'issueTimestamp',
      order: 'desc',
    },
    onFetch: addressesKeyService.getKeysActivities,
    initialColumns: keysInitialColumns,
  });

  return (
    <div className="eiq-secure-keys-page">
      <Row gutter={16}>
        <Col flex="auto">
          <Card className="eiq-card eiq-card-border" style={{ width: '100%' }}>
            <Row justify="space-between" className="content-title">
              <Space size={12}>
                <h2 className="main-title">Keys</h2>
              </Space>
            </Row>

            <TableContextProvider controls={tableControls}>
              <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
                <Col flex="auto">
                  <SearchFilter
                    placeholder="Search by name, number, address"
                    rulesForColumn={{
                      keyNumber: FilterTypesEnum.Contains,
                      address: FilterTypesEnum.Contains,
                      guestName: FilterTypesEnum.Contains,
                      issueUserName: FilterTypesEnum.Contains,
                    }}
                  />
                </Col>
                <Col>
                  <ColumnVisibility contentClass={'so-dropdown'} />
                </Col>
              </Row>
              <div style={{ height: 'calc(100vh - 208px)', overflowY: 'auto' }}>
                <TableBase />
              </div>
            </TableContextProvider>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
