import './addresses.scss';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Layout, Menu, MenuProps } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
const { Sider, Content } = Layout;

export const Addresses = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const addressesSiderItems: MenuProps['items'] = [
    {
      label: 'Addresses',
      key: '/eiq-manage/addresses/addresses-management',
    },
    {
      label: 'Streets',
      key: '/eiq-manage/addresses/streets',
    },
    {
      label: 'Communities',
      key: '/eiq-manage/addresses/communities',
    },
    {
      label: 'Keys management',
      key: '/eiq-manage/addresses/keys-management',
    },
  ];

  return (
    <Layout hasSider className="addresses-layout">
      <Sider className="sider-wrapper">
        <Menu
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={[location.pathname]}
          items={addressesSiderItems}
          className="sider-tabs"
          onClick={(tab) => navigate(tab.key)}
        />
      </Sider>
      <Content className="content-style">
        <Outlet />
      </Content>
    </Layout>
  );
});
