import { Key } from './key/key';
import { HotkeyLink } from './hotkey-link/hotkey-link';
import { Clock } from './ clock/clock';

export type { KeyProps } from './key/key.types';
export type { HotkeyLinkProps } from './hotkey-link/hotkey-link.types';

export const SH = {
  Key,
  HotkeyLink: HotkeyLink,
  Clock,
};
