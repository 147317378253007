import { formatAddressLabel } from 'common/helpers/address.helper';
import { formatCustomDateTime } from 'common/helpers/date.helper';
import { IIncidentReportRead } from 'common/services/incident-report/incident-report.types';
import BaseCellText from '../../table/base-cell-text';
import { ICellRendererProps } from '../../table/table.types';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import dayjs from 'dayjs';
import { IFilterConfig } from '../../table/filters/column-filter/column-filter.types';
import configurationStore from 'common/stores/configuration/configuration.store';
import addressesService from 'common/services/addresses-service/addresses.service';

export const incidentReportColumns = () => ({
  caseNumber: {
    key: 'caseNumber',
    title: 'Incident#',
    dataKey: 'caseNumber',
    width: 200,
    minWidth: 100,
    resizable: true,
    sortable: true,
  },
  incidentDate: {
    key: 'incidentDate',
    title: 'Date & Time',
    dataKey: 'incidentDate',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<IIncidentReportRead>) => {
      return <BaseCellText>{formatCustomDateTime(cell?.rowData?.incidentDate, true)}</BaseCellText>;
    },
  },
  reportingPersion: {
    key: 'reportingPerson',
    title: 'Reported By',
    dataKey: 'reportingPerson',
    width: 200,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  category: {
    key: 'classification',
    title: 'Category',
    dataKey: 'classification',
    width: 150,
    sortable: false,
    resizable: true,
    cellRenderer: (cellData: any) => {
      const incident = cellData.rowData as IIncidentReportRead;

      if (incident.subCategory) {
        return `${incident.category} - ${incident.subCategory}`;
      } else {
        return incident.category;
      }
    },
  },
  community: {
    key: 'community',
    title: 'Community',
    dataKey: 'address',
    width: 180,
    sortable: true,
    resizable: true,
    cellRenderer: (cellData: any) => {
      const incident = cellData.rowData as IIncidentReportRead;
      return incident.address.street.community?.name;
    },
  },
  location: {
    key: 'location',
    title: 'Location',
    dataKey: 'address',
    width: 400,
    sortable: true,
    resizable: true,
    cellRenderer: (cellData: any) => {
      const incident = cellData.rowData as IIncidentReportRead;
      return formatAddressLabel(incident.address);
    },
  },
  description: {
    key: 'description',
    title: 'Description',
    dataKey: 'description',
    width: 500,
    sortable: false,
    resizable: true,
  },
});

export const incidentReportListFilter: IFilterConfig[] = [
  {
    name: 'category',
    filterType: FilterTypesEnum.Equals,
    label: 'Category',
    type: {
      options: (configurationStore.configuration?.incidentCategories || []).map((category) => ({
        label: category.name,
        value: category.name,
      })),
      type: 'SELECT',
    },
  },
  {
    name: 'incidentDate',
    filterType: FilterTypesEnum.Equals,
    label: 'Date Range',
    type: {
      nameEnd: 'incidentDate',
      type: 'DATE_RANGE',
    },
    onChange(filterValue, tableContext) {
      if (filterValue) {
        const getDayStart = (date: string) => dayjs(date).startOf('day').unix();
        const getDayEnd = (date: string) => dayjs(date).endOf('day').unix();

        tableContext.updateFilter(
          'incidentDate',
          [
            { operator: FilterTypesEnum.GreaterThanOrEqual, value: getDayStart(filterValue.start), innerFilterType: 'AND' },
            { operator: FilterTypesEnum.LessThanOrEqual, value: getDayEnd(filterValue.end) },
          ],
          'AND',
        );
      }
    },
  },
  {
    name: 'address_street_community_id',
    filterType: FilterTypesEnum.Equals,
    label: 'Community',
    type: {
      optionValue: 'id',
      optionLabel: 'name',
      searchField: 'label',
      onSearch: (searchFilter?: string, searchValue?: string) => {
        return addressesService.getCommunities(null, searchFilter, '+name');
      },
      type: 'SELECT_ASYNC',
    },
  },
];
