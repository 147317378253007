import { Col, Row } from 'antd';
import TableBase from 'common/components/table/table-base';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import React, { FC } from 'react';
import { ITableConfig } from './delete-modal-with-tables.types';
import { baseModalTableHeightConfig, calculateTableHeight } from '../table/table-helpers';

export const DeleteModalTable: FC<{ table: ITableConfig }> = ({ table }) => {
  const modifiedTable = {
    ...table,
    controls: useTableControls({
      clientSide: table.clientSide,
      data: table.data,
      initialColumns: table.initialColumns,
    }),
  };

  return (
    <TableContextProvider controls={modifiedTable.controls}>
      <Row>
        <Col span={24}>
          <h3 className="table-title">{modifiedTable.title}</h3>
        </Col>
      </Row>
      <Row
        className="table-wrapper"
        style={{
          height: calculateTableHeight({ ...baseModalTableHeightConfig, tableDataLength: modifiedTable.controls.dataState.data.length }),
        }}>
        <TableBase disableSelection />
      </Row>
    </TableContextProvider>
  );
};
