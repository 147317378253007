import { ServerClient } from 'common/utils/http/http-clients';
import { IAddress, IStreetResponse } from './street.service.types';
import { IParamsLimit } from '../../models/params-limit.interface';
import { SelectProps } from 'antd';

class StreetService {
  async getStreets(filter?: string | null | undefined, pageLimit?: IParamsLimit): Promise<IStreetResponse> {
    const res = await ServerClient.get<IStreetResponse>('/streets', {
      params: {
        filter,
        ...pageLimit,
      },
    });
    return res?.data;
  }

  async getStreetOptions(filter?: string | null | undefined, pageLimit?: IParamsLimit): Promise<SelectProps['options']> {
    const rest = await this.getStreets(filter, pageLimit);
    return rest.items
      .map((street) => ({ label: street.name, value: street.id }))
      .filter((option, index, self) => index === self.findIndex((o) => o.value === option.value && o.label === option.label));
  }

  async getAddresses(streetId: number | string): Promise<IAddress[]> {
    const res = await ServerClient.get<IAddress[]>(`/streets/${streetId}/addresses`);
    return res?.data;
  }
}

export const streetService = new StreetService();
export default streetService;
