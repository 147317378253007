import { ITableHeightConfig } from './table.types';
import userPersonalizationStore from 'common/stores/user-personalization/user-personalization.store';

export const calculateTableHeight = (tableHeightConfig: ITableHeightConfig) => {
  const { tableDataLength, maxTableHeight, tableRowHeight, maxRowsInTable } = tableHeightConfig;
  return tableDataLength >= maxRowsInTable
    ? `${maxTableHeight}px`
    : `${maxTableHeight + (tableDataLength - maxRowsInTable) * tableRowHeight}px`;
};

export const baseModalTableHeightConfig: ITableHeightConfig = {
  tableDataLength: 0,
  maxTableHeight: 192,
  tableRowHeight: 32,
  maxRowsInTable: 5,
};

export const filterColumnsByPersonalization = (columns: any[], tableName: string) => {
  if (!userPersonalizationStore?.personalization?.tableColumns?.[tableName]) {
    return columns;
  }

  columns.forEach((c) => {
    c.hidden = !userPersonalizationStore.personalization?.tableColumns[tableName].includes(c.key);
  });

  return columns;
};
