import { GuestType } from 'common/enums/guest-type.enum';

export const isServiceGuest = (guestType: GuestType | null | undefined) => {
  return (
    guestType === GuestType.Service ||
    guestType === GuestType.Delivery ||
    guestType === GuestType.RideShare ||
    guestType === GuestType.UrgentService
  );
};
