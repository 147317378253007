import { Button, Modal, Space, notification } from 'antd';
import React, { FC } from 'react';
import { IModal } from '../../../../common/models/model.interface';

const RefreshModal: FC<IModal<any, any>> = ({ isOpen, onClose, title }) => {
  const hideModal = (...rest: any) => {
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };

  const handleStartOver = () => {
    hideModal(true);
  };

  return (
    <Modal centered title={title} width={362} open={isOpen} wrapClassName="custom-modal custom-modal--so" closable={false}>
      <Space className="full-width mb-12" size={8} direction="vertical">
        <span>Thank you. Your registration is completed.</span>
      </Space>
      <Space className="footer">
        <Button className="eiq-button" type="link" onClick={() => hideModal(false)}>
          Cancel
        </Button>
        <Button className="eiq-button" type="primary" onClick={handleStartOver}>
          Ok
        </Button>
      </Space>
    </Modal>
  );
};

export default React.memo(RefreshModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
