import { Row, Col, Select, Radio, Space, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import IncidentEditModeActions from '../../incident-edit-mode-actions/incident-edit-mode-actions';
import { IIncidentDetailsEditProps, IIncidentDetailsFormValues, requiredOptions, weatherConditions } from './incident-details-edit.types';
import configurationStore from 'common/stores/configuration/configuration.store';
import { useMemo, useState, useCallback } from 'react';
import { incidentDetailsFormRules } from './incident-details-edit.validation';
import { IIncidentReportRead } from 'common/services/incident-report/incident-report.types';

const convertToFormValues = (incident: IIncidentReportRead | undefined): IIncidentDetailsFormValues | undefined => {
  if (!incident) {
    return undefined;
  }

  return {
    category: incident.category,
    subCategory: incident.subCategory,
    weatherCondition: incident.weatherCondition,
    isEmsResponseRequired: incident.isEmsResponseRequired,
    isFireDepartmentResponseRequired: incident.isFireDepartmentResponseRequired,
    isPoliceDepartmentResponseRequired: incident.isPoliceDepartmentResponseRequired,
    description: incident.description,
  };
};

const IncidentDetailsEdit = ({ incident, onSave, onCancel, form }: IIncidentDetailsEditProps) => {
  const categories = useMemo(() => configurationStore.configuration?.incidentCategories || [], []);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(incident?.category);

  const categoriesOptions = categories.map((category) => ({ label: category.name, value: category.name }));
  const weatherConditionOptions = weatherConditions.map((c) => ({ label: c, value: c }));

  const getSubCategories = useCallback(() => {
    const category = categories.find((category) => category.name === selectedCategory);
    return (category?.subCategories || []).map((subCategory) => ({ label: subCategory, value: subCategory }));
  }, [selectedCategory, categories]);

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleCategoryChange = (value: any) => {
    form.setFieldsValue({ subCategory: undefined });
    setSelectedCategory(value);
  };

  const initialValues = convertToFormValues(incident) || {
    isEmsResponseRequired: true,
    isFireDepartmentResponseRequired: true,
    isPoliceDepartmentResponseRequired: true,
  };

  return (
    <FormWrapper form={form} autoComplete="false" layout="vertical" onFinish={onSave} initialValues={initialValues}>
      <Row gutter={16}>
        <Col xs={24} lg={8}>
          <Form.Item name="category" label="Category" rules={incidentDetailsFormRules.category}>
            <Select placeholder="Select classification" options={categoriesOptions} onChange={handleCategoryChange} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            name="subCategory"
            label="Sub Category"
            rules={getSubCategories().length ? incidentDetailsFormRules.subCategory : undefined}>
            <Select placeholder="Select sub-incident classification" options={getSubCategories()} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item name="weatherCondition" label="Weather Condition">
            <Select placeholder="Select wheather condition" options={weatherConditionOptions} allowClear={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} lg={8}>
          <Form.Item name="isEmsResponseRequired" label="EMS response required?">
            <Radio.Group name="isEmsResponseRequired" className="vertical-radio-group" options={requiredOptions} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item name="isFireDepartmentResponseRequired" label="Fire department response required?">
            <Radio.Group name="isFireDepartmentResponseRequired" className="vertical-radio-group" options={requiredOptions} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item name="isPoliceDepartmentResponseRequired" label="Police department response required">
            <Radio.Group name="isPoliceDepartmentResponseRequired" className="vertical-radio-group" options={requiredOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Space className="full-width">
        <Form.Item name="description" label="Report narrative" rules={incidentDetailsFormRules.description}>
          <TextArea className="incident-description" showCount placeholder="Describe the problem" maxLength={1000} />
        </Form.Item>
      </Space>
      {incident && <IncidentEditModeActions onCancel={handleCancel} />}
    </FormWrapper>
  );
};

export default IncidentDetailsEdit;
