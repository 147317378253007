import React, { useEffect } from 'react';
import classes from './spinner.module.scss';
import { usePromiseTracker } from 'react-promise-tracker';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function Spinner() {
  useEffect(() => {
    let allElementsAtPage = document.querySelectorAll('body > #root > *');
    allElementsAtPage.forEach((node) => {
      let children = node.querySelectorAll('*');
      promiseInProgress ? node.setAttribute('tabindex', '-1') : node.removeAttribute('tabindex');
      children.forEach((element: any) => {
        promiseInProgress ? element.setAttribute('tabindex', '-1') : element.removeAttribute('tabindex');
      });
    });
  });

  const { promiseInProgress } = usePromiseTracker();

  return (
    <div>
      {promiseInProgress && (
        <div className={classes.mainSpinnerWrapper}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 32, color: '#AEB5BE' }} spin />} />
        </div>
      )}
    </div>
  );
}

export default Spinner;
