import { FormInstance } from 'antd';
import { IIncidentReportRead } from 'common/services/incident-report/incident-report.types';

export const weatherConditions = ['Clear', 'Lightning', 'Poor', 'Rain'];

export const requiredOptions = [
  {
    label: 'Required',
    value: true,
  },
  {
    label: 'Not required',
    value: false,
  },
];

export interface IIncidentDetailsFormValues {
  category: string;
  subCategory: string | undefined;
  weatherCondition: string;
  isEmsResponseRequired: boolean;
  isFireDepartmentResponseRequired: boolean;
  isPoliceDepartmentResponseRequired: boolean;
  description: string;
}

export interface IIncidentDetailsEditProps {
  onSave: (values: IIncidentDetailsFormValues) => void;
  onCancel?: () => void;
  form: FormInstance;
  incident?: IIncidentReportRead;
}
