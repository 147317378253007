import { Button } from 'antd';
import React, { FC } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import styles from './notifications.module.scss';
import { useTableContext } from 'common/components/table/table-context';
import EiqEmail from 'common/components/info-block/contacts/email/email-field';
import EiqPhone from 'common/components/info-block/contacts/phone/phone-field';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import EditNotificationsModal from './edit-notifications-modal/edit-notification-modal';
import { IEditNotifications, IEditNotificationsPhone } from 'common/models/notifications.interface';
import { IResidentAddressRead } from 'common/services/residents-service/residents.service.types';
import { observer } from 'mobx-react-lite';
import { NotificationType } from 'common/enums/notification-type.enum';
import notificationService from 'common/services/notification-service/notification-service';

const Notifications: FC = observer(() => {
  const context = useTableContext();
  const selectedRow = context.getSelectedRow() as IResidentAddressRead;

  const editNotificationModal = useModal({
    onBeforeShow: (setModalInitData) => {
      if (!selectedRow?.resident) {
        return;
      }

      const resident = selectedRow.resident;

      const data = {
        id: resident.id,
        firstName: resident.firstName,
        lastName: resident.lastName,
        phones: resident.phones,
        email: resident.email,
        notificationChannels: resident.notificationChannels,
        isNotificationEnabled: notificationService.getPreferredNotificationType(selectedRow.resident.notificationChannels),
      } as IEditNotifications;
      setModalInitData(data);
    },
    onClose: (isSuccess, updatedRow) => {
      if (isSuccess) {
        const residentToUpdate = {
          ...selectedRow.resident,
          phones: updatedRow.phones,
          email: updatedRow.email,
          notificationChannels: updatedRow.notificationChannels,
        };

        context.updateSelectedRow({ ...selectedRow, resident: residentToUpdate });
      }
    },
  });

  const preferredNotificationType = notificationService.getPreferredNotificationType(selectedRow.resident.notificationChannels);

  return selectedRow ? (
    <>
      <div className={styles.notificationContainer}>
        <Button icon={<EditIcon />} size="middle" className={styles.editButton} onClick={editNotificationModal.showModal}>
          Edit
        </Button>

        <div className={styles.contacts}>
          {preferredNotificationType === NotificationType.SMS ? (
            <div className={styles.phoneContainer}>
              <span className={styles.title}>SMS</span>
              {selectedRow.resident.phones
                ?.filter((item: IEditNotificationsPhone) => item.isNotificationEnabled)
                .map((item: IEditNotificationsPhone, index: number) => {
                  return (
                    <EiqPhone
                      phone={item.number}
                      labels={[{ value: item.label, class: item.label.toLocaleLowerCase() }]}
                      key={`notification-phone-${index}`}
                    />
                  );
                })}
            </div>
          ) : preferredNotificationType === NotificationType.Email ? (
            <div className={styles.emailField}>
              <span className={styles.title}>Email</span>
              <EiqEmail email={selectedRow.resident.email} />
            </div>
          ) : preferredNotificationType === NotificationType.Push ? (
            <span className={styles.title}>Push notification</span>
          ) : (
            <span className={styles.title}>Do not disturb</span>
          )}
        </div>
      </div>

      <EditNotificationsModal
        title={'Edit'}
        isEdit={true}
        isOpen={editNotificationModal?.isOpenModal}
        onClose={editNotificationModal?.hideModal}
        initData={editNotificationModal?.initData}
      />
    </>
  ) : null;
});

export default Notifications;
