import React, { FC, Fragment } from 'react';
import { Button, Modal, Space, notification } from 'antd';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { IModal } from 'common/models/model.interface';
import Item from 'antd/es/list/Item';
import { IDeleteModalWithTablesInitData } from './delete-modal-with-tables.types';
import { DeleteModalTable } from './delete-modal-table';

import './delete-modal-with-tables.scss';

const DeleteModalWithTables: FC<IModal<IDeleteModalWithTablesInitData, any>> = ({ isOpen, onClose, title, initData }) => {
  const hideModal = (isSuccess: boolean) => {
    notification.destroy();
    if (onClose) {
      onClose(isSuccess);
    }
  };

  return (
    <Modal centered title={title} width={900} open={isOpen} wrapClassName="custom-modal delete-modal-with-tables" closable={false}>
      <Space className="full-width" size={13}>
        {initData?.modalLabels?.map((label, i) => (
          <Item key={`deleteModalLabel-${i}`}>
            <p className="street-label">{label.title}</p>
            <p className="street-name">{label.value}</p>
          </Item>
        ))}
      </Space>
      {initData?.tablesConfigs?.map((table: any, i: number) => (
        <Fragment key={`deleteModalTable-${i}`}>{table.data.length > 0 && <DeleteModalTable table={table} />}</Fragment>
      ))}
      <Space className="footer">
        <Button type="link" onClick={() => hideModal(false)} className="button">
          Cancel
        </Button>
        <Button type="primary" onClick={initData?.onDeleteClick} className="button" icon={<DeleteIcon stroke="#F6F8FA" />}>
          Delete
        </Button>
      </Space>
    </Modal>
  );
};

export default React.memo(DeleteModalWithTables, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
