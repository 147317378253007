import { ReactNode, useEffect } from 'react';
import Portal from '../portal/portal';

interface FooterProps {
  children: ReactNode;
  className?: string;
}

const Footer = ({ children, className }: FooterProps) => {
  useEffect(() => {
    const footer: HTMLDivElement | null = document.querySelector('#footer-portal');
    if (footer) {
      footer.style.display = children ? 'flex' : 'none';
    }

    return () => {
      if (footer) {
        footer.style.display = 'none';
      }
    };
  }, [children]);

  return (
    <Portal selector="#footer-portal">
      <div className={`footer-content ${className ?? ''}`}>{children}</div>
    </Portal>
  );
};

export default Footer;
