import { Row, Space, Button } from 'antd';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import React from 'react';

export const IncidentEditButton = ({ onEdit }: { onEdit: () => void }) => {
  return (
    <Row justify="start" style={{ marginTop: '16px' }}>
      <Space size="middle">
        <Button icon={<EditIcon />} onClick={onEdit}>
          Edit
        </Button>
      </Space>
    </Row>
  );
};
