import React, { FC } from 'react';
import { IInfoField } from './info-field.types';
import './info-field.scss';

const InfoField: FC<IInfoField> = ({ value, label, boldValue, valueIcon, valueClassName, bodyClassName }) => {
  return (
    <div className={`info-field ${bodyClassName ?? ''}`}>
      <span className="info-field-lable">{label}</span>
      <div className={`info-field-value ${boldValue ? 'info-field-bold' : ''}`}>
        {valueIcon && <span>{valueIcon}</span>} <span className={valueClassName ?? ''}>{value}</span>
      </div>
    </div>
  );
};

export default InfoField;
