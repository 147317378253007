import React, { useState } from 'react';
import { Button, Carousel, Modal, Space } from 'antd';
import './citations-images.scss';

interface CitationImagesProps {
  photosUrl: string[];
}

export const CitationImages: React.FC<CitationImagesProps> = ({ photosUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <span className="open-citations-btn" onClick={() => setIsOpen(true)}>
        View Images
      </span>
      <Modal centered title="Citation Images" width={500} open={isOpen} wrapClassName="custom-modal" closable={false}>
        <Carousel>
          {photosUrl.map((url: string, index: number) => (
            <div key={`citation-image-${index}`}>
              <img className="citation-image" src={url} />
            </div>
          ))}
        </Carousel>
        <Space className="footer">
          <Button type="link" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </Space>
      </Modal>
    </>
  );
};
