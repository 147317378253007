import React from 'react';

export const communitiesColumns = () => ({
  name: {
    key: 'name',
    title: 'Community',
    dataKey: 'name',
    sortable: true,
    width: 600,
    minWidth: 200,
    resizable: true,
    fixed: 1,
  },
  description: {
    key: 'description',
    title: 'Description',
    dataKey: 'description',
    sortable: true,
    width: 600,
    minWidth: 200,
    resizable: true,
    fixed: 1,
  },
});
