import { Row, Col } from 'antd';
import InfoField from 'common/components/info-block/info-field/info-field';
import { IIncidentInvolvedPersonViewProps } from './incident-involved-person-view.types';
import React from 'react';
import IncidentViewModeActions from '../../incident-view-mode-actions/incident-view-mode-actions';

export const IncidentInvolvedPersonView = ({ person, onDelete, onEdit }: IIncidentInvolvedPersonViewProps) => {
  return (
    <div style={{ border: '1px solid #d9d9d9', borderRadius: '8px', padding: '16px' }}>
      <Row gutter={16}>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 8, order: 1 }}>
          <InfoField value={person.firstName} label="First Name" />
        </Col>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 8, order: 4 }}>
          <InfoField value={person.lastName} label="Last Name" />
        </Col>
        <Col xs={{ span: 24, order: 3 }} lg={{ span: 24, order: 7 }}>
          <InfoField value={person.involvementType} label="Type of Involvement" />
        </Col>
        <Col xs={{ span: 24, order: 4 }} lg={{ span: 8, order: 2 }}>
          <InfoField value={person.phoneNumber} label="Phone Number" />
        </Col>
        <Col xs={{ span: 24, order: 5 }} lg={{ span: 8, order: 5 }}>
          <InfoField value={person.streetName} label="Street Name" />
        </Col>
        <Col xs={{ span: 24, order: 6 }} lg={{ span: 8, order: 3 }}>
          <InfoField value={person.streetNumber} label="Street Number" />
        </Col>
        <Col xs={{ span: 24, order: 7 }} lg={{ span: 8, order: 6 }}>
          <InfoField value={person.address || ''} label="Address Details" />
        </Col>
      </Row>
      <IncidentViewModeActions onEdit={onEdit} onDelete={onDelete} />
    </div>
  );
};
