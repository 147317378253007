import React from 'react';
import { Button, Form, Input } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ISetNewPasswordForm } from './set-new-password.types';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { setNewPasswordValidation } from '../../validation/login.validation';
import { loginService } from '../../service/login.service';

export const SetNewPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async ({ password }: ISetNewPasswordForm) => {
    const token = searchParams.get('token') ?? '';
    await loginService.confirmPassword(password, token);
    navigate('/password-reset');
  };

  return (
    <FormWrapper onFinish={onFinish} form={form} name="validateOnly" layout="vertical" autoComplete="on" initialValues={{ remember: true }}>
      <div className="title">Set new password</div>
      <span className="text ant-mb-32">
        Please set a new password. It must be different from the previous ones and have at least 8 characters.
      </span>

      <Form.Item name="password" label="Password" rules={setNewPasswordValidation.password}>
        <Input.Password name="password" placeholder="Enter password" />
      </Form.Item>

      <Form.Item
        className="ant-mb-32"
        name="password2"
        label="Password"
        dependencies={['password']}
        rules={setNewPasswordValidation.password2}>
        <Input.Password name="password" placeholder="Enter password" />
      </Form.Item>

      <div className="action-line">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />} style={{ width: '216px' }}>
          Set new password
        </Button>
      </div>
    </FormWrapper>
  );
};
