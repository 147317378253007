import { Button, Form, Input, Modal, Select, SelectProps, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { IStreetForm } from 'features/eiq-manage/pages/eiq-manage-addresses/addresses.type';
import { IAddStreetRequestBody } from 'common/services/addresses-service/addresses.service.types';
import addressesService from 'common/services/addresses-service/addresses.service';
import { notification } from 'common/utils/notification';
import './street-add-edit-modal.scss';
import { addEditStreetValidation } from './street-add-edit-modal.validation';

const initialValues = {
  name: '',
  community: '',
};

const StreetAddEditModal = ({ isOpen, onClose, isEdit, title, extraData, initState }: any) => {
  const [form] = Form.useForm();
  const [community, setCommunity] = useState<any>(initState?.community);
  const [communitiesOptions, setCommunitiesOptions] = useState<SelectProps['options']>([]);

  useEffect(() => {
    setCommunity(initState?.community);
  }, [initState?.community]);

  const onFinish = async (values: IStreetForm) => {
    const body: IAddStreetRequestBody = {
      communityId: community.value,
      name: values.name,
    };
    if (isEdit) {
      const res = await addressesService.updateStreet(initState.id, body);
      hideModal(true, res);
    } else {
      const res = await addressesService.addStreet(body);
      hideModal(true, res);
    }
  };

  const handleCommunitiesSearch = (newValue: string) => {
    addressesService
      .getCommunitiesOptions(newValue ? `name:*` + newValue : null)
      .then((options) => {
        setCommunitiesOptions(options);
      })
      .catch((e) => {
        setCommunity(undefined);
        setCommunitiesOptions([]);
        notification.error({
          message: 'Can not fetch street list',
        });
        console.log(e);
      })
      .finally(() => {});
  };

  const handleCommunityChange = (newCommunity: any) => {
    setCommunity(newCommunity);
  };

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initState);
    }
  }, [isOpen, form, initState]);

  const hideModal = (...rest: any) => {
    form.resetFields();
    notification.destroy();
    setCommunity(undefined);
    if (onClose) {
      onClose(...rest);
    }
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper
        form={form}
        onFinish={onFinish}
        name={`streetForm+${title}`}
        layout="vertical"
        autoComplete="on"
        initialValues={initState ?? initialValues}>
        <Space className="full-width" size={13}>
          <Form.Item name="name" label="Street name" rules={addEditStreetValidation.name}>
            <Input name="name" placeholder="Enter street name" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="community" className="eiq-select select-wrapper" label="Community" rules={addEditStreetValidation.community}>
            <Select
              value={community}
              defaultValue={community?.label}
              showArrow={false}
              labelInValue
              // menuItemSelectedIcon={<SearchIcon />}

              onDropdownVisibleChange={(isOpen) => isOpen && communitiesOptions?.length === 0 && handleCommunitiesSearch('')}
              placeholder=""
              options={communitiesOptions}
              onChange={(_id, community) => handleCommunityChange(community)}
            />
          </Form.Item>
        </Space>
        <Space className="footer">
          <Button key="cancel" type="link" onClick={() => hideModal()} style={{ width: '159px' }}>
            Cancel
          </Button>

          <Button key="streetForm" htmlType="submit" type="primary" style={{ width: '159px' }} icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(StreetAddEditModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
