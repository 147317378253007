import { Button, Col, Row, Space } from 'antd';
import { IIncidentInvolvedVehicle } from 'common/services/incident-report/incident-report.types';
import { IVehicleRead } from 'common/services/residents-service/residents.service.types';
import React, { useCallback } from 'react';
import { IIncidentInvolvedVehiclesProps } from './incident-involved-vehicles.types';
import { IncidentInvolvedVehicleSearch } from './incident-involved-vehicle-search/incident-involved-vehicle-search';
import { IncidentInvolvedVehicleEdit } from './incident-involved-vehicle-edit/incident-involved-vehicle-edit';
import { IncidentInvolvedVehicleView } from './incident-involved-vehicle-view/incident-involved-vehicle-view';
import { useIncidentInvolvedForm } from 'common/hooks/use-incident-involved-form/use-incident-involved-form.hook';
import { IInvolvedItem } from 'common/hooks/use-incident-involved-form/use-incident-involved-form.types';
import { PlusOutlined } from '@ant-design/icons';

const IncidentInvolvedVehicles = ({ onChange, initialValues }: IIncidentInvolvedVehiclesProps) => {
  const {
    items,
    addMode,
    currentSelectedItem,
    switchToSearchMode,
    switchToManualMode,
    cancelAddMode,
    handleSelectedItem,
    handleAdd,
    handleSaveEdit,
    changeItemMode,
    deleteItem,
  } = useIncidentInvolvedForm<IIncidentInvolvedVehicle, IVehicleRead>({ initialValues: initialValues || [], onChange, isRequired: false });

  const convertToInvolvedPerson = useCallback((): IIncidentInvolvedVehicle => {
    if (!currentSelectedItem) {
      throw new Error('No vehicle selected');
    }

    return {
      licenseNumber: currentSelectedItem.licenseNumber,
      licenseState: currentSelectedItem.licenseState,
    };
  }, [currentSelectedItem]);

  const renderAddSection = () => {
    switch (addMode) {
      case 'search':
        return <IncidentInvolvedVehicleSearch onSelected={handleSelectedItem} onEnterManuallyClick={() => switchToManualMode(null)} />;
      case 'manual':
        return (
          <IncidentInvolvedVehicleEdit
            initialValue={currentSelectedItem ? convertToInvolvedPerson() : undefined}
            onSave={handleAdd}
            onCancel={cancelAddMode}
          />
        );
      default:
        return (
          <Button className="secondary-btn" onClick={switchToSearchMode} icon={<PlusOutlined />}>
            Add Vehicle
          </Button>
        );
    }
  };

  const renderVehicleItem = (item: IInvolvedItem<IIncidentInvolvedVehicle>, index: number) => {
    if (item.mode === 'view') {
      return (
        <IncidentInvolvedVehicleView vehicle={item.item} onEdit={() => changeItemMode(index, 'edit')} onDelete={() => deleteItem(index)} />
      );
    }

    return (
      <IncidentInvolvedVehicleEdit
        initialValue={item.item}
        onSave={(updatedVehicle: IIncidentInvolvedVehicle) => handleSaveEdit(index, updatedVehicle)}
        onCancel={() => changeItemMode(index, 'view')}
      />
    );
  };

  return (
    <Space direction="vertical" size="middle" className="full-width">
      {items.length !== 0 && (
        <div>
          <Row gutter={[16, 16]}>
            {items.map((item, index) => (
              <Col xs={24} lg={6} key={index}>
                {renderVehicleItem(item, index)}
              </Col>
            ))}
          </Row>
        </div>
      )}
      <Row gutter={[16, 16]}>
        <Col xs={12}>{renderAddSection()}</Col>
      </Row>
    </Space>
  );
};

export default IncidentInvolvedVehicles;
