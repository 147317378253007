import { Tag } from 'antd';
import { ICellRendererProps } from 'common/components/table/table.types';
import { ownershipTypeStatusColorMap, ownershipTypeStatusMap } from 'common/constans/common.constants';
import { formatPhone } from 'common/helpers/phone.helper';
import { IResidentAddressRead, IResidentPhone } from 'common/services/residents-service/residents.service.types';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import React from 'react';

export const residentsColumns = () => ({
  firstName: {
    key: 'firstName',
    title: 'First name',
    dataKey: 'firstName',
    width: 300,
    minWidth: 150,
    resizable: true,
    sortable: true,
  },
  lastName: {
    key: 'lastName',
    title: 'Last name',
    dataKey: 'lastName',
    width: 300,
    minWidth: 150,
    resizable: true,
    sortable: true,
  },
  phones: {
    key: 'phones',
    title: 'Phone',
    dataKey: 'phones',
    width: 200,
    fixed: 1,
    // flexGrow: 1,
    //  flexShrink: 0,
    resizable: true,
    sortable: false, // enable sorting for this column
    //order: SortOrder.ASC,
    cellRenderer: ({ cellData }: { cellData: IResidentPhone[] }) =>
      // <div className={'hovered'} style={{ color: 'red' }}>
      //   {cellData.map((phone: IResidentPhone)=> phone.number ).join(',')}
      // </div>
      {
        const phone = cellData?.find((phone) => phone.label === 'Primary')?.number ?? '';
        return formatPhone(phone);
      },
    // sortMethod: (a: any, b: any, sortOrder: any) => (sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a)),
  },
  pin: {
    key: 'pin',
    title: 'PIN',
    dataKey: 'pin',
    width: 64,
    sortable: true,
    resizable: true,
    fixed: 1,
    //  flexGrow: 1,
    //flexShrink: 0,
  },
  clubId: {
    key: 'clubId',
    title: 'Club ID',
    dataKey: 'clubId',
    width: 150,
    sortable: true,
    resizable: true,
    fixed: 1,
    // flexGrow: 1,
    //flexShrink: 0,
  },
  altPhones: {
    key: 'phones1',
    title: 'Alternate phone numbers',
    dataKey: 'phones',
    width: 300,
    cellRenderer: ({ cellData }: any) => cellData.map((phone: IResidentPhone) => formatPhone(phone.number)).join(','),
    // <div className={'hovered'} style={{ color: 'red' }}>
    //   {cellData.map((phone: IResidentPhone)=> phone.number ).join(',')}
    // </div>
    // <div className={'hovered'}>{cellData.map((phone: IResidentPhone) => phone.number).join(',')}</div>
    hidden: true,
    sortable: true,
    resizable: true,
    fixed: 1,
    // flexGrow: 1,
    //flexShrink: 0,
  },
  email: {
    key: 'email',
    title: 'Email',
    dataKey: 'email',
    width: 200,
    hidden: true,
    sortable: true,
    resizable: true,
    fixed: 1,
    //flexGrow: 1,
    //flexShrink: 0,
  },
  type: {
    key: 'ownershipType',
    title: 'Type',
    dataKey: 'ownershipType',
    width: 300,
    minWidth: 150,
    resizable: true,
    sortable: true,
    cellRenderer: (cell: ICellRendererProps<IResidentAddressRead>) => {
      return (
        cell?.rowData?.ownershipType && (
          <Tag className="eiq-tag" color={ownershipTypeStatusColorMap[cell?.cellData as ResidentTypesEnum]} style={{ color: '#292B2E' }}>
            {ownershipTypeStatusMap[cell?.rowData?.ownershipType]}
          </Tag>
        )
      );
    },
  },
});
