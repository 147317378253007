import { ErrorStatus } from './input-time.types';

export const validateTimePeriod = (startTimeName: string, endTimeName: string, callBack: (status: ErrorStatus) => void) => {
  return ({ getFieldValue }: any) => ({
    async validator() {
      const start = getFieldValue(startTimeName);
      const end = getFieldValue(endTimeName);

      if (start && end) {
        const startDate = new Date(start);
        const endDate = new Date(end);

        startDate.setSeconds(0, 0);
        endDate.setSeconds(0, 0);

        if (startDate >= endDate) {
          callBack('error');
          return Promise.reject('End time should be greater than start');
        }
      }

      callBack('');
      return Promise.resolve();
    },
  });
};
