import dayjs, { Dayjs } from 'dayjs';
import { TIME_HH_mm_ss } from '../constans/date.constants';

export const timeOnlyToString = (time: Dayjs) => {
  if (!time) {
    return null;
  }

  const dateTime = dayjs(time);
  const hours = dateTime.hour();
  const minutes = dateTime.minute();
  const seconds = '00';

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${seconds}`;
};

export const stringToTimeOnly = (timeString?: string | null) => {
  if (!timeString) {
    return null;
  }

  return dayjs(timeString, TIME_HH_mm_ss);
};
