import React, { FC, useEffect } from 'react';
import { IModal } from 'common/models/model.interface';
import { Button, Form, Input, Modal, Space, notification } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import { IChangePassword } from 'features/eiq-manage/service/account-service/account.service.types';
import accountService from 'features/eiq-manage/service/account-service/account.service';
import { changePasswordValidation } from './change-password-modal.validation';

const ChangePasswordModal: FC<IModal<any, any>> = ({ isOpen, onClose }) => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const model: IChangePassword = {
      newPassword: values.newPassword,
      currentPassword: values.currentPassword,
    };

    accountService
      .changePassword(model)
      .then(() => {
        hideModal(true);
      })
      .catch((error) => {});
  };

  const hideModal = (...rest: any) => {
    form.resetFields();
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };

  return (
    <Modal centered title="Change password" width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} layout="vertical">
        <Space className="full-width" size={13}>
          <Form.Item name="currentPassword" label="Current password" rules={changePasswordValidation.currentPassword}>
            <Input.Password name="currentPassword" placeholder="Enter current password" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="newPassword" label="New password" rules={changePasswordValidation.newPassword}>
            <Input.Password name="newPassword" placeholder="Enter new password" />
          </Form.Item>
        </Space>
        <Space className="full-width" size={13}>
          <Form.Item name="confirmPassword" label="Confirm password" rules={changePasswordValidation.confirmPassword}>
            <Input.Password name="confirmPassword" placeholder="Enter new password one more time" />
          </Form.Item>
        </Space>
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className="eiq-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(ChangePasswordModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
