import { formatAddressLabel } from 'common/helpers/address.helper';
import { IGuestRead, IResidentRead } from 'common/services/residents-service/residents.service.types';
import { IAddress } from 'common/services/street-service/street.service.types';
import React, { forwardRef } from 'react';
import './eiq-secure-gust-license.scss';
import dayjs from 'dayjs';
import { DirectionTypeProps, GetDirectionIcon } from 'common/helpers/direction.helper';
import { ReactComponent as EIQSecureIcon } from 'assets/icons/eiq-secure-black.svg';
import EIQLogoIcon from 'assets/icons/entranceiq-logo-black.png';
import { ServerGuestValidity } from 'common/enums/guest-validity.enum';

interface IEiqSecureGuestLicensePrintInstructionProps {
  id?: string;
  address?: IAddress;
  steps?: google.maps.DirectionsResult | null;
  guestName?: string | null;
  guestLicense?: string | null;
  gate?: string | null;
  rules?: string | null;
  communityName: string;
  isMinimize?: boolean | null;
  guest?: IGuestRead | null;
}

const EiqSecureGuestLicensePrintInstruction = forwardRef(
  (
    {
      id,
      address,
      steps,
      communityName,
      gate,
      guestLicense,
      guestName,
      rules,
      isMinimize,
      guest,
    }: IEiqSecureGuestLicensePrintInstructionProps,
    ref: any,
  ) => {
    const getFirstAddressPart = (address?: string | null) => {
      if (!address) {
        return;
      }
      return address.split(',').slice(0, -3).join(',').trim();
    };

    const getSecondAddressPart = (address?: string | null) => {
      if (!address) {
        return;
      }
      return address.split(',').slice(-3).join(',').trim();
    };

    const getInstructions = (instructions?: string | null) => {
      if (!instructions) {
        return '';
      }
      const result = instructions.match(/<div.*?>/i);
      if (result) {
        const index = instructions.indexOf(result[0]);
        return instructions.substring(0, index);
      } else {
        return instructions;
      }
    };

    const getRestrictions = (instructions?: string | null) => {
      if (!instructions) {
        return '';
      }

      const result = instructions.match(/<div[^>]*>([^<]+)<\/div>/);
      return result ? result[0]?.replace(/<\/?[^>]+(>|$)/g, '') : '';
    };

    const getPassValidUntilSection = () => {
      if (guest?.validity === ServerGuestValidity.Permanent) {
        return (
          <>
            <span>Permanent Guest</span>
            <span className="days-of-week">{guest.daysOfWeek.map((i) => i.slice(0, 3)).join('-')}</span>
          </>
        );
      }
      if (guest?.validity === ServerGuestValidity.DateRange) {
        return (
          <>
            <span>{dayjs(guest.endDate).set('hour', 23).set('minute', 59).format('MM/DD/YYYY, hh:mm A')}</span>
          </>
        );
      }
      return (
        <>
          <span>{dayjs().format('MM/DD/YYYY,')} 11:59PM</span>
        </>
      );
    };

    return (
      <div className="license-print-instruction">
        <div id={id} className="license-print-instruction-content" ref={ref}>
          <div className="license-print-instruction-header">
            <div>
              <span>{communityName}</span>
              <span>Community</span>
            </div>
            <div>
              <span>Guest license plate#:</span>
              <span>{guestLicense}</span>
            </div>
          </div>
          <div className="license-print-instruction-body">
            <div>
              <div className="pass-valid-period">
                <span>Pass Valid Until:</span>
                {getPassValidUntilSection()}
              </div>
              <div className="field">
                <span className="name">Guest Name:</span>
                <span>{guestName}</span>
              </div>

              <div className="field">
                <span className="name">Visiting:</span>
                <span>{formatAddressLabel(address)}</span>
              </div>

              <div className="field">
                <span className="name">Pass Issued:</span>
                <span>{dayjs().format('MM/DD/YYYY, hh:mm A')}</span>
              </div>
              <div className="field">
                <span className="name">Issued at:</span>
                <span>{gate}</span>
              </div>
              <div className="rules">Rules</div>
              <div>{rules}</div>
            </div>
            <div>
              <div className="directions-title">Directions</div>
              <div className="directions-details">
                {steps && `Drive ${steps?.routes[0].legs[0].distance?.text} ${steps?.routes[0].legs[0].duration?.text}`}
              </div>
              <div className="restrictions">This route has restricted usage or private roads.</div>
              <div className="address1">{getFirstAddressPart(steps?.routes[0].legs[0].start_address)}</div>
              <div className="address2">{getSecondAddressPart(steps?.routes[0].legs[0].start_address)}</div>
              {steps?.routes[0].legs[0].steps.map((item, index) => {
                return (
                  <div key={`step-${index}`}>
                    {!isMinimize && <div className="step-restrictions">{getRestrictions(item.instructions)}</div>}
                    {!isMinimize && <div>{GetDirectionIcon(item.maneuver as DirectionTypeProps)}</div>}
                    <div
                      dangerouslySetInnerHTML={{ __html: getInstructions(item.instructions) }}
                      className={`instruction-step ${isMinimize ? 'minimize' : ''}`}></div>
                    <div className={`step-distance ${isMinimize ? 'minimize' : ''}`}>{item.distance?.text}</div>
                  </div>
                );
              })}
              <div className="address1">{getFirstAddressPart(steps?.routes[0].legs[0].end_address)}</div>
              <div className="address2">{getSecondAddressPart(steps?.routes[0].legs[0].end_address)}</div>
            </div>
          </div>
          <div className="license-print-instruction-footer">
            <div>
              <img className="logo" src={EIQLogoIcon} alt="logo" />
            </div>
            <div>
              <EIQSecureIcon />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default EiqSecureGuestLicensePrintInstruction;
