import './streets.scss';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { observer } from 'mobx-react-lite';
import { GeneralAddressesTableInitialColumns, StreetsAtStreetsTabInitialColumns } from '../../addresses.config';
import addressesService from 'common/services/addresses-service/addresses.service';
import { Button, Card, Col, Row, Space } from 'antd';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { ReactComponent as ImportIcon } from 'assets/icons/import.svg';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import TableBase from 'common/components/table/table-base';
import InfoBlock from 'common/components/info-block/info-block';
import EiqTabsTitleLeft from 'common/components/info-block/title-left/eiq-tabs-title-left';
import { TabsProps } from 'antd/lib';
import GeneralInfo from '../../components/info-block/general-info/general-info';
import EiqTabs from 'common/components/tabs/eiq-tabs';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import StreetAddEditModal from '../../components/street-add-edit-modal/street-add-edit-modal';
import { IAddressDetails, IAddressesResidentRead, IStreetRead } from 'common/services/addresses-service/addresses.service.types';
import { IStreetForm } from '../../addresses.type';
import StreetDeleteModal from '../../components/street-delete-modal/street-delete-modal';
import { useEffect } from 'react';
import addressesStore from 'features/eiq-manage/stores/addresses.store';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { DeleteModalVariantsEnum } from '../../components/info-block/general-info/general-info-delete-modal/delete-modal-variants.enum';
import {
  addressesInitialColumnsForDeleteModal,
  petsInitialColumnsForDeleteModal,
  residentInitialColumnsForDeleteModal,
  vehicleInitialColumnsForDeleteModal,
} from '../../components/info-block/general-info/general-info-delete-modal/delete-modal-tables.config';
import { notification } from 'common/utils/notification';
import DeleteModalWithTables from 'common/components/delete-modal-with-tables/delete-modal-with-tables';
import { IAccessCardRead, IPetRead, IVehicleRead } from 'common/services/residents-service/residents.service.types';
import { IDeleteModalWithTablesInitData } from 'common/components/delete-modal-with-tables/delete-modal-with-tables.types';
import { IAddress, IStreetDetails } from 'common/services/street-service/street.service.types';
import { accessCardColumns } from 'features/eiq-manage/pages/eiq-manage-residents/components/info-block/access-cards/access-card-list.comfig';
import featureStore from 'common/stores/features/feature.store';

export const Streets = observer(() => {
  const tableControls = useTableControls({
    onFetch: addressesService.getStreets,
    initialColumns: StreetsAtStreetsTabInitialColumns,
  });

  const generalInfoTableControls = useTableControls({
    clientSide: true,
    data: addressesStore.streetDetails?.addresses || [],
    initialColumns: GeneralAddressesTableInitialColumns,
    onSelectedRow: (selectedRow: IAddress) => {
      if (selectedRow) {
        addressesStore.setSelectedAddressId(selectedRow.id);
      }
    },
  });

  const getStreetAddresses = () => {
    const streetId = tableControls.getSelectedRow()?.id;
    if (streetId === null || streetId === undefined) {
      addressesStore.setStreetDetails(null);
      return;
    }

    addressesService.getStreetDetails(streetId).then((data: IStreetDetails) => {
      addressesStore.setStreetDetails(data);
    });
  };

  useEffect(() => {
    getStreetAddresses();
    generalInfoTableControls.setSelectedRow(null);
  }, [tableControls.getSelectedRow()?.id]);

  const addStreetModal = useModal({
    onClose: (isSuccessSaved) => isSuccessSaved && tableControls.refetchData(),
  });

  const editStreetModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();

      if (!selectedRow) {
        return;
      }

      const streetFromData: IStreetForm = transformStreetReadToForm(selectedRow);
      setModalInitData(streetFromData);
    },
    onClose: (isSuccessSaved, updatedRow) => {
      if (isSuccessSaved) {
        tableControls.updateSelectedRow(updatedRow);
      }
    },
  });

  const deleteStreetModal = useModal({
    onBeforeShow: (setModalInitData, deleteModalVariant) => {
      const selectedRow = tableControls.getSelectedRow();

      if (!selectedRow) {
        return;
      }
      const data = {
        id: selectedRow.id,
        name: selectedRow.name,
        deleteModalVariant,
      };
      setModalInitData(data);
    },
    onClose: (isSuccess, variant) => {
      if (isSuccess) {
        tableControls.setSelectedRow(null);
        tableControls.refetchData();
      }

      if (variant === DeleteModalVariantsEnum.WithEntities) {
        deleteStreetModalStepTwo.showModal();
      }
    },
  });

  const deleteStreet = () => {
    const selectedRow = tableControls.getSelectedRow();
    addressesService
      .deleteStreet(selectedRow.id)
      .then((_res) => {
        deleteStreetModalStepTwo.hideModal(true);
        notification.success({ message: `${selectedRow.number} ${selectedRow.name} street was successfully deleted` });
      })
      .catch((error) => {});
  };

  const deleteStreetModalStepTwo = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();
      const street = addressesStore.streetDetails;
      const addresses: IAddressDetails[] = street?.addresses ?? [];
      const residents: IAddressesResidentRead[] = addresses?.reduce((acc, curr) => acc.concat(curr.residents as any), []);
      const pets: IPetRead[] = residents?.reduce((acc, curr) => acc.concat(curr.pets as any), []);
      const vehicles: IVehicleRead[] = residents?.reduce((acc, curr) => acc.concat(curr.vehicles as any), []);
      const accessCards: IAccessCardRead[] = residents?.reduce((acc, curr) => acc.concat(curr.accessCards as any), []);

      let otherDevicesColumns = accessCardColumns;
      let vehiclesColumns = vehicleInitialColumnsForDeleteModal;

      if (!featureStore.isCA3000ExpirationsEnabled) {
        otherDevicesColumns = otherDevicesColumns.filter((i) => i.key !== 'validityPeriod');
        vehiclesColumns = vehiclesColumns.filter((i) => i.key !== 'validityPeriod');
      }
      if (!featureStore.isCA3000CardClassesEnabled) {
        otherDevicesColumns = otherDevicesColumns.filter((i) => i.key !== 'cardClass');
        vehiclesColumns = vehiclesColumns.filter((i) => i.key !== 'cardClass');
      }

      const data: IDeleteModalWithTablesInitData = {
        modalLabels: [
          { title: 'Address', value: `${selectedRow.name}` },
          { title: 'Community', value: selectedRow.community.name },
        ],
        onDeleteClick: deleteStreet,
        tablesConfigs: [
          {
            title: 'Addresses',
            clientSide: true,
            data: addresses,
            initialColumns: addressesInitialColumnsForDeleteModal,
          },
          {
            title: 'Residents',
            clientSide: true,
            data: residents,
            initialColumns: residentInitialColumnsForDeleteModal,
          },
          {
            title: 'Pets',
            clientSide: true,
            data: pets,
            initialColumns: petsInitialColumnsForDeleteModal,
          },
          {
            title: 'Vehicles',
            clientSide: true,
            data: vehicles,
            initialColumns: vehiclesColumns,
          },
        ],
      };

      if (featureStore.isCardAccessEnabled) {
        data.tablesConfigs.push({
          title: 'Other devices',
          clientSide: true,
          data: accessCards,
          initialColumns: otherDevicesColumns,
        });
      }

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        tableControls.setSelectedRow(null);
        tableControls.refetchData();
      }
    },
  });

  const items: TabsProps['items'] = [
    {
      key: 'Streets',
      label: '',
      children: <GeneralInfo selectedParentRow={tableControls.getSelectedRow()} getData={getStreetAddresses} />,
    },
  ];

  const onTabsChange = () => {
    const firstStreetAddressId = addressesStore.streetDetails?.addresses[0]?.id ?? null;

    if (addressesStore.streetDetails?.addresses?.length && addressesStore.selectedAddressId === null) {
      addressesStore.setSelectedAddressId(firstStreetAddressId);
    }
  };

  const onDeleteStreetButtonClick = () => {
    deleteStreetModal.showModal(
      addressesStore.streetDetails?.addresses?.length ? DeleteModalVariantsEnum.WithEntities : DeleteModalVariantsEnum.WithoutEntities,
    );
  };

  return (
    <div className="streets-page">
      <TableContextProvider controls={tableControls}>
        <Card className="eiq-card eiq-card-border">
          <Row className="eiq-card-header">
            <Col span={24}>
              <h1 className="h1">Streets</h1>
            </Col>
          </Row>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                placeholder="Search by street name, community"
                rulesForColumn={{
                  name: FilterTypesEnum.Contains,
                  community_name: FilterTypesEnum.Contains,
                }}
              />
            </Col>
            <Col>
              <Space size={8}>
                <Button type="default" disabled={true} icon={<ImportIcon />} size={'middle'}>
                  Import
                </Button>
                <Button icon={<DeleteIcon stroke="#505762" />} size="middle" onClick={onDeleteStreetButtonClick}>
                  Delete
                </Button>
                <Button icon={<EditIcon />} size="middle" onClick={editStreetModal.showModal}>
                  Edit
                </Button>
                <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={addStreetModal.showModal}>
                  Add
                </Button>
              </Space>
            </Col>
          </Row>
          <TableBase onSelected={tableControls.setSelectedRow} selectFirstRowByDefault />
          <StreetAddEditModal title="Add" isEdit={false} isOpen={addStreetModal.isOpenModal} onClose={addStreetModal.hideModal} />
          <StreetAddEditModal
            {...editStreetModal?.initData}
            title="Edit"
            isEdit={true}
            isOpen={editStreetModal?.isOpenModal}
            onClose={editStreetModal?.hideModal}
          />
        </Card>
      </TableContextProvider>
      <TableContextProvider controls={generalInfoTableControls}>
        <InfoBlock>
          <EiqTabs
            defaultActiveKey="Info"
            items={items}
            tabBarGutter={16}
            onChange={onTabsChange}
            left={<EiqTabsTitleLeft title={`${tableControls.getSelectedRow()?.name}, ${tableControls.getSelectedRow()?.community.name}`} />}
          />
        </InfoBlock>
      </TableContextProvider>
      <StreetDeleteModal
        initData={deleteStreetModal?.initData}
        title="Delete"
        isOpen={deleteStreetModal?.isOpenModal}
        onClose={deleteStreetModal?.hideModal}
      />
      <DeleteModalWithTables
        initData={deleteStreetModalStepTwo?.initData}
        title="Delete"
        isOpen={deleteStreetModalStepTwo?.isOpenModal}
        onClose={deleteStreetModalStepTwo.hideModal}
      />
    </div>
  );
});

export const transformStreetReadToForm = (readData: IStreetRead): any => {
  const { id, name, community } = readData;

  const form: IStreetForm = {
    id,
    name: name,
    community: {
      label: community?.name,
      value: community?.id,
    },
  };

  return {
    initState: form,
  };
};
