import { ICellRendererProps } from '../../../../common/components/table/table.types';
import BaseCellText from '../../../../common/components/table/base-cell-text';
import { IKeyActivitiesRead } from '../../../../common/services/addresses-key-service/addresses-key.service.types';
import { formatCustomDateTime } from '../../../../common/helpers/date.helper';

export const keysColumns = () => ({
  keyNumber: {
    key: 'keyNumber',
    title: 'Key #',
    dataKey: 'keyNumber',
    width: 100,
    resizable: true,
    sortable: true,
  },
  keyName: {
    key: 'keyName',
    title: 'Key name',
    dataKey: 'keyName',
    width: 128,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  address: {
    key: 'address',
    title: 'Address',
    dataKey: 'address',
    width: 161,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  guestName: {
    key: 'guestName',
    title: 'Guest name',
    dataKey: 'guestName',
    width: 173,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  guestType: {
    key: 'guestType',
    title: 'Guest type',
    dataKey: 'guestType',
    width: 161,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  residentName: {
    key: 'residentName',
    title: 'Resident name',
    dataKey: 'residentName',
    width: 200,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  gateOut: {
    key: 'collectGate',
    title: 'Gate out',
    dataKey: 'collectGate',
    width: 100,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  timeOut: {
    key: 'issueTimestamp',
    title: 'Time out',
    dataKey: 'issueTimestamp',
    width: 161,
    resizable: true,
    sortable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IKeyActivitiesRead>) => {
      return <BaseCellText>{formatCustomDateTime(cell?.rowData?.issueTimestamp, true)}</BaseCellText>;
    },
  },
  receivedBy: {
    key: 'collectUserName',
    title: 'Received by',
    dataKey: 'collectUserName',
    width: 160,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
  gateIn: {
    key: 'issueGate',
    title: 'Gate in',
    dataKey: 'issueGate',
    width: 109,
    sortable: true,
    resizable: true,
    fixed: 1,
  },
  timeIn: {
    key: 'collectTimestamp',
    title: 'Time in',
    dataKey: 'collectTimestamp',
    width: 167,
    resizable: true,
    sortable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IKeyActivitiesRead>) => {
      return <BaseCellText>{formatCustomDateTime(cell?.rowData?.collectTimestamp, true)}</BaseCellText>;
    },
  },
  givenBy: {
    key: 'issueUserName',
    title: 'Given by',
    dataKey: 'issueUserName',
    width: 129,
    fixed: 1,
    resizable: true,
    sortable: true,
  },
});

const { keyNumber, keyName, address, guestType, residentName, guestName, timeOut, receivedBy, gateIn, timeIn, givenBy } = keysColumns();

export const keysInitialColumns = [
  keyNumber,
  keyName,
  address,
  residentName,
  guestName,
  guestType,
  timeOut,
  givenBy,
  timeIn,
  gateIn,
  receivedBy,
];
